import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { Repository } from '../../../services/repository'



@Component({
  selector: 'bong-approvers',
  template: `
       <a class="btn-success btn btn-oval" style="margin:5px;" [hidden]="status !== 0">{{ trang }}</a>
        <a class="btn-warning btn btn-oval" style="margin:5px;" [hidden]="status !==1">{{ trang }}</a>
        <a class="btn-primary btn btn-oval" style="margin:5px;" [hidden]="status !== 2">{{ trang }}</a>
  `,
  providers: [Repository]
  })
export class BongApproversDirective implements  OnInit {

    
  renderValue: string;

  @Input() bongid: string | number;
  @Input() trang: string | number;
  status: number = null;
  statusText: any = null;
  public userid;

  constructor(private repo: Repository) {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser != null) {    
      this.userid = currentUser.id;
    }

  }
  ngOnInit() {
    this.repo.getRequest("api/nguoidung/getbongapprovers/" + this.bongid)
      .subscribe(response => {
       
        var keepGoing = true;
        var conguoiduyet = false;
        response.forEach(
          (item) => {
            if (keepGoing && item.TrangThaiDuyet != 'Đã duyệt') {
              keepGoing = false;
            
              this.statusText = 'Đang duyệt'
            }
            if (item.NguoiXuLyId == this.userid) {
              conguoiduyet = true;
            }
          })
        if (keepGoing) {
          this.status = 0
          this.statusText = 'Hoàn thành'
        }
        else {
          if (conguoiduyet) {
            this.status = 1;
          } else {
            this.status = 2;
          }
        }
      });
  }


}
