
<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Ngày in HĐ</label>
      <div class="col-4">
        <kendo-dateinput tabindex="1" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="TimKiems.NgayIn"></kendo-dateinput>
      </div>
      <label class="control-label col-2">Số hóa đơn</label>
      <div class="col-4">
        <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="TimKiems.SoHD">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Hình thức TT</label>
      <div class="col-4">
        <ng-select #slLoaiBaoGia
                   labelForId="99"
                   class="twcol"
                   [tabIndex]="3"
                   [openOnEnter]="false"
                   [items]="inputhinhthucthanhtoans"
                   bindValue="Ten_Doi_Tuong"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [(ngModel)]="TimKiems.HinhThucThanhToan">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2">Hóa đơn của</label>
      <div class="col-4">
        <ng-select #slKhachHang
                   labelForId="99"
                   class="twcol"
                   autofocus
                   [tabIndex]="4"
                   [openOnEnter]="false"
                   [items]="inputkhachhangs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="TimKiems.DoiTuongId">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Ghi chú</label>
      <div class="col-10">
        <textarea class="form-control" [tabIndex]="5" rows="5" [(ngModel)]="TimKiems.GhiChu" placeholder=""></textarea>
      </div>
    </div>



  </div>

</div>




<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
    <button  class="btn btn-success"  (click)="tuychinhmaubaocao('8E415E72-7661-4CEC-B249-668A813C2D52')">Tùy chỉnh mẫu</button>
  </div>
  <div class="">
    <button class="btn btn-primary" (click)="savebg('pdf')" style="margin-left:3px; margin-right:3px">In hóa đơn</button>
    <button class="btn btn-outline-success" (click)="savebg('xlsx')" style="margin-left:3px; margin-right:3px">Xuất hóa đơn (excel)</button>
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal_chondoituong()" title="">Hủy bỏ</button>
  </div>

</div>



<bs-modal #modaltuychinhmaubc class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Tùy chỉnh mẫu báo cáo bán hàng</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="modaltuychinhmaubc.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-quantri-tuychinhmaubcedit #dtsuamaubaocao></app-quantri-tuychinhmaubcedit>
  </bs-modal-body>
</bs-modal>






