import { Component, ViewChild, OnInit, Output, Input, Pipe, PipeTransform , ElementRef } from '@angular/core';
import { Repository } from '../../../services/repository'
import { DomSanitizer } from '@angular/platform-browser'


@Component({
  selector: 'plan-status',
  template: `
       <span class="btn-success btn btn-oval" [hidden]="status !== \'Completed\'">{{ statusText }}</span>
        <span class="btn-warning btn btn-oval" [hidden]="status === \'Completed\'">{{ statusText }}</span>
  `,
  providers: [Repository]
  })
export class PlanStatusDirective implements  OnInit {

    
  renderValue: string;

  @Input() articleid: string | number;
  status: any = null;
  statusText: any = null;
  constructor(private repo: Repository) {


  }
  ngOnInit() {
    this.repo.getRequest("api/kehoach/getplanapprovers/" + this.articleid)
      .subscribe(response => {
       
        var keepGoing = true;
        response.forEach(
          (item) => {
            if (keepGoing && item.TrangThaiDuyet != 'Đã duyệt') {
              keepGoing = false;
              this.status = 'Continue'
              this.statusText = 'Đang duyệt'
            }
          })
        if (keepGoing) {
          this.status = 'Completed'
          this.statusText = 'Hoàn thành'
        }
      });
  }


}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
