import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-ketoan-khautruedit',
  templateUrl: './khautruedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./khautruedit.component.scss'],
    providers: [ListService, Repository]
})


export class khautrueditComponent implements OnInit {
  doanhnghiepid: string = null;
  donviid: string = null;
  namketoan: number = 0;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Tu_Ngay: null, Den_Ngay: null
    , TK_Vao: "1331,1332", TK_Ra: "33311,33312", Ma_Chung_Tu: null
    , Ngay_Chung_Tu: null, Ngay_Hach_Toan: null, Dien_Giai: null
    , DoanhNghiepId: null, DonViId: null, PhanHeId: null, UserName: null
  };       
    nhomdtid:string=null;
    hoatdong:string=null;

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;

  loadDataInit(nhomdoituongid: string) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            this.namketoan = this.currentUser.nam_ke_toan;
            this.donviid = this.currentUser.id_don_vi;
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);

    let tphanheid = "2E5DD4FB-1DC0-4679-B0C1-424F93D0052A";
    

    this.doituongSelect = {
      Id: null, Tu_Ngay: null, Den_Ngay: null
      , TK_Vao: "1331,1332", TK_Ra: "33311,33312", Ma_Chung_Tu: null
      , Ngay_Chung_Tu: null, Ngay_Hach_Toan: null, Dien_Giai: null
      , DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: tphanheid, UserName: this.currentUser.user_name
    };

  

    this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: tphanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response1 => {
        if (response1 != null) {
          this.doituongSelect.Ma_Chung_Tu = response1[0].Ma_Chung_Tu;
        }
      });
 
    let tdate = new Date();
    this.doituongSelect.Tu_Ngay = new Date(this.namketoan, tdate.getMonth(), 1, 0, 0, 0, 0);
    this.doituongSelect.Den_Ngay = new Date(this.namketoan, tdate.getMonth() + 1, 1, 0, 0, 0, 0);
    this.doituongSelect.Den_Ngay.setDate(this.doituongSelect.Den_Ngay.getDate() - 1);
    this.doituongSelect.Ngay_Chung_Tu = this.doituongSelect.Den_Ngay;
    this.doituongSelect.Ngay_Hach_Toan = this.doituongSelect.Den_Ngay;
    this.doituongSelect.Dien_Giai = "Khấu trừ thuế GTGT tháng: " + (this.doituongSelect.Ngay_Hach_Toan.getMonth() + 1) + "/" + this.doituongSelect.Ngay_Hach_Toan.getFullYear();
        
       
  }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    if (this.doituongSelect.Tu_Ngay == null) {
        swal('Lỗi!', 'Vui lòng nhập từ ngày!.', 'error');
        return;
    }
    if (this.doituongSelect.Den_Ngay == null) {
      swal('Lỗi!', 'Vui lòng nhập đến ngày!.', 'error');
      return;
    }

    if (this.doituongSelect.Ngay_Hach_Toan == null) {
      swal('Lỗi!', 'Vui lòng nhập ngày hạch toán!.', 'error');
      return;
    }


    if (this.doituongSelect.Ma_Chung_Tu == null || this.doituongSelect.Ma_Chung_Tu == "") {
      swal('Lỗi!', 'Vui lòng nhập mã chứng từ!.', 'error');
      return;
    }

    if (this.doituongSelect.Dien_Giai == null || this.doituongSelect.Dien_Giai == "") {
      swal('Lỗi!', 'Vui lòng nhập diễn giải!.', 'error');
      return;
    }


      $('#loading').show();
    this.repo.postRequest("api/chungtu/addorupdate_khautru",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('OK')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
    }
   
    
   

   


}
