<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}">
        <a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id="">1.Thông tin chi tiết</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}">
        <a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id="">2.Thông tin bán hàng</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}">
        <a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id="">3.Thông tin bảo hiểm</a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="col-12">
        <div class="row form-group">
          <label class="control-label col-2">Mã</label>
          <div class="col-4">
            <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
          </div>
          <label class="control-label col-2 ng-binding">Cấp trên</label>
          <div class="col-4">

            <a *ngIf="doituongSelect.Parent===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha">
            </a>
            <a *ngIf="doituongSelect.Parent!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha" (ngModelChange)="adddatachondoituongchung(1)">
            </a>
            <!--<ng-select #slCapTren
                       class="twcol"
                       [tabIndex]="2"
                       [openOnEnter]="false"
                       [items]="inputparents"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                        (change)="onDoiTuongChange($event)"
                       [(ngModel)]="doituongSelect.Parent">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>-->

          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Tên</label>
          <div class="col-10">
            <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Số khung</label>
          <div class="col-4">
            <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_Khung">
          </div>
          <label class="control-label col-2 ng-binding">Số máy</label>
          <div class="col-4">
            <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_May">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Hãng</label>
          <div class="col-4">
            <ng-select #slHangxe
                       class="twcol"
                       [tabIndex]="6"
                       [openOnEnter]="false"
                       [items]="inputhangxes"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       (change)="onChangeHangXe($event)"
                       [(ngModel)]="doituongSelect.Hang_Xe">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Model</label>
          <div class="col-4">
            <ng-select #slPhongban
                       class="twcol"
                       [tabIndex]="7"
                       [openOnEnter]="false"
                       [items]="inputmodels"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Model_Xe">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Khách hàng</label>
          <div class="col-4">

            <a *ngIf="doituongSelect.Nha_Cung_Cap_Id===null" (click)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="8" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Khach_Hang">
            </a>
            <a *ngIf="doituongSelect.Nha_Cung_Cap_Id!==null" (dblclick)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="8" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Khach_Hang" (ngModelChange)="adddatachondoituongchung(2)">
            </a>


            <!--<ng-select #slKhachHang
                       class="twcol"
                       [tabIndex]="8"
                       [openOnEnter]="false"
                       [items]="inputkhachhangs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Nha_Cung_Cap_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>-->
          </div>
          <label class="control-label col-2 ng-binding">Màu sắc</label>
          <div class="col-4">
            <ng-select #slMausac
                       class="twcol"
                       [tabIndex]="9"
                       [openOnEnter]="false"
                       [items]="inputmausacs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Mau_Sac">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>
          </div>
        </div>


        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Số Km</label>
          <div class="col-4">
            <input id="gio" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.So_Km"
                   name="value"
                   tabIndex="10"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
          <label class="control-label col-2 ng-binding">Năm sản xuất</label>
          <div class="col-4">
            <input id="gia" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Nam_San_Xuat"
                   name="value"
                   tabIndex="11"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Mã vạch</label>
          <div class="col-4">
            <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Vach">
          </div>
          <label class="control-label col-2 ng-binding">Tên khác</label>
          <div class="col-4">
            <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Khac">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Chất liệu</label>
          <div class="col-4">
            <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Chat_Lieu">
          </div>
          <label class="control-label col-2 ng-binding">Chi phí</label>
          <div class="col-4">
            <input id="gio" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Chi_Phi"
                   name="value"
                   tabIndex="15"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Đơn vị tính</label>
          <div class="col-4">
            <ng-select #slDonViTinh
                       class="twcol"
                       [tabIndex]="16"
                       [openOnEnter]="false"
                       [items]="inputdonvitinhs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Don_Vi_Tinh_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Xuất xứ</label>
          <div class="col-4">
            <ng-select #slDonViTinh
                       class="twcol"
                       [tabIndex]="17"
                       [openOnEnter]="false"
                       [items]="inputxuatxus"
                       bindValue="Id"
                       bindLabel="Ten"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Xuat_Xu_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Ghi chú</label>
          <div class="col-10">
            <input type="text" tabIndex="18" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ghi_Chu">
          </div>
        </div>

        <!--<div class="row form-group">
          <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
          <div class="col-10">
            <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
          </div>
        </div>-->


      </div>

    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Số chìa khóa</label>
        <div class="col-4">
          <input type="text" tabIndex="30" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_Chia_Khoa">
        </div>
        <label class="control-label col-2 ng-binding">Kích cỡ</label>
        <div class="col-4">
          <input type="text" tabIndex="31" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Kich_Co">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Ngày xe về</label>
        <div class="col-4">
          <kendo-dateinput tabIndex="32" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="ngayxeve"></kendo-dateinput>
        </div>
        <label class="control-label col-2 ng-binding">Ngày nhận nợ</label>
        <div class="col-4">
          <kendo-dateinput tabIndex="33" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="ngaynhanno"></kendo-dateinput>
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Ngày giao chìa</label>
        <div class="col-4">
          <kendo-dateinput tabIndex="34" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="ngaygiaochiakhoa"></kendo-dateinput>
        </div>
        <label class="control-label col-2 ng-binding">Phụ kiện</label>
        <div class="col-4">
          <input type="text" tabIndex="35" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Phu_Kien">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Thuế suất</label>
        <div class="col-4">
          <input id="gio" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Thue_Suat"
                 name="value"
                 tabIndex="36"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <label class="control-label col-2 ng-binding">Giá nhập</label>
        <div class="col-4">
          <input id="gia" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Nhap"
                 name="value"
                 tabIndex="37"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Giá bán lẻ</label>
        <div class="col-4">
          <input id="gio" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Ban_Le"
                 name="value"
                 tabIndex="38"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <label class="control-label col-2 ng-binding">Giá bán buôn</label>
        <div class="col-4">
          <input id="gia" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Ban_Buon"
                 name="value"
                 tabIndex="39"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-2">
          <label>
            <input tabIndex="50" type="checkbox" [(ngModel)]="doituongSelect.Theo_Doi_Ton" />
            <!--<span class="fa fa-check"></span>--> Theo dõi tồn
          </label>
        </div>
        <div class="col-2">
          <label>
            <input tabIndex="51" type="checkbox" [(ngModel)]="doituongSelect.Co_Hoa_Don" />
            <!--<span class="fa fa-check"></span>--> Có hóa đơn
          </label>
        </div>
        <div class="col-2">
          <label>
            <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Chinh_Hang" />
            <!--<span class="fa fa-check"></span>--> Chính hãng
          </label>
        </div>


      </div>
    </div>

    <div class="tab-pane" [ngClass]="{'active': tabselected==3}">
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Hãng bảo hiểm</label>
        <div class="col-4">
          <ng-select #slHangBaoHiem
                     class="twcol"
                     [tabIndex]="2"
                     [openOnEnter]="false"
                     [items]="inputhangbaohiems"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [(ngModel)]="doituongSelect.Hang_Bao_Hiem_Id">
            <ng-template ng-option-tmp let-item="item">
              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <label class="control-label col-2 ng-binding">Số hợp đồng</label>
        <div class="col-4">
          <input type="text" (keydown)="keyTenPress($event)" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_Hop_Dong_Bao_Hiem">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Ngày bắt đầu</label>
        <div class="col-4">
          <kendo-dateinput style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="ngaybatdau"></kendo-dateinput>
        </div>
        <label class="control-label col-2 ng-binding">Ngày kết thúc</label>
        <div class="col-4">
          <kendo-dateinput style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="ngayketthuc"></kendo-dateinput>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdChonDoiTuongChung2 class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongkhachhangedit #dtechondoituongchung2edit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongkhachhangedit>
  </bs-modal-body>
</bs-modal>
