<div id="luoibaocaoTH">
  <table id="fixTable" class="table table-striped table-bordered table-hover table-condensed">
    <thead [ngClass]="{'theadcss': sobanghi>16}">
      <tr>
        <th *ngFor="let ut of datatieude" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">

          <!--{{ut.FullName}}-->
          <a (click)="SapXep(ut)">{{ut.FullName}}</a>
          <i class="fas fa-arrow-down" *ngIf="ut.SapXep==1" style="color:red"></i>
          <i class="fas fa-arrow-up" *ngIf="ut.SapXep==0" style="color:red"></i>
        </th>

      </tr>
      <tr>
        <td *ngFor="let ut of datatieude" [ngClass]="{'AnCot': !ut.HienThi}">
          <input class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text"
                 [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearch(ut.TimKiem)">
        </td>
      </tr>
    </thead>
    <tbody style="min-height:350px">

      <tr *ngFor="let u of dataGrid" (dblclick)="doubleClick(u)">
        <td *ngFor="let u1 of datatieude" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal','InDam': u['IDCHUNGTU']===null || u['IDCHUNGTU']==='00000000-0000-0000-0000-000000000000' || u['INDAM']===1}">
          <span style="padding-right:5px;" *ngIf="u1.Type==='decimal' && u1.ColumnName!=='SOLUONG'">{{(u[u1.ColumnName]!=0?u[u1.ColumnName]:null) | number}}</span>
          <span style="padding-right:5px;" *ngIf="u1.Type==='decimal' && u1.ColumnName==='SOLUONG'">{{u[u1.ColumnName] | number:'1.0-2'}}</span>
          <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
          <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

        </td>

        <!--<td><a (click)="editdata(u.Id)">{{u.HoTen}}</a></td>-->
        <!--<td>{{u.NgayGhiSo | date: 'dd/MM/yyyy'}}</td>
        <td>
          <div style="float:left" *ngIf="u.Sua">
            <a (click)="editdata(u.Id)" class="ng-binding"><i class="fa fa-check-square"></i> Sửa</a>
          </div>


        </td>-->
      </tr>
    </tbody>
  </table>
</div>
<!--<modal #modalpc [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Phiếu chi</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-phieuchiedit #ctphieuchiedit (save)="savept($event)"></app-ketoan-phieuchiedit>

  </modal-body>

</modal>
<modal #modalpt [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Phiếu thu</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-phieuthuedit #ctphieuthuedit (save)="savept($event)"></app-ketoan-phieuthuedit>
  </modal-body>
</modal>
<modal #modalbaoco [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Báo có</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-baocoedit #ctbaocoedit (save)="savept($event)"></app-ketoan-baocoedit>

  </modal-body>

</modal>
<modal #modalbaono [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Báo nợ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-baonoedit #ctbaonoedit (save)="savept($event)"></app-ketoan-baonoedit>

  </modal-body>

</modal>
<modal #modaltonghop [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Chứng từ tổng hợp</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-chungtukhacedit #cttonghopedit (save)="savept($event)"></app-ketoan-chungtukhacedit>

  </modal-body>

</modal>
<modal #modalchuyentiennoibo [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Chuyển tiền nội bộ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-cknoiboedit #ctchuyentiennoiboedit (save)="savept($event)"></app-ketoan-cknoiboedit>

  </modal-body>

</modal>
<modal #modalcktaisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Bán tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-bantaisanedit #ctcktaisanedit (save)="savept($event)"></app-ketoan-bantaisanedit>
  </modal-body>
</modal>
<modal #modalnhantaisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Đánh giá lại tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-nhantaisanedit #ctnhantaisanedit (save)="savept($event)"></app-ketoan-nhantaisanedit>
  </modal-body>
</modal>
<modal #modalghigiamtaisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Ghi giảm tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-nhantaisanedit #ctghigiamtaisanedit (save)="savept($event)"></app-ketoan-nhantaisanedit>
  </modal-body>
</modal>
<modal #modalbantaisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Bán tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-bantaisanedit #ctbantaisanedit (save)="savept($event)"></app-ketoan-bantaisanedit>
  </modal-body>
</modal>
<modal #modalmuataisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Mua tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-cktaisanedit #ctmuataisanedit (save)="savept($event)"></app-ketoan-cktaisanedit>
  </modal-body>
</modal>
<modal #modalnhanhienvat [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Nhận hiện vật</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-nhantaisanedit #ctnhanhienvatedit (save)="savept($event)"></app-ketoan-nhantaisanedit>
  </modal-body>
</modal>
<modal #modaltinhhaomontaisan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Tính hao mòn tài sản</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-haomonedit #cttinhhaomontaisanedit (save)="savept($event)"></app-ketoan-haomonedit>
  </modal-body>
</modal>
<modal #modalmuacongcu [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Mua công cụ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-muacongcuedit #ctmuacongcuedit (save)="savept($event)"></app-ketoan-muacongcuedit>
  </modal-body>
</modal>
<modal #modalnhapkho [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Nhập kho</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-nhapkhoedit #ctnhapkhoedit (save)="savept($event)"></app-ketoan-nhapkhoedit>
  </modal-body>
</modal>
<modal #modalxuatkho [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Xuất kho</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-xuatkhoedit #ctxuatkhoedit (save)="savept($event)"></app-ketoan-xuatkhoedit>
  </modal-body>
</modal>
<modal #modalrutdutoanck [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Báo có</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-baocoedit #ctrutdutoanckedit (save)="savept($event)"></app-ketoan-baocoedit>
  </modal-body>
</modal>




<modal #modalbangkethanhtoan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Bảng kê thanh toán</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-bangkethanhtoanedit #bangkethanhtoanedit (save)="savept($event)"></app-ketoan-bangkethanhtoanedit>
  </modal-body>
</modal>
<modal #modalnhandutoan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Nhận dự toán</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-nhandutoanedit #ctnhandutoanedit (save)="savept($event)"></app-ketoan-nhandutoanedit>

  </modal-body>

</modal>
<modal #modalhuydutoan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Hủy dự toán</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-huydutoanedit #cthuydutoanedit (save)="savept($event)"></app-ketoan-huydutoanedit>
  </modal-body>
</modal>
<modal #modaldcdutoan [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Điều chỉnh dự toán</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-dcdutoanedit #ctdcdutoanedit (save)="savept($event)"></app-ketoan-dcdutoanedit>
  </modal-body>
</modal>
<modal #modalruttg [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Rút dự toán tiền gửi</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-rutdutoantgedit #ctrutdutoantgedit (save)="savept($event)"></app-ketoan-rutdutoantgedit>
  </modal-body>
</modal>
<modal #modalcamketchi [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Cam kết chi</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-camketchiedit #ctcamketchiedit (save)="savept($event)"></app-ketoan-camketchiedit>
  </modal-body>
</modal>
<modal #modaldieuchinhcamketchi [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Điều chỉnh cam kết chi</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-dccamketchiedit #ctdieuchinhcamketchiedit (save)="savept($event)"></app-ketoan-dccamketchiedit>
  </modal-body>
</modal>
<modal #modaldieuchuyencongcu [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Điều chuyển công cụ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-dccongcuedit #ctdieuchuyencongcuedit (save)="savept($event)"></app-ketoan-dccongcuedit>
  </modal-body>
</modal>
<modal #modalghigiamcongcu [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Ghi giảm công cụ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-ghigiamcongcuedit #ctghigiamcongcuedit (save)="savept($event)"></app-ketoan-ghigiamcongcuedit>
  </modal-body>
</modal>
<modal #modalghitangcongcu [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Ghi tăng công cụ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-ghitangcongcuedit #ctghitangcongcuedit (save)="savept($event)"></app-ketoan-ghitangcongcuedit>
  </modal-body>
</modal>
<modal #modalphanbocongcu [cssClass]="'modalsanpham'" [backdrop]="'static'">
  <modal-header [show-close]="false">
    <h4 class="modal-title">Phân bổ, hao mòn công cụ</h4>
  </modal-header>
  <modal-body>
    <app-ketoan-phanbocongcuedit #ctphanbocongcuedit (save)="savept($event)"></app-ketoan-phanbocongcuedit>
  </modal-body>
</modal>-->
