import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-ketoan-chitietketchuyenedit',
  templateUrl: './chitietketchuyenedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./chitietketchuyenedit.component.scss'],
    providers: [ListService, Repository]
})


export class chitietketchuyeneditComponent implements OnInit {
  doanhnghiepid: string = null;
  donviid: string = null;
  namketoan: number = 0;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputnhomctmts: any;
  inputtaikhoantaisans: any;
  indLoading: boolean = false;

  public inputloaiketchuyens: Array<{ Id: number, Ma_Doi_Tuong: string, Ten_Doi_Tuong: string }> = [
    { Id: 0, Ma_Doi_Tuong: "Kết chuyển", Ten_Doi_Tuong: "Kết chuyển" },
    { Id: 1, Ma_Doi_Tuong: "Phân bổ", Ten_Doi_Tuong: "Phân bổ" },
  ];
  public inputkieuketchuyens: Array<{ Id: number, Ma_Doi_Tuong: string, Ten_Doi_Tuong: string }> = [
    { Id: 0, Ma_Doi_Tuong: "Kết chuyển bên nợ", Ten_Doi_Tuong: "Kết chuyển bên nợ" },
    { Id: 1, Ma_Doi_Tuong: "kết chuyển bên có", Ten_Doi_Tuong: "kết chuyển bên có" },
    { Id: 2, Ma_Doi_Tuong: "Kết chuyển hai bên", Ten_Doi_Tuong: "Kết chuyển hai bên" },
  ];

  doituongSelect: any = {
    Id: null, Tai_Khoan_Den_Id: null, Tai_Khoan_Den: null
    , Tai_Khoan_Di_Id: null, Tai_Khoan_Di: null, Dien_Giai: null, Thu_Tu: null
    , Loai_Ket_Chuyen: null, Kieu_Ket_Chuyen: null, Trang_Thai: null
    , DoanhNghiepId: null, DonViId: null, PhanHeId: null, UserName: null
  };       
    nhomdtid:string=null;
    hoatdong:string=null;

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
  }

  onDoiTuongChange($event) {

    if (typeof $event === "undefined") {
      this.doituongSelect.Dien_Giai = null;
    }
    else {
      this.doituongSelect.Dien_Giai ="Kết chuyển "+ $event.Ten_Tai_Khoan; 
    }
  }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;

  loadDataInit(nhomdoituongid: string, id: string) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            this.namketoan = this.currentUser.nam_ke_toan;
            this.donviid = this.currentUser.id_don_vi;
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);

    let tphanheid = "2E5DD4FB-1DC0-4679-B0C1-424F93D0052A";


    this.repo.postRequest('api/taikhoan/dstaikhoanv2', { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputtaikhoantaisans = response;
      });

    this.doituongSelect = {
      Id: null, Tai_Khoan_Den_Id: null, Tai_Khoan_Den: null
      , Tai_Khoan_Di_Id: null, Tai_Khoan_Di: null, Dien_Giai: null, Thu_Tu: null
      , Loai_Ket_Chuyen: null, Kieu_Ket_Chuyen: null, Trang_Thai: null
      , DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: tphanheid, UserName: this.currentUser.user_name
    };

    if (id === null) {
     
      this.doituongSelect.Trang_Thai = true;
      
    }
    else {
      console.log("t11");
      this.repo.postRequest("api/chungtu/getbyid_ketchuyen", { Id: id, PhanHeId: null, UserName: this.currentUser.user_name, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          console.log(response);
          this.doituongSelect.Id = id;
          this.doituongSelect.Tai_Khoan_Den_Id = response.Tai_Khoan_Den_Id;
          this.doituongSelect.Tai_Khoan_Den = response.Tai_Khoan_Den;
          this.doituongSelect.Tai_Khoan_Di_Id = response.Tai_Khoan_Di_Id;
          this.doituongSelect.Tai_Khoan_Di = response.Tai_Khoan_Di;
          this.doituongSelect.Dien_Giai = response.Dien_Giai;
          this.doituongSelect.Thu_Tu = response.Thu_Tu;
          this.doituongSelect.Loai_Ket_Chuyen = response.Loai_Ket_Chuyen;
          this.doituongSelect.Kieu_Ket_Chuyen = response.Kieu_Ket_Chuyen;
          this.doituongSelect.Trang_Thai = response.Trang_Thai;
          

        });
    }


  
   
  }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    //if (this.doituongSelect.Tu_Ngay == null) {
    //    swal('Lỗi!', 'Vui lòng nhập từ ngày!.', 'error');
    //    return;
    //}

      $('#loading').show();
    this.repo.postRequest("api/chungtu/addorupdate_ketchuyen",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('OK')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
    }
   
    
   

   


}
