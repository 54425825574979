import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { LocalDataSource } from 'ng2-smart-table';
import { NumberViewComponent } from '../../../shared/components/public/numberview.component';
import { DateViewComponent } from '../../../shared/components/public/dateview.component';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';
import {
  Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
  , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
  , Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
  , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
  , Lists_khachhang, Lists_nhanvien
  , Lists_congcu, Lists_taisancodinh, Lists_chiphi
  , Lists_tiente, Lists_xuatxu, Lists_tinh, Lists_mauson, Lists_giaodich
  , Lists_keuoc, Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
  , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
  , Lists_loaisuachua, Lists_dichvu, Lists_xesua, Lists_nganhang

} from '../../../routes/menu';
const swal = require('sweetalert');


@Component({
    selector: 'app-tonghop-tinhkhauhaotscdedit',
  templateUrl: './tinhkhauhaotscdedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./tinhkhauhaotscdedit.component.scss'],
    providers: [ListService, Repository]
})


export class tinhkhauhaotscdeditComponent implements OnInit {

  XoaCT: boolean = true;
  TuThang: number = 1;
  DenThang: number = 1;
  
  @ViewChild('mdLuaChon_doituong') mdLuaChon_doituong: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
 

  doituongkethua: any;
  doanhnghiepid: string = null;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  datas: any;
  data: any;
  public dulieu: any = { dataGrid: null, dataGridTimKiem: null, datatieude: null, sobanghi: null };
  indLoading: boolean = false;
  dataFrm: FormGroup;
  modalTitle: string;
  modalBtnTitle: string;
  Id: string;
  NgayHienTai: Date = new Date();
  inputDonVi: any;
  public DonViDropId: any = { Id: null, Ten: null };
  public TaiKhoanDropId: any = { Id: null, Ten: null };
  TaiKhoanId: any;
  selecteddonvis: any;
  selectedchuongs: any;
  selectedkhoans: any;
  Ma_Tai_Khoan: any;
  dropdownList_DonVi = [];
  inputTaiKhoan: any;
  dropdownList_TaiKhoan = [];
  selectedItems = [];
  dropdownSettings = {};
  khoangthoigian: number = 0;
  tabselected: number = 1;
 
  public thangs: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 }
  ];

  public quys: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },

  ];

  public nams: Array<{ text: string, value: number }> = [
    { text: (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    { text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    { text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];

  public chons: Array<{ text: string, value: number }> = [
    { text: "Theo ngày", value: 1 },
    { text: "Theo tháng", value: 2 },
    { text: "Theo quý", value: 3 },
    { text: "Theo năm", value: 4 },

  ];
  chongioitinhs: any = [{ Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Nữ" }
    , { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "Nam" }];
  TatCa: boolean = false;
  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number = 5;
  public TimKiem: string;
  public StartPage: number = 1;
  public EndPage: number = 20;
  public pageSize: number = 20;
  public pages: any;
  public totalPages: number;
  public TimKiems: any = {
    Don_Vi_Id: null
    , Doanh_Nghiep_Id: null
    , UserName: null
    , Nam: null
    , TuNgay: null
    , DenNgay: null
    , Phan_He_Id: null
    , TimKiem: null
    , Loai: null
    , startPage: null
    , endPage: null
    , Ngay: null
    , NgayS: null
    , Ma: null
    , Ten: null
    , Cha: null
    , XoaCT: true
    , TuThang: 1
    , DenThang: 1
    , GhiChu: null
    , NhomDoiTuong: null
    , SapXep: null
    , Modified: null
    , ModifiedS: null
    , ModifiedBy: null
    , Id: null
  };
  public ngaytimkiem: Date = new Date();
  TichTatCa() {
    this.datas.forEach((item: any) => {
      item.Tich = !this.TatCa;
    });
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  public TuNgay: Date = new Date();
  public DenNgay: Date = new Date();
  public TuNgay1: Date = new Date();
  public DenNgay1: Date = new Date();
  public tTuNgay: string;
  public tDenNgay: string;

 

 
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: true
  };

  public currentUser;
  donviid: string = null;
  namketoan: number;
  username: string = null;
  phanheid: any;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository) {



  }

  // @HostListener('document:keydown', ['$event'])
  public innerHeight: any
  quyen_them: boolean = false;
  loadDataInit(pheid: string) {
    this.ngaytimkiem = null;
    this.StartPage = 1;
    this.EndPage = 20;
    this.ngaytimkiem = null;
    this.innerHeight = window.innerHeight - 250;
    this.phanheid = pheid;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.donviid = this.currentUser.id_don_vi;
      this.namketoan = this.currentUser.nam_ke_toan;
      this.username = this.currentUser.user_name;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
       
      }

      this.datas = [];
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
      let tdate = new Date();
      this.TimKiems.TuNgay = new Date(this.namketoan, tdate.getMonth(), 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay = new Date(this.namketoan, tdate.getMonth()+1, 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay.setDate(this.TimKiems.DenNgay.getDate() -1);
      
    }
    //this.search();
        
    
  }

  


  editdata(id: string) {
    console.log(id);
    this.modalTitle = "cập nhập thông tin hàng hóa thu giữ";
    this.modalBtnTitle = "Cập nhật";
    //this.doituongchungedit.loadDataInit(this.phanheid, id, this.donviid);
    //this.modal.open();
  }
  //saveModal(id: any) {
  //  this.Loaddatas();
  //  this.modal.close();
  //}

  
  pageChanged(event: any): void {
    // alert(event.page);
    //console.log('a');
    //console.log(event);
    this.currentPage = event.page;
    this.Loaddatas();
  }
  setPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    this.Loaddatas();
  }
  public onBlur(): void {
    this.Loaddatas();
  }
  search() {
    this.currentPage = 1;
    this.pageSize = 50;
    this.Loaddatas();
  }
  search1() {
    this.Loaddatas();
  }
  savebg() {
    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;

    if (this.datas != null) {
      this.TimKiems.Id = this.datas[0].Id;
    }

    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    $('#loading').show();
    this.repo.postRequest("api/chungtu/luukhauhao/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        if (response.TrangThai == "OK") {
         // this.datas = response.Data;
          swal('Thông báo!', "Lưu thay đổi thành công!", 'success');
        }
        else
          swal('Cảnh báo!', response.TrangThai, 'warning');
        
        
      });
  }
  Loaddatas(): void {
    
    this.datas = [];
    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;
    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    $('#loading').show();
    this.repo.postRequest("api/chungtu/get_khauhao/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        this.totalPages = 0;
        this.totalItems = response.Data.length;
        if (response.TrangThai=="OK")
          this.datas = response.Data;
        else
          swal('Cảnh báo!', response.TrangThai, 'warning');

        //(window as any).timer(3, 1500);

      });



  }

  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }
    this.LoadCategory();
  }

  LoadCategory(): void {

  }
  //----------event xử lý di chuyển Enter--------------------------------------
  tongtien: number = 0;
  maxcot: number = 13;

  onDoiTuongChange($event) {

    //if (typeof $event === "undefined") {
    //  this.doituongSelect.Ho_Ten = null;
    //}
    //else {
    //  this.doituongSelect.Ho_Ten = $event.Ten_Doi_Tuong;

    //  this.doituongSelect.ChiTiets.forEach(
    //    (item) => {
    //      if (item.Doi_Tuong_Id2 === null)
    //        item.Doi_Tuong_Id2 = $event.Id;
    //    }
    //  );

    //  this.repo.getRequest("api/baselayout/ThongTinDiaChi/" + $event.Id)
    //    .subscribe(response => {
    //      //console.log(response);
    //      this.doituongSelect.Dia_Chi = response.Dia_Chi;

    //    });
    //}
  }

  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;

  public save_chondoituong2(item: any) {
    let tdoituong = [];
    tdoituong.push(item);
    this.savect.emit(tdoituong);
  }
  public save_chondoituong() {  
    let tdoituong = [];
    this.savect.emit(tdoituong);

    //this.datas.forEach(
    //  (item) => {
    //    if (item.Tich === true)
    //      tdoituong.push(item);
    //  }
    //);
    //if (tdoituong != null && tdoituong.length > 0)
    //  this.savect.emit(tdoituong);
    //else
    //  this.savect.emit(null);

  }
  closeModal_chondoituong() {
    let tdoituong =null;
    this.savect.emit(tdoituong);
  }
  nhomdoituongid: string;
  onLoaiNguoiNop(row: any) {
    this.nhomdoituongid = row.Id;
    //console.log(loai);
  }

  

  
   
}
