import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { DialogComponent, ButtonPropsModel, PositionDataModel, ResizeDirections } from '@syncfusion/ej2-angular-popups';
import { AnimationSettingsModel } from '@syncfusion/ej2-splitbuttons';
import { process, orderBy, filterBy } from '@progress/kendo-data-query/dist/es/main';
import 'jquery'
import 'bootstrap'
import {
  tiens, tiens_phieuchi, tiens_phieuthu
} from '../../../routes/menu';

const swal = require('sweetalert');
@Component({
  selector: 'app-baocao-luoitonghopedit',
  templateUrl: './luoitonghopedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./luoitonghopedit.component.scss'],
  providers: [ListService, Repository]
})


export class luoitonghopeditComponent implements OnInit {

  doanhnghiepid: string = null;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any
  indLoading: boolean = false;
  hoatdong: string = null;
  dataGridTimKiem: any;
  sobanghi: number;
  dataGrid: any;
  datatieude: any;
  public currentUser;

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() changeTH: EventEmitter<any> = new EventEmitter();
  //@ViewChild('modalpc', { static: false }) modalpc: ModalComponent;
  //@ViewChild('ctphieuchiedit', { static: false }) phieuchiedit: any;
  //@ViewChild('modalpt', { static: false }) modalpt: ModalComponent;
  //@ViewChild('ctphieuthuedit', { static: false }) phieuthuedit: any;
  //@ViewChild('modalbangkethanhtoan', { static: false }) modalbangkethanhtoan: ModalComponent;
  //@ViewChild('bangkethanhtoanedit', { static: false }) bangkethanhtoanedit: any;
  //@ViewChild('modalbaoco', { static: false }) modalbaoco: ModalComponent;
  //@ViewChild('ctbaocoedit', { static: false }) ctbaocoedit: any;
  //@ViewChild('modalbaono', { static: false }) modalbaono: ModalComponent;
  //@ViewChild('ctbaonoedit', { static: false }) ctbaonoedit: any;
  //@ViewChild('modaltonghop', { static: false }) modaltonghop: ModalComponent;
  //@ViewChild('cttonghopedit', { static: false }) cttonghopedit: any;
  //@ViewChild('modalchuyentiennoibo', { static: false }) modalchuyentiennoibo: ModalComponent;
  //@ViewChild('ctchuyentiennoiboedit', { static: false }) ctchuyentiennoiboedit: any;
  //@ViewChild('modalrutdutoanck', { static: false }) modalrutdutoanck: ModalComponent;
  //@ViewChild('ctrutdutoanckedit', { static: false }) ctrutdutoanckedit: any;

  //@ViewChild('modalnhandutoan', { static: false }) modalnhandutoan: ModalComponent;
  //@ViewChild('ctnhandutoanedit', { static: false }) ctnhandutoanedit: any;
  //@ViewChild('modalhuydutoan', { static: false }) modalhuydutoan: ModalComponent;
  //@ViewChild('cthuydutoanedit', { static: false }) cthuydutoanedit: any;
  //@ViewChild('modaldcdutoan', { static: false }) modaldcdutoan: ModalComponent;
  //@ViewChild('ctdcdutoanedit', { static: false }) ctdcdutoanedit: any;
  //@ViewChild('modalruttg', { static: false }) modalruttg: ModalComponent;
  //@ViewChild('ctrutdutoantgedit', { static: false }) ctrutdutoantgedit: any;
  //@ViewChild('modalcktaisan', { static: false }) modalcktaisan: ModalComponent;
  //@ViewChild('ctcktaisanedit', { static: false }) ctcktaisanedit: any;
  //@ViewChild('modalnhantaisan', { static: false }) modalnhantaisan: ModalComponent;
  //@ViewChild('ctnhantaisanedit', { static: false }) ctnhantaisanedit: any;
  //@ViewChild('modalghigiamtaisan', { static: false }) modalghigiamtaisan: ModalComponent;
  //@ViewChild('ctghigiamtaisanedit', { static: false }) ctghigiamtaisanedit: any;
  //@ViewChild('modalbantaisan', { static: false }) modalbantaisan: ModalComponent;
  //@ViewChild('ctbantaisanedit', { static: false }) ctbantaisanedit: any;
  //@ViewChild('modalmuataisan', { static: false }) modalmuataisan: ModalComponent;
  //@ViewChild('ctmuataisanedit', { static: false }) ctmuataisanedit: any;
  //@ViewChild('modalnhanhienvat', { static: false }) modalnhanhienvat: ModalComponent;
  //@ViewChild('ctnhanhienvatedit', { static: false }) ctnhanhienvatedit: any;
  //@ViewChild('modaltinhhaomontaisan', { static: false }) modaltinhhaomontaisan: ModalComponent;
  //@ViewChild('cttinhhaomontaisanedit', { static: false }) cttinhhaomontaisanedit: any;
  //@ViewChild('modalcamketchi', { static: false }) modalcamketchi: ModalComponent;
  //@ViewChild('ctcamketchiedit', { static: false }) ctcamketchiedit: any;
  //@ViewChild('modaldieuchinhcamketchi', { static: false }) modaldieuchinhcamketchi: ModalComponent;
  //@ViewChild('ctdieuchinhcamketchiedit', { static: false }) ctdieuchinhcamketchiedit: any;
  //@ViewChild('modaldieuchuyencongcu', { static: false }) modaldieuchuyencongcu: ModalComponent;
  //@ViewChild('ctdieuchuyencongcuedit', { static: false }) ctdieuchuyencongcuedit: any;
  //@ViewChild('modalghigiamcongcu', { static: false }) modalghigiamcongcu: ModalComponent;
  //@ViewChild('ctghigiamcongcuedit', { static: false }) ctghigiamcongcuedit: any;
  //@ViewChild('modalghitangcongcu', { static: false }) modalghitangcongcu: ModalComponent;
  //@ViewChild('ctghitangcongcuedit', { static: false }) ctghitangcongcuedit: any;
  //@ViewChild('modalmuacongcu', { static: false }) modalmuacongcu: ModalComponent;
  //@ViewChild('ctmuacongcuedit', { static: false }) ctmuacongcuedit: any;
  //@ViewChild('modalnhapkho', { static: false }) modalnhapkho: ModalComponent;
  //@ViewChild('ctnhapkhoedit', { static: false }) ctnhapkhoedit: any;
  //@ViewChild('modalphanbocongcu', { static: false }) modalphanbocongcu: ModalComponent;
  //@ViewChild('ctphanbocongcuedit', { static: false }) ctphanbocongcuedit: any;
  //@ViewChild('modalxuatkho', { static: false }) modalxuatkho: ModalComponent;
  //@ViewChild('ctxuatkhoedit', { static: false }) ctxuatkhoedit: any;




  doubleClick(row) {
    this.datatieude.forEach((item: any) => {
      if (item.ColumnName.toLowerCase() == "matk") {

        this.savept(row.MaTK);

      }
      else {
        if (item.ColumnName.toLowerCase() == "doituongid") {

          this.savept(row.DOITUONGID);

        }
      }




    });
  }
  savept(doituong: any) {


    //this.modalpc.dismiss();
    //this.modalpt.dismiss();
    //this.modalbangkethanhtoan.dismiss();
    //this.modalbaoco.dismiss();
    //this.modalbaono.dismiss();
    //this.modaltonghop.dismiss();
    //this.modalchuyentiennoibo.dismiss();
    //this.modalrutdutoanck.dismiss();
    //this.modalnhandutoan.dismiss();
    //this.modalhuydutoan.dismiss();
    //this.modaldcdutoan.dismiss();
    //this.modalruttg.dismiss();
    //this.modalcktaisan.dismiss();
    //this.modalnhantaisan.dismiss();
    //this.modalghigiamtaisan.dismiss();
    //this.modalmuataisan.dismiss();
    //this.modalnhanhienvat.dismiss();
    //this.modaltinhhaomontaisan.dismiss();
    //this.modalcamketchi.dismiss();
    //this.modaldieuchinhcamketchi.dismiss();
    //this.modaldieuchuyencongcu.dismiss();
    //this.modalghigiamcongcu.dismiss();
    //this.modalghitangcongcu.dismiss();
    //this.modalmuacongcu.dismiss();
    //this.modalnhapkho.dismiss();
    //this.modalphanbocongcu.dismiss();
    //this.modalxuatkho.dismiss();
    if (doituong != null) {
      // tải lại lưới khi sửa chứng từ
      this.changeTH.emit(doituong);
    }
    else {
      // tải lại lưới khi sửa chứng từ
      this.changeTH.emit(null);
    }
    //if (doituong != null) {
    //  this.export('table');
    //  if (doituong.Phan_He_Id.toLowerCase() == tiens_phieuchi.id.toLowerCase()) {
    //    this.modalpc.dismiss();
    //  }
    //  if (doituong.Phan_He_Id.toLowerCase() == tiens_phieuthu.id.toLowerCase()) {
    //    this.modalpt.dismiss();
    //  }
    //}
    //else {
    //  this.modalpc.dismiss();
    //  this.modalpt.dismiss();
    //}

  }
  changesearch(value) {
    let datatam = this.dataGridTimKiem;
    //console.log(datatam);
    this.datatieude.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.dataGrid = datatam;

  }
  SapXep(row: any) {

    let sapxep = row.SapXep;
    let trow = null;

    this.datatieude.forEach(
      (div) => {
        div.SapXep = null;
        if (div.ColumnName == row.ColumnName)
          trow = div;
      }
    );
    if (sapxep == null) {
      sapxep = 0;
      this.dataGrid = orderBy(this.dataGridTimKiem, [{ field: row.ColumnName, dir: "asc" }]);
    }
    else {
      if (sapxep == 1) {
        sapxep = null;
        this.dataGrid = this.dataGridTimKiem;
        //orderBy(this.dataGridTimKiem, [{ field: row.ColumnName, dir: "asc" }]);
      }
      else {
        sapxep = 1;
        this.dataGrid = orderBy(this.dataGridTimKiem, [{ field: row.ColumnName, dir: "desc" }]);
      }
    }

    trow.SapXep = sapxep;

  }
  constructor(private _dataService: ListService, private repo: Repository) {

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {
      this.currentUser = JSON.parse(authlogin);
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
    }
  }

  ngOnInit(): void {

  }


  loadDataInit(data: any) {

    this.dataGrid = data.dataGrid;
    this.dataGridTimKiem = data.dataGridTimKiem;
    this.datatieude = data.datatieude;
    this.sobanghi = data.sobanghi;
  }

  keyTenPress(event: any) {
    if (event.which === 13) {
      this.slCapTren.focus();
      event.preventDefault();
    }
  }
  public closeModal() {
    this.save.emit(null);
  }








}
