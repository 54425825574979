<div class="row ng-scope">
  <div class="col-12">

    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kết chuyển từ</label>
      <div class="col-9">
        <ng-select #slTaiKhoanTaiSan
                   class="twcollong"
                   autofocus
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputtaikhoantaisans"
                   bindValue="Id"
                   bindLabel="Ma_Tai_Khoan"
                   notFoundText="Không có dữ liệu"
                   (change)="onDoiTuongChange($event)"
                   [(ngModel)]="doituongSelect.Tai_Khoan_Di_Id">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>

    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kết chuyển đến</label>
      <div class="col-9">
        <ng-select #slTaiKhoanHaoMon
                   class="twcollong"
                   autofocus
                   [tabIndex]="7"
                   [openOnEnter]="false"
                   [items]="inputtaikhoantaisans"
                   bindValue="Id"
                   bindLabel="Ma_Tai_Khoan"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.Tai_Khoan_Den_Id">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>

    </div>

    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-9">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Giai">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Loại kết chuyển</label>
      <div class="col-9">
        <ng-select #slTieuMuc
                   class="twcol"
                   [tabIndex]="1"
                   [openOnEnter]="false"
                   [items]="inputloaiketchuyens"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Loai_Ket_Chuyen">


          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3 ng-binding">kiểu kết chuyển</label>
      <div class="col-9">
        <ng-select #slLoaiKhoan
                   class="twcol"
                   [openOnEnter]="false"
                   [tabIndex]="2"
                   [items]="inputkieuketchuyens"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Kieu_Ket_Chuyen">

          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>





    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Thứ tự</label>
      <div class="col-9">
        <input class="form-control"
               maxlength="20"
               currencyMask
               [(ngModel)]="doituongSelect.Thu_Tu"
               name="value"
               [placeholder]="'Số thứ tự'"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
    </div>
  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <input tabIndex="10" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary">Lưu lại</button>
  </div>

</div>


