import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';
const swal = require('sweetalert');
@Component({
    selector: 'app-tonghop-choncanboedit',
    templateUrl: './choncanboedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./choncanboedit.component.scss'],
    providers: [ListService, Repository]
})


export class choncanboeditComponent implements OnInit {
  doanhnghiepid: string = null;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  datas: any;
  data: any;
  public dulieu: any = { dataGrid: null, dataGridTimKiem: null, datatieude: null, sobanghi: null };
  indLoading: boolean = false;
  dataFrm: FormGroup;
  modalTitle: string;
  modalBtnTitle: string;
  Id: string;
  NgayHienTai: Date = new Date();
  inputDonVi: any;
  public DonViDropId: any = { Id: null, Ten: null };
  public TaiKhoanDropId: any = { Id: null, Ten: null };
  TaiKhoanId: any;
  selecteddonvis: any;
  selectedchuongs: any;
  selectedkhoans: any;
  Ma_Tai_Khoan: any;
  dropdownList_DonVi = [];
  inputTaiKhoan: any;
  dropdownList_TaiKhoan = [];
  selectedItems = [];
  dropdownSettings = {};
  khoangthoigian: number = 0;
  tabselected: number = 1;
  inputthangs: any = [
    { text: "Hôm nay", value: 0 },
    { text: "Tháng 1", value: 1 },
    { text: "Tháng 2", value: 2 },
    { text: "Tháng 3", value: 3 },
    { text: "Tháng 4", value: 4 },
    { text: "Tháng 5", value: 5 },
    { text: "Tháng 6", value: 6 },
    { text: "Tháng 7", value: 7 },
    { text: "Tháng 8", value: 8 },
    { text: "Tháng 9", value: 9 },
    { text: "Tháng 10", value: 10 },
    { text: "Tháng 11", value: 11 },
    { text: "Tháng 12", value: 12 },
    { text: "Quý I", value: 13 },
    { text: "Quý II", value: 14 },
    { text: "Quý III", value: 15 },
    { text: "Quý IV", value: 16 },
    { text: "Tuần này", value: 17 },
    { text: "Tháng này", value: 18 },
    { text: "Năm nay", value: 19 },
    { text: "Đầu năm đến nay", value: 20 },
    { text: "Năm trước", value: 21 },

  ];

  public thangs: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 }
  ];

  public quys: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },

  ];

  public nams: Array<{ text: string, value: number }> = [
    { text: (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    { text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    { text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];

  public chons: Array<{ text: string, value: number }> = [
    { text: "Theo ngày", value: 1 },
    { text: "Theo tháng", value: 2 },
    { text: "Theo quý", value: 3 },
    { text: "Theo năm", value: 4 },

  ];
  chongioitinhs: any = [{ Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Nữ" }
    , { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "Nam" }];
  TatCa: boolean = false;
  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number = 5;
  public TimKiem: string;
  public StartPage: number = 1;
  public EndPage: number = 20;
  public pageSize: number = 20;
  public pages: any;
  public totalPages: number;
  public TimKiems: any = {
    DonViId: null
    , UserName: null
    , TuNgay: null
    , DenNgay: null
    , TimKiem: null
    , startPage: null
    , endPage: null
    , HoVaTen: null
    , MaNV: null
    , Ngay: null
    , NgayS: null
    , SoCMND: null
    , IDGioiTinh: null
    , DiaChi: null
    , PhongBan: null
    , DonVi: null
    , KhoaHocId: null
  };
  public ngaytimkiem: Date = new Date();
  TichTatCa() {
    this.datas.forEach((item: any) => {
      item.Tich = !this.TatCa;
    });
  }
  public TuNgay: Date = new Date();
  public DenNgay: Date = new Date();
  public TuNgay1: Date = new Date();
  public DenNgay1: Date = new Date();
  public tTuNgay: string;
  public tDenNgay: string;

 


  doituongSelect: any = {
    Id: null, Don_Vi_Id: null, MaCongVan: null, NgayDen: null, NgayDenS: null,
    NgayBatDau: null, NgayBatDauS: null
    , NgayKetThuc: null, NgayKetThucS: null, TenCongViec: null,
    Phan_He_Id: null, NoiDung: null, IDCongViec: null
    , NoiDungCongViec: null, ChiTiets: []
  };
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: true
  };

  public currentUser;
  donviid: string = null;
  namketoan: number;
  username: string = null;
  phanheid: any;

  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository) {



  }

  // @HostListener('document:keydown', ['$event'])
  public innerHeight: any
  
  loadDataInit(id: string, pheid: string, lopid: string) {
    this.ngaytimkiem = null;
    this.StartPage = 1;
    this.EndPage = 20;
    this.ngaytimkiem = null;
    this.innerHeight = window.innerHeight - 250;
    this.phanheid = pheid;
    this.TimKiems.KhoaHocId = lopid;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.donviid = this.currentUser.id_don_vi;
      this.namketoan = this.currentUser.nam_ke_toan;
      this.username = this.currentUser.user_name;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        //if (giatri[0].Them == true)
        //  this.quyen_them = true;
        //else
        //  this.quyen_them = false;
       
      }
     
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
    }
    this.search();
        
    
  }
  adddata() {
   
  }
  editdata(id: string) {
  

  }
  savehspt(doituong: any) {
  
  }
  pageChanged(event: any): void {
    // alert(event.page);
    //console.log('a');
    //console.log(event);
    this.currentPage = event.page;
    this.Loaddatas();
  }
  setPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    this.Loaddatas();
  }
  public onBlur(): void {
    this.Loaddatas();
  }
  search() {
    this.currentPage = 1;
    this.pageSize = 1000;
    this.Loaddatas();
  }
  search1() {
    this.Loaddatas();
  }
  Loaddatas(): void {
    this.indLoading = true;
    this.datas = [];
    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.DonViId = this.currentUser.id_don_vi;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.TuNgay = this.tTuNgay;
    this.TimKiems.DenNgay = this.tDenNgay;
    this.TimKiems.PhanHeId = null;
    this.TimKiems.TimKiem = this.TimKiem;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;
    if (this.ngaytimkiem != null) {
      this.TimKiems.NgayS = this.ngaytimkiem.getDate() + '/' + (this.ngaytimkiem.getMonth() + 1) + '/' + this.ngaytimkiem.getFullYear();
    }
    else
      this.TimKiems.NgayS = null;

    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };

    this.repo.postRequest("api/donvi/GetDS_NhanSu/", data)
      .subscribe(response => {
        //console.log(response);
        this.totalPages = 0;
        this.totalItems = 0;
        if (response.length > 0) {

          var item = response[0];
          this.totalItems = item["totalRows"];
          this.totalPages = Math.ceil(this.totalItems / this.pageSize);

          let start, end;

          if (this.totalPages <= 5) {
            start = 1;
            end = this.totalPages;
          } else {
            if (this.currentPage <= 3) {
              start = 1;
              end = 5;
            } else if (this.currentPage + 1 >= this.totalPages) {
              start = this.totalPages - 4;
              end = this.totalPages;
            } else {
              start = this.currentPage - 2;
              end = this.currentPage + 2;
            }
          }



          // this.pages = range(start, end + 1);
          let resultArray: Array<any> = [];
          for (var i = start; i <= end; i++) {
            resultArray.push({ Id: i, Ten: null });
            //alert(i);
          }
          this.pages = resultArray;

          console.log(start);
          console.log(end);



        }
        this.datas = response;
        // this.source.load(response);
      });
  }

  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }
    this.LoadCategory();
  }

  LoadCategory(): void {

  }
  //----------event xử lý di chuyển Enter--------------------------------------
  tongtien: number = 0;
  maxcot: number = 13;

  onDoiTuongChange($event) {

    //if (typeof $event === "undefined") {
    //  this.doituongSelect.Ho_Ten = null;
    //}
    //else {
    //  this.doituongSelect.Ho_Ten = $event.Ten_Doi_Tuong;

    //  this.doituongSelect.ChiTiets.forEach(
    //    (item) => {
    //      if (item.Doi_Tuong_Id2 === null)
    //        item.Doi_Tuong_Id2 = $event.Id;
    //    }
    //  );

    //  this.repo.getRequest("api/baselayout/ThongTinDiaChi/" + $event.Id)
    //    .subscribe(response => {
    //      //console.log(response);
    //      this.doituongSelect.Dia_Chi = response.Dia_Chi;

    //    });
    //}
  }

  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;

  public savectu() {
    let tdoituong = [];
    this.datas.forEach(
      (item) => {
        if (item.Tich === true)
          tdoituong.push(item);
      }
    );
    if (tdoituong != null && tdoituong.length > 0)
      this.savect.emit(tdoituong);
    else
      this.savect.emit(null);

  }
  closeModal() {
    this.savect.emit(null);
  }

  

}
