<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Mã</label>
      <div class="col-10">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="Nếu không nhập hệ thống tự sinh" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
      </div>
      
      <!--<label class="control-label col-2 ng-binding">Cấp trên</label>
    <div class="col-4">

      <ng-select #slCapTren
                 class="twcol"
                 autofocus
                 [tabIndex]="2"
                 [openOnEnter]="false"
                 [items]="inputparents"
                 bindValue="Id"
                 bindLabel="Ten_Doi_Tuong"
                 notFoundText="Không có dữ liệu"
                 [searchFn]="comboSearchFn"
                 [(ngModel)]="doituongSelect.Parent">
        <ng-template ng-option-tmp let-item="item">

          <div class="rTableRow">
            <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
            <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
          </div>

        </ng-template>
      </ng-select>

    </div>-->
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Tên</label>
      <div class="col-10">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Địa chỉ</label>
      <div class="col-10">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Dia_Chi">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Điện thoại</label>
      <div class="col-4">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Dien_Thoai">
      </div>
      <label class="control-label col-2 ng-binding">Mã số thuế</label>
      <div class="col-4">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Ma_So_Thue">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Số tài khoản</label>
      <div class="col-4">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.So_Tai_Khoan">
      </div>
      <label class="control-label col-2 ng-binding">Chi nhánh</label>
      <div class="col-4">
        <input type="text" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Chi_Nhanh">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
      <div class="col-10">
        <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>


