import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  user: any = { HoSoId: null, UserName: null, Picture: null }
  currentUser: any;
    constructor(public userblockService: UserblockService) {

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

      if (this.currentUser != null) {
        this.user.HoSoId = this.currentUser.ho_so_id;
        this.user.Picture = this.currentUser.picture;
        this.user.UserName = this.currentUser.user_name;
      }

       
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
