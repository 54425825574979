<div class="item user-block" *ngIf="userBlockIsVisible()">
  <!-- User picture-->
  <div class="user-block-picture">
    <div class="user-block-status">
      <img class="img-thumbnail rounded-circle" [src]="user.Picture" alt="Avatar" />
      <div class="circle bg-success circle-lg"></div>
    </div>
  </div>
  <!-- Name and Job-->
  <div class="user-block-info">
    <span class="user-block-name">{{ 'sidebar.WELCOME' | translate }}  {{user.UserName}}</span>
    <span class="user-block-role"></span>
  </div>
</div>
