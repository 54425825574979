<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Mã</label>
      <div class="col-4">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
      </div>
      <label class="control-label col-2 ng-binding">Tên</label>
      <div class="col-4">
        <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Cấp trên</label>
      <div class="col-4">
        <ng-select #slCapTren
                   class="twcol"
                   [tabIndex]="3"
                   [openOnEnter]="false"
                   [items]="inputparents"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Parent">
          <ng-template ng-option-tmp let-item="item">

            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2 ng-binding">Nhà cung cấp</label>
      <div class="col-4">
        <ng-select #slNhaCungCap
                   class="twcol"
                   [tabIndex]="4"
                   [openOnEnter]="false"
                   [items]="inputnhacungcaps"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.To_Chuc">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row form-group" *ngIf="doituongSelect.To_Chuc==false">
      <label class="control-label col-2 ng-binding">Ngày sinh</label>
      <div class="col-4">
        <kendo-dateinput style="width:100%;" tabIndex="5" [format]="'dd/MM/yyyy'" [(value)]="ngaysinh"></kendo-dateinput>
      </div>
      <label class="control-label col-2 ng-binding">Giới tính/CMND</label>
      <div class="col-2">
        <ng-select #slGioiTinh
                   class="twcol"
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputgioitinhs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.Gioi_Tinh">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-2">
        <input type="text" tabIndex="7" class="form-control" placeholder="" [(ngModel)]="doituongSelect.CMND_So">
      </div>
    </div>
    <div class="row form-group" *ngIf="doituongSelect.To_Chuc==false">
     <label class="control-label col-2 ng-binding">Ngày cấp</label>
      <div class="col-4">
        <kendo-dateinput style="width:100%;" tabIndex="8" [format]="'dd/MM/yyyy'" [(value)]="ngaycmnd"></kendo-dateinput>
      </div>
      <label class="control-label col-2 ng-binding">Nơi cấp</label>
      <div class="col-4">
        <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="doituongSelect.CMND_NoiCap">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Địa chỉ</label>
      <div class="col-4">
        <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi">
      </div>
      <label class="control-label col-2 ng-binding">Điện thoại</label>
      <div class="col-4">
        <input type="text" tabIndex="11" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Email</label>
      <div class="col-4">
        <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Hom_Thu">
      </div>
      <label class="control-label col-2 ng-binding">Fax</label>
      <div class="col-4">
        <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Fax">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Điện thoại cơ quan</label>
      <div class="col-4">
        <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai_Co_Quan">
      </div>
      <label class="control-label col-2 ng-binding">Điện thoại nhà</label>
      <div class="col-4">
        <input type="text" tabIndex="15" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai_Nha">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Địa chỉ website</label>
      <div class="col-4">
        <input type="text" tabIndex="16" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi_Web">
      </div>
      <label class="control-label col-2 ng-binding">Mã số thuế</label>
      <div class="col-4">
        <input type="text" tabIndex="17" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_So_Thue">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Tên người liên hệ</label>
      <div class="col-4">
        <input type="text" tabIndex="18" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Hoa_Don">
      </div>
      <label class="control-label col-2 ng-binding">Xưng hô</label>
      <div class="col-4">
        <input type="text" tabIndex="19" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Xung_Ho">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Chức vụ</label>
      <div class="col-4">
        <input type="text" tabIndex="20" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Chuc_Vu">
      </div>
      <label class="control-label col-2 ng-binding">Địa chỉ người liên hệ</label>
      <div class="col-4">
        <input type="text" tabIndex="21" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi_Hoa_Don">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">TK ngân hàng</label>
      <div class="col-4">
        <input type="text" tabIndex="22" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Tai_Khoan_Ngan_Hang">
      </div>
      <label class="control-label col-2 ng-binding">Chi nhánh</label>
      <div class="col-4">
        <input type="text" tabIndex="23" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Ngan_Hang">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Giới hạn công nợ</label>
      <div class="col-4">
        <input id="gia" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Gioi_Han_Cong_No"
               name="value"
               tabIndex="24"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <label class="control-label col-2 ng-binding">Ghi chú</label>
      <div class="col-4">
        <input type="text" tabIndex="25" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ghi_Chu">
      </div>
    </div>

    <div class="row form-group">
      <div class="col-2">
        <label>
          <input tabIndex="50" type="checkbox" [(ngModel)]="doituongSelect.La_Nguoi_Lien_Lac" />
          <!--<span class="fa fa-check"></span>--> NCC, KH là người liên lạc
        </label>
      </div>
      <div class="col-2">
        <label>
          <input tabIndex="51" type="checkbox" [(ngModel)]="doituongSelect.Rieng_Tu" />
          <!--<span class="fa fa-check"></span>--> Riêng tư
        </label>
      </div>
      <div class="col-2">
        <label>
          <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Web" />
          <!--<span class="fa fa-check"></span>--> Web
        </label>
      </div>
      <div class="col-2">
        <label>
          <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
          <!--<span class="fa fa-check"></span>--> Hoạt động
        </label>
      </div>

    </div>

    <!--<div class="row form-group">
    <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
    <div class="col-10">
      <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
    </div>
  </div>-->


  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>
