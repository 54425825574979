
<ejs-filemanager #fileManager id='overview' [ajaxSettings]='ajaxSettings' [view]='view'
                 (beforeSend)="OnbeforeSend($event)" locale="vi"
                 (menuOpen)="onmenuOpen($event)"
                 >
</ejs-filemanager>






