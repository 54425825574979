<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}">
        <a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id="">1.Thông tin chi tiết</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}" *ngIf="la_hop_dong_ban===true">
        <a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id="">2.Danh sách hàng hóa</a>
      </li>
    </ul>
    <a (click)="RowNewClick()" *ngIf="tabselected==2" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng</a>
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="col-12">
        <div class="row form-group">
          <label class="control-label col-2">Mã</label>
          <div class="col-4">
            <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
          </div>
          <label class="control-label col-2 ng-binding">Tên</label>
          <div class="col-4">
            <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
          </div>
        </div>


        <div class="row form-group" *ngIf="la_hop_dong_ban===true">
          <label class="control-label col-2 ng-binding">Khách hàng</label>
          <div class="col-4">
            <a *ngIf="doituongSelect.Doi_Tuong_Id===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="3" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Doi_Tuong">
            </a>
            <a *ngIf="doituongSelect.Doi_Tuong_Id!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="3" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Doi_Tuong" (ngModelChange)="adddatachondoituongchung(1)">
            </a>

            <!--<ng-select #slKhachhang
             class="twcol"
             [tabIndex]="3"
             [openOnEnter]="false"
             [items]="inputkhachhangs"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             [searchFn]="comboSearchFn"
             [(ngModel)]="doituongSelect.Doi_Tuong_Id">
    <ng-template ng-option-tmp let-item="item">

      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->

          </div>
          <label class="control-label col-2 ng-binding">Kiểu thanh toán</label>
          <div class="col-4">
            <ng-select #slKieuThanhToan
                       class="twcol"
                       [tabIndex]="4"
                       [openOnEnter]="false"
                       [items]="inputkieuthanhtoans"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Thanh_Toan">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
        </div>

        <div class="row form-group" *ngIf="la_hop_dong_ban===true">
          <label class="control-label col-2 ng-binding">Kiểu bán lẻ</label>
          <div class="col-4">
            <ng-select #slKieubanle
                       class="twcol"
                       [tabIndex]="5"
                       [openOnEnter]="false"
                       [items]="inputkieubanles"
                       bindValue="Id"
                       bindLabel="Ten"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Kieu_Ban_Le">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
          <label class="control-label col-2 ng-binding">Nhân viên bán</label>
          <div class="col-4">
            <ng-select #slNhanVien
                       class="twcol"
                       [tabIndex]="6"
                       [openOnEnter]="false"
                       [items]="inputnhanviens"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Nhan_Vien_Id">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
        </div>

        <div class="row form-group" *ngIf="la_hop_dong_ban===false">
          <label class="control-label col-2 ng-binding">Nhà cung cấp</label>
          <div class="col-4">
            <ng-select #slNhaCungCap
                       class="twcol"
                       [tabIndex]="7"
                       [openOnEnter]="false"
                       [items]="inputnhacungcaps"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Doi_Tuong_Id">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
          <label class="control-label col-2 ng-binding">Kiểu thanh toán</label>
          <div class="col-4">
            <ng-select #slKieuThanhToan
                       class="twcol"
                       [tabIndex]="8"
                       [openOnEnter]="false"
                       [items]="inputkieuthanhtoans"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Thanh_Toan">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
        </div>

        <div class="row form-group" *ngIf="la_hop_dong_ban===false">
          <label class="control-label col-2 ng-binding">Kiểu mua hàng</label>
          <div class="col-4">
            <ng-select #slKieubanle
                       class="twcol"
                       [tabIndex]="9"
                       [openOnEnter]="false"
                       [items]="inputkieumuahangs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Kieu_Ban_Le">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
          <label class="control-label col-2 ng-binding">Nhân viên mua</label>
          <div class="col-4">
            <ng-select #slNhanVien
                       class="twcol"
                       [tabIndex]="10"
                       [openOnEnter]="false"
                       [items]="inputnhanviens"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Nhan_Vien_Id">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Mã đơn vị</label>
          <div class="col-4">
            <ng-select #slKieubanle
                       class="twcol"
                       [tabIndex]="11"
                       [openOnEnter]="false"
                       [items]="inputmadonvis"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Cua_Hang_Id">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>

          </div>
          <label class="control-label col-2 ng-binding">Hủy hợp đồng</label>
          <div class="col-4">
            <label>
              <input tabIndex="12" type="checkbox" [(ngModel)]="doituongSelect.Huy_Hop_Dong" />
            </label>


          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Ngày ký</label>
          <div class="col-4">
            <kendo-dateinput style="width:100%;" tabIndex="13" [format]="'dd/MM/yyyy'" [(value)]="ngayky"></kendo-dateinput>
          </div>
          <label class="control-label col-2 ng-binding">Hạn thanh toán</label>
          <div class="col-4">
            <kendo-dateinput style="width:100%;" tabIndex="14" [format]="'dd/MM/yyyy'" [(value)]="hanthanhtoan"></kendo-dateinput>
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Giá bán thực</label>
          <div class="col-4">
            <input id="gio" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Gia_Ban"
                   name="value"
                   tabIndex="15"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
          <label class="control-label col-2 ng-binding">Giá trị hợp đồng</label>
          <div class="col-4">
            <input id="gia" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Gia_Tri"
                   name="value"
                   tabIndex="16"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Tiền tệ</label>
          <div class="col-4">
            <ng-select #slTienTe
                       class="twcol"
                       [tabIndex]="17"
                       [openOnEnter]="false"
                       [items]="inputtientes"
                       bindValue="Id"
                       bindLabel="Ten"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Tien_Te_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Giá trị ngoại tệ</label>
          <div class="col-4">
            <input id="gia" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Gia_Tri_Ngoai_Te"
                   name="value"
                   tabIndex="18"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Tài khoản</label>
          <div class="col-4">
            <ng-select #slTaiKhoan
                       class="twcol"
                       [tabIndex]="19"
                       [openOnEnter]="false"
                       [items]="inputtaikhoans"
                       bindValue="Id"
                       bindLabel="Ten_Tai_Khoan"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFnTK"
                       [(ngModel)]="doituongSelect.Tai_Khoan_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Động cơ</label>
          <div class="col-4">
            <input type="text" tabIndex="20" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dong_Co">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Đặt cọc</label>
          <div class="col-4">
            <input id="gia" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Dat_Coc"
                   name="value"
                   tabIndex="21"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
          <label class="control-label col-2 ng-binding">Phụ kiện</label>
          <div class="col-4">
            <input type="text" tabIndex="22" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Phu_Kien">
          </div>
        </div>


        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Ghi chú</label>
          <div class="col-10">
            <input type="text" tabIndex="23" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ghi_Chu">
          </div>
        </div>

        <!--<div class="row form-group">
          <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
          <div class="col-10">
            <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
          </div>
        </div>-->


      </div>

    </div>

    <div class="tab-pane" [ngClass]="{'active': tabselected==2}" *ngIf="la_hop_dong_ban===true">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="min-height:400px;background:#fff;">
          <table class="table tableinput table-bordered table-hover">
            <thead>
              <tr>
                <th width="50px">#</th>
                <th width="180px">Xe</th>
                <th width="100px">Model</th>
                <th width="130px">Đặc tả</th>
                <th width="130px">Màu xe</th>
                <th width="130px">Số lượng</th>
                <th width="130px">Ngày giao xe</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let row of doituongSelect.HangHoas; let i = index' style="height:30px">
                <td>
                  <div style="width:50px;">
                    <a title="Xóa dòng" (click)="deleterow(i,3)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <a *ngIf="row.Hang_Hoa_Id===null" (click)="adddatachondoituongchung_luoi('Hang_Hoa_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_3_1" type="text"
                           (keydown)="keyComBoPress($event,i,3,1)" class="form-control"
                            style="width:100% !important;height: 30px !important" 
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa">
                  </a>
                  <a *ngIf="row.Hang_Hoa_Id!==null" (dblclick)="adddatachondoituongchung_luoi('Hang_Hoa_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_3_1" type="text"
                           (keydown)="keyComBoPress($event,i,3,1)" class="form-control"
                            style="width:100% !important;height: 30px !important" 
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa" (ngModelChange)="adddatachondoituongchung_luoi('Hang_Hoa_Id',i)">
                  </a>

                  <!--<ng-select #slLuoi0 (keydown)="keyComBoPress($event,i,3,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             labelForId="{{i}}_3_1"
             [openOnEnter]="false"
             [items]="inputxes"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Hang_Hoa_Id">

    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>
    </ng-template>
  </ng-select>-->
                </td>
                <td>
                  <ng-select #slLuoi1 (keydown)="keyComBoPress($event,i,3,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_3_2"
                             [openOnEnter]="false"
                             [items]="inputmodelxes"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Model_Id">

                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi3 (keydown)="keyComBoPress($event,i,3,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_3_3"
                             [openOnEnter]="false"
                             [items]="inputdactas"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Dac_Ta_Id">

                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi4 (keydown)="keyComBoPress($event,i,3,4)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_3_4"
                             [openOnEnter]="false"
                             [items]="inputmauxes"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Mau_Xe_Id">

                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <input id="{{i}}_3_5" #myinput type="number" style="width:100% !important;height: 30px !important" (keydown)="keyComBoPress($event,i,3,1)" class="form-control" placeholder="" [(ngModel)]="row.So_Luong">
                </td>
                <td>
                  <kendo-dateinput style="width:100%;" tabIndex="8" [format]="'dd/MM/yyyy'" [(value)]="row.Ngay_Giao_Xe"></kendo-dateinput>
                </td>


              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>




