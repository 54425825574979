<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}">
        <a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id="">1.Thông tin chi tiết</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}">
        <a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id="">2.Thông tin bán hàng</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}">
        <a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id="">3.Thông tin vị trí đặt</a>
      </li>
    </ul>
    <a (click)="RowNewClick()" *ngIf="tabselected==3" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng</a>
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="col-12">
        <div class="row form-group">
          <label class="control-label col-2">Mã</label>
          <div class="col-4">
            <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
          </div>
          <label class="control-label col-2 ng-binding">Cấp trên</label>
          <div class="col-4">
            <a *ngIf="doituongSelect.Parent===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha">
            </a>
            <a *ngIf="doituongSelect.Parent!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha" (ngModelChange)="adddatachondoituongchung(1)">
            </a>
            <!--<ng-select #slCapTren
             class="twcol"
             [tabIndex]="2"
             [openOnEnter]="false"
             [items]="inputparents"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             [searchFn]="comboSearchFn"
             [(ngModel)]="doituongSelect.Parent">
    <ng-template ng-option-tmp let-item="item">

      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->

          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Tên</label>
          <div class="col-10">
            <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Hãng</label>
          <div class="col-4">
            <ng-select #slHangxe
                       class="twcol"
                       [tabIndex]="6"
                       [openOnEnter]="false"
                       [items]="inputhangxes"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       (change)="onChangeHangXe($event)"
                       [(ngModel)]="doituongSelect.Hang_Xe">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Model</label>
          <div class="col-4">
            <ng-select #slPhongban
                       class="twcol"
                       [tabIndex]="7"
                       [openOnEnter]="false"
                       [items]="inputmodels"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [searchFn]="comboSearchFn"
                       [(ngModel)]="doituongSelect.Model_Xe">
              <ng-template ng-option-tmp let-item="item">

                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>
          </div>
        </div>

       
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Mã vạch</label>
          <div class="col-4">
            <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Vach">
          </div>
          <label class="control-label col-2 ng-binding">Tên khác</label>
          <div class="col-4">
            <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Khac">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Chất liệu</label>
          <div class="col-10">
            <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Chat_Lieu">
          </div>
         
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Đơn vị tính</label>
          <div class="col-4">
            <ng-select #slDonViTinh
                       class="twcol"
                       [tabIndex]="16"
                       [openOnEnter]="false"
                       [items]="inputdonvitinhs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Don_Vi_Tinh_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <label class="control-label col-2 ng-binding">Xuất xứ</label>
          <div class="col-4">
            <ng-select #slDonViTinh
                       class="twcol"
                       [tabIndex]="17"
                       [openOnEnter]="false"
                       [items]="inputxuatxus"
                       bindValue="Id"
                       bindLabel="Ten"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Xuat_Xu_Id">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Ghi chú</label>
          <div class="col-10">
            <input type="text" tabIndex="18" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ghi_Chu">
          </div>
        </div>

        <!--<div class="row form-group">
    <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
    <div class="col-10">
      <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
    </div>
  </div>-->


      </div>

    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Thuế suất</label>
        <div class="col-4">
          <input id="gio" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Thue_Suat"
                 name="value"
                 tabIndex="36"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <label class="control-label col-2 ng-binding">Giá nhập</label>
        <div class="col-4">
          <input id="gia" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Nhap"
                 name="value"
                 tabIndex="37"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Giá bán lẻ</label>
        <div class="col-4">
          <input id="gio" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Ban_Le"
                 name="value"
                 tabIndex="38"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <label class="control-label col-2 ng-binding">Giá bán buôn</label>
        <div class="col-4">
          <input id="gia" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="doituongSelect.Gia_Ban_Buon"
                 name="value"
                 tabIndex="39"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-2">
          <label>
            <input tabIndex="50" type="checkbox" [(ngModel)]="doituongSelect.Theo_Doi_Ton" />
            <!--<span class="fa fa-check"></span>--> Theo dõi tồn
          </label>
        </div>
        <div class="col-2">
          <label>
            <input tabIndex="51" type="checkbox" [(ngModel)]="doituongSelect.Co_Hoa_Don" />
            <!--<span class="fa fa-check"></span>--> Có hóa đơn
          </label>
        </div>
        <div class="col-2">
          <label>
            <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Chinh_Hang" />
            <!--<span class="fa fa-check"></span>--> Chính hãng
          </label>
        </div>


      </div>
    </div>

    <div class="tab-pane" [ngClass]="{'active': tabselected==3}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="min-height:300px;background:#fff;">
          <table class="table tableinput table-bordered table-hover">
            <thead>
              <tr>
                <th width="50px">#</th>
                <th width="200px">Kho hàng</th>
                <th width="100px">Thuộc tầng</th>
                <th width="150px">Thuộc kệ</th>
                <th width="150px">Thuộc ô</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let row of doituongSelect.ViTris; let i = index' style="height:30px">
                <td>
                  <div style="width:50px;">
                    <a title="Xóa dòng" (click)="deleterow(i,3)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <ng-select #slLuoi0 (keydown)="keyComBoPress($event,i,3,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_3_1"
                             [openOnEnter]="false"
                             [items]="inputkhohangs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomloaikhoanid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Kho_Hang_Id">

                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <input id="{{i}}_3_2" #myinput type="text" style="width:100% !important" (keydown)="keyComBoPress($event,i,3,1)" class="form-control" placeholder="" [(ngModel)]="row.Tang">
                </td>
                <td>
                  <input id="{{i}}_3_3" #myinput type="text" style="width:100% !important" (keydown)="keyComBoPress($event,i,3,1)" class="form-control" placeholder="" [(ngModel)]="row.Ke">
                </td>
                <td>
                  <input id="{{i}}_3_4" #myinput type="text" style="width:100% !important" (keydown)="keyComBoPress($event,i,3,1)" class="form-control" placeholder="" [(ngModel)]="row.O">
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>




<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>
