import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { LocalDataSource } from 'ng2-smart-table';
import { NumberViewComponent } from '../../../shared/components/public/numberview.component';
import { DateViewComponent } from '../../../shared/components/public/dateview.component';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';
import {
  Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
  , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
  , Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
  , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
  , Lists_khachhang, Lists_nhanvien
  , Lists_congcu, Lists_taisancodinh, Lists_chiphi
  , Lists_tiente, Lists_xuatxu, Lists_tinh, Lists_mauson, Lists_giaodich
  , Lists_keuoc, Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
  , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
  , Lists_loaisuachua, Lists_dichvu, Lists_xesua, Lists_nganhang

} from '../../../routes/menu';
const swal = require('sweetalert');


@Component({
  selector: 'app-ketoan-phanboedit',
  templateUrl: './phanboedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./phanboedit.component.scss'],
  providers: [ListService, Repository]
})


export class phanboeditComponent implements OnInit {
  HienThi_PhanBo: boolean = false;
  HienThi_Luu: boolean = false;
  HienThi_KetChuyen: boolean = false;
  XoaCT: boolean = true;
  TuThang: number = 1;
  DenThang: number = 1;

  @ViewChild('mdLuaChon_doituong') mdLuaChon_doituong: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;

  @ViewChild('modalchitietketchuyen') modalchitietketchuyen: BsModalComponent;
  @ViewChild('dtchitietketchuyenedit') dtchitietketchuyenedit: any;
  doanhthu: number = 0;
  tienson: number = 0;

  doituongkethua: any;
  doanhnghiepid: string = null;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  datas: any;
  data: any;
  public dulieu: any = { dataGrid: null, dataGridTimKiem: null, datatieude: null, sobanghi: null };
  indLoading: boolean = false;
  dataFrm: FormGroup;
  modalTitle: string;
  modalBtnTitle: string;
  Id: string;
  NgayHienTai: Date = new Date();
  inputDonVi: any;
  public DonViDropId: any = { Id: null, Ten: null };
  public TaiKhoanDropId: any = { Id: null, Ten: null };
  TaiKhoanId: any;
  selecteddonvis: any;
  selectedchuongs: any;
  selectedkhoans: any;
  Ma_Tai_Khoan: any;
  dropdownList_DonVi = [];
  inputTaiKhoan: any;
  dropdownList_TaiKhoan = [];
  selectedItems = [];
  dropdownSettings = {};
  khoangthoigian: number = 0;
  tabselected: number = 1;
  inputtaikhoantaisans: any;

  inputthangs: any = [
    { text: "Tháng 1", value: 1 },
    { text: "Tháng 2", value: 2 },
    { text: "Tháng 3", value: 3 },
    { text: "Tháng 4", value: 4 },
    { text: "Tháng 5", value: 5 },
    { text: "Tháng 6", value: 6 },
    { text: "Tháng 7", value: 7 },
    { text: "Tháng 8", value: 8 },
    { text: "Tháng 9", value: 9 },
    { text: "Tháng 10", value: 10 },
    { text: "Tháng 11", value: 11 },
    { text: "Tháng 12", value: 12 },
    { text: "Quý I", value: 13 },
    { text: "Quý II", value: 14 },
    { text: "Quý III", value: 15 },
    { text: "Quý IV", value: 16 },
    { text: "Năm nay", value: 19 },
    { text: "Năm trước", value: 21 },
  ];

  public inputloais: Array<{ Id: number, Ma_Doi_Tuong: string, Ten_Doi_Tuong: string }> = [
    { Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Phân bổ, kc tiền vật tư sơn" },
    { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "K/c tiền phụ tùng sửa chữa" },
  ];


  public thangs: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 }
  ];

  public quys: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },

  ];

  public nams: Array<{ text: string, value: number }> = [
    { text: (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    { text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    { text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];

  public chons: Array<{ text: string, value: number }> = [
    { text: "Theo ngày", value: 1 },
    { text: "Theo tháng", value: 2 },
    { text: "Theo quý", value: 3 },
    { text: "Theo năm", value: 4 },

  ];
  chongioitinhs: any = [{ Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Nữ" }
    , { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "Nam" }];
  TatCa: boolean = false;
  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number = 5;
  public TimKiem: string;
  public StartPage: number = 1;
  public EndPage: number = 20;
  public pageSize: number = 20;
  public pages: any;
  public totalPages: number;
  public TimKiems: any = {
    Don_Vi_Id: null
    , Doanh_Nghiep_Id: null
    , UserName: null
    , Nam: null
    , TuNgay: null
    , DenNgay: null
    , Phan_He_Id: null
    , TimKiem: null
    , Loai: 0
    , startPage: null
    , endPage: null
    , Ngay: null
    , NgayS: null
    , Ma: null
    , Ten: null
    , Cha: null
    , XoaCT: false
    , TuThang: 1
    , DenThang: 1
    , SoTien: null
    , GhiChu: null
    , NhomDoiTuong: null
    , SapXep: null
    , Modified: null
    , ModifiedS: null
    , ModifiedBy: null
    , Id: null
    , TKNo: null
    , TKCo: null
    , DienGiai: null
    , MaChungTu: null
    , NgayGhiSo: null
    , NgayChungTu: null
    , ChiTiets:[]
  };
  public ngaytimkiem: Date = new Date();
  TichTatCa() {
    this.datas.forEach((item: any) => {
      item.Tich = !this.TatCa;
    });
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  public TuNgay: Date = new Date();
  public DenNgay: Date = new Date();
  public TuNgay1: Date = new Date();
  public DenNgay1: Date = new Date();
  public tTuNgay: string;
  public tDenNgay: string;




  idFocus: string = '00000000-0000-0000-0000-000000000000';

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: true
  };

  public currentUser;
  donviid: string = null;
  namketoan: number;
  username: string = null;
  phanheid: any;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository) {



  }

  // @HostListener('document:keydown', ['$event'])
  public innerHeight: any
  quyen_them: boolean = false;
  loadDataInit(pheid: string) {
    this.HienThi_PhanBo = false;
    this.HienThi_Luu = false;
    this.HienThi_KetChuyen = false;

    this.tabselected = 1;
    this.ngaytimkiem = null;
    this.StartPage = 1;
    this.EndPage = 20;
    this.ngaytimkiem = null;
    this.innerHeight = window.innerHeight - 250;
    this.phanheid = pheid;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.donviid = this.currentUser.id_don_vi;
      this.namketoan = this.currentUser.nam_ke_toan;
      this.username = this.currentUser.user_name;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;

      }

      this.datas = [];
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

      let tdate = new Date();
      this.khoangthoigian = tdate.getMonth()+1;
      this.TimKiems.TuNgay = new Date(this.namketoan, tdate.getMonth(), 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay = new Date(this.namketoan, tdate.getMonth() + 1, 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay.setDate(this.TimKiems.DenNgay.getDate() - 1);

    }
    //this.search();

    this.repo.postRequest('api/taikhoan/dstaikhoanv2', { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputtaikhoantaisans = response;
      });


  }

  public onChangeTuNgay(value) {
    if (value != null) {
      this.tTuNgay = value.getDate() + '/' + (value.getMonth() + 1) + '/' + value.getFullYear();

    }
    else
      this.tTuNgay = '';
  }
  public onChangeDenNgay(value) {
    if (value != null) {
      this.tDenNgay = value.getDate() + '/' + (value.getMonth() + 1) + '/' + value.getFullYear();
    }
    else
      this.tDenNgay = '';
  }

  adddata() {
    this.modalTitle = "Chi tiết cấu hình";
    this.modalBtnTitle = "Cập nhật";
    this.dtchitietketchuyenedit.loadDataInit(this.phanheid, null);
    this.modalchitietketchuyen.open();
  }



  editdata(id: string) {
    this.modalTitle = "Chi tiết cấu hình";
    this.modalBtnTitle = "Cập nhật";
    this.dtchitietketchuyenedit.loadDataInit(this.phanheid, id);
    this.modalchitietketchuyen.open();
  }
  saveModal(id: any) {
    this.Loaddatas();
    this.modalchitietketchuyen.close();
  }
  deletedata(id: string) {


    swal({
      title: 'Bạn muốn xóa bản ghi?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          $('#loading').show();
          this.repo.postRequest('api/chungtu/deletecauhinhketchuyen/', { Id: id, PhanHeId: this.phanheid, UserName: this.currentUser.user_name, DoanhNghiepId: this.doanhnghiepid }).subscribe(
            data => {
              $('#loading').hide();
              if (data === "OK") //Success
              {
                swal('Deleted!', 'Dữ liệu đã được xóa!.', 'success');

                this.Loaddatas();
              }
              else {
                // this.msg = data;
                swal('Cảnh báo!', data, 'warning');
              }


            },
            error => {
              //this.msg = error;
            }
          );
        }
      });


  }


  pageChanged(event: any): void {
    // alert(event.page);
    //console.log('a');
    //console.log(event);
    this.currentPage = event.page;
    this.Loaddatas();
  }
  setPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    this.Loaddatas();
  }
  public onBlur(): void {
    //this.Loaddatas();
  }
  search() {
    this.currentPage = 1;
    this.pageSize = 50;

    if (this.TimKiems.Loai == 0) {
      this.HienThi_PhanBo = true;
    }
    else {
      this.HienThi_PhanBo = false;
      this.HienThi_Luu = false;
      this.HienThi_KetChuyen = true;

      let tphanheid = "CE4F89FF-0A00-43D0-BADC-AAB6AB860E0E";

      this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response1 => {
          if (response1 != null) {
            this.TimKiems.MaChungTu = response1[0].Ma_Chung_Tu;
          }
        });

      let tdate = new Date();
      this.TimKiems.NgayChungTu = this.TimKiems.DenNgay;
      this.TimKiems.NgayGhiSo = this.TimKiems.DenNgay;

      if (this.TimKiems.Loai == 0) {
        this.TimKiems.DienGiai = "Kết chuyển tiền vật tư sơn tháng " + (this.TimKiems.NgayGhiSo.getMonth() + 1) + "/" + this.TimKiems.NgayGhiSo.getFullYear();
        this.TimKiems.TKNo = ("ED6E75CB-5A6C-4075-851D-AC91C4D8406D").toLowerCase();
        this.TimKiems.TKCo = ("7808E457-ECC8-4E48-91EC-EB5C8A93E7C9").toLowerCase();
      }
      if (this.TimKiems.Loai == 1) {
        this.TimKiems.DienGiai = "Kết chuyển tiền phụ tùng sửa chữa tháng " + (this.TimKiems.NgayGhiSo.getMonth() + 1) + "/" + this.TimKiems.NgayGhiSo.getFullYear();
        this.TimKiems.TKNo = ("ED6E75CB-5A6C-4075-851D-AC91C4D8406D").toLowerCase();
        this.TimKiems.TKCo = ("E204A1E0-C451-4DFA-BB71-060E3D9779E4").toLowerCase();
      }


    }

    this.Loaddatas();
  }
  search1() {
    this.Loaddatas();
  }
  chiaphanbo() {
    this.HienThi_Luu = true;
    this.TimKiems.ChiTiets = this.datas;

    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    $('#loading').show();
    this.repo.postRequest("api/chungtu/chiaphanbo/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        this.totalPages = 0;
        this.totalItems = response.Data.length;
        if (response.TrangThai == "OK") {
          this.datas = response.Data;
          //this.TimKiems.SoTien = response.SoTien;
        }
        else
          swal('Cảnh báo!', response.TrangThai, 'warning');

        //(window as any).timer(3, 1500);

      });


  }
  phanbo() {
    
    

    if (this.TimKiems.TuNgay == null) {
      swal('Lỗi!', 'Vui lòng nhập từ ngày!.', 'error');
      return;
    }
    if (this.TimKiems.DenNgay == null) {
      swal('Lỗi!', 'Vui lòng nhập đến ngày!.', 'error');
      return;
    }

    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;

    if (this.datas != null) {
      this.TimKiems.Id = this.datas[0].Id;
    }

    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };

    swal({
      title: 'Bạn muốn kết chuyển không?',
      text: 'Thông báo!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }) 
      .then(willDelete => {
        if (willDelete) {

          $('#loading').show();
          this.repo.postRequest("api/chungtu/luuphanbo/", this.TimKiems)
            .subscribe(response => {
              $('#loading').hide();
              if (response.TrangThai == "OK") {
                // this.datas = response.Data;
                swal('Thông báo!', "Phân bổ thành công!", 'success');
              }
              else
                swal('Cảnh báo!', response.TrangThai, 'warning');


            });

        }
      });



  }
  savebg() {

    if (this.TimKiems.TuNgay == null) {
      swal('Lỗi!', 'Vui lòng nhập từ ngày!.', 'error');
      return;
    }
    if (this.TimKiems.DenNgay == null) {
      swal('Lỗi!', 'Vui lòng nhập đến ngày!.', 'error');
      return;
    }

    this.HienThi_KetChuyen = true;

    let tphanheid = "CE4F89FF-0A00-43D0-BADC-AAB6AB860E0E";

    this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response1 => {
        if (response1 != null) {
          this.TimKiems.MaChungTu = response1[0].Ma_Chung_Tu;
        }
      });

    let tdate = new Date();
    this.TimKiems.NgayChungTu = this.TimKiems.DenNgay;
    this.TimKiems.NgayGhiSo = this.TimKiems.DenNgay;
    if (this.TimKiems.Loai == 0) {
      this.TimKiems.DienGiai = "Kết chuyển tiền vật tư sơn tháng " + (this.TimKiems.NgayGhiSo.getMonth() + 1) + "/" + this.TimKiems.NgayGhiSo.getFullYear();
      this.TimKiems.TKNo = ("ED6E75CB-5A6C-4075-851D-AC91C4D8406D").toLowerCase();
      this.TimKiems.TKCo = ("7808E457-ECC8-4E48-91EC-EB5C8A93E7C9").toLowerCase();
    }
    if (this.TimKiems.Loai == 1) {
      this.TimKiems.DienGiai = "Kết chuyển tiền phụ tùng sửa chữa tháng " + (this.TimKiems.NgayGhiSo.getMonth() + 1) + "/" + this.TimKiems.NgayGhiSo.getFullYear();
      this.TimKiems.TKNo = ("ED6E75CB-5A6C-4075-851D-AC91C4D8406D").toLowerCase();
      this.TimKiems.TKCo = ("E204A1E0-C451-4DFA-BB71-060E3D9779E4").toLowerCase();
    }




   

    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;

    if (this.datas != null) {
      this.TimKiems.Id = this.datas[0].Id;
    }

    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };

    $('#loading').show();
    this.repo.postRequest("api/chungtu/luutienson/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        if (response.TrangThai == "OK") {
          // this.datas = response.Data;
          swal('Thông báo!', "Lưu thành công!", 'success');
        }
        else
          swal('Cảnh báo!', response.TrangThai, 'warning');


      });


  }
  Loaddatas(): void {

    this.doanhthu = 0;
    this.tienson = 0;

    this.datas = [];
    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;
    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    $('#loading').show();
    this.repo.postRequest("api/chungtu/get_phanbo/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        this.totalPages = 0;
        this.totalItems = response.Data.length;
        if (response.TrangThai == "OK") {
          this.datas = response.Data;
          this.TimKiems.SoTien = response.SoTien;

          this.datas.forEach(
              (item) => {
                this.doanhthu = this.doanhthu + (item.Doanh_Thu === null ? 0 : item.Doanh_Thu);
                this.tienson = this.tienson + (item.Tien_Son === null ? 0 : item.Tien_Son);
              }
            );

          if (this.TimKiems.Loai == 1) {
            this.TimKiems.ChiTiets = this.datas;

            let tphanheid = "CE4F89FF-0A00-43D0-BADC-AAB6AB860E0E";

            this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
              .subscribe(response1 => {
                if (response1 != null) {
                  this.TimKiems.MaChungTu = response1[0].Ma_Chung_Tu;
                }
              });
          }

        }
        else
          swal('Cảnh báo!', response.TrangThai, 'warning');

        //(window as any).timer(3, 1500);

      });



  }

  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }
    this.LoadCategory();
  }

  LoadCategory(): void {

  }
  //----------event xử lý di chuyển Enter--------------------------------------
  
  maxcot: number = 13;

  onDoiTuongChange($event) {
    var getNow = new Date();
    var Nam = this.currentUser.nam_ke_toan;

    if (typeof $event !== "undefined") {
      if ($event.value > 0 && $event.value < 13) {
        this.TimKiems.TuNgay = new Date(Nam, $event.value - 1, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, $event.value, 0, 0, 0, 0);
      }
      if ($event.value === 13) {
        this.TimKiems.TuNgay = new Date(Nam, 0, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, 3, 0, 0, 0, 0);
      }
      if ($event.value === 14) {
        this.TimKiems.TuNgay = new Date(Nam, 3, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, 6, 0, 0, 0, 0);
      }
      if ($event.value === 15) {
        this.TimKiems.TuNgay = new Date(Nam, 6, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, 9, 0, 0, 0, 0);
      }
      if ($event.value === 16) {
        this.TimKiems.TuNgay = new Date(Nam, 9, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, 11, 31, 0, 0, 0);
      }
      if ($event.value === 17) {
        var current = new Date();     // get current date    
        var weekstart = current.getDate() - current.getDay() + 1;
        var weekend = weekstart + 6;       // end day is the first day + 6 
        this.TimKiems.TuNgay = new Date(current.setDate(weekstart));
        this.TimKiems.DenNgay = new Date(current.setDate(weekend));
      }
      if ($event.value === 18) {
        var thang = getNow.getMonth() + 1;
        this.TimKiems.TuNgay = new Date(Nam, thang - 1, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, thang, 0, 0, 0, 0);
      }
      if ($event.value === 19) {
        this.TimKiems.TuNgay = new Date(Nam, 0, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam, 11, 31, 0, 0, 0);
      }
      if ($event.value === 20) {
        this.TimKiems.TuNgay = new Date(Nam, 0, 1, 0, 0, 0);
        this.TimKiems.DenNgay = getNow;
      }
      if ($event.value === 21) {
        this.TimKiems.TuNgay = new Date(Nam - 1, 0, 1, 0, 0, 0);
        this.TimKiems.DenNgay = new Date(Nam - 1, 11, 31, 0, 0, 0);
      }
    }
  }

  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;

  public save_chondoituong2(item: any) {
    let tdoituong = [];
    tdoituong.push(item);
    this.savect.emit(tdoituong);
  }
  public save_chondoituong() {
    let tdoituong = [];
    this.savect.emit(tdoituong);

    //this.datas.forEach(
    //  (item) => {
    //    if (item.Tich === true)
    //      tdoituong.push(item);
    //  }
    //);
    //if (tdoituong != null && tdoituong.length > 0)
    //  this.savect.emit(tdoituong);
    //else
    //  this.savect.emit(null);

  }
  closeModal_chondoituong() {
    let tdoituong = null;
    this.savect.emit(tdoituong);
  }
  nhomdoituongid: string;
  onLoaiNguoiNop(row: any) {
    this.nhomdoituongid = row.Id;
    //console.log(loai);
  }





}
