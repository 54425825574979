import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists, Lists_phongban } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmucnganhangedit',
  templateUrl: './danhmucnganhangedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmucnganhangedit.component.scss'],
    providers: [ListService, Repository]
})


export class danhmucnganhangeditComponent implements OnInit {
  doanhnghiepid: string = null;
   
    
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputphongbans: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    , Doanh_Nghiep_Id: null
    , Dia_Chi: null, Dien_Thoai: null, Ma_So_Thue: null, So_Tai_Khoan: null
    , Chi_Nhanh: null

  };       
    nhomdtid:string=null;
  hoatdong: string = null;

  inputloais: any = [
    { text: "Nhân công", value: 0 },
    { text: "Vật tư sơn", value: 1 },
  ];

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;

  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata : any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputparents = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_phongban.id, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputphongbans = response;
      });
    

    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid
      , Dia_Chi: null, Dien_Thoai: null, Ma_So_Thue: null, So_Tai_Khoan: null
      , Chi_Nhanh: null
    };    
    if (id === null)
    {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
              this.doituongSelect.Ma_Doi_Tuong = response;
            });
          
          this.doituongSelect.Trang_Thai = true;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = new Date();
          this.doituongSelect.CreatedBy = this.currentUser.user_name;
          this.doituongSelect.Modified = new Date();
          this.doituongSelect.ModifiedBy = this.currentUser.user_name;
          if (rowdata != null) {
            this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
            this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
            this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
            this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
            this.doituongSelect.Parent = rowdata.Parent;

            //this.doituongSelect.Dia_Chi = rowdata.Dia_Chi;
            //this.doituongSelect.Loai = rowdata.Loai;
            //this.doituongSelect.Gio_Dinh_Muc = rowdata.Gio_Dinh_Muc;
            //this.doituongSelect.Gia_Dinh_Muc = rowdata.Gia_Dinh_Muc;
            //this.doituongSelect.Gia_Ban = rowdata.Gia_Ban;
            //this.doituongSelect.Vat = rowdata.Vat;
            //this.doituongSelect.Model = rowdata.Model;
            //this.doituongSelect.Phong_Ban_Id = rowdata.Phong_Ban_Id;

          }
          
    }
        else {
      console.log("t11");
      this.repo.postRequest("api/doituongnganhang/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
                console.log(response);
              this.doituongSelect.Id = id;
              this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
              this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
              this.doituongSelect.Trang_Thai = response.Trang_Thai;
              this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
              this.doituongSelect.Parent = response.Parent;
              this.doituongSelect.Ma_Ao = response.Ma_Ao;
              this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
              this.doituongSelect.Created = response.Created;
              this.doituongSelect.CreatedBy = response.CreatedBy;
              this.doituongSelect.Modified = new Date();
              this.doituongSelect.ModifiedBy = this.currentUser.user_name;

              this.doituongSelect.Dia_Chi = response.Dia_Chi;
              this.doituongSelect.Dien_Thoai = response.Dien_Thoai;
              this.doituongSelect.Ma_So_Thue = response.Ma_So_Thue;
              this.doituongSelect.So_Tai_Khoan = response.So_Tai_Khoan;
              this.doituongSelect.Chi_Nhanh = response.Chi_Nhanh;
              

              this.doituongSelect.SapXep = response.SapXep;
            }); 
        }
        
       
    }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    if (this.doituongSelect.Ten_Doi_Tuong == null || this.doituongSelect.Ten_Doi_Tuong == "") {
      swal('Lỗi!', 'Vui lòng nhập tên đối tượng!.', 'error');
      return;
    }
     
    //console.log("save_ttt");
    //console.log(this.doituongSelect);
    $('#loading').show();
    this.repo.postRequest("api/doituongnganhang/addorupdate",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('-')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
    }
   
    
   

   


}
