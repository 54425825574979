<div class="tab-container">


  <!--<div class="row"><span style="font-weight:bold">Đơn vị sử dụng</span></div>-->
  <div class="row form-group">
    <label class="control-label col-3">Mã đơn vị</label>
    <div class="col-9">
      <input type="text"  tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Don_Vi">
    </div>
    <!--<div class="col-2">
      <input class="form-control"
             maxlength="20"
             currencyMask
             [(ngModel)]="doituongSelect.Thu_Tu"
             name="value"
             placeholder="Thứ tự"
             [options]="ngxCurrencyOptions"
             style="width:100%" />
    </div>-->
  </div>
  <div class="row form-group">
    <label class="control-label col-3">Tên đơn vị</label>
    <div class="col-9">
      <input type="text" tabIndex="2" (keydown)="keyTenPress($event)" class="form-control" placeholder="Tên đơn vị" [(ngModel)]="doituongSelect.Ten_Don_Vi">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">Địa chỉ</label>
    <div class="col-9">
      <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi">
    </div>
  </div>

  <div class="row form-group">
    <label class="control-label col-3">Mã số thuế</label>
    <div class="col-9">
      <input type="text" tabIndex="8" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_So_Thue">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">Điện thoại</label>
    <div class="col-9">
      <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">Fax</label>
    <div class="col-9">
      <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Fax">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">connectionString</label>
    <div class="col-9">
      <textarea name="description" tabIndex="10" [(ngModel)]="doituongSelect.connectionString" placeholder="" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="2"> </textarea>
      <!--<input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.connectionString">-->
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">InitialCatalog</label>
    <div class="col-9">
      <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.InitialCatalog">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">name</label>
    <div class="col-9">
      <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.name">
    </div>
  </div>
  <div class="row form-group">
    <label class="control-label col-3">providerName</label>
    <div class="col-9">
      <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.providerName">
    </div>
  </div>

</div>
    
 



<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="">

  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" tabindex="22" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top: 0px !important;">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" tabindex="23" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top: 0px !important;">Lưu lại</button>
  </div>

</div>

<!--<modal #mdDoiTuongChung [cssClass]="'modal6'" [backdrop]="'static'">
  <modal-header [show-close]="true">
    <h4 class="modal-title">Thêm mới</h4>
  </modal-header>
  <modal-body>
    <app-dm-nganhangedit #dteChung (save)="saveModal($event)"></app-dm-nganhangedit>

  </modal-body>
</modal>-->
