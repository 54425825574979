import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmuckieubanleedit',
  templateUrl: './danhmuckieubanleedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmuckieubanleedit.component.scss'],
    providers: [ListService, Repository]
})


export class danhmuckieubanleeditComponent implements OnInit {
  doanhnghiepid: string = null;
   
    
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma: null, Ten: null
    , Ghi_Chu: null, Trang_Thai: null
    ,Doanh_Nghiep_Id: null
  };       
    nhomdtid:string=null;
    hoatdong:string=null;

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;

  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata : any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);

    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputparents = response;
    //  });
    

    this.doituongSelect = {
      Id: null, Ma: null, Ten: null
      , Ghi_Chu: null, Trang_Thai: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid
    };    
    if (id === null)
    {
      //this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
      //      .subscribe(response => {  
      //        this.doituongSelect.Ma_Doi_Tuong = response;
      //      });
          
          this.doituongSelect.Trang_Thai = true;
          this.doituongSelect.Created = new Date();
          this.doituongSelect.CreatedBy = this.currentUser.user_name;
          this.doituongSelect.Modified = new Date();
        this.doituongSelect.ModifiedBy = this.currentUser.user_name;
        if (rowdata != null) {
          this.doituongSelect.Ma = rowdata.Ma;
          this.doituongSelect.Ten = rowdata.Ten;
          this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
          this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
         }
          
    }
        else {
      console.log("t11");
      this.repo.postRequest("api/kieubanle/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
                console.log(response);
              this.doituongSelect.Id = id;
              this.doituongSelect.Ma = response.Ma;
              this.doituongSelect.Ten = response.Ten;
              this.doituongSelect.Trang_Thai = response.Trang_Thai;
              this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
              this.doituongSelect.Created = response.Created;
              this.doituongSelect.CreatedBy = response.CreatedBy;
              this.doituongSelect.Modified = new Date();
              this.doituongSelect.ModifiedBy = this.currentUser.user_name;
              

              this.doituongSelect.SapXep = response.SapXep;
            }); 
        }
        
       
    }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    if (this.doituongSelect.Ten == null || this.doituongSelect.Ten == "") {
        swal('Lỗi!', 'Vui lòng nhập tên đối tượng!.', 'error');
        return;
      }
      $('#loading').show();
        this.repo.postRequest("api/kieubanle/addorupdate",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('-')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
    }
   
    
   

   


}
