<ul class="navbar">
  <li class="nav-item active">
    
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="savectu()">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Chọn
    </a>
    <!--<a class="nav-link" (click)="closeModal()">
    <i class="fa-2x mr-2 fa fa-window-close">
    </i>
    Thoát
  </a>-->
  </li>
</ul>


<div class="row">
  <div class="col-9">
    <label class="control-label" style="font-weight:bold;color:cornflowerblue">Danh sách cán bộ</label>
  </div>
  <!--<div class="col-3">
    <button style="float:right; margin-top:0px !important;margin-bottom:2px" class="btn btn-info btn-bloc" (click)="adddata()">
      <i class="fa fa-plus"></i>
      Thêm cán bộ
    </button>
  </div>-->
</div>

<!-- lưới chi tiết -->
<div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 170px)">
  <div class="row">
    <table class="table table-striped table-bordered table-hover">
      <thead [ngClass]="{'theadcss': totalItems>16}">
        <tr>
          <th style="width:50px;text-align:center">Chọn</th>
          <th style="width:100px;text-align:center">Mã cán bộ</th>
          <th style="width:150px;text-align:center">Họ và Tên</th>
          <th style="width:110px;text-align:center">Ngày sinh</th>
          <th style="width:110px;text-align:center">Giới tính</th>
          <th style="width:110px;text-align:center">Số CMND</th>
          <th style="width:150px;text-align:center">Phòng ban/đơn vị</th>
          <th style="width:150px;text-align:center">Đơn vị cấp trên</th>
          
          <th style="width:60px;text-align:center"></th>
        </tr>

        <tr>
          <td style="text-align:center">
            <input type="checkbox" [(ngModel)]="TatCa" (click)="TichTatCa()" />
          </td>

          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.MaNV" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.HoVaTen" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td>
            <!--<kendo-dateinput style="width:100%;height: 32px !important" [format]="'dd/MM/yyyy'" [(value)]="ngaytimkiem" (valueChange)="search()"></kendo-dateinput>-->
          </td>
          <td>
            <ng-select #slGioiTinh
                       labelForId="99"
                       class="twcol"
                       [openOnEnter]="false"
                       [items]="chongioitinhs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       placeholder=""
                       [searchFn]="comboSearchFn"
                       style="width:100px;height:25px !important"
                       [(ngModel)]="TimKiems.IDGioiTinh"
                       (change)="search()">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>

              </ng-template>
            </ng-select>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.SoCMND" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.PhongBan" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td></td>
          
        </tr>

      </thead>
      <tbody style="min-height:330px">
        <tr *ngFor="let u of datas">
          <td style="text-align:center;width:50px"><input type="checkbox" [(ngModel)]="u.Tich" /></td>
          <td style="width:100px"><a (click)="editdata(u.Id)">{{u.MaNV}}</a></td>
          <td style="width:150px"><a (click)="editdata(u.Id)">{{u.HoVaTen}}</a></td>
          <td style="text-align:center;width:110px"><a (click)="editdata(u.Id)">{{u.Ngay | date: 'dd/MM/yyyy'}}</a></td>
          <td style="width:110px"><a (click)="editdata(u.Id)">{{u.GioiTinh}}</a></td>
          <td style="width:110px">{{u.SoCMND}}</td>
          <td style="width:150px">{{u.PhongBan}}</td>
          <td style="width:150px">{{u.DonVi}}</td>
          <td style="width:60px">
            <!--<div style="float:left" *ngIf="u.Sua">
              <a (click)="editdata(u.Id)" class="ng-binding" style="color:#1e6bb8 !important;font-weight:normal"><i class="mb-1 fa fa-edit"></i>Sửa</a>
            </div>-->

          </td>
        </tr>
      </tbody>
    </table>


  </div>
  
</div>





<div class="modal-footer" style="display: flex;justify-content: space-between;margin-top:5px;">
  <div>
    <div *ngIf="totalItems>=10">
      <ul class="pagination">
        <li [ngClass]="{disabled: currentPage === 1}">
          <a (click)="setPage(1)"><<</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(currentPage + 1)">></a>
        </li>
        <li *ngFor="let page of pages" [ngClass]="{active: currentPage === page.Id}">
          <a (click)="setPage(page.Id)">{{page.Id}}</a>
        </li>
        <li [ngClass]="{disabled:currentPage === 1}">
          <a (click)="setPage(currentPage - 1)"><</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(totalPages)">>></a>
        </li>
        <li>
          <input type="number" style="width:50px;height:30px;text-align:center" [(ngModel)]="pageSize" (blur)="search1()" />
        </li>
        <li><span style="font-weight:bold">Tổng số: {{totalItems | number}}</span></li>
      </ul>
    </div>
  </div>
  <div class="">

  </div>

</div>


