<div class="row ng-scope" style="min-height:500px">

  <div class="col-4">
    <div class="row">
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 1: Tải tệp mẫu (<a style="color:blue" (click)="taitepmau()">Tại đây</a>)
      </p>
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 2: Nhập dữ liệu vào tệp excel đã tải về
      </p>
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 3: Ấn nút "Chọn file" bên dưới để chọn tệp excel đã nhập dữ liệu
      </p>
      <p style="line-height: 20px;float:right;padding-left:10px">
        Thông tin nhập dữ liệu hiển thị ở lưới bên trái
      </p>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="fileUpload btn btn-primary">
          <!--*ngIf="isUploadBtn"-->
          <span>Chọn file</span>
          <input  type="file" id="btnUpload" value="Upload" (change)="fileChange($event)" class="upload" />
        </div>
        <!--<form>
    <input type="file" id="myfile" />
    <input type="button" (click)="uploadFile()" value="Upload" />
  </form>-->
        <!--<kendo-upload [autoUpload]="false"
                [saveUrl]="uploadSaveUrl"
                [removeUrl]="uploadRemoveUrl"
                [restrictions]="uploadRestrictions"
                (select)="selectEventHandler($event)"
                (clear)="clearEventHandler($event)"
                (remove)="removeEventHandler($event)"
                (upload)="uploadEventHandler($event)"
                (success)="successEventHandler($event)"
                (complete)="completeEventHandler($event)">

  </kendo-upload>-->
      </div>
    </div>
  </div>
  <div class="col-8" style="border-left:1px solid rgba(0, 0, 0, 0.12);font-size:10px">
    <table class="table table-striped table-bordered table-hover">
      <thead>
        <tr class="thCenter">

          <th class="thCenter">
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              Ngày import
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              Người import
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              Thành công
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              Lỗi
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              File nguồn
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              File lỗi
            </a>
          </th>
          <th>
            <a _ngcontent-c11="" class="ng2-smart-sort-link sort ng-star-inserted">
              Chức năng
            </a>
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let u of datas'>
          <td>{{u.Created | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          <td>{{u.CreatedBy}}</td>
          <td class="thCenter">{{u.TotalSuss}}</td>
          <td class="thCenter">{{u.TotalError}}</td>
          <td>
            <a style="color:blue;cursor:pointer" (click)='OnFileImportClick(u.Id)'>{{u.TenFileImport}}</a>
          </td>
          <td>
            <a style="color:blue;cursor:pointer" (click)='OnFileErrorClick(u.Id)'>{{u.TenFileError}}</a>
          </td>
          <td><a style="color:blue;cursor:pointer" (click)='deletedata(u.Id)'><i class="mb-1 far fa-trash-alt"> Xóa</i></a></td>

        </tr>
      </tbody>

    </table>
  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
        <div class="">
                
            </div>
    <div class="">
        
    </div>
    
</div>


