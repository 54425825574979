<ul class="navbar">
  <li class="nav-item active">
    Từ ngày
    <kendo-dateinput [(ngModel)]="TimKiems.TuNgay">
    </kendo-dateinput>
    đến ngày
    <kendo-dateinput [(ngModel)]="TimKiems.DenNgay">
    </kendo-dateinput>
  </li>
  <!--<li class="nav-item">
    <button class="btn btn-info btn-primary" (click)="search()" style="margin-left:3px; margin-right:3px">
      <i class="fas fa-search"></i>
      Tìm kiếm
    </button>
  </li>-->

  <li class="nav-item">
    <button class="btn btn-info btn-danger" (click)="search()" style="margin-left:3px; margin-right:3px">
      <i class="fas fa-edit"></i>
      Đánh lại
    </button>
  </li>
  <li class="nav-item">
    <button class="btn btn-info btn-primary" (click)="savebg()" style="margin-left:3px; margin-right:3px">Lưu thay đổi</button>
  </li>


  <li class="nav-item">
    <a class="nav-link" (click)="closeModal_chondoituong()">
      <i class="fa-2x mr-2 fa fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>



<!-- lưới chi tiết -->
<div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 150px)">
  <div class="row">
    <table class="table table-striped table-bordered table-hover table-condensed">
      <thead [ngClass]="{'theadcss': totalItems>16}">
        <!--<thead>-->
        <tr>

          <th style="width:200px;text-align:center;background-color:#f3f8fc">Khách hàng</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Biển số</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Số khung</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Số máy</th>
          <th style="width:110px;text-align:center;background-color:#f3f8fc">Ngày lập</th>
          <th style="width:150px;text-align:center;background-color:#f3f8fc">Mã BG cũ</th>
          <th style="width:150px;text-align:center;background-color:#f3f8fc">Mã BG mới</th>

        </tr>

        <!--<tr class="filter-row">
          
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ma" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ten" placeholder="Gõ ký tự và ấn enter" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Cha" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.GhiChu" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.NhomDoiTuong" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>


        </tr>-->

      </thead>
      <tbody style="height:calc(100vh - 210px);">
        <tr *ngFor="let u of datas">
          <td style="width:200px"><a>{{u.KhachHang}}</a></td>
          <td style="width:120px"><a>{{u.Bien_So}}</a></td>
          <td style="width:120px"><a>{{u.So_Khung}}</a></td>
          <td style="width:120px">{{u.So_May}}</td>
          <td style="width:110px">{{u.Ngay_Lap | date: 'dd/MM/yyyy'}}</td>
          <td style="width:150px">{{u.MBG_Cu}}</td>
          <td style="width:150px">{{u.MBG_Moi}}</td>
        </tr>
      </tbody>
    </table>


  </div>

</div>






