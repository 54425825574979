<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Mã</label>
      <div class="col-4">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
      </div>
      <label class="control-label col-2 ng-binding">Cấp trên</label>
      <div class="col-4">

        <a *ngIf="doituongSelect.Parent===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha">
        </a>
        <a *ngIf="doituongSelect.Parent!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha" (ngModelChange)="adddatachondoituongchung(1)">
        </a>

        <!--<ng-select #slCapTren
            class="twcol"
            [tabIndex]="2"
            [openOnEnter]="false"
            [items]="inputparents"
            bindValue="Id"
            bindLabel="Ten_Doi_Tuong"
            notFoundText="Không có dữ liệu"
            [searchFn]="comboSearchFn"
            [(ngModel)]="doituongSelect.Parent">
           <ng-template ng-option-tmp let-item="item">
             <div class="rTableRow">
               <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
               <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
             </div>
           </ng-template>
         </ng-select>-->


      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Số khung</label>
      <div class="col-4">
        <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_Khung">
      </div>
      <label class="control-label col-2 ng-binding">Số máy</label>
      <div class="col-4">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_May">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Hãng</label>
      <div class="col-4">
        <ng-select #slHangxe
                   class="twcol"
                   [tabIndex]="5"
                   [openOnEnter]="false"
                   [items]="inputhangxes"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   (change)="onChangeHangXe($event)"
                   [(ngModel)]="doituongSelect.Hang_Xe">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2 ng-binding">Model</label>
      <div class="col-4">
        <ng-select #slPhongban
                   class="twcol"
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputmodels"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Model_Xe">
          <ng-template ng-option-tmp let-item="item">

            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Khách hàng</label>
      <div class="col-4">

        <a *ngIf="doituongSelect.Nha_Cung_Cap_Id===null" (click)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="7" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Khach_Hang">
        </a>
        <a *ngIf="doituongSelect.Nha_Cung_Cap_Id!==null" (dblclick)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="7" type="text" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="doituongSelect.Khach_Hang" (ngModelChange)="adddatachondoituongchung(2)">
        </a>

        <!--<ng-select #slKhachHang
                   class="twcol"
                   [tabIndex]="7"
                   [openOnEnter]="false"
                   [items]="inputkhachhangs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.Nha_Cung_Cap_Id">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>-->
      </div>
      <label class="control-label col-2 ng-binding">Màu sắc</label>
      <div class="col-4">
        <ng-select #slMausac
                   class="twcol"
                   [tabIndex]="8"
                   [openOnEnter]="false"
                   [items]="inputmausacs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Mau_Sac">
          <ng-template ng-option-tmp let-item="item">

            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>


    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Số Km</label>
      <div class="col-4">
        <input id="gio" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.So_Km"
               name="value"
               tabIndex="9"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <label class="control-label col-2 ng-binding">Năm sản xuất</label>
      <div class="col-4">
        <input id="gia" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Nam_San_Xuat"
               name="value"
               tabIndex="10"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Hãng bảo hiểm</label>
      <div class="col-4">
        <ng-select #slHangBaoHiem
                   class="twcol"
                   [tabIndex]="11"
                   [openOnEnter]="false"
                   [items]="inputhangbaohiems"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.Hang_Bao_Hiem_Id">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2 ng-binding">Số hợp đồng</label>
      <div class="col-4">
        <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="doituongSelect.So_Hop_Dong_Bao_Hiem">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Ngày bắt đầu</label>
      <div class="col-4">
        <kendo-dateinput style="width:100%;" tabIndex="13" [format]="'dd/MM/yyyy'" [(value)]="ngaybatdau"></kendo-dateinput>
      </div>
      <label class="control-label col-2 ng-binding">Ngày kết thúc</label>
      <div class="col-4">
        <kendo-dateinput style="width:100%;" tabIndex="14" [format]="'dd/MM/yyyy'" [(value)]="ngayketthuc"></kendo-dateinput>
      </div>
    </div>
    <!--<div class="row form-group">
      <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
      <div class="col-10">
        <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
      </div>
    </div>-->


  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>

<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>


<bs-modal #mdChonDoiTuongChung2 class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongkhachhangedit #dtechondoituongchung2edit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongkhachhangedit>
  </bs-modal-body>
</bs-modal>
