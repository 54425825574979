import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule  } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { BsModalModule } from 'ng2-bs3-modal';


import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { ArticleApproversAvatarDirective } from './directives/approversavatar/articleapproversavatar.directive';
import { PlanApproversAvatarDirective } from './directives/approversavatar/planapproversavatar.directive';
import { ArticleStatusDirective } from './directives/approversavatar/articlestatus.directive';
import { PlanStatusDirective } from './directives/approversavatar/planstatus.directive';
import { SafeHtmlPipe } from './directives/approversavatar/planstatus.directive';
import { BongApproversDirective } from './directives/approversavatar/bongapprovers.directive';

import { Repository } from '../services/repository'
import { ToolDataService } from '../services/tooldata.service'
import { AuthorizationService } from '../services/authorization.service'
//import { ToasterModule, ToasterService } from 'angular2-toaster';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';

import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { DatePickerModule, DateTimePickerModule, TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CheckBoxModule, ButtonModule, SwitchModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { MultiSelectAllModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownButtonModule, SplitButtonModule, ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { TreeGridAllModule } from '@syncfusion/ej2-angular-treegrid';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule, TreeViewModule, ContextMenuModule, TabAllModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { NgxCurrencyModule } from "ngx-currency";
import { FileUploadModule } from 'ng2-file-upload';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgxSpinnerModule } from "ngx-spinner";

import { tuychinhmaubceditComponent } from './components/tonghop/tuychinhmaubcedit.component'
import { luoichitieteditComponent } from './components/tonghop/luoichitietedit.component'
import { luoitonghopeditComponent } from './components/tonghop/luoitonghopedit.component'



import { dkycanboeditComponent } from './components/tonghop/dkycanboedit.component'
import { filemanagerComponent } from './components/quantri/filemanager.component'
import { phanheeditComponent } from './components/quantri/phanheedit.component'
import { importeditComponent } from './components/tonghop/importedit.component'
import { danhmucchungeditComponent } from './components/danhmuc/danhmucchungedit.component'
import { choncanboeditComponent } from './components/tonghop/choncanboedit.component'
import { danhmucdichvueditComponent } from './components/danhmuc/danhmucdichvuedit.component'
import { danhmucxesuaeditComponent } from './components/danhmuc/danhmucxesuaedit.component'
import { danhmucnganhangeditComponent } from './components/danhmuc/danhmucnganhangedit.component'

import { danhmuchangxeeditComponent } from './components/danhmuc/danhmuchangxeedit.component'
import { danhmuckieubanleeditComponent } from './components/danhmuc/danhmuckieubanleedit.component'
import { danhmucmausoneditComponent } from './components/danhmuc/danhmucmausonedit.component'
import { danhmuctaikhoaneditComponent } from './components/danhmuc/danhmuctaikhoanedit.component'
import { danhmuctienteeditComponent } from './components/danhmuc/danhmuctienteedit.component'
import { danhmucxuatxueditComponent } from './components/danhmuc/danhmucxuatxuedit.component'
import { danhmucmodelxeeditComponent } from './components/danhmuc/danhmucmodelxeedit.component'
import { danhmucxeeditComponent } from './components/danhmuc/danhmucxeedit.component'
import { danhmucvattueditComponent } from './components/danhmuc/danhmucvattuedit.component'
import { danhmucnhacungcapeditComponent } from './components/danhmuc/danhmucnhacungcapedit.component'
import { danhmuckhachhangeditComponent } from './components/danhmuc/danhmuckhachhangedit.component'
import { danhmucnhanvieneditComponent } from './components/danhmuc/danhmucnhanvienedit.component'
import { danhmuchopdongeditComponent } from './components/danhmuc/danhmuchopdongedit.component'
import { danhmuctaisaneditComponent } from './components/danhmuc/danhmuctaisanedit.component'


import { quyettoaneditComponent } from './components/ketoan/quyettoanedit.component'
import { chungtuchungeditComponent } from './components/ketoan/chungtuchungedit.component'
import { chungtuthuchieditComponent } from './components/ketoan/chungtuthuchiedit.component'
import { phanbodatcoceditComponent } from './components/ketoan/phanbodatcocedit.component'
import { muahangchuahdeditComponent } from './components/ketoan/muahangchuahdedit.component'
import { banhangeditComponent } from './components/ketoan/banhangedit.component'
import { nhanhoadoneditComponent } from './components/ketoan/nhanhoadonedit.component'


import { chondoituongchungeditComponent } from './components/tonghop/chondoituongchungedit.component'
import { chondoituongchung2editComponent } from './components/tonghop/chondoituongchung2edit.component'
import { chondoituongkhachhangeditComponent } from './components/tonghop/chondoituongkhachhangedit.component'
import { donvieditComponent } from './components/quantri/donviedit.component'
import { doanhnghiepeditComponent } from './components/quantri/doanhnghiepedit.component'
//import { choncauhoieditComponent } from './components/tonghop/choncauhoiedit.component'


import { danhlaimabaogiaeditComponent } from './components/tonghop/danhlaimabaogiaedit.component'
import { baogiadichvueditComponent } from './components/ketoan/baogiadichvuedit.component'
import { baogiaphutungeditComponent } from './components/ketoan/baogiaphutungedit.component'
import { dathangeditComponent } from './components/ketoan/dathangedit.component'
import { yeucauxuatkhoeditComponent } from './components/ketoan/yeucauxuatkhoedit.component'


import { tinhkhauhaotscdeditComponent } from './components/tonghop/tinhkhauhaotscdedit.component'
import { hoadonvat_misaeditComponent } from './components/ketoan/hoadonvat_misaedit.component'
import { hoadonvat_vnpteditComponent } from './components/ketoan/hoadonvat_vnptedit.component'
import { hoadonvateditComponent } from './components/ketoan/hoadonvatedit.component'
import { hoadonbanhangeditComponent } from './components/ketoan/hoadonbanhangedit.component'
import { ketchuyeneditComponent } from './components/ketoan/ketchuyenedit.component'
import { phanboeditComponent } from './components/ketoan/phanboedit.component'
import { khautrueditComponent } from './components/ketoan/khautruedit.component'
import { chitietketchuyeneditComponent } from './components/ketoan/chitietketchuyenedit.component'



import { FlotDirective } from './directives/flot/flot.directive';
//import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';


// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    //ToasterModule,
    ToastModule,
    NgSelectModule,
    DialogModule,
    DateInputsModule,
    MultiSelectAllModule,
    DropDownListModule,
    MaskedTextBoxModule,
    DropDownButtonModule,
    TreeGridAllModule,
    RichTextEditorAllModule,
    ListViewAllModule,
    ToolbarModule,
    TreeViewModule,
    TabAllModule,
    CheckBoxModule,
    RadioButtonModule,
    ButtonModule,
    SwitchModule,
    TextBoxModule,
    CKEditorModule,
    NumericTextBoxModule,
 
    DatePickerModule,
    DateTimePickerModule,
   TimePickerModule,
    RecurrenceEditorAllModule,
    ScheduleAllModule,
    FileManagerAllModule,
    ContextMenuModule,
    GridAllModule,
    NgxCurrencyModule,
    FileUploadModule,
    UploaderModule,
    NgxSpinnerModule,
    BsModalModule
    
  ],
  providers: [ColorsService,
    Repository,
    ToolDataService,
    AuthorizationService
    
    //ToasterService,
  ],
  declarations: [
    FlotDirective,
    EasypiechartDirective,
    CheckallDirective,
    
    NowDirective,
    ScrollableDirective,
    ArticleApproversAvatarDirective,
    PlanApproversAvatarDirective,
    ArticleStatusDirective,
    PlanStatusDirective,
    SafeHtmlPipe,
    BongApproversDirective,
    //DynamicComponentDirective,

    tuychinhmaubceditComponent,
    luoichitieteditComponent,
    luoitonghopeditComponent,
    dkycanboeditComponent,
    filemanagerComponent,
    phanheeditComponent,
    importeditComponent,
    danhmucchungeditComponent,
    danhmucdichvueditComponent,
    danhmucxesuaeditComponent,
    danhmucnganhangeditComponent,
    danhmuchangxeeditComponent,
    danhmuckieubanleeditComponent,
    danhmucmausoneditComponent,
    danhmuctaikhoaneditComponent,
    danhmuctienteeditComponent,
    danhmucxuatxueditComponent,
    danhmucmodelxeeditComponent,
    danhmucxeeditComponent,
    danhmucvattueditComponent,
    danhmucnhacungcapeditComponent,
    danhmuckhachhangeditComponent,
    danhmucnhanvieneditComponent,
    danhmuchopdongeditComponent,
    danhmuctaisaneditComponent,
    chungtuchungeditComponent,
    chungtuthuchieditComponent,
    phanbodatcoceditComponent,
    muahangchuahdeditComponent,
    banhangeditComponent,
    nhanhoadoneditComponent,
    quyettoaneditComponent,
    chondoituongchungeditComponent,
    chondoituongchung2editComponent,
    chondoituongkhachhangeditComponent,
    donvieditComponent,
    doanhnghiepeditComponent,
    choncanboeditComponent,
    danhlaimabaogiaeditComponent,
    baogiadichvueditComponent,
    baogiaphutungeditComponent,
    dathangeditComponent,
    yeucauxuatkhoeditComponent,
    tinhkhauhaotscdeditComponent,
    hoadonvat_misaeditComponent,
    hoadonvat_vnpteditComponent,
    hoadonvateditComponent,
    hoadonbanhangeditComponent,
    ketchuyeneditComponent,
    phanboeditComponent,
    khautrueditComponent,
    chitietketchuyeneditComponent,
    //choncauhoieditComponent,
  ],
  exports: [
    FlotDirective,
    EasypiechartDirective,
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToastrModule,
    CKEditorModule,
    CheckallDirective,
    ContextMenuModule,
    NowDirective,
    ScrollableDirective,
    ArticleApproversAvatarDirective,
    PlanApproversAvatarDirective,
    ArticleStatusDirective,
    PlanStatusDirective,
    SafeHtmlPipe,
    BongApproversDirective,
    //DynamicComponentDirective,

    //ToasterModule,
    ToastModule,
    DialogModule,
    MultiSelectAllModule,
    DropDownListModule,
    MaskedTextBoxModule,
    DropDownButtonModule,
    TreeGridAllModule,
    RichTextEditorAllModule,
    ListViewAllModule,
    ToolbarModule,
    TreeViewModule,
    TabAllModule,
    CheckBoxModule,
    RadioButtonModule,
    SwitchModule,
    TextBoxModule,
    ButtonModule,
    NumericTextBoxModule,
    NgSelectModule,
    DatePickerModule,
    DateTimePickerModule,
   TimePickerModule,
    RecurrenceEditorAllModule,
    ScheduleAllModule,
    FileManagerAllModule, 
   
    GridAllModule,
    NgxCurrencyModule,
    //NgxTreeSelectModule,
    FileUploadModule,
    UploaderModule,
    NgxSpinnerModule,
    tuychinhmaubceditComponent,
    luoichitieteditComponent,
    luoitonghopeditComponent,
    dkycanboeditComponent,
    filemanagerComponent,
    phanheeditComponent,
    importeditComponent,
    danhmucchungeditComponent,
    danhmucdichvueditComponent,
    danhmucxesuaeditComponent,
    danhmucnganhangeditComponent,
    danhmuchangxeeditComponent,
    danhmuckieubanleeditComponent,
    danhmucmausoneditComponent,
    danhmuctaikhoaneditComponent,
    danhmuctienteeditComponent,
    danhmucxuatxueditComponent,
    danhmucmodelxeeditComponent,
    danhmucxeeditComponent,
    danhmucvattueditComponent,
    danhmucnhacungcapeditComponent,
    danhmuckhachhangeditComponent,
    danhmucnhanvieneditComponent,
    danhmuchopdongeditComponent,
    danhmuctaisaneditComponent,
    chungtuchungeditComponent,
    chungtuthuchieditComponent,
    phanbodatcoceditComponent,
    muahangchuahdeditComponent,
    banhangeditComponent,
    nhanhoadoneditComponent,
    quyettoaneditComponent,
    chondoituongchungeditComponent,
    chondoituongchung2editComponent,
    chondoituongkhachhangeditComponent,
    donvieditComponent,
    doanhnghiepeditComponent,
    choncanboeditComponent,
    danhlaimabaogiaeditComponent,
    baogiadichvueditComponent,
    baogiaphutungeditComponent,
    dathangeditComponent,
    yeucauxuatkhoeditComponent,
    tinhkhauhaotscdeditComponent,
    hoadonvat_misaeditComponent,
    hoadonvat_vnpteditComponent,
    hoadonvateditComponent,
    hoadonbanhangeditComponent,
    ketchuyeneditComponent,
    phanboeditComponent,
    khautrueditComponent,
    chitietketchuyeneditComponent,
    //choncauhoieditComponent,
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
