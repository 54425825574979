import { Global, BaoCao } from '../shared/global';
export const Home = {
  id: '',
  text: 'Trang chủ',
  link: '/home',
  icon: 'fa fa-home'
};
export const Lists_loaitailieu = {
  id: '0AF84C3A-B45C-4047-94DE-9D1D8D506200',
  text: 'Loại tài liệu',
  link: '/danhmuc/loaitailieu',
  visible: true,
  huongdan: Global.App_url
};

//----------------------Bao cao------------------------------------------------------

//export const Reports_phanhequy = { id: '4A8C72C3-CF92-480C-9CE1-D3562DED8A1C', text: 'Phân hệ quỹ', link: '/baocao/phanhequy', huongdan: Global.App_url };
export const Reports_nhatkythutien = { id: '0F39DEE6-DB17-4A92-9E74-832AFAE5805D', text: 'Nhật ký thu tiền', link: '/baocao/nhatkythutien/0F39DEE6-DB17-4A92-9E74-832AFAE5805D', huongdan: Global.App_url };
export const Reports_nhatkychitien = { id: '8AAD15A9-E376-4956-ACE4-4685A959EC77', text: 'Nhật ký chi tiền', link: '/baocao/nhatkychitien/8AAD15A9-E376-4956-ACE4-4685A959EC77', huongdan: Global.App_url };
export const Reports_soquy = { id: '1226CB67-81A0-4935-9475-8AD1EF24E22A', text: 'Sổ quỹ', link: '/baocao/soquy/1226CB67-81A0-4935-9475-8AD1EF24E22A', huongdan: Global.App_url };
export const Reports_sotiengui = { id: '6A9775EA-6291-41C2-BA57-DD9A74C515A5', text: 'Sổ tiền gửi', link: '/baocao/sotiengui/6A9775EA-6291-41C2-BA57-DD9A74C515A5', huongdan: Global.App_url };
export const Reports_sotienguitheochitiet = { id: 'A1018B51-1B2E-4E67-BA04-3353B9534ABD', text: 'Sổ tiền gửi theo chi tiết', link: '/baocao/sotienguitheochitiet/A1018B51-1B2E-4E67-BA04-3353B9534ABD', huongdan: Global.App_url };
//export const Reports_phanhemuahang = { id: '567C68DB-1477-47EF-AA5A-BDFF086E0688', text: 'Phân hệ mua hàng', link: '/baocao/phanhemuahang', huongdan: Global.App_url };
export const Reports_tonghopcongnophaitra_hh = { id: '0E5AE963-A589-4BB9-866A-4786390D48DB', text: 'Tổng hợp công nợ phải trả theo HH', link: '/baocao/tonghopcongnophaitra_hh/0E5AE963-A589-4BB9-866A-4786390D48DB', huongdan: Global.App_url };
export const Reports_chitietcongnophaitra = { id: 'ABB35E46-1467-4942-8E20-71CF255D7592', text: 'Sổ chi tiết công nợ phải trả', link: '/baocao/chitietcongnophaitra/ABB35E46-1467-4942-8E20-71CF255D7592', huongdan: Global.App_url };
export const Reports_tonghopcongnophaitra_hd = { id: '53E05E2E-019D-4565-A115-F084A2C215F9', text: 'Tổng hợp công nợ phải trả theo HĐ', link: '/baocao/tonghopcongnophaitra_hd/53E05E2E-019D-4565-A115-F084A2C215F9', huongdan: Global.App_url };
//export const Reports_phanhekhohang = { id: '584327DD-1B28-4F42-8CB2-6708BDF5B072', text: 'Phân hệ kho hàng', link: '/baocao/phanhekhohang', huongdan: Global.App_url };
export const Reports_bctonkho2 = { id: '6DDEAB0C-2066-49BA-8289-CDE3B1B82DA1', text: 'Báo cáo tồn kho 2', link: '/baocao/bctonkho2/6DDEAB0C-2066-49BA-8289-CDE3B1B82DA1', huongdan: Global.App_url };
export const Reports_bctonkho = { id: '3990650F-E3AD-4B45-861F-93E22C360C8B', text: 'Báo cáo tồn kho', link: '/baocao/bctonkho/3990650F-E3AD-4B45-861F-93E22C360C8B', huongdan: Global.App_url };
export const Reports_chitietvattu = { id: 'A930AA6F-D794-4BEF-8A70-2FB6B09698A8', text: 'Sổ chi tiết vật tư, hàng hóa', link: '/baocao/chitietvattu/A930AA6F-D794-4BEF-8A70-2FB6B09698A8', huongdan: Global.App_url };
export const Reports_thekho = { id: '5F1A7FF4-06B6-4A5A-A2BB-35CC9C9E2413', text: 'Thẻ kho', link: '/baocao/thekho/5F1A7FF4-06B6-4A5A-A2BB-35CC9C9E2413', huongdan: Global.App_url };
export const Reports_tonghopnxt = { id: 'FF98ABE7-996C-4288-B56F-40530C5AC10D', text: 'Tổng hợp NXT', link: '/baocao/tonghopnxt/FF98ABE7-996C-4288-B56F-40530C5AC10D', huongdan: Global.App_url };
//export const Reports_phanhebanhang = { id: 'F0A746C0-4CED-46F2-BD7C-93B9343BFC66', text: 'Phân hệ bán hàng', link: '/baocao/phanhebanhang', huongdan: Global.App_url };
export const Reports_bcbanxe = { id: 'B73EF6FC-7007-4D6E-ABB8-8CFD0857AE91', text: 'Báo cáo bán xe', link: '/baocao/bcbanxe/B73EF6FC-7007-4D6E-ABB8-8CFD0857AE91', huongdan: Global.App_url };
export const Reports_chitietcongnophaithu = { id: 'C86516B1-194B-4048-9A3A-818308482ABF', text: 'Sổ chi tiết công nợ phải thu', link: '/baocao/chitietcongnophaithu/C86516B1-194B-4048-9A3A-818308482ABF', huongdan: Global.App_url };
export const Reports_tonghopcongnophaithu_hd = { id: '65E0218F-EEAA-402F-9CE6-E871551923D4', text: 'Tổng hợp công nợ phải thu theo HĐ', link: '/baocao/tonghopcongnophaithu_hd/65E0218F-EEAA-402F-9CE6-E871551923D4', huongdan: Global.App_url };
export const Reports_tonghopcongno = { id: 'B06932FA-E5C6-4E77-91D3-7DD19EC8DACD', text: 'Tổng hợp công nợ', link: '/baocao/tonghopcongno/B06932FA-E5C6-4E77-91D3-7DD19EC8DACD', huongdan: Global.App_url };
//export const Reports_phanhetaisan = { id: '1B39464A-09C6-4E4E-80FF-067CC7B5ACC7', text: 'Phân hệ tài sản', link: '/baocao/phanhetaisan', huongdan: Global.App_url };
export const Reports_phanboccdc = { id: '16D77C23-F370-4C67-9500-B1A3743BD54D', text: 'Bảng phân bổ công cụ', link: '/baocao/phanboccdc/16D77C23-F370-4C67-9500-B1A3743BD54D', huongdan: Global.App_url };
export const Reports_phanbochiphi = { id: '7DCE23BA-C9ED-4582-817B-F18CE4FBEA63', text: 'Bảng phân bổ chi phí', link: '/baocao/phanbochiphi/7DCE23BA-C9ED-4582-817B-F18CE4FBEA63', huongdan: Global.App_url };
export const Reports_thetscd = { id: '4F528C26-490E-4281-A6D8-4F11D054738D', text: 'Thẻ tài sản', link: '/baocao/thetscd/4F528C26-490E-4281-A6D8-4F11D054738D', huongdan: Global.App_url };
export const Reports_sotscd = { id: 'E46DDBC7-5CDE-4592-AE6D-62515D558304', text: 'Sổ tài sản', link: '/baocao/sotscd/E46DDBC7-5CDE-4592-AE6D-62515D558304', huongdan: Global.App_url };
export const Reports_bangketscd = { id: 'B163DB61-2A1E-492E-95DC-CD563A46DBF4', text: 'Bảng kê tài sản', link: '/baocao/bangketscd/B163DB61-2A1E-492E-95DC-CD563A46DBF4', huongdan: Global.App_url };
export const Reports_bangkhauhaotscd = { id: '24931D8D-BCA4-4B79-9EF4-2E036A2947C4', text: 'Bảng khấu hao tài sản', link: '/baocao/bangkhauhaotscd/24931D8D-BCA4-4B79-9EF4-2E036A2947C4', huongdan: Global.App_url };
//export const Reports_phanhetonghop = { id: 'CC03A562-46E6-4628-A34C-F46005E30F83', text: 'Phân hệ tổng hợp', link: '/baocao/phanhetonghop', huongdan: Global.App_url };
export const Reports_tonghopchitiettaikhoan = { id: 'BB5E1ECE-2DD7-4F89-9324-5FC80CCFEE56', text: 'Sổ tổng hợp chi tiết tài khoản', link: '/baocao/tonghopchitiettaikhoan/BB5E1ECE-2DD7-4F89-9324-5FC80CCFEE56', huongdan: Global.App_url };
export const Reports_socaitaikhoan = { id: '4BDC6A56-C92F-4160-8655-A123890218E1', text: 'Sổ cái tài khoản', link: '/baocao/socaitaikhoan/4BDC6A56-C92F-4160-8655-A123890218E1', huongdan: Global.App_url };
export const Reports_sochitiettaikhoan = { id: 'D4B584C6-A3E5-4441-B07F-8807B017630A', text: 'Sổ chi tiết tài khoản', link: '/baocao/sochitiettaikhoan/D4B584C6-A3E5-4441-B07F-8807B017630A', huongdan: Global.App_url };
export const Reports_sonhatkychung = { id: 'DA2553CE-B8C4-4CCC-ADDC-F162F5FD9D1E', text: 'Sổ nhật ký chung', link: '/baocao/sonhatkychung/DA2553CE-B8C4-4CCC-ADDC-F162F5FD9D1E', huongdan: Global.App_url };

export const Reports_danhsachvattutheongaynhap = { id: 'DA2553CE-B8C4-4CCC-ADDC-F162F5FD9D1E', text: 'Danh sách vật tư theo ngày nhập', link: '/baocao/danhsachvattu/D5912A61-B7AE-45B1-8651-53A7DCFAC0B4', huongdan: Global.App_url };

//export const Reports_phanhethue = { id: '3B45688B-AAED-4EFE-B48B-8372298BE2DB', text: 'Phân hệ thuế', link: '/baocao/phanhethue', huongdan: Global.App_url };
export const Reports_bangkevat_vao = { id: 'BC16AFAB-70A6-40AC-8069-73AB3A3A2BB3', text: 'Bảng kê VAT đầu vào', link: '/baocao/bangkevat_vao/BC16AFAB-70A6-40AC-8069-73AB3A3A2BB3', huongdan: Global.App_url };
export const Reports_bangkevat_ra = { id: '26EB95AF-861C-499A-BF51-9E4C2C664A43', text: 'Bảng kê VAT đầu ra', link: '/baocao/bangkevat_ra/26EB95AF-861C-499A-BF51-9E4C2C664A43', huongdan: Global.App_url };
export const Reports_tokhai = { id: '2D0637B9-1152-4582-B265-473154274368', text: 'Tờ khai', link: '/baocao/tokhai/2D0637B9-1152-4582-B265-473154274368', huongdan: Global.App_url };
//export const Reports_BCTC = { id: 'D7DAD745-2124-4558-85C3-91F673837F50', text: 'Báo cáo tài chính', link: '/baocao/BCTC', huongdan: Global.App_url };
export const Reports_bangcandoitaikhoan = { id: '7AE62001-9E86-4C83-AA53-28F5545B995D', text: 'Bảng cân đối tài khoản', link: '/baocao/bangcandoitaikhoan/7AE62001-9E86-4C83-AA53-28F5545B995D', huongdan: Global.App_url };
export const Reports_bangcandoiketoan = { id: 'BBE052F1-7029-4626-806A-8C692C434A2C', text: 'Bảng cân đối kế toán', link: '/baocao/bangcandoiketoan/BBE052F1-7029-4626-806A-8C692C434A2C', huongdan: Global.App_url };
export const Reports_ketquakinhdoanh = { id: '21FF2E26-3486-4B36-BE1C-B3C87C40EEFE', text: 'Kết quả kinh doanh', link: '/baocao/ketquakinhdoanh/21FF2E26-3486-4B36-BE1C-B3C87C40EEFE', huongdan: Global.App_url };
export const Reports_luuchuyentiente = { id: '1067D24D-DBA4-4B90-99CF-8115C152C5A4', text: 'Bảng lưu chuyển tiền tệ', link: '/baocao/luuchuyentiente/1067D24D-DBA4-4B90-99CF-8115C152C5A4', huongdan: Global.App_url };
//export const Reports_danhmucxuong = { id: 'ECE193C8-2A65-4629-ADCB-285EA07F79AB', text: 'Phân hệ sửa chữa', link: '/baocao/danhmucxuong', huongdan: Global.App_url };
export const Reports_bcdichvu = { id: '7E36C00E-6C8A-4CF1-A3DC-1166C000196D', text: 'Báo cáo lãi lỗ dịch vụ', link: '/baocao/bcdichvu/7E36C00E-6C8A-4CF1-A3DC-1166C000196D', huongdan: Global.App_url };
export const Reports_bangkephanbo = { id: '1143700C-69A2-41E0-AF9A-A22F345365C2', text: 'Bảng kê tập hợp, phân bổ sơn', link: '/baocao/bangkephanbo/1143700C-69A2-41E0-AF9A-A22F345365C2', huongdan: Global.App_url };
export const Reports_bangketaphopchiphi = { id: 'E49F4FF3-4738-4524-B940-710EE2815E41', text: 'Bảng kê tập hợp chi phí phụ tùng', link: '/baocao/bangketaphopchiphi/E49F4FF3-4738-4524-B940-710EE2815E41', huongdan: Global.App_url };
export const Reports_bcdoanhthutheohangmuc = { id: '2DBED086-23E7-445D-B2A9-E6013D6ECCB6', text: 'Báo cáo doanh thu theo hạng mục', link: '/baocao/bcdoanhthutheohangmuc/2DBED086-23E7-445D-B2A9-E6013D6ECCB6', huongdan: Global.App_url };
export const Reports_thongkexetheohangbh = { id: 'A778FC6E-6017-4BC8-BFD2-E4938C1AC692', text: 'Thống kê xe theo hãng bảo hiểm', link: '/baocao/thongkexetheohangbh/A778FC6E-6017-4BC8-BFD2-E4938C1AC692', huongdan: Global.App_url };

export const Reports = {
  id: '',
  text: 'Báo cáo',
  link: '/reports',
  icon: 'fa fas fa-chart-area',
  submenu: [
    {
      id: '',
      text: 'Phân hệ sửa chữa',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [

          Reports_bcdichvu
          , Reports_bangkephanbo
          , Reports_bangketaphopchiphi
          , Reports_bcdoanhthutheohangmuc
          , Reports_thongkexetheohangbh

        ]
    }
    ,

    {
      id: '',
      text: 'Phân hệ quỹ',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_nhatkythutien
        , Reports_nhatkychitien
        , Reports_soquy
        , Reports_sotiengui
        , Reports_sotienguitheochitiet
      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ kho hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        //  Reports_bangkemuahang
        Reports_bctonkho2
        , Reports_bctonkho
        , Reports_chitietvattu
        , Reports_thekho
        , Reports_tonghopnxt
      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ mua hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        //  Reports_bangkemuahang
        Reports_tonghopcongnophaitra_hh
        , Reports_chitietcongnophaitra
        , Reports_tonghopcongnophaitra_hd
        
      ]
    }
    , {
      id: '',
      text: 'Phân hệ bán hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_bcbanxe
        , Reports_chitietcongnophaithu
        , Reports_tonghopcongnophaithu_hd
        , Reports_tonghopcongno
        
      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ tài sản',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_phanboccdc
        , Reports_phanbochiphi
        , Reports_bangkhauhaotscd
        , Reports_thetscd
        , Reports_sotscd
        , Reports_bangketscd
        
        
      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ thuế',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_bangkevat_vao
        , Reports_bangkevat_ra
        , Reports_tokhai
        ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ tổng hợp',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_tonghopchitiettaikhoan
        , Reports_socaitaikhoan
        , Reports_sochitiettaikhoan
        , Reports_sonhatkychung
        , Reports_danhsachvattutheongaynhap
      ]
    }
    ,
    {
      id: '',
      text: 'Báo cáo tài chính',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Reports_bangcandoitaikhoan
        , Reports_bangcandoiketoan
        , Reports_ketquakinhdoanh
        , Reports_luuchuyentiente
      ]
    }
    
   
    
  ]
};

export const taisan_mua = {
  id: 'C1EA12BA-A424-4DC0-BAD4-809FED778768',
  text: 'Mua tài sản',
  link: '/ketoan/muataisan',
  huongdan: Global.App_url
};
export const taisan_nhanhienvat = {
  id: '401FF54E-6EB1-4C79-AA18-D66144345C56',
  text: 'Nhận hiện vật',
  link: '/ketoan/nhanhienvat',
  huongdan: Global.App_url,
  border: 'Co'
};
export const taisan_ghigiam = {
  id: 'A5581D07-8A95-4519-9BD2-BEA2E0E43F8A',
  text: 'Ghi giảm tài sản',
  link: '/ketoan/ghigiamtaisan',
  huongdan: Global.App_url
};
export const taisan_danhgia = {
  id: '6AB780E6-414F-49C3-90C9-7768365D1CE4',
  text: 'Đánh giá tài sản',
  link: '/ketoan/danhgiataisan',
  huongdan: Global.App_url
};
export const taisan_dieuchuyentaisan = {
  id: '2342D2D6-1CC1-4ECC-802F-0BB91BE05E9D',
  text: 'Điều chuyển tài sản',
  link: '/ketoan/dieuchuyentaisan',
  huongdan: Global.App_url
};

export const taisan_ban = {
  id: '1CDCB850-A3D5-4750-A3C7-F1360A4802B1',
  text: 'Bán tài sản',
  link: '/ketoan/bantaisan',
  huongdan: Global.App_url,
  border: 'Co'
};

export const taisan_haomon = {
  id: '8EB28651-7268-4C92-AFE0-395CD1DCE370',
  text: 'Khấu hao tài sản',
  link: '/ketoan/tinhhaomontaisan',
  huongdan: Global.App_url
};

//----------công cụ
export const congcus_soducongcudungcu = {
  id: '237B544D-D773-4F06-8D86-2D2C9498ACEE',
  text: 'Khai báo công cụ dụng cụ ban đầu',
  link: '/ketoan/congcudungcubandau',
  huongdan: Global.App_url
};
export const congcus_ghitang = {
  id: '8A54FE30-9E2C-4845-8ADD-D347E33F300D',
  text: 'Ghi tăng công cụ',
  link: '/ketoan/ghitangcongcu',
  huongdan: Global.App_url
};
export const congcus_ghigiam = {
  id: '70C529FA-3957-4470-8D03-C85FDFB4D977',
  text: 'Ghi giảm công cụ',
  link: '/ketoan/ghigiamcongcu',
  huongdan: Global.App_url
};
export const congcus_dieuchuyencongcu = {
  id: '4E5B8003-CF61-4FA1-8D80-12E0086EC169',
  text: 'Điều chuyển công cụ',
  link: '/ketoan/dieuchuyencongcu',
  huongdan: Global.App_url,
  border: 'co'
};
export const congcus_phanbo = {
  id: 'BBE6FD1C-CE59-48BD-95DC-C926E88103A2',
  text: 'Phân bổ công cụ',
  link: '/ketoan/phanbocongcu',
  huongdan: Global.App_url,

};
export const congcus_cauhinh = {
  id: '2683A34A-D6EF-40A7-8B0E-98210A98B20E',
  text: 'Cấu hình công cụ',
  link: '/danhmuc/cauhinhcongcu',
  huongdan: Global.App_url,

};
export const congcus_phanbochiphi = {
  id: 'FCCE4DC5-EB4A-407E-A11B-E16E8A5E958F',
  text: 'Phân bổ chi phi',
  link: '/ketoan/phanbochiphi',
  huongdan: Global.App_url,

};


export const TaiSans = {
  id: '',
  text: 'Tài sản, CCDC',
  icon: 'fa fa-cube',
  submenu: [taisan_haomon, congcus_phanbo, congcus_phanbochiphi]
};




//--------------------danh mục

//export const Lists_phanhenganhang = { id: 'F4524F6B-AD87-4882-8F0E-9872125A94BF', text: 'Phân hệ ngân hàng', link: '/danhmuc/phanhenganhang', visible: true, huongdan: Global.App_url };
export const Lists_nganhang = { id: '4316191F-5C48-413C-82B3-DFD870C0FAFE', text: 'Ngân hàng', link: '/danhmuc/dmnganhang', visible: true, huongdan: Global.App_url };
//export const Lists_phanhemuahang = { id: '567C68DB-1477-47EF-AA5A-BDFF086E0688', text: 'Phân hệ mua hàng', link: '/danhmuc/phanhemuahang', visible: true, huongdan: Global.App_url };
export const Lists_nhacungcap = { id: 'DE964E8B-BE82-417F-A7DF-2CFB240E8E44', text: 'Nhà cung cấp', link: '/danhmuc/dmnhacungcap', visible: true, huongdan: Global.App_url };
export const Lists_phongban = { id: 'C83F032C-8834-44D8-94B5-D9E5A91D9A56', text: 'Phòng ban', link: '/danhmuc/dmphongban', visible: true, huongdan: Global.App_url };
export const Lists_hopdongmua = { id: '2E76671D-9E9D-45FF-9187-6C7E6192C859', text: 'Hợp đồng mua', link: '/danhmuc/dmhopdongmua', visible: true, huongdan: Global.App_url };
//export const Lists_phanhekhohang = { id: '584327DD-1B28-4F42-8CB2-6708BDF5B072', text: 'Phân hệ kho hàng', link: '/danhmuc/phanhekhohang', visible: true, huongdan: Global.App_url };
export const Lists_vattu = { id: 'B8A00ED6-8EA0-462B-B1D0-D41466880EFB', text: 'Vật tư, phụ tùng', link: '/danhmuc/dmvattu', visible: true, huongdan: Global.App_url };
export const Lists_xe = { id: 'A132FA40-3D64-4649-9E79-D69D2CCFFDE2', text: 'Xe ', link: '/danhmuc/dmxe', visible: true, huongdan: Global.App_url };
export const Lists_hanghoa = { id: '4FF0E7D6-4954-4ED4-8333-DE66D2A24B12', text: 'Hàng hóa', link: '/danhmuc/dmhanghoa', visible: true, huongdan: Global.App_url };
export const Lists_khohang = { id: '24AADC58-6F18-44B0-A1D3-DEDCF0BA2060', text: 'Kho hàng', link: '/danhmuc/dmkhohang', visible: true, huongdan: Global.App_url };
//export const Lists_phanhebanhang = { id: 'F0A746C0-4CED-46F2-BD7C-93B9343BFC66', text: 'Phân hệ bán hàng', link: '/danhmuc/phanhebanhang', visible: true, huongdan: Global.App_url };
export const Lists_doituong = { id: '532A61EA-D765-844B-8CBF-E610B209E332', text: 'Đối tượng', link: '/danhmuc/dmdoituong', visible: true, huongdan: Global.App_url };
export const Lists_loaiquanhe = { id: '1176B289-4EE7-419A-8AEF-9DCAD56CFE57', text: 'Loại quan hệ', link: '/danhmuc/dmloaiquanhe', visible: true, huongdan: Global.App_url };
export const Lists_kieubanle = { id: '4D0E98E8-D793-4607-B751-658C621CFD2C', text: 'Kiểu bán lẻ', link: '/danhmuc/dmkieubanle', visible: true, huongdan: Global.App_url };
export const Lists_khachhangtiemnang = { id: '33BDC51F-209D-4A3C-A09D-AFC5920A1122', text: 'Khách hàng tiềm năng', link: '/danhmuc/dmkhachhangtiemnang', visible: true, huongdan: Global.App_url };
export const Lists_nhanvien = { id: '9B6F13B5-88F8-4CF1-90CF-DAE04C849B26', text: 'Nhân viên', link: '/danhmuc/dmnhanvien', visible: true, huongdan: Global.App_url };
export const Lists_khachhang = { id: '2CEA2A10-9E2A-4EDF-969A-B4DFBE99C48A', text: 'Khách hàng', link: '/danhmuc/dmkhachhang', visible: true, huongdan: Global.App_url };
export const Lists_nhomkhachhang = { id: 'F0C8AB15-73FF-4288-A748-9CBD84A920A2', text: 'Nhóm khách hàng', link: '/danhmuc/dmnhomkhachhang', visible: true, huongdan: Global.App_url };
export const Lists_linhvuc = { id: 'D61F1A0F-F601-46B3-87F0-A153DABF9978', text: 'Lĩnh vực', link: '/danhmuc/dmlinhvuc', visible: true, huongdan: Global.App_url };
export const Lists_nguonkhach = { id: 'E276C889-2D43-47EB-94FF-FA05A2950946', text: 'Nguồn khách hàng', link: '/danhmuc/dmnguonkhach', visible: true, huongdan: Global.App_url };
export const Lists_nghenghiep = { id: 'EA6413BB-26D7-4C17-9E8F-3B2C2206153A', text: 'Nghề nghiệp', link: '/danhmuc/dmnghenghiep', visible: true, huongdan: Global.App_url };
export const Lists_hangxe = { id: '84FDBFFE-EBDA-467B-A7F6-3D5F842B372A', text: 'Hãng xe', link: '/danhmuc/dmhangxe', visible: true, huongdan: Global.App_url };
export const Lists_modelxe = { id: 'AC8B7A75-9ABD-459C-A536-F9C54556A26A', text: 'Model xe', link: '/danhmuc/dmmodelxe', visible: true, huongdan: Global.App_url };
export const Lists_hopdongban = { id: '0BF2B218-3D3A-4E2A-906B-06E4A13F135B', text: 'Hợp đồng bán', link: '/danhmuc/dmhopdongban', visible: true, huongdan: Global.App_url };
//export const Lists_phanhetaisan = { id: '1B39464A-09C6-4E4E-80FF-067CC7B5ACC7', text: 'Phân hệ tài sản', link: '/danhmuc/phanhetaisan', visible: true, huongdan: Global.App_url };
export const Lists_congcu = { id: '13AD5FED-19D5-4DB7-A40A-9489E636B0AF', text: 'Công cụ dụng cụ', link: '/danhmuc/dmcongcu', visible: true, huongdan: Global.App_url };
export const Lists_taisan = { id: '6D38A19A-B453-4A34-BD6A-FC71A24362F9', text: 'Tài sản', link: '/danhmuc/dmtaisan', visible: true, huongdan: Global.App_url };
export const Lists_chiphi = { id: 'BA2C8CF6-0B44-BE4D-B877-F0C04B13911C', text: 'Chi phí phân bổ', link: '/danhmuc/dmchiphiphanbo', visible: true, huongdan: Global.App_url };
export const Lists_taisancodinh = { id: '5A44C760-BA5C-4D6C-84A7-D9A5BA8D7B60', text: 'Tài sản cố định', link: '/danhmuc/dmtaisan', visible: true, huongdan: Global.App_url };
//export const Lists_phanhetonghop = { id: 'CC03A562-46E6-4628-A34C-F46005E30F83', text: 'Phân hệ tổng hợp', link: '/danhmuc/phanhetonghop', visible: true, huongdan: Global.App_url };
export const Lists_taikhoan = { id: 'C1371682-A61F-4C16-A27E-68462B12D822', text: 'Tài khoản', link: '/danhmuc/dmtaikhoan', visible: true, huongdan: Global.App_url };
export const Lists_khoanmuc = { id: '73F13C3A-1C1A-466E-96C5-A20E79F0105D', text: 'Khoản mục chi phí', link: '/danhmuc/dmkhoanmucchiphi', visible: true, huongdan: Global.App_url };
export const Lists_nguonvon = { id: 'AB87EB25-3A82-41C0-8D7D-BD68689345E7', text: 'Nguồn vốn', link: '/danhmuc/dmnguonvon', visible: true, huongdan: Global.App_url };
export const Lists_donvitinh = { id: 'F00AAC21-AA52-4BF5-809D-82C90674BD22', text: 'Đơn vị tính', link: '/danhmuc/dmdonvitinh', visible: true, huongdan: Global.App_url };
export const Lists_madonvi = { id: '629EA170-3750-4013-92C1-B4AB004EA97E', text: 'Mã đơn vị', link: '/danhmuc/dmmadonvi', visible: true, huongdan: Global.App_url };
export const Lists_keuoc = { id: 'D9D96609-9FED-47AA-871F-D9F14E002F7D', text: 'Danh mục kế ước', link: '/danhmuc/dmkeuoc', visible: true, huongdan: Global.App_url };
export const Lists_hopdong = { id: '5A6BE02B-AF90-41B8-8EAF-0C9EDB0AC8CB', text: 'Hợp đồng', link: '/danhmuc/dmhopdong', visible: true, huongdan: Global.App_url };
export const Lists_mauson = { id: '004FCBF0-3B83-457A-8E3C-77BE8CCED09A', text: 'Danh mục màu sơn', link: '/danhmuc/dmmauson', visible: true, huongdan: Global.App_url };
export const Lists_giaodich = { id: 'B01F4230-AC45-4054-BE35-82F779FC7151', text: 'Danh mục giao dịch', link: '/danhmuc/dmgiaodich', visible: true, huongdan: Global.App_url };
export const Lists_tinh = { id: '5870D5CC-6B3E-4E19-8155-71685E2B675B', text: 'Danh mục tỉnh, thành phố', link: '/danhmuc/dmtinh', visible: true, huongdan: Global.App_url };
export const Lists_loaitaisan = { id: '96A4CB47-2CCF-48FF-B49B-EC55DE24AFEF', text: 'Danh mục loại tài sản', link: '/danhmuc/dmloaitaisan', visible: true, huongdan: Global.App_url };
export const Lists_xuatxu = { id: 'DA8FF299-09A0-470D-9275-463536BCB810', text: 'Xuất xứ', link: '/danhmuc/dmxuatxu', visible: true, huongdan: Global.App_url };
export const Lists_tiente = { id: '0A153DF1-7E93-4EF6-A372-2E5F39E613E7', text: 'Loại tiền tệ', link: '/danhmuc/dmtiente', visible: true, huongdan: Global.App_url };
//export const Lists_danhmucxuong = { id: 'ECE193C8-2A65-4629-ADCB-285EA07F79AB', text: 'Phân hệ sửa chữa', link: '/danhmuc/danhmucxuong', visible: true, huongdan: Global.App_url };
export const Lists_dichvu = { id: 'CC273674-D746-418A-8393-7C317B26C812', text: 'Danh mục dịch vụ', link: '/danhmuc/dmdichvu', visible: true, huongdan: Global.App_url };
export const Lists_loaisuachua = { id: 'C634F614-6EC5-4DB8-A94E-1DBC76AEBCD2', text: 'Loại sửa chữa', link: '/danhmuc/dmloaisuachua', visible: true, huongdan: Global.App_url };
export const Lists_xesua = { id: '27D51241-887E-4D24-90E1-A917CA016B73', text: 'Xe sửa', link: '/danhmuc/dmxesua', visible: true, huongdan: Global.App_url };
export const Lists_lenhsuachua = { id: 'AC497D5C-137C-47CA-B9C7-5A4F71627F32', text: 'Lệnh sửa chữa', link: '/danhmuc/dmlenhsuachua', visible: true, huongdan: Global.App_url }; 

export const DanhMucs = [Lists_nganhang
  , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
  , Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
  , Lists_doituong, Lists_loaiquanhe, Lists_kieubanle, Lists_khachhangtiemnang, Lists_nhanvien, Lists_khachhang, Lists_nhomkhachhang
  , Lists_linhvuc, Lists_nguonkhach, Lists_nghenghiep, Lists_hangxe, Lists_modelxe, Lists_hopdongban
  , Lists_congcu, Lists_chiphi, Lists_taisancodinh
  , Lists_taikhoan, Lists_khoanmuc, Lists_nguonvon, Lists_donvitinh, Lists_madonvi, Lists_keuoc, Lists_hopdong
  , Lists_mauson, Lists_giaodich, Lists_tinh, Lists_loaitaisan, Lists_xuatxu, Lists_tiente
  , Lists_dichvu, Lists_loaisuachua, Lists_xesua, Lists_lenhsuachua
]
export const Lists = {
  id: '',
  text: 'Danh mục',
  link: '/elements',
  icon: 'fa fa-list-ul',
  submenu: [
    
    {
      id: '',
      text: 'Phân hệ kho hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_vattu, Lists_xe
        //, Lists_hanghoa
        , Lists_khohang
        
      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ mua hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_nhacungcap, Lists_phongban, Lists_hopdongmua

      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ bán hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
        , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
        , Lists_khachhang, Lists_nhanvien
        //, Lists_khachhangtiemnang
        

      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ tài sản',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_congcu, Lists_taisancodinh, Lists_chiphi

      ]
    }
    ,
    {
      id: '',
      text: 'Phân hệ tổng hợp',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [

        Lists_tiente, Lists_xuatxu
        //, Lists_tinh
        , Lists_mauson
        //, Lists_giaodich
        //, Lists_keuoc
        , Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
        , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
        //,Lists_lenhsuachua, Lists_hopdong
        
      ]
    }
    , {
      id: '',
      text: 'Phân hệ sửa chữa',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_loaisuachua, Lists_dichvu, Lists_xesua
        //, Lists_lenhsuachua
      ]
    },
    {
      id: '',
      text: 'Phân hệ ngân hàng',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [
        Lists_nganhang

      ]
    }
    
  ]

};

//-----------------hang hóa, kho-----------------------
export const Products_dondathang = {
  id: 'A8DA7B47-4FA0-4D91-AB6F-0E01CE00BFCB',
  text: 'Đơn đặt hàng',
  link: '/ketoan/dondathang',
  huongdan: Global.App_url,
 
};
export const Products_chiphimuahang = {
  id: '81576447-ABB1-49B6-8402-D0740295270E',
  text: 'Chi phí mua hàng',
  link: '/ketoan/chiphimuahang',
  huongdan: Global.App_url
};
export const Products_phanbodatcoc = {
  id: '9D26F759-7A97-45A6-BDE0-1350129D3053',
  text: 'Phân bổ đặt cọc',
  link: '/ketoan/phanbodatcoc',
  huongdan: Global.App_url
};

export const Products_muahangchuahd = {
  id: '0188D348-D1FC-4BD6-A72E-34879FA154E8',
  text: 'Mua hàng chưa HĐ',
  link: '/ketoan/muahangchuahd',
  huongdan: Global.App_url
};
export const Products_nhanhoadon = {
  id: '73799014-B4D1-4C6A-8B8A-A19C3FF68D69',
  text: 'Nhận hóa đơn',
  link: '/ketoan/nhanhoadon',
  huongdan: Global.App_url
};

export const Products_muahang = {
  id: 'B82D62C4-3589-4EEC-B442-F7C85767A990',
  text: 'Mua hàng',
  link: '/ketoan/muahang',
  huongdan: Global.App_url,
  
};
export const Products_nhapkho = {
  id: '203EEA83-2293-45E6-B80D-ED7ED80565A6',
  text: 'Nhập kho ',
  link: '/ketoan/nhapkho',
  huongdan: Global.App_url
};
export const Products_xuatkho = {
  id: '5CCCD93D-6444-462B-8A98-85B31691C742',
  text: 'Xuất kho ',
  link: '/ketoan/xuatkho',
  huongdan: Global.App_url
};
export const Products_banhang = {
  id: 'BAA913DA-D364-458C-B15D-59BF1336B213',
  text: 'Bán hàng',
  link: '/ketoan/banhang',
  huongdan: Global.App_url,
  
};

export const Products_tinhgia = {
  id: '25A71D95-7E86-41E4-8B10-AE6ABF9DCDB2',
  text: 'Tính lại giá xuất ',
  link: '/ketoan/tinhgiaxuat', 
  huongdan: Global.App_url
};
export const Products = {
  id: '',
  text: 'Hàng hóa ',
  link: '/dashboard',
  icon: 'fa fa-shopping-cart',
  submenu: [
     Products_dondathang, Products_phanbodatcoc, Products_chiphimuahang
    , Products_muahangchuahd, Products_nhanhoadon, Products_muahang, Products_banhang
    , Products_nhapkho, Products_xuatkho, Products_tinhgia]
};


//-------------------phân hệ quỹ
export const tiens_phieuthu = {
  id: 'C7CE86D5-2FF0-4BA5-9B93-DAA964D03B6B',
  text: 'Phiếu thu tiền mặt',
  link: '/ketoan/phieuthu',
  huongdan: Global.App_url
};
export const tiens_phieuchi = {
  id: 'A78618AD-EF7F-44E6-A6E2-201AF7B6E2DA',
  text: 'Phiếu chi tiền mặt',
  link: '/ketoan/phieuchi',
  huongdan: Global.App_url,
  border: 'Co'
};
export const tiens_baoco = {
  id: '6B4FF64A-8A34-4151-9FB2-D05E498FE3E8',
  text: 'Báo có',
  link: '/ketoan/baoco',
  huongdan: Global.App_url
};
export const tiens_baono = {
  id: 'A5C1EF47-FF01-4CB1-B4E2-A27E97310AA8',
  text: 'Báo nợ',
  link: '/ketoan/baono',
  huongdan: Global.App_url,
  border: 'Co'
};
export const tiens_cknoibo = {
  id: '7AC8A5FF-FBB8-4C50-B4E0-0D885D152CD1',
  text: 'Chuyển tiền nội bộ',
  link: '/ketoan/chuyentiennoibo', // chưa có trong DM_PhanHe
  huongdan: Global.App_url,
  border: ''
};

export const tonghops_chungtukhac = {
  id: '2E5DD4FB-1DC0-4679-B0C1-424F93D0052A',
  text: 'Chứng từ tổng hợp',
  link: '/ketoan/chungtukhac',
  huongdan: Global.App_url
};
export const tonghops_chungtuketchuyen = {
  id: 'CE4F89FF-0A00-43D0-BADC-AAB6AB860E0E',
  text: 'Chứng từ kết chuyển',
  link: '/ketoan/chungtuketchuyen',
  huongdan: Global.App_url
};
export const tonghops_dudauky = {
  id: 'FFE5DC43-7EC9-46BB-A5AE-73BECA5F6537',
  text: 'Số dư đầu kỳ',
  link: '/ketoan/sodudauky',
  huongdan: Global.App_url
};

export const ChucNangs_cnphanbocongcu = { id: 'CDB9CC19-543B-4F42-934F-192D7D4C4485', text: 'Phân bổ công cụ', link: '/nhaplieu/cnphanbocongcu', huongdan: Global.App_url };
export const ChucNangs_cndanhsobaogia = { id: '6298039B-E359-4DB0-A8D1-670047BBD36C', text: 'Đánh số báo giá', link: '/nhaplieu/cndanhsobaogia', huongdan: Global.App_url };
export const ChucNangs_cndanhsochungtu = { id: 'A758239A-A9AC-4A3B-9816-794B07127268', text: 'Đánh số chứng từ', link: '/nhaplieu/cndanhsochungtu', huongdan: Global.App_url };
export const ChucNangs_cnchuyentaikhoan = { id: '1D0284DB-7889-4450-B351-84B7AB4250CA', text: 'Chuyển tài khoản', link: '/nhaplieu/cnchuyentaikhoan', huongdan: Global.App_url };
export const ChucNangs_cntinhgiavon = { id: '25A71D95-7E86-41E4-8B10-AE6ABF9DCDB2', text: 'Tính giá xuất', link: '/nhaplieu/cntinhgiavon', huongdan: Global.App_url };
export const ChucNangs_cnphanbochiphi = { id: '26DA068A-5B2D-441D-A2D3-CC9F8F5D100F', text: 'Phân bổ chi phí', link: '/nhaplieu/cnphanbochiphi', huongdan: Global.App_url };
export const ChucNangs_cnphanboson = { id: '66B99BD2-41B8-463F-9432-D42A4F9A5870', text: 'Phân bổ sơn', link: '/nhaplieu/cnphanboson', huongdan: Global.App_url };
export const ChucNangs_cncongcu = { id: 'C641370B-520E-48CF-A95E-D58E82607D42', text: 'Cộng cụ, kiểm tra lỗi', link: '/nhaplieu/cncongcu', huongdan: Global.App_url };
export const ChucNangs_cnlaydulieu = { id: 'F266B8F7-E792-4BFC-A0DE-D8DB987419A9', text: 'Lấy dữ liệu từ CSDL khác', link: '/nhaplieu/cnlaydulieu', huongdan: Global.App_url };
export const ChucNangs_cngopmadoituong = { id: '400DC945-1399-4DF2-A375-E62B00F43D39', text: 'Gộp mã đối tượng', link: '/nhaplieu/cngopmadoituong', huongdan: Global.App_url };
export const ChucNangs_cnketchuyen = { id: '031B65E6-6AFB-469F-8037-ED4260709390', text: 'Kết chuyển', link: '/nhaplieu/cnketchuyen', huongdan: Global.App_url };
export const ChucNangs_cnkhauhao = { id: '9D1C7836-3F74-4299-99AD-FE1A926B344B', text: 'Khấu hao tài sản', link: '/nhaplieu/cnkhauhao', huongdan: Global.App_url }; 

export const tiens = {
  id: '',
  text: ' Chứng từ',
  link: '/',
  icon: 'fas fa-dollar-sign',
  submenu: [tiens_phieuthu, tiens_phieuchi, tiens_baoco, tiens_baono, tiens_cknoibo
    , Products_muahang
    , Products_banhang, Products_nhapkho, Products_xuatkho
    , tonghops_chungtukhac, tonghops_chungtuketchuyen
    //, tonghops_dudauky
  ]
};

export const NghiepVus_dathang = { id: 'E7B9F34E-92C0-4391-8685-385BD348D23F', text: 'Đặt hàng HTC', link: '/ketoan/dathang', huongdan: Global.App_url };
export const NghiepVus_dexuatbanhang = { id: '15387EBF-A5BE-4B9B-AE38-4F3343CAA181', text: 'Đề xuất bán hàng', link: '/nhaplieu/dexuatbanhang', huongdan: Global.App_url };
//export const NghiepVus_hopdongbanhang = { id: 'EF41EF00-B340-499A-9913-969B8D60C369', text: 'Hợp đồng bán hàng', link: '/nhaplieu/hopdongbanhang', huongdan: Global.App_url };
export const NghiepVus_hopdongbanhang = { id: '0BF2B218-3D3A-4E2A-906B-06E4A13F135B', text: 'Hợp đồng bán hàng', link: '/danhmuc/dmhopdongban', huongdan: Global.App_url }; 
export const NghiepVus = {
  id: '',
  text: ' Nghiệp vụ',
  link: '/',
  icon: 'fas fa-edit',
  submenu: [NghiepVus_dathang,
    //NghiepVus_dexuatbanhang,
    NghiepVus_hopdongbanhang
  ]
}; 

export const SuaChuas_chucnangxuong = { id: 'AC3D3929-1885-48AD-B82B-4D53C115946B', text: 'Chức năng', link: '/nhaplieu/chucnangxuong', huongdan: Global.App_url };
export const SuaChuas_baogia = { id: 'C18B1A14-0C8F-40C6-A042-CDD9BBE2B0F0', text: 'Báo giá dịch vụ', link: '/ketoan/baogiadichvu', huongdan: Global.App_url };
export const SuaChuas_phieuvaoxuong = { id: '714C446C-40AD-41EB-A61B-9D09E00E64FB', text: 'Báo giá phụ tùng', link: '/ketoan/baogiaphutung', huongdan: Global.App_url };
export const SuaChuas_quyettoan = { id: '069FFF3E-002D-4AF9-8601-5542EA23F844', text: 'Quyết toán', link: '/ketoan/quyettoan', huongdan: Global.App_url };
export const SuaChuas_yeucauxuatkho = { id: 'E69174F3-D305-4E5A-9DB6-FFCF56DAEBD3', text: 'Yêu cầu xuất kho', link: '/ketoan/yeucauxuatkho', huongdan: Global.App_url };
export const SuaChuas_baoduong = { id: 'EBDCF88D-B723-44C5-AD03-5A3ACFA63567', text: 'Cảnh báo bảo dưỡng', link: '/nhaplieu/baoduong', huongdan: Global.App_url }; 

export const SuaChuas = {
  id: '',
  text: ' Sửa chữa',
  link: '/',
  icon: 'fas fa-hammer',
  submenu: [SuaChuas_baogia, SuaChuas_phieuvaoxuong
    , SuaChuas_quyettoan, SuaChuas_yeucauxuatkho
    //, SuaChuas_baoduong
  ]
}; 
 

//-------------------tiện ích
export const Ultil_nguoidung = {
  id: 'CC11D304-2673-40B5-9E2F-295888991A35',
  text: 'Quản lý người dùng',
  link: '/quantri/nguoidung',
  huongdan: Global.App_url
};
export const Ultil_phanquyen = {
  id: '5EB2FFBB-B173-42FE-B155-AB473CE914C1',
  text: 'Quản lý phân quyền',
  link: '/quantri/vaitro',
  huongdan: Global.App_url
};
export const Ultil_doimatkhau = {
  id: '',
  text: 'Đổi mật khẩu',
  link: '/quantri/doimatkhau',
  huongdan: Global.App_url
};
export const Ultil_cauhinhnhapdulieutuexcel = {
  id: '1EC79DE0-51D4-43EF-BF16-5C8E94CB1CD1',
  text: 'Cấu hình nhập dữ liệu từ excel',
  link: '/quantri/maubaocao',
  huongdan: Global.App_url
};


export const Ultil_inchungtu = {
  id: '0FB79A4C-3259-4B74-BC24-82C693DFD4BC',
  text: 'In chứng từ',
  link: '/quantri/inchungtu', 
  huongdan: Global.App_url
};

export const Ultil_bug = {
  id: '5159E97E-2428-4C72-999D-DE2F444B2D39',
  text: 'Quản lý test',
  link: '/quantri/bug',
  huongdan: Global.App_url
};
export const Ultil_nhatkytruycap = {
  id: '2391F2AB-836E-48E9-B8C0-BE1FC543E387',
  text: 'Nhật ký truy cập',
  link: '/quantri/nhatkytruycap', 
  border: 'co',
  huongdan: Global.App_url
};


export const Ultil_nhanvien = {
  id: 'EB568D2C-E77C-4DE5-9CAB-82359D2A13EB',
  text: 'Danh sách nhân viên',
  link: '/caidat/nhanvien',
  huongdan: Global.App_url
};
export const Ultil_hoahong = {
  id: '73ABA6E6-BF7B-496D-AC7D-F9E3FE241002',
  text: 'Hoa hồng nhân viên',
  link: '/caidat/hoahong',
  huongdan: Global.App_url
};

export const Ultil_khachhang = {
  id: '24A92939-9CEB-4DB6-BB4A-2C5379A3ED65',
  text: 'Quản lý khách hàng',
  link: '/caidat/khachhang',
  huongdan: Global.App_url
};
export const Ultil_soquy = {
  id: '8C7443C8-7A12-4903-B27D-66EEF0947B71',
  text: 'Sổ quỹ tiền mặt',
  link: '/caidat/soquy',
  huongdan: Global.App_url
};
export const Ultil_tinhgia = {
  id: '0A4BB264-56CA-4ADC-B851-0DAC63AECB6B',
  text: 'Tính giá vốn',
  link: '/caidat/tinhgiavon',
  huongdan: Global.App_url
};
export const Ultil_cauhinhdongthoi = {
  id: '155C1465-40AC-4D2A-B51E-B2BA1DFA0FA2',
  text: 'Cấu hình hạch toán đồng thời',
  link: '/ketoan/cauhinhdongthoi',
  huongdan: Global.App_url
};
export const Ultil_hachtoanquy = {
  id: '5A0B6C81-0811-466D-ACAA-E2841EF25988',
  text: 'Cấu hình hạch toán quỹ',
  link: '/danhmuc/hachtoanquy',
  huongdan: Global.App_url
};
export const Ultil_hachtoankhobac = {
  id: 'CED94EE4-A72E-4411-89F7-D46197DF4283',
  text: 'Cấu hình hạch toán kho bạc',
  link: '/danhmuc/hachtoankhobac',
  huongdan: Global.App_url
};
export const Ultil_hachtoantaisan = {
  id: 'FBB08A81-C1F7-47C2-85AA-37950C368F61',
  text: 'Cấu hình hạch toán tài sản',
  link: '/danhmuc/hachtoantaisan',
  huongdan: Global.App_url
};
export const Ultil_hachtoanmuahang = {
  id: '5B3EA275-DED9-462C-8185-B2E05DF2C1FE',
  text: 'Cấu hình hạch toán mua hàng',
  link: '/danhmuc/hachtoanmuahang',
  huongdan: Global.App_url
};
export const Ultil_hachtoanluong = {
  id: 'C1A16123-E644-4180-91A4-31EB2534AE25',
  text: 'Cấu hình hạch toán lương',
  link: '/danhmuc/hachtoanluong',
  border: 'co',
  huongdan: Global.App_url
};
export const Ultil_khoaso = {
  id: '2BB77568-1346-4056-9839-60B6D85F80FC',
  text: 'Khóa sổ kế toán',
  link: '/danhmuc/khoaso',
  huongdan: Global.App_url
};

//A758239A-A9AC-4A3B-9816-794B07127268
//6E03C81C-C426-4C94-AE24-BA7E769B944F

export const Ultil_danhsochungtu = {
  id: 'A758239A-A9AC-4A3B-9816-794B07127268',
  text: 'Đánh số chứng từ',
  link: '/ketoan/danhsochungtu',
  huongdan: Global.App_url
};
export const Ultil_timdoituong = {
  id: '3E366F69-BDEE-4D73-963A-6DCAA2BC070B',
  text: 'Tìm đối tượng',
  link: '/ketoan/timdoituong',
  huongdan: Global.App_url
};
export const Ultil_kiemtraloi = {
  id: 'C641370B-520E-48CF-A95E-D58E82607D42',
  text: 'Kiểm tra lỗi',
  link: '/ketoan/kiemtraloi',
  huongdan: Global.App_url
};


export const Ultil_lydulieutudonvikhac = {
  id: 'F266B8F7-E792-4BFC-A0DE-D8DB987419A9',
  text: 'Lấy dữ liệu từ đơn vị khác',  // lấy dữ liệu
  link: '/ketoan/laydulieu',
  huongdan: Global.App_url
};



export const Ultil_tailieu = {
  id: '4034D0E4-356E-4CEB-9C1A-2DD64882807C',
  text: 'Quản lý tài liệu',
  link: '/quantri/quanlytailieu',
  huongdan: Global.App_url
};
export const Ultil_baocaotaichinh = {
  id: 'BBE052F1-7029-4626-806A-8C692C434A2C',
  text: 'Cấu hình báo cáo tình hình tài chính',
  link: '/danhmuc/cauhinhbaocaotaichinh',
  huongdan: Global.App_url
};
export const Ultil_baocaotaichinhmaudongia = {
  id: '5402F5FF-5BA5-4DD0-BB64-4628F659BC9C',
  text: 'Cấu hình báo cáo tài chính (mẫu đơn giản)',
  link: '/danhmuc/cauhinhbaocaotaichinhmaudongian',
  huongdan: Global.App_url
};
export const Ultil_baocaoketquahoatdong = {
  id: '21FF2E26-3486-4B36-BE1C-B3C87C40EEFE',
  text: 'Cấu hình báo cáo kết quả hoạt động',
  link: '/danhmuc/cauhinhbaocaoketquahoatdong',
  huongdan: Global.App_url
};

export const Ultil_baocaoluuchuyentientegiantiep = {
  id: '1067D24D-DBA4-4B90-99CF-8115C152C5A4',
  text: 'Cấu hình báo cáo lưu chuyển tiền tệ',
  link: '/danhmuc/cauhinhbaocaoluuchuyentientegiantiep',
  huongdan: Global.App_url
};
export const Ultil_baocaoluuchuyentientetructiep = {
  id: 'E79E5743-E6B2-43C8-B25A-1C7574AEF596',
  text: 'Cấu hình báo cáo lưu chuyển tiền tệ (trực tiếp)',
  link: '/danhmuc/cauhinhbaocaoluuchuyentientetructiep',
  huongdan: Global.App_url
};
export const Ultil_baocaothuyetminhtaichinh = {
  id: 'EDE794E0-B59D-454A-93C9-D3BEC39B9315',
  text: 'Cấu hình thuyết minh báo cáo tài chính (B04/BCTC)',
  link: '/danhmuc/cauhinhthuyetminhtaichinh',
  huongdan: Global.App_url
};

export const Ultil_baocaobosungthongtintaichinh = {
  id: 'D76B6D63-013A-4B0F-B6BB-5F9067E0A195',
  text: 'Cấu hình bổ sung thông tin tài chính (B01/BSTT)',
  link: '/danhmuc/cauhinhbosungthongtintaichinh',
  huongdan: Global.App_url
};

export const Ultil = {
  id: '',
  text: 'Tiện ích',
  link: '/headingComponents',
  icon: 'fa fas fa-th',
  submenu: [
    Ultil_timdoituong,
    Ultil_kiemtraloi,
    Ultil_danhsochungtu,
    Ultil_khoaso,
    Ultil_lydulieutudonvikhac,
    //Ultil_tailieu,
    //Ultil_nhatkytruycap,
    Ultil_cauhinhnhapdulieutuexcel,
    Ultil_cauhinhdongthoi,
    Ultil_hachtoanquy,
    Ultil_hachtoankhobac,
    Ultil_hachtoantaisan,
    Ultil_hachtoanmuahang,
    Ultil_hachtoanluong,

    Ultil_baocaotaichinh,
    Ultil_baocaoketquahoatdong,
    Ultil_baocaoluuchuyentientetructiep,
    Ultil_baocaoluuchuyentientegiantiep,
    Ultil_baocaothuyetminhtaichinh,
    Ultil_baocaotaichinhmaudongia,
    Ultil_baocaobosungthongtintaichinh,
  ]
};

//------------------------bảng lương
export const payroll_bangcong = {
  id: '81BEFB36-CBE4-4AC0-8526-53F3F9BDD1E0',
  text: 'Bảng công',
  link: '/ketoan/bangcong',
  huongdan: Global.App_url
};
export const payroll_cauhinh = {
  id: '1D6B2542-2C1C-4FFC-9280-7886BDF2644E',
  text: 'Cấu hình lương',
  link: '/quantri/cauhinhluong',
  huongdan: Global.App_url
};
export const payroll_thunhap = {
  id: 'FBF4ED65-8450-4BED-96F5-BAF239295076',
  text: 'Thu nhập không thường xuyên',
  link: '/ketoan/thunhapkhongthuongxuyen',
  huongdan: Global.App_url
};
export const payroll_khautru = {
  id: '25425981-B501-4474-99F2-C1436459F145',
  text: 'Khấu trừ không thường xuyên',
  link: '/ketoan/khautrukhongthuongxuyen',
  huongdan: Global.App_url
};
export const payroll_tinhluong = {
  id: 'B5925C7F-6898-4C54-AF64-DBA3F9BEAC0E',
  text: 'Tính lương',
  link: '/ketoan/tinhluong',
  border: 'Co',
  huongdan: Global.App_url
};
export const payroll_truylinh = {
  id: 'A6688B24-A4C8-41BB-AB4C-2061985284EE',
  text: 'Truy lĩnh lương',
  link: '/ketoan/truylinhluong',
  huongdan: Global.App_url
};
export const payroll_tinhthuetncn = {
  id: '49152B91-5B71-4A3D-99B6-BD0712689F11',
  text: 'Quản lý thuế TNCN',
  link: '/ketoan/thueTNCN',
  huongdan: Global.App_url
};
export const payroll_noptncn = {
  id: '396AE085-7480-43B7-8CB9-41A9FB33B4F3',
  text: 'Nộp thuế TNCN',
  link: '/ketoan/nopTNCN',
  huongdan: Global.App_url
};
export const payroll_hachtoanluong = {
  id: '8319E0CE-4A72-4592-B11C-74CA27BC24CC',
  text: 'Hạch toán lương',
  link: '/ketoan/hachtoanluong',
  huongdan: Global.App_url
};
export const payroll_traluong = {
  id: 'D031CEEC-45E7-48F8-B641-6E18612555A7',
  text: 'Trả lương',
  link: '/ketoan/traluong',
  huongdan: Global.App_url
};
export const payroll_baohiem = {
  id: 'C028552D-0D34-4838-9077-7952E9AFC45D',
  text: 'Nộp bảo hiểm',
  link: '/ketoan/nopbaohiem',
  border: 'Co',
  huongdan: Global.App_url
};
export const payroll_nangcauhinh = {
  id: '3955FF27-9344-4389-A888-58096FD83C9F',
  text: 'Cấu hình truy lĩnh lương',
  link: '/quantri/nangcauhinh',
  huongdan: Global.App_url
};
export const Payroll = {
  id: '',
  text: 'Lương',
  link: '',
  icon: 'fa fas fa-history',
  submenu: [
    //payroll_bangcong,
    payroll_thunhap, payroll_khautru, payroll_truylinh, payroll_tinhluong, payroll_tinhthuetncn, payroll_noptncn, payroll_hachtoanluong, payroll_traluong, payroll_baohiem, payroll_cauhinh
    //, payroll_nangcauhinh
  ],
  huongdan: Global.App_url
};
//----------------------quản trị
export const System_vaitro = {
  id: '5EB2FFBB-B173-42FE-B155-AB473CE914C1',
  text: 'Vai trò',
  link: '/quantri/vaitro',
  border: 'co',
  huongdan: Global.App_url
};
export const System_nguoidung = {
  id: 'CC11D304-2673-40B5-9E2F-295888991A35',
  text: 'Người dùng',
  link: '/quantri/nguoidung',
  huongdan: Global.App_url
};
export const System_thamso = {
  id: 'EB0CE66D-656D-48C9-8BBA-E74309682A18',
  text: 'Tham số hệ thống',  
  link: '/quantri/thamso',
  huongdan: Global.App_url
};
export const Ultil_donvi = {
  id: '610562E5-AA28-45CB-811B-F349395DA524',
  text: 'Quản lý đơn vị dữ liệu',  // đơn vị
  link: '/quantri/donvi',
  huongdan: Global.App_url
};

export const Ultil_doanhnghiep = {
  id: '1575B35C-4695-4301-A35E-801132A7511E',
  text: 'Quản lý đơn vị sử dụng',  
  link: '/quantri/doanhnghiep',
  huongdan: Global.App_url
};

export const Ultil_phanhe = {
  id: 'E84CDFB9-E9BB-4E79-9CEB-74BF2C175331',
  text: 'Cấu trúc phân hệ',
  link: '/quantri/phanhe',
  border: 'co',
  huongdan: Global.App_url
};

export const System_history = {
  id: '',
  text: 'Bản mới',
  link: '/danhmuc/history',
  icon: 'fa fas fa-history',
  huongdan: Global.App_url
};
export const Ultil_thietlapngamdinh = {
  id: '62EEB8A0-084B-4D9B-B892-C5CF3C94D6E7',
  text: 'Thiết lập ngầm định',
  link: '/ketoan/thietlapngamdinh',
  huongdan: Global.App_url
};
export const Ultil_saoluudulieu = {
  id: 'DC4D283D-4181-4773-BE7E-D576B1430EB8',
  text: 'Sao lưu dữ liệu',
  link: '/quantri/saoluudulieu',
  huongdan: Global.App_url
};
export const Ultil_nhatkysaoluu = {
  id: '9B779F0A-3D4B-4CDB-87BB-F41529819CC9',
  text: 'Nhật ký sao lưu',
  link: '/quantri/nhatkysaoluu', 
  huongdan: Global.App_url
};
export const System = {
  id: '',
  text: 'Hệ thống',
  link: '',
  icon: 'fa fa-cog',
  submenu: [
    System_thamso, Ultil_thietlapngamdinh, Ultil_saoluudulieu, Ultil_nhatkysaoluu, Ultil_nhatkytruycap, System_nguoidung, System_vaitro, Ultil_donvi, Ultil_doanhnghiep
    , Ultil_phanhe
    , System_history, Ultil_bug
  ],
  huongdan: Global.App_url
};

//-----------------------------------------

//--- dự toán
export const DuToans_dutoanthuxuphat = {
  id: 'C3E10F64-9166-4C91-A2C6-2C9895F44CDE',
  text: 'Dự toán thu ngân sách',
  link: '/dutoan/dutoanthuxuphat',
  huongdan: Global.App_url
};


export const DuToans_dutoantrangbioto = {
  id: '13B637DC-7051-49BD-B6E4-86159080A28E',
  text: 'Dự toán trang bị ô tô',
  link: '/dutoan/dutoantrangbioto',
  huongdan: Global.App_url
};

export const DuToans_dutoantrangbimaytinh = {
  id: 'CFAE507A-F29E-4002-AF8F-AAC258681FF5',
  text: 'Dự toán trang bị máy tính',
  link: '/dutoan/dutoantrangbimaytinh',
  huongdan: Global.App_url
};


export const DuToans_dutoanthutubanhang = {
  id: '11188390-5328-4627-B1D4-97CC53843F66',
  text: 'Dự toán thu từ bán hàng',
  link: '/dutoan/dutoanthuxuphat',
  huongdan: Global.App_url
};
export const DuToans_dutoanthutuhangtieuhuy = {
  id: 'CF89D60E-C9A4-44A4-A040-2D1AE884A6A1',
  text: 'Dự toán thu từ hàng tiêu hủy',
  link: '/dutoan/dutoanthuxuphat',
  huongdan: Global.App_url
};
export const DuToans_dutoanchikhongthuongxuyen = {
  id: 'BC5FAD2A-E5C2-4B5F-9740-153B30EB93B8',
  text: 'Chi quản lý hành chính không tự chủ',
  link: '/dutoan/dutoanchikhongthuongxuyen',
  huongdan: Global.App_url
};
export const DuToans_dutoanchituchu = {
  id: '6ACEF66F-DD8A-4263-BE0E-6F07C64EA5E3',
  text: 'Chi tự chủ',
  link: '/dutoan/dutoanchituchu',
  huongdan: Global.App_url
};
export const DuToans_dutoanchidaotao = {
  id: 'E457C764-CCC2-480F-8B0A-5543962464B6',
  text: 'Chi sự nghiệp đào tạo',
  link: '/dutoan/dutoanchidaotao',
  huongdan: Global.App_url
};
export const DuToans_dutoanchituchutonghop = {
  id: '2F9B7802-9128-47DD-9E72-6F9FB187ECCD',
  text: 'Chi tự chủ tổng hợp',
  link: '/dutoan/dutoanchituchutonghop',
  huongdan: Global.App_url
};

export const DuToans_dutoanchiyte = {
  id: '3B2D0F11-6733-49EF-A188-508EBF2A9D2D',
  text: 'Chi chương trình mục tiêu Y tế, dân số',
  link: '/dutoan/dutoanchiyte',
  huongdan: Global.App_url
};
export const DuToans_dutoanquyluongcongchuc = {
  id: '1D2F3763-DC3B-4F85-BDA0-358791D8625F',
  text: 'Dự toán quỹ lương công chức',
  link: '/dutoan/dutoanluongcongchuc',
  huongdan: Global.App_url
};
export const DuToans_dutoanquyluongchotuyen = {
  id: '69067956-572D-4D74-99D4-AF98F2F22DBD',
  text: 'Dự toán quỹ lương chờ tuyển, chờ ký HĐ',
  link: '/dutoan/dutoanquyluongchotuyen',
  huongdan: Global.App_url
};
export const DuToans_dutoanquyluong_hd68 = {
  id: '9B51E6F3-7B6F-4989-9F00-97816C12FE44',
  text: 'Dự toán quỹ lương HĐ 68',
  link: '/dutoan/dutoanquyluonghd',
  huongdan: Global.App_url
};
export const DuToans_dutoantrangphuc = {
  id: 'FACB3A81-D316-47D5-9E0B-1F16C7CB54B4',
  text: 'Dự toán trang phục',
  link: '/dutoan/dutoantrangphuc',
  huongdan: Global.App_url
};
export const DuToans_dutoananchi = {
  id: '6C0F7AF3-AA87-4049-92BD-22300A47905D',
  text: 'Dự toán ấn chỉ',
  link: '/dutoan/dutoananchi',
  huongdan: Global.App_url
};
export const DuToans_dutoankinhphixuphat153 = {
  id: '43C242EE-57FE-4F71-BA9B-20458DEFBA0F',
  text: 'Dự toán kinh phí xử phạt 153',
  link: '/dutoan/dutoankinhphixuphat153',
  huongdan: Global.App_url
};
export const DuToans_dutoanmuasamtaisan = {
  id: 'D622070E-4BAC-4D21-9F56-65A357754082',
  text: 'Dự toán mua sắm tài sản',
  link: '/dutoan/dutoanmuasamtaisan',
  huongdan: Global.App_url
};
export const DuToans_dutoanpheduyet = {
  id: '0E0C10EB-40F7-4E6E-A67F-B9E121CF6BB9',
  text: 'Phê duyệt dự toán',
  link: '/dutoan/pheduyetdutoan',
  huongdan: Global.App_url
};
export const DuToans_dutoansuachua = {
  id: '5031FB5C-6152-4970-9C24-4EAF4DE458DF',
  text: 'Dự toán sửa chữa',
  link: '/dutoan/dutoansuachua',
  huongdan: Global.App_url
};
export const DuToans_dutoanthuetruso = {
  id: '3B428FB2-7207-41BC-BB07-C3840B520796',
  text: 'Dự toán thuê trụ sở, kho bãi',
  link: '/dutoan/dutoanthuetruso',
  huongdan: Global.App_url
};
export const DuToans_dutoanchodetai = {
  id: '7069FC02-4F88-4477-B868-21596A0D0EA6',
  text: 'Dự toán cho đề án',
  link: '/dutoan/dutoanchodetai',
  huongdan: Global.App_url
};
export const DuToans_dutoandoankiemtra = {
  id: '08EB2B52-A37C-4186-A71D-7AF0307AD8E4',
  text: 'Dự toán đoàn kiểm tra liên ngành',
  link: '/dutoan/dutoandoankiemtra',
  huongdan: Global.App_url
};
export const DuToans_dutoankhoasolieu = {
  id: '9C82DFE8-BF7F-459F-A22A-872393CBEC56',
  text: 'Khóa số liệu dự toán',
  link: '/dutoan/dutoankhoasolieu',
  huongdan: Global.App_url
};
export const DuToans_dutoandieuchinh = {
  id: '38BF8C51-4DBA-4BDB-810D-6E4BD16A16DA',
  text: 'Phân bổ dự toán',
  link: '/dutoan/phanbodutoan',
  huongdan: Global.App_url
};
export const DuToans_dutoanhuy = {
  id: '48FC391C-6E9F-4D1C-AD15-7224C8986472',
  text: 'Hủy dự toán',
  link: '/dutoan/dutoanhuy',
  huongdan: Global.App_url
};



export const DuToans_tongnhucauvadukienkehoachdautu = {
  id: BaoCao.BC150_DuToan_TongNhuCauVaDuKienKeHoachDauTu,
  text: 'Tổng nhu cầu và dự kiến kế hoạch đầu tư',
  link: '/baocaodutoan/tongnhucauvadukienkehoachdautu/' + BaoCao.BC150_DuToan_TongNhuCauVaDuKienKeHoachDauTu,
  huongdan: Global.App_url
};

export const DuToans_chitietdukienkehoachdautu = {
  id: BaoCao.BC151_DuToan_ChiTietDuKienKeHoachDauTu,
  text: 'Chi tiết dự kiến kế hoạch đầu tư',
  link: '/baocaodutoan/chitietdukienkehoachdautu/' + BaoCao.BC151_DuToan_ChiTietDuKienKeHoachDauTu,
  huongdan: Global.App_url
};

export const DuToans_tonghopketquagiao = {
  id: BaoCao.BC152_DuToan_TongHopKetQuaGiaoVaKeHoachDauTu,
  text: 'Tổng hợp kết quả giao và thông báo kế hoạch đầu tư',
  link: '/baocaodutoan/tonghopketquagiao/' + BaoCao.BC152_DuToan_TongHopKetQuaGiaoVaKeHoachDauTu,
  huongdan: Global.App_url
};
export const DuToans_chitietketquagiao = {
  id: BaoCao.BC153_ChiTietKetQuaGiaoVaThongBaoKeHoachDauTu,
  text: 'Chi tiết kết quả giao và thông báo kế hoạch đầu tư',
  link: '/baocaodutoan/chitietketquagiao/' + BaoCao.BC153_ChiTietKetQuaGiaoVaThongBaoKeHoachDauTu,
  huongdan: Global.App_url
};
export const DuToans_tinhhinhthuchienvakehoachgiaingandautu = {
  id: BaoCao.BC154_DuToan_TongHopTinhHinhThucHienVaKeHoachGiaiNganDauTu,
  text: 'Tổng hợp tình hình thực hiện và kế hoạch giải ngân đầu tư',
  link: '/baocaodutoan/tinhhinhthuchienvakehoachgiaingandautu/' + BaoCao.BC154_DuToan_TongHopTinhHinhThucHienVaKeHoachGiaiNganDauTu,
  huongdan: Global.App_url
};

export const DuToans_tinhhinhthuchienvakehoachdautuphattrien = {
  id: BaoCao.BC155_TongHopTinhHinhThucHienKeHoachDauTuPhatTrien,
  text: 'Tổng hợp tình hình thực hiện kế hoạch đầu tư phát triển',
  link: '/baocaodutoan/tinhhinhthuchienvakehoachdautuphattrien/' + BaoCao.BC155_TongHopTinhHinhThucHienKeHoachDauTuPhatTrien,
  huongdan: Global.App_url
};

export const DuToans_chitiettinhhinhthuchienkehoachdautu = {
  id: BaoCao.BC156_ChiTietTinhHinhThucHienKeHoachDauTu,
  text: 'Chi tiết tình hình thực hiện kế hoạch đầu tư (theo dự án)',
  link: '/baocaodutoan/chitiettinhhinhthuchienkehoachdautu/' + BaoCao.BC156_ChiTietTinhHinhThucHienKeHoachDauTu,
  huongdan: Global.App_url
};

export const DuToans_chitiettinhhinhthuchienvagiaingantheokehoachdautu = {
  id: BaoCao.BC157_ChiTietTinhHinhThucHienVaGiaiNganTheoKeHoachDauTu,
  text: 'Chi tiết tình hình thực hiện và giải ngân theo kế hoạch đầu tư',
  link: '/baocaodutoan/chitiettinhhinhthuchienvagiaingantheokehoachdautu/' + BaoCao.BC157_ChiTietTinhHinhThucHienVaGiaiNganTheoKeHoachDauTu,
  huongdan: Global.App_url
};

export const DuToans_bangtonghopquyluongcongchuc = {
  id: BaoCao.BC158_BangTongHopQuyLuongCongChuc,
  text: 'Bảng tổng hợp quỹ lương cho công chức',
  link: '/baocaodutoan/bangtonghopquyluongcongchuc/' + BaoCao.BC158_BangTongHopQuyLuongCongChuc,
  huongdan: Global.App_url
};

export const DuToans_bangtonghopquyluongchotuyen = {
  id: BaoCao.BC159_BangTongHopQuyLuongChoTuyen,
  text: 'Bảng tổng hợp quỹ lương chờ tuyển',
  link: '/baocaodutoan/bangtonghopquyluongchotuyen/' + BaoCao.BC159_BangTongHopQuyLuongChoTuyen,
  huongdan: Global.App_url
};

export const DuToans_bangtonghopquyluonghd68 = {
  id: BaoCao.BC160_BangTongHopQuyLuongHD68,
  text: 'Bảng tổng hợp quỹ lương HĐ 68',
  link: '/baocaodutoan/bangtonghopquyluonghd68/' + BaoCao.BC160_BangTongHopQuyLuongHD68,
  huongdan: Global.App_url
};

export const DuToans_bangtonghopquyluong = {
  id: BaoCao.BC161_BangTongHopQuyLuong,
  text: 'Bảng tổng hợp quỹ lương',
  link: '/baocaodutoan/bangtonghopquyluong/' + BaoCao.BC161_BangTongHopQuyLuong,
  huongdan: Global.App_url
};
export const DuToans_thuyetminhtonghopdutoan = {
  id: BaoCao.BC163_ThuyetMinhTongHopDuToan,
  text: 'Thuyết minh tổng hợp dự toán',
  link: '/baocaodutoan/thuyetminhtonghopdutoan/' + BaoCao.BC163_ThuyetMinhTongHopDuToan,
  huongdan: Global.App_url
};

export const DuToans_dutoanthuchingansachduocgiaovaphanbo = {
  id: BaoCao.BC164_DuToanThuChiNganSachDuocGiaoVaPhanBo,
  text: 'Dự toán thu - chi ngân sách được giao và phân bổ cho đơn vị trực thuộc',
  link: '/baocaodutoan/dutoanthuchingansachduocgiaovaphanbo/' + BaoCao.BC164_DuToanThuChiNganSachDuocGiaoVaPhanBo,
  huongdan: Global.App_url
};

export const DuToans_dutoanthuchingansach = {
  id: BaoCao.BC165_DuToanThuChiNSNN,
  text: 'Dự toán thu - chi ngân sách nhà nước',
  link: '/baocaodutoan/dutoanthuchingansach/' + BaoCao.BC165_DuToanThuChiNSNN,
  huongdan: Global.App_url
};

export const DuToans_danhgiathuchiendutoanthuchins = {
  id: BaoCao.BC166_DanhGiaThucHienDuToanThuChiNS,
  text: 'Đánh giá thực hiện dự toán thu chi ngân sách',
  link: '/baocaodutoan/danhgiathuchiendutoanthuchins/' + BaoCao.BC166_DanhGiaThucHienDuToanThuChiNS,
  huongdan: Global.App_url
};

export const DuToans_dutoanthuchiduocgiaophanbochodonvitructhuoc = {
  id: BaoCao.BC167_DuToanThuChiNSNNDuocGiaoVaPhanBoChoDonViTrucThuoc,
  text: 'Dự toán thu chi ngân sách nhà nước hỗ trợ được giao và phân bổ cho đơn vị trực thuộc',
  link: '/baocaodutoan/dutoanthuchiduocgiaophanbochodonvitructhuoc/' + BaoCao.BC167_DuToanThuChiNSNNDuocGiaoVaPhanBoChoDonViTrucThuoc,
  huongdan: Global.App_url
};

export const DuToans_baocaodutoanthuchingansach = {
  id: BaoCao.BC168_DuToanThuChiNganSach,
  text: 'Dự toán thu chi ngân sách',
  link: '/baocaodutoan/baocaodutoanthuchingansach/' + BaoCao.BC168_DuToanThuChiNganSach,
  huongdan: Global.App_url
};
export const DuToans_soxulyviphamhanhchinh = {
  id: BaoCao.BC169_SoXuLyViPhamHanhChinh,
  text: 'Sổ xử lý vi phạm hành chính',
  link: '/baocaodutoan/soxulyviphamhanhchinh/' + BaoCao.BC169_SoXuLyViPhamHanhChinh,
  huongdan: Global.App_url
};
export const DuToans_TongHopDuToanThuNganSach = {
  id: BaoCao.BC170_TongHopDuToanThuNganSach,
  text: 'Phụ lục 10: Báo cáo tổng hợp nhu cầu thu chi giai đoạn 3 năm',
  link: '/baocaodutoan/tonghopdutoanthungansach/' + BaoCao.BC170_TongHopDuToanThuNganSach,
  huongdan: Global.App_url
};

export const DuToans_TongHopDuToanThuNganSach01 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc01,
  text: 'Phụ lục 07: Báo cáo danh sách tinh giản biên chế',
  link: '/baocaodutoan/tonghopdutoanthungansach01/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc01,
  huongdan: Global.App_url
};
export const DuToans_TongHopDuToanThuNganSach02 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc02,
  text: 'Phụ lục 08: Báo cáo kinh phí dành cho công tác đào tạo',
  link: '/baocaodutoan/tonghopdutoanthungansach02/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc02,
  huongdan: Global.App_url
};
export const DuToans_BangTongHopQuyLuong03 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc03,
  text: 'Phụ lục 01, 02: Bảng tổng hơp quỹ lương',
  link: '/baocaodutoan/bangtonghopquyluong03/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc03,
  huongdan: Global.App_url
};

export const DuToans_KeHoachThucHienNhiemVu04 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc04,
  text: 'Phụ lục 05: Kế hoạch thực hiện nhiệm vụ và nội dung chi KTC',
  link: '/baocaodutoan/kehoachthuchiennhiemvu04/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc04,
  huongdan: Global.App_url
};

export const DuToans_KeHoachThucHienNhiemVu05 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc05,
  text: 'Phụ lục 03: Kế hoạch thực hiện nhiệm vụ và nội dung chi KTC',
  link: '/baocaodutoan/kehoachthuchiennhiemvu05/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc05,
  huongdan: Global.App_url
};
export const DuToans_BangTongHopQuyLuong06 = {
  id: BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc06,
  text: 'Phụ lục 04: Bảng tổng hợp quỹ lương HĐ',
  link: '/baocaodutoan/kehoachthuchiennhiemvu06/' + BaoCao.BC_DT_ThuChiNganSach_TH_PhuLuc06,
  huongdan: Global.App_url
};



export const DuToans_TongHopDuToanThuNganSach07 = {
  id: BaoCao.BC_DT_ThuNganSach_TH_PhuLuc07,
  text: 'Phụ lục 09: Báo cáo tổng hợp số thu từ công tác XPHC',
  link: '/baocaodutoan/tonghopdutoanthungansach07/' + BaoCao.BC_DT_ThuNganSach_TH_PhuLuc07,
  huongdan: Global.App_url
};
export const DuToans_TongHopDuToanThuNganSach08 = {
  id: BaoCao.BC_DT_ThuNganSach_TH_PhuLuc08,
  text: 'Phụ lục 11: Báo cáo thực hiện nhiệm vụ ngân sách nhà nước',
  link: '/baocaodutoan/tonghopdutoanthungansach08/' + BaoCao.BC_DT_ThuNganSach_TH_PhuLuc08,
  huongdan: Global.App_url
};
export const DuToans_ThuyetMinhDuToan09 = {
  id: BaoCao.BC_DT_ThuyetMinhTangGiamDuToan_PhuLuc09,
  text: 'Phụ lục 06: Báo cáo danh sách công trình cải tạo, sửa chữa',
  link: '/baocaodutoan/thuyetminhtanggiamdutoan/' + BaoCao.BC_DT_ThuyetMinhTangGiamDuToan_PhuLuc09,
  huongdan: Global.App_url
};

export const DuToans_TongHopDuToanMuaSamTrangPhuc = {
  id: BaoCao.BC_DT_TongHopDuToanTrangPhuc_TH_PhuLuc12,
  text: 'Phụ lục 12: Bảng tổng hợp dự toán mua sắm trang phục',
  link: '/baocaodutoan/tonghopdutoanmuasamtrangphuc12/' + BaoCao.BC_DT_TongHopDuToanTrangPhuc_TH_PhuLuc12,
  huongdan: Global.App_url
};



export const DuToans_TongHopDuToanTrangBiOto = {
  id: '539AEEB6-52D1-46FA-B04B-480DE9E44255',
  text: 'Phụ lục 13: Bảng tổng hợp dự toán trang bị ô tô',
  link: '/baocaodutoan/tonghopdutoantrangbioto/' + '539AEEB6-52D1-46FA-B04B-480DE9E44255',
  huongdan: Global.App_url
};

export const DuToans_TongHopDuToanTrangBiMayTinh = {
  id: 'F7C78144-882E-45CE-8DB6-1EA91B821A45',
  text: 'Phụ lục 14: Bảng tổng hợp dự toán trang bị máy tính, máy in',
  link: '/baocaodutoan/tonghopdutoantrangbimaytinh/' + 'F7C78144-882E-45CE-8DB6-1EA91B821A45',
  huongdan: Global.App_url
};

export const DuToans_TongHopDuToanThietBiChuyenDung = {
  id: BaoCao.BC_DT_TongHopDuToanThietBiChuyenDung_TH_PhuLuc15,
  text: 'Phụ lục 15: Bảng tổng hợp dự toán thiết bị chuyên dùng',
  link: '/baocaodutoan/tonghopdutoanthietbi15/' + BaoCao.BC_DT_TongHopDuToanThietBiChuyenDung_TH_PhuLuc15,
  huongdan: Global.App_url
};


export const DuToans_chitieu = {
  id: 'A4768A90-EC0B-43F1-AD57-A9935F939F9E',
  text: 'Chỉ tiêu dự toán chi',
  link: '/dutoan/chitieuchi/',
  huongdan: Global.App_url
};
export const DuToans_chitieuthu = {
  id: '11ea3811-2cfe-4e85-9184-989ffbe6f0d6',
  text: 'Chỉ tiêu thu ngân sách',
  link: '/dutoan/chitieuthu/',
  huongdan: Global.App_url
};
export const DuToans_dmmuasamtaptrung = {
  id: '9EE80858-B031-4DF3-8431-67EF5391990F',
  text: 'Danh mục mua sắm tập trung',
  link: '/danhmuc/muasamtaptrung/',
  huongdan: Global.App_url
};
export const DuToans_dmtrangphuc = {
  id: '2015CCBB-79BE-4763-B86A-16D6BABCF56E',
  text: 'Danh mục trang phục',
  link: '/danhmuc/trangphucquanao/',
  huongdan: Global.App_url
};
export const DuToans_dmcongcuhotro = {
  id: 'DC2BB0D1-D22B-4F81-9A58-5EC4D81B6AD3',
  text: 'Danh mục công cụ',
  link: '/danhmuc/congcuhotro/',
  huongdan: Global.App_url
};

export const DuToans_dmthietbichuyendung = {
  id: '4C96618D-E244-4739-9DE7-822500118BC0',
  text: 'Danh mục thiết bị chuyên dùng',
  link: '/danhmuc/thietbichuyendung/',
  huongdan: Global.App_url
};

export const DuToans_phienban = {
  id: '6EEDE551-78CE-44B2-A4C4-B126336BD344',
  text: 'Quản lý phiên bản',
  link: '/dutoan/quanlyphienban/',
  huongdan: Global.App_url
};


export const DuToans = {
  id: '',
  text: ' Dự toán',
  link: '/dutoans',
  icon: 'fa fas fa-money-check',
  submenu: [
    DuToans_phienban,
    DuToans_dutoanthuxuphat,
    //{
    //  id: '',
    //  text: 'Dự toán thu',
    //  link: '/elements',
    //  icon: 'fa fa-list-ul',
    //  submenu:
    //    [
    //      DuToans_dutoanthuxuphat,
    //      DuToans_dutoanthutubanhang,
    //      DuToans_dutoanthutuhangtieuhuy,
    //      DuToans_TongHopDuToanThuNganSach,
    //    ]
    //},
    {
      id: '',
      text: 'Bảng lương',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          DuToans_dutoanquyluongchotuyen,
          DuToans_dutoanquyluongcongchuc,
          DuToans_dutoanquyluong_hd68,
        ]
    },
    {
      id: '',
      text: 'Dự toán chi',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          //DuToans_dutoanchituchu,
          // DuToans_dutoanchituchutonghop,
          DuToans_dutoanchidaotao,
          DuToans_dutoanchikhongthuongxuyen,
          DuToans_dutoantrangbioto,
          DuToans_dutoantrangbimaytinh

          //DuToans_dutoanchiyte,
        ]
    },

    {
      id: '',
      text: 'Báo cáo',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [

        DuToans_BangTongHopQuyLuong03,
        DuToans_KeHoachThucHienNhiemVu05,
        DuToans_BangTongHopQuyLuong06,
        DuToans_KeHoachThucHienNhiemVu04,
        DuToans_ThuyetMinhDuToan09,
        DuToans_TongHopDuToanThuNganSach01,
        DuToans_TongHopDuToanThuNganSach02,
        DuToans_TongHopDuToanThuNganSach07,
        DuToans_TongHopDuToanThuNganSach,
        DuToans_TongHopDuToanThuNganSach08,
        DuToans_TongHopDuToanMuaSamTrangPhuc,
        DuToans_TongHopDuToanTrangBiOto,
        DuToans_TongHopDuToanTrangBiMayTinh,
        DuToans_TongHopDuToanThietBiChuyenDung





        //DuToans_tongnhucauvadukienkehoachdautu,
        //DuToans_chitietdukienkehoachdautu,
        //DuToans_tonghopketquagiao,
        //DuToans_chitietketquagiao,
        //DuToans_tinhhinhthuchienvakehoachgiaingandautu,
        //DuToans_tinhhinhthuchienvakehoachdautuphattrien,
        //DuToans_chitiettinhhinhthuchienkehoachdautu,
        //DuToans_chitiettinhhinhthuchienvagiaingantheokehoachdautu,
        //DuToans_bangtonghopquyluongcongchuc,
        //DuToans_bangtonghopquyluongchotuyen,
        //DuToans_bangtonghopquyluonghd68,
        //DuToans_bangtonghopquyluong,
        //DuToans_thuyetminhtonghopdutoan,
        //DuToans_dutoanthuchingansachduocgiaovaphanbo,
        //DuToans_dutoanthuchingansach,
        //DuToans_danhgiathuchiendutoanthuchins,
        //DuToans_dutoanthuchiduocgiaophanbochodonvitructhuoc,
        //DuToans_baocaodutoanthuchingansach,
        //DuToans_soxulyviphamhanhchinh,

      ]
    },
    {
      id: '',
      text: 'Danh mục',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          DuToans_chitieu,
          DuToans_dmmuasamtaptrung,
          DuToans_dmcongcuhotro,
          DuToans_dmtrangphuc,
          DuToans_chitieuthu,
          DuToans_dmthietbichuyendung
        ]
    },
    //DuToans_dutoanpheduyet,
    DuToans_dutoandieuchinh,
    //DuToans_dutoanhuy,
    DuToans_dutoankhoasolieu,

  ]
};
//--- dự toán

//--- quyết toán
export const QuyetToans_baocaochitietkinhphi = {
  id: '4864E1F0-FAE5-43E8-8DC7-7CE0E6661FB9',
  text: 'Nhập báo cáo chi tiết kinh phí',
  link: '/quyettoan/bc01',
  huongdan: Global.App_url
};
export const QuyetToans_baocaochitietkinhphimaumoi = {
  id: '4864E1F0-FAE5-43E8-8DC7-7CE0E6661FB9',
  text: 'Nhập báo cáo chi tiết kinh phí (TT 107 mẫu mới)',
  link: '/quyettoan/bc12',
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghopkinhphi = {
  id: '3965D1BC-E4C0-4730-9E5D-6674C1AEFAD8',
  text: 'Nhập báo cáo tổng hợp kinh phí',
  link: '/quyettoan/bc03',
  huongdan: Global.App_url
};
export const QuyetToans_baocaotonghopkinhphimaumoi = {
  id: '3965D1BC-E4C0-4730-9E5D-6674C1AEFAD8',
  text: 'Nhập báo cáo tổng hợp kinh phí (TT 107 mẫu mới)',
  link: '/quyettoan/bc31',
  huongdan: Global.App_url
};

export const QuyetToans_baocaothuchihoatdongsunghiep = {
  id: '6214C01C-9F44-4F2A-B93D-78457E6C8FB6',
  text: 'Nhập báo cáo thu chi hoạt động sự nghiệp và hoạt động dịch vụ',
  link: '/quyettoan/bc02',
  huongdan: Global.App_url
};

export const QuyetToans_baothuphi1a = {
  id: '286F3F12-63AD-4F2F-8ACC-A3874D70390A',
  text: 'Nhập báo cáo thu phí, lệ phí (mẫu 1a)',
  link: '/quyettoan/bc04',
  huongdan: Global.App_url
};

export const QuyetToans_baocaoketquahoatdong1b02 = {
  id: 'D2C76005-7C71-4F77-8679-8A188D5DA655',
  text: 'Nhập báo cáo kết quả hoạt động (mẫu 1b-B02)',
  link: '/quyettoan/bc05',
  huongdan: Global.App_url
};
export const QuyetToans_baocaoketquahoatdong1b05 = {
  id: '5C9095BD-D9AE-4D84-B76E-A564270E1498',
  text: 'Nhập báo cáo kết quả hoạt động (mẫu 1b-B05)',
  link: '/quyettoan/bc06',
  huongdan: Global.App_url
};

export const QuyetToans_baocaotaichinh_b01bctc = {
  id: '169E6049-D411-4A35-9146-B84736F79C1A',
  text: 'Nhập báo cáo tình hình tài chính (B01/BCTC)',
  link: '/quyettoan/bc07',
  huongdan: Global.App_url
};

export const QuyetToans_luuchuyentiente_b03abctc = {
  id: 'BC51BA05-E387-4017-B435-78F4A4E01AE1',
  text: 'Nhập báo cáo lưu chuyển tiền tệ (B03a/BCTC)',
  link: '/quyettoan/bc08',
  huongdan: Global.App_url
};

export const QuyetToans_luuchuyentiente_b03bbctc = {
  id: '047D85BD-0CA5-4307-89DF-284DDF45D3D9',
  text: 'Nhập báo cáo lưu chuyển tiền tệ (B03b/BCTC)',
  link: '/quyettoan/bc11',
  huongdan: Global.App_url
};

export const QuyetToans_thuyetminhtaichinh = {
  id: '83DAC068-96F2-45C6-AA71-EAF4A95BBC13',
  text: 'Nhập báo cáo thuyết minh báo cáo tài chính (B04/BCTC)',
  link: '/quyettoan/bc09',
  huongdan: Global.App_url
};
export const QuyetToans_nhapbaocaobosungthongtintaichinh = {
  id: '4834108F-8607-4D7E-AE8D-8461495A1625',
  text: 'Nhập báo cáo bổ sung thông tin tài chính (B01/BSTT)',
  link: '/quyettoan/bc10',
  huongdan: Global.App_url
};

//id: 'BC5FAD2A-E5C2-4B5F-9740-153B30EB93B8',
export const QuyetToans_baocaotinhhinhthuchien_nsnn = {
  id: 'AD476CCD-201D-468B-9A18-6EEB34ADD052',
  text: 'Nhập báo cáo tình hình thực hiện NSNN',
  link: '/quyettoan/bc13',
  huongdan: Global.App_url
};

export const QuyetToans_nhapbangcandoiphatsinh = {
  id: '85E108EF-04D1-48FA-B30C-EE31BABE50C3',
  text: 'Nhập báo cáo bảng cân đối số phát sinh (S05-H)',
  link: '/quyettoan/bc32',
  huongdan: Global.App_url
};


export const QuyetToans_khoasolieubaocao = {
  id: '1CE56C7B-2F24-41C6-B52A-FF6EE356CAFC',
  text: 'Khóa số liệu',
  link: '/quyettoan/khoasolieubaocao',
  huongdan: Global.App_url
};

export const QuyetToans_quyettoanloaikhoan = {
  id: '052F8DBB-DC86-410C-8823-FEE4B9D1F4EF',
  text: 'Danh mục loại khoản',
  link: '/quyettoan/loaikhoan',
  huongdan: Global.App_url
};
export const QuyetToans_quyettoanloaikhoanchung = {
  id: '052F8DBB-DC86-410C-8823-FEE4B9D1F4EF',
  text: 'Danh mục loại khoản (mẫu tổng hợp và chi tiết kinh phí mới)',
  link: '/quyettoan/loaikhoanchung',
  huongdan: Global.App_url
};


export const QuyetToans_chitieuthuchihoatdongsunghiep = {
  id: '048FD13F-4456-44B3-87B1-1BC432E5D09C',
  text: 'Danh mục chỉ tiêu thu chi HĐ sự nghiệp và Dịch vụ ',
  link: '/quyettoan/chitieu',
  huongdan: Global.App_url
};
export const QuyetToans_danhmuctieumuc = {
  id: '8DF41B33-409E-45C8-B3D1-E91BC78DC048',
  text: 'Danh mục mục - tiểu mục',
  link: '/quyettoan/tieumuc',
  huongdan: Global.App_url
};
export const QuyetToans_danhmucchitieu1a = {
  id: '9D37504D-6C80-4BED-9E58-27EBF6EF871A',
  text: 'Danh mục chỉ tiêu mẫu 1a',
  link: '/quyettoan/chitieumau1a',
  huongdan: Global.App_url
};
export const QuyetToans_danhmucchitieu1b02 = {
  id: '1AC7D419-B01C-4B66-9223-0D0A74A4AAF4',
  text: 'Danh mục chỉ tiêu mẫu 1b-B02',
  link: '/quyettoan/chitieumau1b',
  huongdan: Global.App_url
};
export const QuyetToans_danhmucchitieu1b05 = {
  id: '340D3554-66DC-46BD-A893-0A1E797B6E0E',
  text: 'Danh mục chỉ tiêu mẫu 1b-B05',
  link: '/quyettoan/chitieumau1b05',
  huongdan: Global.App_url
};

export const QuyetToans_cauhinhchitieubaocaochitiet = {
  id: 'DF6D9B3E-BD40-444A-BAC6-E60E99211737',
  text: 'Cấu hình tiểu mục nhập báo cáo chi tiết kinh phí',
  link: '/quyettoan/cauhinhtieumuc',
  huongdan: Global.App_url
};
export const QuyetToans_cauhinhchitieubaocaochitietmaumoi = {
  id: 'DF6D9B3E-BD40-444A-BAC6-E60E99211737',
  text: 'Cấu hình tiểu mục nhập báo cáo chi tiết kinh phí (mẫu mới)',
  link: '/quyettoan/cauhinhchitiet',
  huongdan: Global.App_url
};

export const QuyetToans_cauhinhchitieuthuchisunghiep = {
  id: 'A1927326-0735-4E15-9415-2CDF8292B8A7',
  text: 'Cấu hình chỉ tiêu thu chi hoạt động sự nghiệp và hoạt động dịch vụ',
  link: '/quyettoan/cauhinhchitieu',
  huongdan: Global.App_url
};

export const QuyetToans_cauhinhchitieumau1a = {
  id: 'F5FD5134-D184-46E7-872E-8793B0B03001',
  text: 'Cấu hình chỉ tiêu mẫu 1a',
  link: '/quyettoan/cauhinhchitieumau1a',
  huongdan: Global.App_url
};



export const QuyetToans_tonghopchitietkinhphi_th = {
  id: BaoCao.BC200_TongHopChiTietKinhPhi_TH,
  text: 'Báo cáo tổng hợp chi tiết kinh phí (mẫu TH)',
  link: '/baocaoquyettoan/tonghopchitietkinhphi_th/' + BaoCao.BC200_TongHopChiTietKinhPhi_TH,
  huongdan: Global.App_url
};
export const QuyetToans_tonghopchitietkinhphi_thmaumoi = {
  id: BaoCao.BC200_TongHopChiTietKinhPhi_TH,
  text: 'Báo cáo tổng hợp chi tiết kinh phí (mẫu TH mẫu mới)',
  link: '/baocaoquyettoan/tonghopchitietkinhphi_thmaumoi/' + BaoCao.BC200_TongHopChiTietKinhPhi_TH,
  huongdan: Global.App_url
};


export const QuyetToans_tonghopchitietkinhphi_donvi = {
  id: BaoCao.BC201_TongHopChiTietKinhPhi_DonVi,
  text: 'Báo cáo tổng hợp chi tiết kinh phí (mẫu theo đơn vị)',
  link: '/baocaoquyettoan/tonghopchitietkinhphi_donvi/' + BaoCao.BC201_TongHopChiTietKinhPhi_DonVi,
  huongdan: Global.App_url
};

export const QuyetToans_baocaothuchihoatdongsunghiepdichvu = {
  id: BaoCao.BC202_BaoCaoThuChiHoatDongSuNghiepVaDichVu,
  text: 'Báo cáo thu chi HĐ sự nghiệp và HĐ dịch vụ',
  link: '/baocaoquyettoan/baocaothuchihoatdongsunghiepdichvu/' + BaoCao.BC202_BaoCaoThuChiHoatDongSuNghiepVaDichVu,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_bienban_tatca = {
  id: BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  text: 'Báo cáo tổng hợp (mẫu biên bản tất cả)',
  link: '/baocaoquyettoan/baocaotonghop_bienban_tatca/' + BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_bienban_tatcamaumoi = {
  id: BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  text: 'Báo cáo tổng hợp (mẫu biên bản tất cả mẫu mới)',
  link: '/baocaoquyettoan/baocaotonghop_bienban_tatcamaumoi/' + BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  huongdan: Global.App_url
};

export const QuyetToans_baocaoquyettoanthuchingansach_bieuso8 = {
  id: BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  text: 'Báo cáo quyết toán thu - chi ngân sách nhà nước',
  link: '/baocaoquyettoan/baocaoquyettoanthuchingansach_bieuso8/' + BaoCao.BC203_BaoCaoTongHop_BienBan_TatCa,
  huongdan: Global.App_url
};


export const QuyetToans_baocaotonghop_bienban_loaikhoan = {
  id: BaoCao.BC204_BaoCaoTongHop_BienBan_LoaiKhoan,
  text: 'Báo cáo tổng hợp (mẫu biên bản theo loại khoản)',
  link: '/baocaoquyettoan/baocaotonghop_bienban_loaikhoan/' + BaoCao.BC204_BaoCaoTongHop_BienBan_LoaiKhoan,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_thuchien_nsnn = {
  id: BaoCao.BC217_BaoCaoTongHop_ThucHienNSNN,
  text: 'Báo cáo tổng hợp tình hình thực hiện NSNN',
  link: '/baocaoquyettoan/baocaotonghop_thuchien_nsnn/' + BaoCao.BC217_BaoCaoTongHop_ThucHienNSNN,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_bangcandoisophatsinh = {
  id: BaoCao.BC218_BaoCaoTongHop_BangCanDoiSoPhatSinh,
  text: 'Báo cáo tổng hợp bảng cân đối số phát sinh (S05-H)',
  link: '/baocaoquyettoan/baocaotonghop_bangcandoisophatsinh/' + BaoCao.BC218_BaoCaoTongHop_BangCanDoiSoPhatSinh,
  huongdan: Global.App_url
};


export const QuyetToans_baocaotonghop_bienban_loaikhoanmaumoi = {
  id: BaoCao.BC204_BaoCaoTongHop_BienBan_LoaiKhoan,
  text: 'Báo cáo tổng hợp (mẫu biên bản mẫu mới)',
  link: '/baocaoquyettoan/baocaotonghop_bienban_loaikhoanmaumoi/' + BaoCao.BC204_BaoCaoTongHop_BienBan_LoaiKhoan,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_baocao_tatca = {
  id: BaoCao.BC205_BaoCaoTongHop_BaoCao_TatCa,
  text: 'Báo cáo tổng hợp (mẫu báo cáo tất cả)',
  link: '/baocaoquyettoan/baocaotonghop_baocao_tatca/' + BaoCao.BC205_BaoCaoTongHop_BaoCao_TatCa,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_baocao_loaikhoan = {
  id: BaoCao.BC206_BaoCaoTongHop_BaoCao_LoaiKhoan,
  text: 'Báo cáo tổng hợp (mẫu báo cáo in theo khoản)',
  link: '/baocaoquyettoan/baocaotonghop_baocao_loaikhoan/' + BaoCao.BC206_BaoCaoTongHop_BaoCao_LoaiKhoan,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghop_baocao_loaikhoanmaumoi = {
  id: BaoCao.BC206_BaoCaoTongHop_BaoCao_LoaiKhoan,
  text: 'Báo cáo tổng hợp (mẫu báo cáo mẫu mới)',
  link: '/baocaoquyettoan/baocaotonghop_baocao_loaikhoanmaumoi/' + BaoCao.BC206_BaoCaoTongHop_BaoCao_LoaiKhoan,
  huongdan: Global.App_url
};

export const QuyetToans_bienbanquyetoanchingansach = {
  id: BaoCao.BC207_BienBanQuyettoanChiNganSach,
  text: 'Biên bản quyết toán chi ngân sách',
  link: '/baocaoquyettoan/bienbanquyetoanchingansach/' + BaoCao.BC207_BienBanQuyettoanChiNganSach,
  huongdan: Global.App_url
};

export const QuyetToans_baocaokiemtraloi = {
  id: BaoCao.BC208_BaoCaoKiemTraLoi,
  text: 'Báo cáo kiểm tra lỗi dữ liệu',
  link: '/baocaoquyettoan/baocaokiemtraloi/' + BaoCao.BC208_BaoCaoKiemTraLoi,
  huongdan: Global.App_url
};
export const QuyetToans_baocaothuphilephi = {
  id: BaoCao.BC209_BaoCaoTHThuPhiLePhi,
  text: 'Báo cáo TH thu phí, lệ phí',
  link: '/baocaoquyettoan/baocaothuphilephi/' + BaoCao.BC209_BaoCaoTHThuPhiLePhi,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghopketquahoatdong1b02 = {
  id: BaoCao.BC210_BaoCaoKetQuaHoatDong1b02,
  text: 'Báo cáo tổng hợp kết quả hoạt động  (mẫu 1b - B02)',
  link: '/baocaoquyettoan/baocaotonghopketquahoatdong1b02/' + BaoCao.BC210_BaoCaoKetQuaHoatDong1b02,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotonghopketquahoatdong1b05 = {
  id: BaoCao.BC211_BaoCaoKetQuaHoatDong1b05,
  text: 'Báo cáo tổng hợp kết quả hoạt động  (mẫu 1b - B05)',
  link: '/baocaoquyettoan/baocaotonghopketquahoatdong1b05/' + BaoCao.BC211_BaoCaoKetQuaHoatDong1b05,
  huongdan: Global.App_url
};

export const QuyetToans_quyettoanthuchinguonnsnn = {
  id: BaoCao.BC212_QuyetToanThuChiNguonNSNN,
  text: 'Quyết toán thu chi nguồn NSNN, nguồn khác',
  link: '/baocaoquyettoan/quyettoanthuchinguonnsnn/' + BaoCao.BC212_QuyetToanThuChiNguonNSNN,
  huongdan: Global.App_url
};

export const QuyetToans_baocaotaichinh = {
  id: BaoCao.BC213_BaoCaoTinhHinhTaiChinh_B01_BCTC,
  text: 'Báo cáo tình hình tài chính (B01/BCTC)',
  link: '/baocaoquyettoan/baocaotaichinh/' + BaoCao.BC213_BaoCaoTinhHinhTaiChinh_B01_BCTC,
  huongdan: Global.App_url
};
export const QuyetToans_baocaoluuchuyentiente = {
  id: BaoCao.BC214_BaoCaoLuuChuyenTienTe_B03a_BCTC,
  text: 'Báo cáo lưu chuyển tiền tệ (B03a/BCTC)',
  link: '/baocaoquyettoan/baocaoluuchuyentiente/' + BaoCao.BC214_BaoCaoLuuChuyenTienTe_B03a_BCTC,
  huongdan: Global.App_url
};

export const QuyetToans_baocaoluuchuyentienteb = {
  id: BaoCao.BC214_BaoCaoLuuChuyenTienTe_B03b_BCTC,
  text: 'Báo cáo lưu chuyển tiền tệ (B03b/BCTC)',
  link: '/baocaoquyettoan/baocaoluuchuyentienteb/' + BaoCao.BC214_BaoCaoLuuChuyenTienTe_B03b_BCTC,
  huongdan: Global.App_url
};

export const QuyetToans_thuyetminhbaocaotaichinh = {
  id: BaoCao.BC215_BaoCaoThuyetMinhTaiChinh,
  text: 'Báo cáo thuyết minh báo cáo tài chính (B04/BCTC)',
  link: '/baocaoquyettoan/thuyetminhbaocaotaichinh/' + BaoCao.BC215_BaoCaoThuyetMinhTaiChinh,
  huongdan: Global.App_url
};

export const QuyetToans_baocaobosungthongtintaichinh = {
  id: BaoCao.BC216_BaoCaoBoSungThongTinTaiChinh_B01_BSTT,
  text: 'Báo cáo bổ sung thông tin tài chính (B01/BSTT)',
  link: '/baocaoquyettoan/baocaobosungthongtintaichinh/' + BaoCao.BC216_BaoCaoBoSungThongTinTaiChinh_B01_BSTT,
  huongdan: Global.App_url
};


export const QuyetToans = {
  id: '',
  text: ' Quyết toán',
  link: '/quyettoans',
  icon: 'fas fa-money-check-alt',
  submenu: [
    {
      id: '',
      text: 'Nhập số liệu',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          QuyetToans_baocaochitietkinhphimaumoi,
          QuyetToans_baocaotonghopkinhphimaumoi,
          QuyetToans_baocaothuchihoatdongsunghiep,
          QuyetToans_baothuphi1a,
          QuyetToans_baocaoketquahoatdong1b02,
          QuyetToans_baocaoketquahoatdong1b05,
          // QuyetToans_danhmucchitieu1a,
          QuyetToans_baocaotaichinh_b01bctc,
          QuyetToans_luuchuyentiente_b03abctc,
          QuyetToans_luuchuyentiente_b03bbctc,
          QuyetToans_thuyetminhtaichinh,
          QuyetToans_nhapbaocaobosungthongtintaichinh,
          QuyetToans_nhapbangcandoiphatsinh,
          QuyetToans_baocaotinhhinhthuchien_nsnn,
          QuyetToans_baocaochitietkinhphi,
          QuyetToans_baocaotonghopkinhphi,

        ]
    }
    ,
    QuyetToans_khoasolieubaocao
    ,
    {
      id: '',
      text: 'Báo cáo',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [

        //QuyetToans_tonghopchitietkinhphi_donvi, không dùng
        QuyetToans_tonghopchitietkinhphi_thmaumoi,
        QuyetToans_baocaotonghop_bienban_tatcamaumoi,
        QuyetToans_baocaotonghop_bienban_loaikhoanmaumoi,
        QuyetToans_baocaotonghop_baocao_loaikhoanmaumoi,
        QuyetToans_baocaotonghopketquahoatdong1b02,
        QuyetToans_baocaotonghopketquahoatdong1b05,


        QuyetToans_baocaotaichinh,
        QuyetToans_baocaoluuchuyentiente,
        QuyetToans_baocaoluuchuyentienteb,
        QuyetToans_thuyetminhbaocaotaichinh,
        QuyetToans_baocaobosungthongtintaichinh,
        QuyetToans_baocaotonghop_bangcandoisophatsinh,
        QuyetToans_baocaotonghop_thuchien_nsnn,
        QuyetToans_baocaokiemtraloi,
        QuyetToans_baocaothuchihoatdongsunghiepdichvu,



      ]
    },
    {
      id: '',
      text: 'Báo cáo khác',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu: [

        QuyetToans_baocaoquyettoanthuchingansach_bieuso8,
        QuyetToans_tonghopchitietkinhphi_th,
        QuyetToans_baocaotonghop_bienban_tatca,
        QuyetToans_baocaotonghop_bienban_loaikhoan,
        QuyetToans_baocaotonghop_baocao_tatca,
        QuyetToans_baocaotonghop_baocao_loaikhoan,
        QuyetToans_bienbanquyetoanchingansach,
        QuyetToans_baocaothuphilephi,
        QuyetToans_quyettoanthuchinguonnsnn,

      ]
    },
    {
      id: '',
      text: 'Cấu hình',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          QuyetToans_cauhinhchitieubaocaochitiet,

          QuyetToans_cauhinhchitieubaocaochitietmaumoi,

          QuyetToans_cauhinhchitieuthuchisunghiep,
          QuyetToans_cauhinhchitieumau1a,
        ]
    },
    {
      id: '',
      text: 'Danh mục',
      link: '/elements',
      icon: 'fa fa-list-ul',
      submenu:
        [
          QuyetToans_quyettoanloaikhoan,

          QuyetToans_quyettoanloaikhoanchung,

          QuyetToans_chitieuthuchihoatdongsunghiep,
          QuyetToans_danhmuctieumuc,
          QuyetToans_danhmucchitieu1a,
          QuyetToans_danhmucchitieu1b02,
          QuyetToans_danhmucchitieu1b05,
        ]
    }

  ]
};
//--- quyết toán


export const menu = [
  Home,
  tiens,
  TaiSans,
  Products,
  NghiepVus,
  SuaChuas,
  Reports,
  Ultil,
  Lists,
  System
];


export const DungChungs = {
  id: '',
  text: ' Dùng chung',
  link: '/',
  icon: 'fas fa-dollar-sign',
  submenu: [tiens_phieuthu, tiens_phieuchi, tiens_baoco, tiens_baono, tiens_cknoibo
    , Products_muahang
    , Products_banhang, Products_nhapkho, Products_xuatkho
    , tonghops_chungtukhac, tonghops_chungtuketchuyen, tonghops_dudauky
    , taisan_haomon, congcus_phanbo, congcus_phanbochiphi
    , Products_dondathang, Products_phanbodatcoc, Products_chiphimuahang
    , Products_muahangchuahd, Products_nhanhoadon, Products_muahang, Products_banhang
    , Products_nhapkho, Products_xuatkho, Products_tinhgia
    , NghiepVus_dathang, NghiepVus_dexuatbanhang, NghiepVus_hopdongbanhang
    , SuaChuas_baogia, SuaChuas_phieuvaoxuong
    , SuaChuas_quyettoan, SuaChuas_yeucauxuatkho, SuaChuas_baoduong

    , Reports_bcdichvu
    , Reports_bangkephanbo
    , Reports_bangketaphopchiphi
    , Reports_bcdoanhthutheohangmuc
    , Reports_thongkexetheohangbh
    , Reports_nhatkythutien
    , Reports_nhatkychitien
    , Reports_soquy
    , Reports_sotiengui
    , Reports_sotienguitheochitiet
    , Reports_bctonkho2
    , Reports_bctonkho
    , Reports_chitietvattu
    , Reports_thekho
    , Reports_tonghopnxt
    , Reports_tonghopcongnophaitra_hh
    , Reports_chitietcongnophaitra
    , Reports_tonghopcongnophaitra_hd
    , Reports_bcbanxe
    , Reports_chitietcongnophaithu
    , Reports_tonghopcongnophaithu_hd
    , Reports_tonghopcongno
    , Reports_phanboccdc
    , Reports_phanbochiphi
    , Reports_thetscd
    , Reports_sotscd
    , Reports_bangketscd
    , Reports_bangkhauhaotscd
    , Reports_bangkevat_vao
    , Reports_bangkevat_ra
    , Reports_tokhai
    , Reports_tonghopchitiettaikhoan
    , Reports_socaitaikhoan
    , Reports_sochitiettaikhoan
    , Reports_sonhatkychung
    , Reports_bangcandoitaikhoan
    , Reports_bangcandoiketoan
    , Reports_ketquakinhdoanh
    , Reports_luuchuyentiente

    , Ultil_timdoituong,
    Ultil_kiemtraloi,
    Ultil_danhsochungtu,
    Ultil_khoaso,
    Ultil_lydulieutudonvikhac,
    
    Ultil_cauhinhnhapdulieutuexcel,
    Ultil_cauhinhdongthoi,
    Ultil_hachtoanquy,
    Ultil_hachtoankhobac,
    Ultil_hachtoantaisan,
    Ultil_hachtoanmuahang,
    Ultil_hachtoanluong,

    Ultil_baocaotaichinh,
    Ultil_baocaoketquahoatdong,
    Ultil_baocaoluuchuyentientetructiep,
    Ultil_baocaoluuchuyentientegiantiep,
    Ultil_baocaothuyetminhtaichinh,
    Ultil_baocaotaichinhmaudongia,
    Ultil_baocaobosungthongtintaichinh,

    Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
    , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
    , Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
    , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
    , Lists_khachhang, Lists_nhanvien
    , Lists_congcu, Lists_taisancodinh, Lists_chiphi
    , Lists_tiente, Lists_xuatxu, Lists_tinh, Lists_mauson, Lists_giaodich
    , Lists_keuoc, Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
    , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
    , Lists_loaisuachua, Lists_dichvu, Lists_xesua, Lists_nganhang
    , System_thamso, Ultil_thietlapngamdinh, Ultil_saoluudulieu, Ultil_nhatkysaoluu, Ultil_nhatkytruycap, System_nguoidung, System_vaitro, Ultil_donvi, Ultil_doanhnghiep
    , Ultil_phanhe
    , System_history, Ultil_bug



  ]
};
