import { Component, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { Repository } from '../../services/repository'
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../shared/global';
import { environment } from '../../../environments/environment';

import { System_vaitro, System_nguoidung, System_thamso } from '../../routes/menu';


const swal = require('sweetalert');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  username: string;
  isAuthentication: number;
  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', { static: false }) fsbutton;  // the fullscreen button
  // @ViewChild('modalDoiMatKhau', {static: false}) modalDoiPass: BsModalComponent;
  returnUrl: string;
  vaitro: string = null;
  hotro1s: any;
  hotro2s: any;
  nguoidung: string = null;
  thamso: string = null;
  tendoanhnghiep: string = null;
  madoanhnghiep: string = null;
  tendonvi: string = null;
  dschinhanhs: any = [];
  dsdoanhnghieps: any = [];
  Nam: number;
  NamString: string = null;
  noidung: string = null;
  NgayHienTai: Date = new Date();
  public nams: Array<{ text: string, value: number }> = [

    { text: "Năm " + this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: "Năm " + (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: "Năm " + (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: "Năm " + (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: "Năm " + (this.NgayHienTai.getFullYear() - 4).toString(), value: (this.NgayHienTai.getFullYear() - 4) },

    { text: "Năm " + (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    //{ text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    //{ text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];
  currentApplicationVersion: any;
  trangthaiupdate: any;
  constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, private authenticationService: Repository, private route: ActivatedRoute, private router: Router) {

    settings.toggleLayoutSetting('asideToggled');

    this.currentApplicationVersion = environment.appVersion;
    this.vaitro = System_vaitro.id;
    this.nguoidung = System_nguoidung.id;
    this.thamso = System_thamso.id;
    // show only a few items on demo
    this.menuItems = menu.getMenu();//.slice(0,4); // for horizontal layout

    var currentUser1 = localStorage.getItem('currentUser');
    if (currentUser1) {

      let data = JSON.parse(currentUser1);
      console.log(data);
      if (data != null) {
        this.username = data.user_name;
        this.tendonvi = data.ten_don_vi;
        this.tendoanhnghiep = data.ten_doanh_nghiep;
        this.madoanhnghiep = data.ma_doanh_nghiep;
        //document.title = this.tendoanhnghiep; 

        this.isAuthentication = 1;
        this.Nam = parseInt(data.nam_ke_toan);
        this.NamString = "Năm " + data.nam_ke_toan.toString();
      }
      else {
        this.isAuthentication = 0;
        this.Nam = this.NgayHienTai.getFullYear();
        this.NamString = "Năm " + this.NgayHienTai.getFullYear().toString();
      }
      this.nams = [
        { text: "Năm " + (this.Nam + 3).toString(), value: (this.Nam + 3) },
        { text: "Năm " + (this.Nam + 2).toString(), value: (this.Nam + 2) },
        { text: "Năm " + (this.Nam + 1).toString(), value: (this.Nam + 1) },
        { text: "Năm " + this.Nam.toString(), value: this.Nam },
        { text: "Năm " + (this.Nam - 1).toString(), value: (this.Nam - 1) },
        { text: "Năm " + (this.Nam - 2).toString(), value: (this.Nam - 2) },
        { text: "Năm " + (this.Nam - 3).toString(), value: (this.Nam - 3) },
        { text: "Năm " + (this.Nam - 4).toString(), value: (this.Nam - 4) },
        //{ text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
        //{ text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

      ];

      
      this.authenticationService.getRequest('api/login/getdoanhnghiep/')
        .subscribe(response => {

          this.dsdoanhnghieps = response;

        });

      this.authenticationService.getRequest('api/login/getchinhanh/')
        .subscribe(response => {

          this.dschinhanhs = response;

        });


      //this.authenticationService.getRequest('api/login/gethotro/')
      //  .subscribe(response => {
      //    this.hotro1s = response.filter(name => name.Id === 1);
      //    this.hotro2s = response.filter(name => name.Id === 2);
      //  });


    }

    authenticationService.postRequest('api/chungtu/getappversion', { Chuoi: this.currentApplicationVersion })
      .subscribe(response => {
        //console.log(response);
        this.trangthaiupdate = response.Trang_Thai;

        if (response.Trang_Thai == 1 || response.Trang_Thai == 2) {
          this.noidung = response.Noi_Dung;
          if (this.noidung != null) {
            swal('Thông báo phiên bản mới!', this.noidung, 'success');
          }
        }

      });

  }
  reloadPage() {
    this.router.navigate([this.router.url]);
  }
  valueChange(value): void {
    // this.Loaddatas();
    var currentUser1 = localStorage.getItem('currentUser');
    let data = JSON.parse(currentUser1);
    data.nam_ke_toan = value.value;
    this.Nam = value.value;
    this.NamString = "Năm " + value.value.toString();
    localStorage.setItem('currentUser', JSON.stringify(data));



    //this.nams =[
    //  { text: "Năm " + this.Nam.toString(), value: this.Nam },
    //  { text: "Năm " + (this.Nam - 1).toString(), value: (this.Nam - 1) },
    //  { text: "Năm " + (this.Nam - 2).toString(), value: (this.Nam - 2) },
    //  { text: "Năm " + (this.Nam - 3).toString(), value: (this.Nam - 3) },
    //  { text: "Năm " + (this.Nam - 4).toString(), value: (this.Nam - 4) },

    //  { text: "Năm " + (this.Nam + 1).toString(), value: (this.Nam + 1) },
    //  { text: "Năm " + (this.Nam + 2).toString(), value: (this.Nam + 2) },
    //  { text: "Năm " + (this.Nam + 3).toString(), value: (this.Nam + 3) },
    //  //{ text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    //  //{ text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

    //];

    window.location.reload();
  }
  changedoanhnghiep(row) {
    this.tendoanhnghiep = row.Ten;
    this.madoanhnghiep = row.Ma;
    

    this.authenticationService.getRequest('api/Login/doidoanhnghiep/' + row.Id + '/' + this.username)
      .subscribe(response => {

        var currentUser1 = localStorage.getItem('currentUser');
        let data = JSON.parse(currentUser1);
        data.id_doanh_nghiep = row.Id;
        data.ten_doanh_nghiep = row.Ten;
        data.ma_doanh_nghiep = row.Ma;
        localStorage.setItem('currentUser', JSON.stringify(data));
        window.location.reload();
      });


  }
  changechinhanh(row) {
    this.tendonvi = row.Ten;

    this.authenticationService.getRequest('api/Login/doichinhanh/' + row.Id)
      .subscribe(response => {

        var currentUser1 = localStorage.getItem('currentUser');
        let data = JSON.parse(currentUser1);
        data.id_don_vi = row.Id;
        data.ten_don_vi = row.Ten;
        localStorage.setItem('currentUser', JSON.stringify(data));
        window.location.reload();
      });
  }
  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
  }
  generateCustomLink(): string {
    console.log("Xoa cookie trên Angular")
    localStorage.removeItem('currentUser');
    return '/Home/Logout';
  }
  logout() {
    //localStorage.removeItem('currentUser');
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.router.navigate(['/login']);
  }
  lichsu() {
    this.router.navigate(['/danhmuc/history']);
  }
  capnhat() {
    this.router.navigate(['/danhmuc/capnhat']);
  }
  DoiMatKhau() {
    //this.modalDoiPass.open();
  }
  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    let el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('fa-expand').addClass('fa-compress');
    }
    else {
      el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
  }
}
