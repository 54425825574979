import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';

import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';


const swal = require('sweetalert');
@Component({
    selector: 'app-quantri-importedit',
    templateUrl: './importedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
    styleUrls: ['./importedit.component.scss'],
    providers: [ListService, Repository]
})


export class importeditComponent implements OnInit {

  doanhnghiepid: string = null;
    quyen_them: boolean = false;
    quyen_sua: boolean = false;
    quyen_xoa: boolean = false;
    indLoading: boolean = false;    
    tieude:string=null;
    duongdan: string = null;
    datas: any;
  import: any;
  id: any;
  public currentUser;
  currentFileUpload: any;

    @ViewChild('slCapTren',{static:false}) slCapTren: NgSelectComponent;
    @Output() save: EventEmitter<any> = new EventEmitter();
    

  onCustom(event) {

    //if (event.action == 'edit') {

    //  this.editdata(event.data.Id);
    //}
    if (event.action == 'delete') {

      this.deletedata(event.data.Id);
    }

  }
  files: any;

  public uploadSaveUrl = Global.App_url +'/import/upload'; // should represent an actual API endpoint
  public uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  $ :any;
  
  //file upload event  
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
    
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      formData.append('user', this.currentUser.user_name);
      formData.append('donvi', this.currentUser.id_don_vi);
      formData.append('nam', this.currentUser.nam_ke_toan);
      formData.append('doanhnghiep', this.doanhnghiepid);
      formData.append('functionid', this.id);
      let headers = new HttpHeaders()
      headers.append('Content-Type', 'json');  
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.currentUser.access_token);
      this.http.post<any>(Global.App_url + 'api/importexcel/upload2/', formData, {
        headers: headers
      })
        .subscribe((res) => {
          event.srcElement.value = null;
         
          if (res != null && res != "")
            swal('Lỗi!', res, 'error');
          else {
            swal('Hoàn thành!', "Nhập dữ liệu hoàn thành!", 'success');
            this.save.emit(null);
            this.Loaddatas();
           
          }

        });

     // window.location.reload();
    }
  }


  uploadFile() {
    console.log(this.files);
    var xhr = new XMLHttpRequest();
    var file = this.files[0];
    xhr.open("POST", Global.App_url + 'api/importexcel/upload/');
    xhr.setRequestHeader("filename", file.name);
    xhr.send(file);
  }
  



  
  private log(event: string): void {
    

  }

  constructor(private _dataService: ListService, private repo: Repository, private http: HttpClient) {
       
        
    }

    ngOnInit(): void {
       
    }

  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  ten_tep_mau: string = 'tep_mau_danh_muc';

  loadDataInit(data: any) {
          this.id = data.id;
          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === data.id.toLowerCase());
            if (giatri != null && giatri.length > 0) {

              this.uploadSaveUrl = Global.App_url + '/import/upload/?user=' + this.currentUser.user_name + "&donvi=" + this.currentUser.id_don_vi + '&nam=' + this.currentUser.nam_ke_toan + '&functionid=' + this.id + '&doanhnghiep=' + this.doanhnghiepid;
              //this.source = new LocalDataSource();

              this.tieude = giatri[0].ten_Phan_He;
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }
          }

       this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
        //setTimeout(() => {
        //    this.mafocus.nativeElement.focus();  
        //},500);
    this.repo.getRequest("api/importexcel/getbyfunctionid/" + this.id)
      .subscribe(response => {
        if (response != null)
          this.duongdan = response.TemplatePath;
        this.import = response;

      });

    this.repo.getRequest("api/phanhe/getby2id/" + this.id)
      .subscribe(response => {
        console.log(response);
        if (response != null)
          this.ten_tep_mau = response.Ma_Phan_He;
      });

    this.Loaddatas();
        
  }
  Loaddatas(): void {
    this.repo.postRequest("api/importlog/getview/", { NhomDoiTuongId: this.id, DonViId: this.currentUser.id_don_vi, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.datas = response;
      });


  }
  public OnFileImportClick(id: string) {
    window.open(Global.App_url + 'Import/ShowFileSuscess/' + id + '/' + this.doanhnghiepid, "_blank");
    
  }
  public OnFileErrorClick(id: string) {
    window.open(Global.App_url + 'Import/ShowFileError/' + id + '/' + this.doanhnghiepid, "_blank");
   }
  onImageRemoved() {
    // this.sanphamSelect.ImgUrl = null;
  }

  onUploadFinished(event) {

    //console.log(JSON.parse(event.serverResponse.response._body))
    //this.sanphamSelect.ImgUrl = event.serverResponse.response.json().data;

    console.log(event.serverResponse.response.json());
    alert(event.serverResponse.response.json());
  }

   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
  }
  public taitepmau() {
    console.log(this.ten_tep_mau);
    let _tt = this.ten_tep_mau;
    //$('#loading').show();
    let data = {
      Id: BaoCao.BC02_MauNhapTuExcel,
      Type: 'xlsx',
      Data: JSON.stringify({
         DonViId: this.currentUser.id_don_vi
        , UserName: this.currentUser.user_name
        , duongdan: this.duongdan
        , FunctionId: this.id

      })
    };
    this.repo.postPdf(Global.BASE_BaoCao2_SAVE, data)
      .subscribe(response => {
        var file = new Blob([response.body], { type: 'vnd.ms-excel' });
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement("a");
        a.href = fileURL;
        //$('#loading').hide();
        a.download = _tt + ".xlsx";
        document.body.appendChild(a);
        a.click();

      });

    //window.open(this.duongdan, "_blank");
  }
    public saveproduct() {
        //if (this.doituongSelect.Ten_Doi_Tuong == null || this.doituongSelect.Ten_Doi_Tuong == "") {
        //    swal('Lỗi!', 'Vui lòng nhập tên đối tượng!.', 'error');
        //    return;
        //}
        //this.repo.postRequest("api/doituong/addorupdate",this.doituongSelect)
        //    .subscribe(response => {  
        //        console.log(response);
        //        this.doituongSelect.Id=response;
        //        this.save.emit(this.doituongSelect);
        //    }); 
    }
   
    
  deletedata(id: string) {

    console.log(id);



    swal({
      title: 'Bạn muốn xóa bản ghi?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.repo.postRequest('api/importlog/delete/', { DoanhNghiepId: this.doanhnghiepid, Id: id}).subscribe(
            data => {
              this.Loaddatas();
              if (data == "OK") //Success
              {
                swal('Deleted!', 'Dữ liệu đã được xóa!.', 'success');
                
              }
              else {
                // this.msg = data;
                swal('Cảnh báo!', data, 'warning');
              }


            },
            error => {
              //this.msg = error;
            }
          );
        }
      });


  }

   


}
