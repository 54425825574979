<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}">
        <a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id="">1.Thông tin chi tiết</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}">
        <a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id="">2.Phân loại</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}">
        <a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id="">3.Danh sách hàng hóa</a>
      </li>
    </ul>
    <!--<a (click)="RowNewClick()" *ngIf="tabselected==3" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng</a>-->
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="col-12">
        <div class="row form-group">
          <label class="control-label col-2">Mã</label>
          <div class="col-4">
            <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
          </div>
          <label class="control-label col-2 ng-binding">Tên</label>
          <div class="col-4">
            <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Cấp trên</label>
          <div class="col-4">
            <a *ngIf="doituongSelect.Parent===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="3" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha">
            </a>
            <a *ngIf="doituongSelect.Parent!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
              <input id="99" tabindex="3" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha" (ngModelChange)="adddatachondoituongchung(1)">
            </a>

            <!--<ng-select #slCapTren
             class="twcol"
             [tabIndex]="3"
             [openOnEnter]="false"
             [items]="inputparents"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             [searchFn]="comboSearchFn"
             [(ngModel)]="doituongSelect.Parent">
    <ng-template ng-option-tmp let-item="item">

      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
          </div>
          <label class="control-label col-2 ng-binding">Nhà cung cấp</label>
          <div class="col-4">
            <ng-select #slNhaCungCap
                       class="twcol"
                       [tabIndex]="4"
                       [openOnEnter]="false"
                       [items]="inputnhacungcaps"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.To_Chuc">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="row form-group" *ngIf="doituongSelect.To_Chuc==false">
          <label class="control-label col-2 ng-binding">Ngày sinh</label>
          <div class="col-4">
            <kendo-dateinput style="width:100%;" tabIndex="5" [format]="'dd/MM/yyyy'" [(value)]="ngaysinh"></kendo-dateinput>
          </div>
          <label class="control-label col-2 ng-binding">Giới tính/CMND</label>
          <div class="col-2">
            <ng-select #slGioiTinh
                       class="twcol"
                       [tabIndex]="6"
                       [openOnEnter]="false"
                       [items]="inputgioitinhs"
                       bindValue="Id"
                       bindLabel="Ten_Doi_Tuong"
                       notFoundText="Không có dữ liệu"
                       [(ngModel)]="doituongSelect.Gioi_Tinh">
              <ng-template ng-option-tmp let-item="item">
                <div class="rTableRow">
                  <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-2">
            <input type="text" tabIndex="7" class="form-control" placeholder="" [(ngModel)]="doituongSelect.CMND_So">
          </div>
        </div>
        <div class="row form-group" *ngIf="doituongSelect.To_Chuc==false">
          <label class="control-label col-2 ng-binding">Ngày cấp</label>
          <div class="col-4">
            <kendo-dateinput style="width:100%;" tabIndex="8" [format]="'dd/MM/yyyy'" [(value)]="ngaycmnd"></kendo-dateinput>
          </div>
          <label class="control-label col-2 ng-binding">Nơi cấp</label>
          <div class="col-4">
            <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="doituongSelect.CMND_NoiCap">
          </div>
        </div>

        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Địa chỉ</label>
          <div class="col-4">
            <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi">
          </div>
          <label class="control-label col-2 ng-binding">Điện thoại</label>
          <div class="col-4">
            <input type="text" tabIndex="11" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Email</label>
          <div class="col-4">
            <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Hom_Thu">
          </div>
          <label class="control-label col-2 ng-binding">Fax</label>
          <div class="col-4">
            <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Fax">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Điện thoại cơ quan</label>
          <div class="col-4">
            <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai_Co_Quan">
          </div>
          <label class="control-label col-2 ng-binding">Điện thoại nhà</label>
          <div class="col-4">
            <input type="text" tabIndex="15" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai_Nha">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Địa chỉ website</label>
          <div class="col-4">
            <input type="text" tabIndex="16" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi_Web">
          </div>
          <label class="control-label col-2 ng-binding">Mã số thuế</label>
          <div class="col-4">
            <input type="text" tabIndex="17" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_So_Thue">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Tên người liên hệ</label>
          <div class="col-4">
            <input type="text" tabIndex="18" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Hoa_Don">
          </div>
          <label class="control-label col-2 ng-binding">Xưng hô</label>
          <div class="col-4">
            <input type="text" tabIndex="19" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Xung_Ho">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Chức vụ</label>
          <div class="col-4">
            <input type="text" tabIndex="20" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Chuc_Vu">
          </div>
          <label class="control-label col-2 ng-binding">Địa chỉ người liên hệ</label>
          <div class="col-4">
            <input type="text" tabIndex="21" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi_Hoa_Don">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">TK ngân hàng</label>
          <div class="col-4">
            <input type="text" tabIndex="22" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Tai_Khoan_Ngan_Hang">
          </div>
          <label class="control-label col-2 ng-binding">Chi nhánh</label>
          <div class="col-4">
            <input type="text" tabIndex="23" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ten_Ngan_Hang">
          </div>
        </div>
        <div class="row form-group">
          <label class="control-label col-2 ng-binding">Giới hạn công nợ</label>
          <div class="col-4">
            <input id="gia" #myinputsl class="form-control"
                   maxlength="20"
                   style="width:100%;"
                   currencyMask
                   [(ngModel)]="doituongSelect.Gioi_Han_Cong_No"
                   name="value"
                   tabIndex="24"
                   [placeholder]="''"
                   [options]="ngxCurrencyOptions" autocomplete="off" />
          </div>
          <label class="control-label col-2 ng-binding">Ghi chú</label>
          <div class="col-4">
            <input type="text" tabIndex="25" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ghi_Chu">
          </div>
        </div>

        <div class="row form-group">
          <div class="col-3">
            <label>
              <input tabIndex="50" type="checkbox" [(ngModel)]="doituongSelect.La_Nguoi_Lien_Lac" />
              <!--<span class="fa fa-check"></span>--> NCC, KH là người liên lạc
            </label>
          </div>
          <div class="col-2">
            <label>
              <input tabIndex="51" type="checkbox" [(ngModel)]="doituongSelect.Rieng_Tu" />
              <!--<span class="fa fa-check"></span>--> Riêng tư
            </label>
          </div>
          <div class="col-2">
            <label>
              <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Web" />
              <!--<span class="fa fa-check"></span>--> Web
            </label>
          </div>
          <div class="col-2">
            <label>
              <input tabIndex="52" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
              <!--<span class="fa fa-check"></span>--> Hoạt động
            </label>
          </div>

        </div>

        <!--<div class="row form-group">
    <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
    <div class="col-10">
      <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
    </div>
  </div>-->


      </div>

    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Loại khách hàng</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="50"
                     [openOnEnter]="false"
                     [items]="inputloaikhachs"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Nhom_Khach_Hang">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
        <label class="control-label col-2 ng-binding">Loại quan hệ</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="51"
                     [openOnEnter]="false"
                     [items]="inputloaiquanhes"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Loai_Quan_He">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Lĩnh vực</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="52"
                     [openOnEnter]="false"
                     [items]="inputlinhvucs"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Linh_Vuc">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
        <label class="control-label col-2 ng-binding">Nguồn khách</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="53"
                     [openOnEnter]="false"
                     [items]="inputnguonkhachs"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Nguon_Khach">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Nghề nghiệp</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="54"
                     [openOnEnter]="false"
                     [items]="inputnghenghieps"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Nghe_Nghiep">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
        <label class="control-label col-2 ng-binding">Huyện, tỉnh</label>
        <div class="col-2">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="55"
                     [openOnEnter]="false"
                     [items]="inputhuyens"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Huyen_Id">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
        <div class="col-2">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="56"
                     [openOnEnter]="false"
                     [items]="inputtinhs"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Tinh_Id">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Tên khách hàng giới thiệu</label>
        <div class="col-4">
          <input type="text" tabIndex="57" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Gioi_Thieu_Khach">
        </div>
        <label class="control-label col-2 ng-binding">Điện thoại</label>
        <div class="col-4">
          <input type="text" tabIndex="58" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Gioi_Thieu_Dien_Thoai">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-2 ng-binding">Nhân viên phụ trách</label>
        <div class="col-4">
          <ng-select #slCapTren
                     class="twcol"
                     [tabIndex]="59"
                     [openOnEnter]="false"
                     [items]="inputnhanviens"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="doituongSelect.Nhan_Vien_Phu_Trach">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
       
      </div>

    </div>

    <div class="tab-pane" [ngClass]="{'active': tabselected==3}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="min-height:300px;background:#fff;">
          <table class="table tableinput table-bordered table-hover">
            <thead>
              <tr>
                <!--<th width="50px">#</th>-->
                <th width="200px">Hợp đồng</th>
                <th width="100px">Hàng hóa</th>
                <th width="150px">Model</th>
                <th width="150px">Đặc tả</th>
                <th width="150px">Màu sắc</th>
                <th width="150px">Số lượng</th>
                <th width="150px">Ngày giao</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let row of doituongSelect.ChiTiets; let i = index' style="height:30px">
                <!--<td>
                <div style="width:50px;">
                  <a title="Xóa dòng" (click)="deleterow(i,3)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                </div>
              </td>-->
                <td>
                  {{row.Ten_Hop_Dong}}
                </td>
                <td>
                  {{row.Hang_Hoa}}
                </td>
                <td>
                  {{row.Model_Xe}}
                </td>
                <td>
                  {{row.Dac_Ta}}
                </td>
                <td>
                  {{row.Mau_Xe}}
                </td>
                <td>
                  {{row.So_Luong | number}}
                </td>
                <td>
                  {{row.Ngay_Giao_Xe | date: 'dd/MM/yyyy'}}
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>




<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>
