import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists, Lists_phongban } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmucnhanvienedit',
  templateUrl: './danhmucnhanvienedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmucnhanvienedit.component.scss'],
  providers: [ListService, Repository]
})


export class danhmucnhanvieneditComponent implements OnInit {
  doanhnghiepid: string = null;
  public ngaysinh: Date = null;
  public ngaycmnd: Date = null;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputhangbaohiems: any;
  inputphongbans: any;
  inputhangxes: any;
  inputkhachhangs: any;
  inputmausacs: any;
  inputmodels: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    , Doanh_Nghiep_Id: null

    , Ngay_Sinh: null, Ngay_SinhS: null, Gioi_Tinh: null
    , Chuc_Vu: null, Dia_Chi: null, Dien_Thoai: null, Email: null, CMND: null, Ma_So_Thue: null


  };
  nhomdtid: string = null;
  hoatdong: string = null;
  inputnhacungcaps: any = [
    { Ten_Doi_Tuong: "Cá nhân", Ma_Doi_Tuong: '0', Id: false },
    { Ten_Doi_Tuong: "Tổ chức", Ma_Doi_Tuong: '1', Id: true },
  ];

  inputgioitinhs: any = [
    { Ten_Doi_Tuong: "Nữ", Ma_Doi_Tuong: '0', Id: false },
    { Ten_Doi_Tuong: "Nam", Ma_Doi_Tuong: '1', Id: true },
  ];

  inputloais: any = [
    { text: "Nhân công", value: 0 },
    { text: "Vật tư sơn", value: 1 },
  ];

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  constructor(private _dataService: ListService, private repo: Repository) {


  }

  ngOnInit(): void {

  }

  // @HostListener('document:keydown', ['$event'])
  // public handleKeyboardEvent(event: KeyboardEvent): void {

  //   if(event.key==="F9")
  //   {
  //       this.saveproduct();
  //   }
  //   event.stopPropagation();

  // }

  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;
  onChangeHangXe($event) {

    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: this.doituongSelect.Hang_Xe })
      .subscribe(response => {
        this.inputmodels = response;
      });



  }
  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata: any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

    this.hoatdong = "Hoạt động"

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {

      this.currentUser = JSON.parse(authlogin);
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }

      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

    }

    this.nhomdtid = nhomdoituongid;
    setTimeout(() => {
      this.mafocus.nativeElement.focus();
    }, 500);
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputparents = response;
      });
    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_phongban.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputphongbans = response;
    //  });

    //this.repo.postRequest("api/doituong/gethangxe/", { DoanhNghiepId: this.doanhnghiepid,  Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputhangxes = response;
    //  });
    //this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputmodels = response;
    //  });

    //this.repo.postRequest("api/doituong/getkhachhang/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputkhachhangs = response;
    //  });

    //this.repo.postRequest("api/doituong/getmausac/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputmausacs = response;
    //  });

    //this.repo.postRequest("api/doituong/gethangbaohiem/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputhangbaohiems = response;
    //  });


    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid

      , Ngay_Sinh: null, Ngay_SinhS: null, Gioi_Tinh: null
      , Chuc_Vu: null, Dia_Chi: null, Dien_Thoai: null, Email: null, CMND: null, Ma_So_Thue: null

    };
    if (id === null) {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
        .subscribe(response => {
          this.doituongSelect.Ma_Doi_Tuong = response;
        });

      this.doituongSelect.Trang_Thai = true;
      this.doituongSelect.To_Chuc = false;
      this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
      this.doituongSelect.Created = new Date();
      this.doituongSelect.CreatedBy = this.currentUser.user_name;
      this.doituongSelect.Modified = new Date();
      this.doituongSelect.ModifiedBy = this.currentUser.user_name;
      if (rowdata != null) {
        this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
        this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
        this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
        this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
        this.doituongSelect.Parent = rowdata.Parent;

        //this.doituongSelect.Bao_Hanh = rowdata.Bao_Hanh;
        //this.doituongSelect.Loai = rowdata.Loai;
        //this.doituongSelect.Gio_Dinh_Muc = rowdata.Gio_Dinh_Muc;
        //this.doituongSelect.Gia_Dinh_Muc = rowdata.Gia_Dinh_Muc;
        //this.doituongSelect.Gia_Ban = rowdata.Gia_Ban;
        //this.doituongSelect.Vat = rowdata.Vat;
        //this.doituongSelect.Model = rowdata.Model;
        //this.doituongSelect.Phong_Ban_Id = rowdata.Phong_Ban_Id;

      }

    }
    else {
      console.log("t11");
      this.repo.postRequest("api/doituongnhanvien/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
        .subscribe(response => {
          console.log(response);
          this.doituongSelect.Id = id;
          this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
          this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
          this.doituongSelect.Trang_Thai = response.Trang_Thai;
          this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
          this.doituongSelect.Parent = response.Parent;
          this.doituongSelect.Ma_Ao = response.Ma_Ao;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = response.Created;
          this.doituongSelect.CreatedBy = response.CreatedBy;
          this.doituongSelect.Modified = new Date();
          this.doituongSelect.ModifiedBy = this.currentUser.user_name;





          this.doituongSelect.Xung_Ho = response.Xung_Ho;
          this.doituongSelect.Chuc_Vu = response.Chuc_Vu;
          this.doituongSelect.Dia_Chi = response.Dia_Chi;
          this.doituongSelect.Dien_Thoai = response.Dien_Thoai;
          this.doituongSelect.Dien_Thoai_Nha = response.Dien_Thoai_Nha;
          this.doituongSelect.Dien_Thoai_Co_Quan = response.Dien_Thoai_Co_Quan;
          this.doituongSelect.Fax = response.Fax;
          this.doituongSelect.Ma_So_Thue = response.Ma_So_Thue;
          this.doituongSelect.Hom_Thu = response.Hom_Thu;
          this.doituongSelect.Tai_Khoan_Ngan_Hang = response.Tai_Khoan_Ngan_Hang;
          this.doituongSelect.Ten_Ngan_Hang = response.Ten_Ngan_Hang;

          this.doituongSelect.Tinh_Id = response.Tinh_Id;
          this.doituongSelect.Huyen_Id = response.Huyen_Id;
          this.doituongSelect.Gioi_Tinh = response.Gioi_Tinh;
          this.doituongSelect.Tiem_Nang = response.Tiem_Nang;
          this.doituongSelect.Web = response.Web;
          this.doituongSelect.To_Chuc = response.To_Chuc;
          this.doituongSelect.Nhan_Vien_Phu_Trach = response.Nhan_Vien_Phu_Trach;
          this.doituongSelect.Nguon_Khach = response.Nguon_Khach;
          this.doituongSelect.Nguoi_Gioi_Thieu = response.Nguoi_Gioi_Thieu;
          this.doituongSelect.Theo_Doi_Khach_Hang = response.Theo_Doi_Khach_Hang;

          this.doituongSelect.Gioi_Han_Cong_No = response.Gioi_Han_Cong_No;
          this.doituongSelect.Dia_Chi_Hoa_Don = response.Dia_Chi_Hoa_Don;
          this.doituongSelect.Ten_Hoa_Don = response.Ten_Hoa_Don;
          this.doituongSelect.CMND_So = response.CMND_So;
          this.doituongSelect.CMND_NoiCap = response.CMND_NoiCap;

          this.doituongSelect.La_Nguoi_Lien_Lac = response.La_Nguoi_Lien_Lac;
          this.doituongSelect.Dia_Chi_Web = response.Dia_Chi_Web;
          this.doituongSelect.Rieng_Tu = response.Rieng_Tu;



          if (response.Ngay_Sinh != null)
            this.ngaysinh = new Date(response.Ngay_Sinh);
          else
            this.ngaysinh = null;
          if (response.CMND_Ngay != null)
            this.ngaycmnd = new Date(response.CMND_Ngay);
          else
            this.ngaycmnd = null;






          //this.doituongSelect.SapXep = response.SapXep;
        });
    }


  }

  keyTenPress(event: any) {
    if (event.which === 13) {
      this.slCapTren.focus();
      event.preventDefault();
    }
  }
  public closeModal() {
    this.save.emit(null);
  }
  public saveproduct() {
    if (this.doituongSelect.Ma_Doi_Tuong == null || this.doituongSelect.Ma_Doi_Tuong == "") {
      swal('Lỗi!', 'Vui lòng nhập mã đối tượng!.', 'error');
      return;
    }
    if (this.ngaysinh != null)
      this.doituongSelect.Ngay_SinhS = this.ngaysinh.getDate() + '/' + (this.ngaysinh.getMonth() + 1) + '/' + this.ngaysinh.getFullYear();
    if (this.ngaycmnd != null)
      this.doituongSelect.CMND_NgayS = this.ngaycmnd.getDate() + '/' + (this.ngaycmnd.getMonth() + 1) + '/' + this.ngaycmnd.getFullYear();


    //console.log("save_ttt");
    //console.log(this.doituongSelect);
    $('#loading').show();
    this.repo.postRequest("api/doituongnhanvien/addorupdate", this.doituongSelect)
      .subscribe(response => {
        $('#loading').hide();
        if (response != null && response.match('-')) {
          this.doituongSelect.Id = response;
          this.save.emit(this.doituongSelect);
        }
        else {
          swal('Lỗi!', response, 'error');
        }
      });
  }







}
