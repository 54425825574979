import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class ListService {
    private token: string = "";
    private data: any;
    constructor(private _http: HttpClient) {
        console.log('khoi dong');
        var aa = localStorage.getItem('currentUser');
        
        if (aa != null) {
            this.data = JSON.parse(localStorage.getItem('currentUser'));
            this.token = this.data.access_token;
            //console.log(this.token);
        }

    }

    

}


