import { ViewChild, ElementRef ,Injectable } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    @ViewChild("ipusername") ipusername: ElementRef;
    constructor(private router: Router, public authorizationService: AuthorizationService)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean
    {
      
      var aa = localStorage.getItem('currentUser');
      console.log('vao kiem tra quyen ');
      //console.log(aa);
      if (aa) {
        console.log("vao trong aa");
        return true;
        //  let data = JSON.parse(aa);
        //  if (data != null && (data.user_name == "admin" || data.is_admin == "1")) {
        //      return true;
        //  }
        //console.log(route);
        //  if (route.data['auth'] == undefined)
        //      return this.hasRequiredPermission(route.firstChild.data['auth'], route.firstChild.data['func']);           
        //  var abc = this.hasRequiredPermission(route.data['auth'], route.data['func']);
        //  return abc;
      }
      //var ua = this.ipusername.nativeElement.value;
      //if (ua) {
      //  console.log('Vao Auth Router');
      //  console.log(ua);
      //  this.router.navigate(['/waitlogin'], { queryParams: { returnUrl: state.url } });
      //  return false;

      //}

      // not logged in so redirect to login page with the return url
      console.log('redirect to login');
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    protected hasRequiredPermission(authGroup: number, functionid: string): Promise<boolean> | boolean {
      console.log('vao kiem tra quyen ' + authGroup)

        if (authGroup == 0)
            return true;
        
        // If user’s permissions already retrieved from the API
        if (this.authorizationService.permissions && this.authorizationService.permissions.length > 0) {
            console.log('Da co quyen')
            //console.log(this.authorizationService.permissions);
            var abc = this.authorizationService.hasPermission(authGroup, functionid);
            console.log(abc);
            return abc;
        } else {
            console.log('vao khoi tao phan quyen')
            // Otherwise, must request permissions from the API first
            const promise = new Promise<boolean>((resolve, reject) => {
                this.authorizationService.initializePermissions()
                    .then(() => {
                        var ms = this.authorizationService.hasPermission(authGroup, functionid);
                        console.log("test ms");
                        console.log(ms);
                        resolve(ms);

                    }).catch(() => {
                        resolve(false);
                    });
          });
          
            return promise;
        }
    }
}
