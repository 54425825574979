

<div class="row">
  <div class="col-6">
    <div class="row">
      <div class="col-8">
        <div style="width:20%;float:left">Từ tháng</div>
        <div style="width:30%;float:left">
          <input class="form-control"
                 maxlength="14"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="TimKiems.TuThang"
                 name="value"
                 min="1"
                 max="12"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <div style="width:20%;float:left">Đến tháng</div>
        <div style="width:30%;float:left">
          <input class="form-control"
                 maxlength="14"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="TimKiems.DenThang"
                 name="value"
                 min="1"
                 max="12"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
      <div class="col-4">
        <label>
          <input tabIndex="52" type="checkbox" [(ngModel)]="TimKiems.XoaCT" />
          Xóa CT đã tính KH
        </label>
      </div>
    </div>
    
    
  </div>
  <div class="col-6">
    <ul class="navbar">

      <li class="nav-item">
        <button class="btn btn-primary" (click)="search()" style="margin-left:3px; margin-right:3px">
          <i class="fas fa-edit"></i>
          Khấu hao
        </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-success" (click)="savebg()" style="margin-left:3px; margin-right:3px">Lưu chứng từ</button>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="closeModal_chondoituong()">
          <i class="fa-2x mr-2 fa fa-window-close">
          </i>
          Thoát
        </a>
      </li>
    </ul>
  </div>
</div>



  <!-- lưới chi tiết -->
  <div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 150px)">
    <div class="row">
      <table class="table table-striped table-bordered table-hover table-condensed">
        <thead [ngClass]="{'theadcss': totalItems>16}">
          <!--<thead>-->
          <tr>

            <th style="width:150px;text-align:center;background-color:#f3f8fc">Mã tài sản</th>
            <th style="width:180px;text-align:center;background-color:#f3f8fc">Tên tài sản</th>
            <th style="width:120px;text-align:center;background-color:#f3f8fc">TK chi phí</th>
            <th style="width:120px;text-align:center;background-color:#f3f8fc">TK khấu hao</th>
            <th style="width:110px;text-align:center;background-color:#f3f8fc">Tháng</th>
            <th style="width:150px;text-align:center;background-color:#f3f8fc">Giá trị KH</th>

          </tr>

          <!--<tr class="filter-row">

            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Ma" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Ten" placeholder="Gõ ký tự và ấn enter" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Cha" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.GhiChu" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.NhomDoiTuong" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>


          </tr>-->

        </thead>
        <tbody style="height:calc(100vh - 210px);">
          <tr *ngFor="let u of datas">
            <td style="width:150px"><a>{{u.MA}}</a></td>
            <td style="width:180px"><a>{{u.TEN}}</a></td>
            <td style="width:120px;text-align:center"><a>{{u.TKCP}}</a></td>
            <td style="width:120px;text-align:center">{{u.TKKH}}</td>
            <td style="width:110px;text-align:center">{{u.THANG}}</td>
            <td style="width:150px;text-align:right">{{u.SOTIEN | number}}</td>
          </tr>
        </tbody>
      </table>


    </div>

  </div>






