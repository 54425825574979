import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { LocalDataSource } from 'ng2-smart-table';
import { NumberViewComponent } from '../../../shared/components/public/numberview.component';
import { DateViewComponent } from '../../../shared/components/public/dateview.component';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';


const swal = require('sweetalert');
@Component({
    selector: 'app-quantri-tuychinhmaubcedit',
  templateUrl: './tuychinhmaubcedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./tuychinhmaubcedit.component.scss'],
    providers: [ListService, Repository]
})


export class tuychinhmaubceditComponent implements OnInit {
  doanhnghiepid: string = null;
    source: LocalDataSource;
    quyen_them: boolean = false;
    quyen_sua: boolean = false;
    quyen_xoa: boolean = false;
    indLoading: boolean = false;    
    tieude:string=null;
    duongdan: string = null;
    datas: any;
    import: any;
    id: any;
    public currentUser;
    currentFileUpload: any;
    dasuamau: boolean = false;    

    @ViewChild('slCapTren',{static:false}) slCapTren: NgSelectComponent;
    @Output() save: EventEmitter<any> = new EventEmitter();
    

   
  //file upload event  
  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      $('#loading').show();
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      formData.append('user', this.currentUser.user_name);
      formData.append('donvi', this.currentUser.id_don_vi);
      formData.append('doanhnghiep', this.doanhnghiepid);
      formData.append('functionid', this.id);
      let headers = new HttpHeaders()
      headers.append('Content-Type', 'json');  
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + this.currentUser.access_token);
      this.http.post<any>(Global.App_url + 'api/importexcel/uploadmaubc/', formData, {
        headers: headers
      })
        .subscribe((res) => {
          event.srcElement.value = null;
          $('#loading').hide();
          if (res != null && res != "")
            swal('Lỗi!', res, 'error');
          else {
            swal('Hoàn thành!', "Nhập dữ liệu hoàn thành!", 'success');
            this.Loaddatas();
          }

        });

     // window.location.reload();
    }
  }
  
  constructor(private _dataService: ListService, private repo: Repository, private http: HttpClient) {
       
        
    }

    ngOnInit(): void {
       
    }

    @ViewChild("mafocus",{static:false}) mafocus : ElementRef;

          loadDataInit(functionid: any) {
            this.id = functionid;
          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === functionid.toLowerCase());
            if (giatri != null && giatri.length > 0) {

              this.tieude = giatri[0].ten_Phan_He;
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
          }

           

        //setTimeout(() => {
        //    this.mafocus.nativeElement.focus();  
        //},500);
    //this.repo.getRequest("api/importexcel/getbyfunctionid/" + this.id)
    //  .subscribe(response => {
    //    this.duongdan = response.TemplatePath;
    //    this.import = response;
    //    //if(this.duon)
            //  });
            this.repo.postRequest("api/importexcel/LayMauBaoCao/", { NhomDoiTuongId: this.id, DonViId: this.currentUser.id_don_vi, DoanhNghiepId: this.doanhnghiepid })
              .subscribe(response => {
                if (response == "1")
                  this.dasuamau = true;
                else
                  this.dasuamau = false;
              });


    this.Loaddatas();
        
  }
  Loaddatas(): void {
    this.repo.postRequest("api/importlog/getview/", { NhomDoiTuongId: this.id, DonViId: this.currentUser.id_don_vi, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.datas = response;
      });


  }
  public OnFileClick(id: string) {
    window.open(Global.App_url + 'Import/ShowFile/?functionid=' + id + '&donviid=' + this.currentUser.id_don_vi +'&doanhnghiepid=' + this.doanhnghiepid, "_blank");
    
  }
  
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
  }
  public taitepmau() {
    //$('#loading').show();
    let type = "xlsx";
    // 2E76671D-9E9D-45FF-9187-6C7E6192C859 hợp đồng cá nhân
    // 0BF2B218-3D3A-4E2A-906B-06E4A13F135B hợp đồng tổ chức
    if (this.id.toLocaleUpperCase() == "2E76671D-9E9D-45FF-9187-6C7E6192C859"
      || this.id.toLocaleUpperCase() == "0BF2B218-3D3A-4E2A-906B-06E4A13F135B")
      type = "docx";

    let msg = "";

    let data = {
      Id: BaoCao.BC01_XuatMauBaoCao, //ID cứng trong code để chạy vào class XuatMauBaoCao trong thu muc reports
      Type: type,
      Data: JSON.stringify({
        DonViId: this.currentUser.id_don_vi
        , FunctionId: this.id
        , UserName: this.currentUser.user_name
        , duongdan: this.duongdan
        , DoanhNghiepId: this.doanhnghiepid

      })
    };
    if (type === 'xlsx') {
      this.repo.postPdf(Global.BASE_BaoCao2_SAVE, data)
        .subscribe(response => {
          var file = new Blob([response.body], { type: 'vnd.ms-excel' });
          var fileURL = URL.createObjectURL(file);
          var a = document.createElement("a");
          a.href = fileURL;
          //$('#loading').hide();
          a.download = "mau_bao_cao.xlsx";
          document.body.appendChild(a);
          a.click();
        });
    }
    if (type === 'docx') {
      this.repo.postPdf(Global.BASE_BaoCao2_SAVE, data)
        .subscribe(response => {
          //console.log("tt");
          //console.log(response);
          if (response == "L") {
            this.repo.postRequest(Global.BASE_BaoCao2_SAVE, data).subscribe(
              data => {
                $('#loading').hide();
              },
              error => {
                msg = error;
              }
            );
          }
          else {
            $('#loading').hide();
            var file = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "EPrescription");
          }

        });
    }

    
  }
   
  public phuchoimaubaocao() {
    swal({
      title: 'Bạn muốn phục hồi tệp mẫu báo cáo ban đầu?',
      text: 'Hệ thống sẽ xóa mẫu tùy chỉnh!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.repo.postRequest('api/importexcel/xoamaubaocao/', { NhomDoiTuongId: this.id, DonViId: this.currentUser.id_don_vi, DoanhNghiepId: this.doanhnghiepid }).subscribe(
            data => {
              if (data == "OK") //Success
              {
                swal('Deleted!', 'Mẫu báo cáo đã được phục hồi!.', 'success');

              }
              else {
                // this.msg = data;
                swal('Cảnh báo!', data, 'warning');
              }


            },
            error => {
              //this.msg = error;
            }
          );
        }
      });
    //this.repo.postRequest("api/doituong/addorupdate",this.doituongSelect)
    //    .subscribe(response => {  
    //        console.log(response);
    //        this.doituongSelect.Id=response;
    //        this.save.emit(this.doituongSelect);
    //    }); 
  } 
 

   


}
