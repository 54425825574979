<ul class="navbar">
  <li class="nav-item active">
    <a class="nav-link" (click)="save_chondoituong()">
      <i style="color:red" class="fa-2x mr-2 far fa-window-close"></i>
      Hủy bỏ
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="closeModal_chondoituong()">
      <i class="fa-2x mr-2 fa fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>


<div class="row">
  <div class="col-9">
    <label class="control-label" style="font-weight:bold;color:cornflowerblue">Danh sách đối tượng</label>
  </div>
  <div class="col-3">
    <button *ngIf="quyen_them===true" style="float:right" class="btn btn-info btn-bloc" (click)="adddata()">
      Thêm mới
    </button>
  </div>
</div>

<!-- lưới chi tiết -->
<div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 150px)">
  <div class="row">
    <table class="table table-striped table-bordered table-hover table-condensed">
      <thead [ngClass]="{'theadcss': totalItems>16}">
        <!--<thead>-->
        <tr>
          <th style="width:100px;text-align:center">Chọn</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Mã đối tượng</th>
          <th style="width:200px;text-align:center;background-color:#f3f8fc">Tên đối tượng</th>
          <th style="width:200px;text-align:center;background-color:#f3f8fc">Cấp trên</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Ghi chú</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Nhóm đối tượng</th>
          <!--<th style="width:100px;text-align:center">Chọn</th>-->

        </tr>

        <tr class="filter-row">
          <td style="text-align:center">
            <!--<input type="checkbox" [(ngModel)]="TatCa" (click)="TichTatCa()" />-->
            <button class="btn btn-info btn-primary" (click)="search()">
              <i class="fas fa-search"></i>
              Tìm kiếm
            </button>
          </td>

          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ma" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ten" placeholder="Gõ ký tự và ấn enter" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Cha" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.GhiChu" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.NhomDoiTuong" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <!--<td></td>-->

        </tr>

      </thead>
      <tbody style="height:calc(100vh - 210px);">
        <tr *ngFor="let u of datas">
          <!--<td style="text-align:center;width:50px"><input type="checkbox" [(ngModel)]="u.Tich" /></td>-->
          <td style="width:100px;text-align:center"><button (click)="save_chondoituong2(u)" class="btn btn-success">Chọn</button></td>
          <td style="width:120px"><a (click)="editdata(u.Id)">{{u.Ma}}</a></td>
          <td style="width:200px"><a (click)="editdata(u.Id)">{{u.Ten}}</a></td>
          <td style="width:200px"><a (click)="editdata(u.Id)">{{u.Cha}}</a></td>
          <td style="width:120px">{{u.Ghi_Chu}}</td>
          <td style="width:120px">{{u.NhomDoiTuong}}</td>

          <!--<td style="width:60px">-->
          <!--<div style="float:left" *ngIf="u.Sua">
            <a (click)="editdata(u.Id)" class="ng-binding" style="color:#1e6bb8 !important;font-weight:normal"><i class="mb-1 fa fa-edit"></i>Sửa</a>
          </div>-->
          <!--</td>-->
        </tr>
      </tbody>
    </table>


  </div>

</div>





<div class="modal-footer" style="display: flex;justify-content: space-between;margin-top:5px;">
  <div>
    <div *ngIf="totalItems>=10">
      <ul class="pagination">
        <li [ngClass]="{disabled: currentPage === 1}">
          <a (click)="setPage(1)"><<</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(currentPage + 1)">></a>
        </li>
        <li *ngFor="let page of pages" [ngClass]="{active: currentPage === page.Id}">
          <a (click)="setPage(page.Id)">{{page.Id}}</a>
        </li>
        <li [ngClass]="{disabled:currentPage === 1}">
          <a (click)="setPage(currentPage - 1)"><</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(totalPages)">>></a>
        </li>
        <li>
          <input type="number" style="width:50px;height:25px;text-align:center" [(ngModel)]="pageSize" (blur)="search1()" />
        </li>
        <li><span style="font-weight:bold">Tổng số: {{totalItems | number}}</span></li>
      </ul>
    </div>
  </div>
  <div class="">

  </div>

</div>

<ejs-dialog #ejskhoahoc [buttons]='dlgButtons' [visible]='false' [header]='"Bạn muốn thêm đối tượng nào?"'
            [showCloseIcon]='true' [height]='"300px"' [width]='"400px"'>
  <div class="formedit">


    <div class="c-radio" *ngFor="let u of doituongkethua; let i = index">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(u)" value="option{{i}}" />
        <span class="fa fa-circle"></span>{{u.Ten_Phan_He}}
      </label>
    </div>


  </div>
</ejs-dialog>

<!--<bs-modal #mdLuaChon_doituong class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Bạn muốn thêm đối tượng nào?</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChon_doituong.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <div class="c-radio" *ngFor="let u of doituongkethua; let i = index">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(u)" value="option{{i}}" checked="" />
        <span class="fa fa-circle"></span>{{u.Ten_Phan_He}}
      </label>
    </div>
  </bs-modal-body>
  <bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">

      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChon_doituong.close()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormNguoiNop()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>

    </div>
  </bs-modal-footer>
</bs-modal>-->



<bs-modal #mdKhachHang class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdKhachHang.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuckhachhangedit #dteKhachHang (save)="saveModal($event)"></app-dm-danhmuckhachhangedit>
  </bs-modal-body>
</bs-modal>




<bs-modal #mdDoiTuongNhaCC class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongNhaCC.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmucnhacungcapedit #dtenhacungcap (save)="saveModal($event)"></app-dm-danhmucnhacungcapedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdCanBo class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdCanBo.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmucnhanvienedit #dteCanBo (save)="saveModal($event)"></app-dm-danhmucnhanvienedit>
  </bs-modal-body>
</bs-modal>


