import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { Repository } from '../../../services/repository'



@Component({
    selector: 'article-approvers-avatar',
  template: `
        <span *ngFor='let approver of approvers; let i = index'> \
        <img alt="image" title="{{ approver.FullName }}/{{ approver.ChucVu }}" class="img-circle" [src]="approver.AvatarUrl"> \
        <i class="fa fa-arrow-right"></i>
        </span>
  `,
  providers: [Repository]
  })
export class ArticleApproversAvatarDirective implements  OnInit {

    
  renderValue: string;

  @Input() articleid: string | number;
  @Output() completeEvent: EventEmitter<any> = new EventEmitter();

  approvers: any = [];
  
  constructor(private repo: Repository) {

   
  }
  ngOnInit() {
    this.repo.getRequest("api/nguoidung/getarticleapprovers/" + this.articleid)
      .subscribe(response => {
        this.approvers = response;
        if (response.length==1)
          this.completeEvent.emit(0);
        else
          this.completeEvent.emit(1);
      });
  }


}
