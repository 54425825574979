import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../shared/global';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'


@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }


  getLoginToken() {


   
    let httpHeaders = new HttpHeaders()
      //.set('authorization','Bearer ' + this.token)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

    return this.http.get(Global.App_url + '/api/Account/LoginToken', {
      headers: httpHeaders,
      responseType: 'json'
    });
  
  }

  login(username: string, password: string, loginToken: string) {


    //var data = {
    //  UserName: username, Password: password
    //}
    //let httpHeaders = new HttpHeaders()
    //  //.set('authorization','Bearer ' + this.token)
    //  .set('Content-Type', 'application/json')
    //  .set('Access-Control-Allow-Origin', '*');

    //return this.http.post(Global.App_url + 'api/login/authenticate', data, {
    //  headers: httpHeaders,
    //  responseType: 'json'
    //});

    var data = "grant_type=password&username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&login-token=" + encodeURIComponent(loginToken) + "&g-recaptcha-response=" + encodeURIComponent('');

 

    let httpHeaders = new HttpHeaders()
      //.set('authorization','Bearer ' + this.token)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

    return this.http.post(Global.App_url + '/token', data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });	  
 
  }

  public getRequest(url: string, accesstoken: string, data?: any)
    : Observable<any> {
            
    //console.log(this.token);

    let httpHeaders = new HttpHeaders()
      .set('authorization', 'Bearer ' + accesstoken)
      .set('Content-Type', 'application/json');

    return this.http.get(Global.App_url + url, {
      headers: httpHeaders,
      params: data,
      responseType: 'json'
    });

  }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }

    fcAuthenticate(functionRight: string, accessRight: number): Observable<any> {

        const headers = new HttpHeaders().set('content-type', 'application/json');
        var user = localStorage.getItem('currentUser');
        
        var body = {
            functionRight: functionRight, accessRight: accessRight, username: user.split(',')[1].split(':')[1]
        }

        return this.http.post(Global.App_url + 'api/login/fcAuthenticate', body);

        
    }

}
