import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef, ViewEncapsulation } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Global } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import {  Lists_phongban } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'


const swal = require('sweetalert');
@Component({
    selector: 'app-quantri-phanheedit',
  templateUrl: './phanheedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./phanheedit.component.scss'],
  providers: [ListService, Repository],
  encapsulation: ViewEncapsulation.None
})


export class phanheeditComponent implements OnInit {
  doanhnghiepid: string = null;
  
    @ViewChild('dteChung') doituongchungedit: any;
    @ViewChild('slCapTren') slCapTren: any;
    @ViewChild('slGiaoDien') slGiaoDien: any;
  
  
    quyen_them: boolean = false;
    quyen_sua: boolean = false;
    quyen_xoa: boolean = false;
    public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
      precision: 0,
      allowNegative: true
  };

  inputparents: any;
  inputluoi: any = [{ Id: 1, Name: 'Trang chi tiết' }, {Id:2, Name: 'Trang thống kê'}]
  inputhienthis: any = [
    { Id: 1, Ma_Doi_Tuong: 'HT', Ten_Doi_Tuong: 'Hệ thống' }
    , { Id: 2, Ma_Doi_Tuong: 'DM', Ten_Doi_Tuong: 'Danh mục' }
    //, { Id: 3, Ma_Doi_Tuong: 'CT', Ten_Doi_Tuong: 'Chứng từ' }
    , { Id: 4, Ma_Doi_Tuong: 'BC', Ten_Doi_Tuong: 'Báo cáo' }
    , { Id: 5, Ma_Doi_Tuong: 'PH', Ten_Doi_Tuong: 'Phân hệ' }
    , { Id: 6, Ma_Doi_Tuong: 'CN', Ten_Doi_Tuong: 'Chức năng' }
    ];
    
    donviid: any;
    tabselected: number = 1;

    indLoading: boolean = false;    
  doituongSelect: any = {
    ChiTiet: {
      Id: null, Ma_Phan_He: null, Ten_Phan_He: null
      , Ma_Giao_Dien: null, Hien_Thi: null, Trang_Thai: null
      , Chu_Thich_Ma: null, Chu_Thich_Ten: null
      ,Parent:null
    }
    , TaiKhoans: {
      Tich:null,Id: null, Ma_Tai_Khoan: null, Ten_Tai_Khoan: null
      , Parent: null
        }
    , PhanHes: {
      Tich: null, Id: null, Ma_Phan_He: null, Ten_Phan_He: null
      , Parent: null
    }, CapTrens: {
      Id: null, Ma_Phan_He: null, Ten_Phan_He: null
      , Parent: null
    }, GiaoDiens: {
       Id: null, Ma_Giao_Dien: null, Ten_Giao_Dien: null
      
    }
    , DataTieuDeTK: null, DataTieuDePH: null, DataTieuDeCot:null
    , TaiKhoan_TimKiems: null, PhanHe_TimKiems: null
    };    
    nhomdtid:string=null;


    @Output() save: EventEmitter<any> = new EventEmitter();
  
   

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
      

    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }
  TichTK(value) {
    this.doituongSelect.TaiKhoan_TimKiems.forEach((item: any) => {
      if (item.Id == value.Id)
        item.Tich = value.Tich;
    });
  }
  TichPH(value) {
    this.doituongSelect.PhanHe_TimKiems.forEach((item: any) => {
      if (item.Id == value.Id)
        item.Tich = value.Tich;
    });
  }
  TichCot(value) {
    this.doituongSelect.Cot_TimKiems.forEach((item: any) => {
      if (item.Id == value.Id)
        item.Tich = value.Tich;
    });
  }
  changesearchTK(value) {
    let datatam = this.doituongSelect.TaiKhoan_TimKiems;
    //console.log(datatam);
    this.doituongSelect.DataTieuDeTK.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.doituongSelect.TaiKhoans = datatam;

  }
  changesearchCot(value) {
    let datatam = this.doituongSelect.Cot_TimKiems;
    //console.log(datatam);
    this.doituongSelect.DataTieuDeCot.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.doituongSelect.Cots = datatam;

  }
  changesearchPH(value) {
    let datatam = this.doituongSelect.PhanHe_TimKiems;
    //console.log(datatam);
    this.doituongSelect.DataTieuDePH.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.doituongSelect.PhanHes = datatam;

  }
    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_PH(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Phan_He.toLowerCase().indexOf(term) > -1 || item.Ten_Phan_He.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_GD(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Giao_Dien.toLowerCase().indexOf(term) > -1 || item.Ten_Giao_Dien.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_TK(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Tai_Khoan.toLowerCase().indexOf(term) > -1 || item.Ten_Tai_Khoan.toLowerCase().indexOf(term) > -1;
  }
    @ViewChild("mafocus") mafocus : ElementRef;

  loadDataInit(nhomdoituongid: string, id: string, donviid: string) {

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {

      let data = JSON.parse(authlogin);
      this.donviid = data.id_don_vi;
      var giatri = data.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }
      this.doanhnghiepid = data.id_doanh_nghiep;
    }

    this.nhomdtid = nhomdoituongid;
    setTimeout(() => {
      this.mafocus.nativeElement.focus();
    }, 500);

    //this.repo.getRequest("api/doituong/getall2/" + Lists_donvitinh.id + '/null/' + donviid)
    //  .subscribe(response => {
    //    this.inputdvts = response;
    //  });


    this.doituongSelect = {
      ChiTiet: {
        Id: null, Ma_Phan_He: null, Ten_Phan_He: null
        , Ma_Giao_Dien: null, Hien_Thi: null, Trang_Thai: null
        , Chu_Thich_Ma: null, Chu_Thich_Ten: null
        , Parent: null
      }
      , TaiKhoans: {
        Tich: null, Id: null, Ma_Tai_Khoan: null, Ten_Tai_Khoan: null
        , Parent: null
      }
      , PhanHes: {
        Tich: null, Id: null, Ma_Phan_He: null, Ten_Phan_He: null
        , Parent: null
      }
      , Cots: {
        Tich: null, Id: null, Ma_Phan_He: null, Ten_Phan_He: null
        , Parent: null
      }
      , CapTrens: {
        Id: null, Ma_Phan_He: null, Ten_Phan_He: null
        , Parent: null
      }, GiaoDiens: {
        Id: null, Ma_Giao_Dien: null, Ten_Giao_Dien: null

      }
      , DataTieuDeTK: null, DataTieuDePH: null, DataTieuCot: null
      , TaiKhoan_TimKiems: null, PhanHe_TimKiems: null, Cot_TimKiems: null
    };

    console.log("ttt");
    this.repo.postRequest("api/phanhe/getmodelbyid/", {Id: id})
            .subscribe(response => {
              
              console.log(response);
              this.doituongSelect.ChiTiet = response.ChiTiet;
              this.doituongSelect.TaiKhoans = response.TaiKhoans;
              this.doituongSelect.PhanHes = response.PhanHes;
              this.doituongSelect.Cots = response.Cots;
              this.doituongSelect.TaiKhoan_TimKiems = response.TaiKhoans;
              this.doituongSelect.PhanHe_TimKiems = response.PhanHes;
              this.doituongSelect.Cot_TimKiems = response.Cots;
              this.doituongSelect.CapTrens = response.CapTrens;
              this.doituongSelect.GiaoDiens = response.GiaoDiens;
              this.doituongSelect.DataTieuDeTK = response.DataTieuDeTK;
              this.doituongSelect.DataTieuDePH = response.DataTieuDePH;
              this.doituongSelect.DataTieuDeCot = response.DataTieuDeCot;
            }); 
       
    }
  selectedTab(tab: number) {
    this.tabselected = tab;
    if (tab === 2) {


     
    }
    else {
      setTimeout(() => {
        this.mafocus.nativeElement.focus();
      }, 100);
    }
   
  }
    keyTenPress(event: any) { 
        if (event.which === 13) {
          //this.slLoaiTaiSan.focus();
            event.preventDefault();
          }
  }
  
  public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    this.doituongSelect.TaiKhoans = this.doituongSelect.TaiKhoan_TimKiems;
    this.doituongSelect.PhanHes = this.doituongSelect.PhanHe_TimKiems;
    this.doituongSelect.Cots = this.doituongSelect.Cot_TimKiems;
    if (this.doituongSelect.ChiTiet.Ma_Phan_He == null || this.doituongSelect.ChiTiet.Ma_Phan_He == "") {
      swal('Lỗi!', 'Vui lòng nhập mã phân hệ!.', 'error');
      return;
    }

      if (this.doituongSelect.ChiTiet.Ten_Phan_He == null || this.doituongSelect.ChiTiet.Ten_Phan_He == "") {
            swal('Lỗi!', 'Vui lòng nhập tên phân hệ!.', 'error');
            return;
      }

     
      
      this.repo.postRequest("api/phanhe/addorupdate2",this.doituongSelect)
            .subscribe(response => {  
                console.log(response);
                //this.doituongSelect.ChiTiet.Id=response;
                this.save.emit(this.doituongSelect);
            }); 
    }
   
  comboSeleted: NgSelectComponent;
  AddDoiTuong(cobo: NgSelectComponent) {

    this.comboSeleted = cobo;
    cobo.close();
    this.doituongchungedit.loadDataInit(Lists_phongban.id, null, this.donviid);
    
  }
  saveModal(doituong: any) {
    console.log(doituong);
   

    //this.doituongSelect.Loai_Tai_San_Id = doituong.Id;
   
    this.comboSeleted.focus();
  }
   
  keySoTKPress(event: any) {
    if (event.which === 13) {
      //this.inputTenKB.focus();
      event.preventDefault();
    }
  }

}


