<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Mã</label>
      <div class="col-4">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="Nếu không nhập hệ thống tự sinh" [(ngModel)]="doituongSelect.Ma_Doi_Tuong">
      </div>
      <label class="control-label col-2 ng-binding">Cấp trên</label>
      <div class="col-4">

        <a *ngIf="doituongSelect.Parent===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha">
        </a>
        <a *ngIf="doituongSelect.Parent!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" tabindex="2" type="text" class="form-control" placeholder="Chọn cấp trên" [(ngModel)]="doituongSelect.Cha" (ngModelChange)="adddatachondoituongchung(1)">
        </a>

        <!--<ng-select #slCapTren
             class="twcol"
             [tabIndex]="2"
             [openOnEnter]="false"
             [items]="inputparents"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             [searchFn]="comboSearchFn"
             [(ngModel)]="doituongSelect.Parent">
    <ng-template ng-option-tmp let-item="item">

      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->

      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Tên</label>
      <div class="col-10">
        <input type="text" (keydown)="keyTenPress($event)" tabIndex="3" class="form-control" placeholder="Tên" [(ngModel)]="doituongSelect.Ten_Doi_Tuong">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Giờ định mức</label>
      <div class="col-4">
        <input id="gio" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Gio_Dinh_Muc"
               name="value"
               tabIndex="4"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <label class="control-label col-2 ng-binding">Giá định mức</label>
      <div class="col-4">
        <input id="gia" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Gia_Dinh_Muc"
               name="value"
               tabIndex="4"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Giá bán</label>
      <div class="col-4">
        <input id="gio" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Gia_Ban"
               name="value"
               tabIndex="4"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <label class="control-label col-2 ng-binding">Vat</label>
      <div class="col-4">
        <input id="gia" #myinputsl class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.Vat"
               name="value"
               tabIndex="4"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">Loại</label>
      <div class="col-4">
        <ng-select #slLoai
                   class="twcol"
                   [tabIndex]="2"
                   [openOnEnter]="false"
                   [items]="inputloais"
                   bindValue="value"
                   bindLabel="text"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.Loai">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.text}}</div>
              </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2 ng-binding">Phòng ban</label>
      <div class="col-4">
        <ng-select #slPhongban
                   class="twcol"
                   [tabIndex]="2"
                   [openOnEnter]="false"
                   [items]="inputphongbans"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="doituongSelect.Phong_Ban_Id">
          <ng-template ng-option-tmp let-item="item">

            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2 ng-binding">{{tieudectmt}}</label>
      <div class="col-10">
        <textarea name="description" tabIndex="4" [(ngModel)]="doituongSelect.Ghi_Chu" placeholder="{{tieudectmtplace}}" type="text" class="form-control input-xs ng-pristine ng-untouched ng-valid ng-binding ng-empty" rows="3"> </textarea>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
  </div>

</div>


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchung2edit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchung2edit>
  </bs-modal-body>
</bs-modal>
