<div class="row ng-scope" style="min-height:300px">

  <div class="col-8">
    <div class="row">
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 1: Tải tệp mẫu (<a style="color:blue" (click)="taitepmau()">Tại đây</a>)
      </p>
    </div>
    <div class="row">
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 2: Hiệu chỉnh mẫu
      </p>
    </div>
    <div class="row">
      <p style="line-height: 20px;float:right;padding-left:10px">
        Bước 3: Ấn nút "Tải tệp mẫu lên" bên dưới để cập nhật biểu mẫu
      </p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="fileUpload btn btn-primary">
          <!--*ngIf="isUploadBtn"-->
          <span>Tải tệp mẫu lên</span>
          <input type="file" id="btnUpload" value="Upload" (change)="fileChange($event)" class="upload" />
        </div>

      </div>
    </div>
  </div>
  <div class="col-4" style="border-left:1px solid rgba(0, 0, 0, 0.12);font-size:11px">
    <p style="line-height: 20px;float:right;padding-left:10px">
      <button class="b-button btn-danger" (click)="phuchoimaubaocao()">Phục hồi lại mẫu ban đầu</button>
    </p>
    <p style="line-height: 20px;text-align:center;padding-left:10px;color:red" *ngIf="dasuamau">
      <span>Đã chỉnh sửa mẫu</span>
    </p>
  </div>

</div>



