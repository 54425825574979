import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DBOperation } from '../../../shared/enum';
import { Global, BaoCao } from '../../../shared/global';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Repository } from '../../../services/repository'
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { CurrencyMaskInputMode } from 'ngx-currency'
import 'jquery'
import 'bootstrap'

import {
  Lists_nhacungcap, Lists_nganhang,
  Lists_taisancodinh, Lists_congcu, Lists_vattu, Lists_nhanvien, Lists_khachhang, Lists_hanghoa
  , Lists_khohang, Lists_donvitinh, Lists_lenhsuachua, Lists_khoanmuc, Lists_madonvi, Lists_hopdong, Lists_doituong
  , Lists_taisan,
  Lists_xesua,
  Lists_dichvu,
  Lists_loaisuachua
} from '../../../routes/menu'
import { load } from '@telerik/kendo-intl';


const swal = require('sweetalert');
@Component({
  selector: 'app-ketoan-dathangedit',
  templateUrl: './dathangedit.component.html',
  styleUrls: ['./dathangedit.component.scss'],
  providers: [ListService, Repository],
  encapsulation: ViewEncapsulation.None
})


export class dathangeditComponent implements OnInit {
  Trang_Thai: number;
  tien_hanghoa: number = 0;
  tien_danhsachxe: number = 0;
  tienthue: number = 0;
  HienThis: any = {
    Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
    , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
    , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
    , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
    , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
    , Ty_Gia: false, Ngoai_Te: false
  };
  DoRongs: any = {
    Dien_Giai_Chi_Tiet: 250, Hang_Hoa_Id_No: 130, Kho_Hang_Id_No: 130, Don_Vi_Tinh_Id_No: 130
    , Hang_Hoa_Id_Co: 130, Kho_Hang_Id_Co: 130, Don_Vi_Tinh_Id_Co: 130
    , Doi_Tuong_Id_No: 130, Hop_Dong_Id_No: 150
    , Tai_San_Id_No: 150, Lenh_Sua_Chua_Id_No: 150, Khoan_Muc_Id_No: 130, Doi_Tuong_Id_1_No: 120
    , Doi_Tuong_Id_Co: 130, Hop_Dong_Id_Co: 150, Tai_San_Id_Co: 150, Lenh_Sua_Chua_Id_Co: 150
    , Khoan_Muc_Id_Co: 130, Doi_Tuong_Id_1_Co: 120, So_Luong: 150, Don_Gia: 150, So_Tien: 150
    , Ty_Gia: 150, Ngoai_Te: 150
  };
  inputnguoisuas: any;
  inputloaidichvus: any;
  inputhangbaohiems: any;
  inputhangxes: any;
  inputdactas: any;
  inputxuatxus: any;
  inputmausacs: any;
  inputmodels: any;
  currentUser: any;
  inputtrangthais: any = [
    { Ten_Doi_Tuong: "Chờ duyệt", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "Duyệt lần 1 (A1)", Ma_Doi_Tuong: '1', Id: 1 },
    { Ten_Doi_Tuong: "Duyệt lần 2 (A2)", Ma_Doi_Tuong: '2', Id: 2 },
    { Ten_Doi_Tuong: "Không duyệt", Ma_Doi_Tuong: '3', Id: 3 },
    { Ten_Doi_Tuong: "Hủy đặt hàng", Ma_Doi_Tuong: '4', Id: 4 }
  ];
  inputloaibaogias: any = [
    { Ten_Doi_Tuong: "BG sửa chữa", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "BG đồng sơn", Ma_Doi_Tuong: '1', Id: 1 },
    { Ten_Doi_Tuong: "BG khuyến mại", Ma_Doi_Tuong: '2', Id: 2 }
  ];
  He_So: number = 1;
  doanhnghiepid: string = null;
  thaydoi: boolean = false;
  dorong: any = '250';
  fixcot: number = 0;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  quyen_themdoituong: boolean = false;
  quyen_themnguon: boolean = false;
  quyen_themchuong: boolean = false;
  quyen_themkhoan: boolean = false;
  quyen_themtieumuc: boolean = false;
  quyen_themcapphat: boolean = false;
  quyen_themnghiepvu: boolean = false;
  quyen_themnganhang: boolean = false;
  quyen_themctmt: boolean = false;
  quyen_themhoatdong: boolean = false;
  quyen_themthongke: boolean = false;
  quyen_themnhommuchi: boolean = false;
  quyen_themvattuhanghoa: boolean = false;
  quyen_themtaisan: boolean = false;
  quyen_themcongcu: boolean = false;
  
  quyen_themtaphop: boolean = false;
  quyen_themtaphop2: boolean = false;
  inputtaphops: any;
  inputtaphops2: any;

  inputtaisans: any;

  msg: string;

  @ViewChild('mdLuaChonDoRong') mdLuaChonDoRong: BsModalComponent;

  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
  @ViewChildren(NgSelectComponent) slcombo: QueryList<NgSelectComponent>

  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;

  
  datas: Idata[];
  data: Idata;
  indLoading: boolean = false;
  dataFrm: FormGroup;
  dbops: DBOperation;
  modalTitle: string;
  modalBtnTitle: string;

  inputhanghoas: any;
  inputkhohangs: any;
  inputdonvitinhs: any;
  inputhopdongs: any;
  inputlenhsuachuas: any;
  inputkhoanmucs: any;
  inputmadonvis: any;
  inputloaithues: any;
  inputloaidonhangs: any = [
    { Ten_Doi_Tuong: "Trong kế hoạch", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "Ngoài kế hoạch", Ma_Doi_Tuong: '1', Id: 1 }
  ];

  inputtaikhoans: any;
  inputkhachhangs: any;
  inputnguonkinhphis: any;
  inputdoituongs: any;
  inputchuongs: any;
  inputcaphats: any;
  inputnghiepvus: any;
  inputnganhangs: any;
  inputhoatdongs: any;
  inputcmmts: any;
  inputthongkes: any;
  inputloaikhoans: any;
  inputtieumucs: any;
  inputlienquans: any;
  themdoituongtrenluoi: number;
  public ngaychungtu: Date = new Date();
  public ngaydathang: Date = new Date();
  taikhoanno: any = null;
  tendoituong: any = null;
  tabselected: number = 1;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  @Output() savechungtu: EventEmitter<any> = new EventEmitter();
  isHidden: number = 0;
  public loading = true;
  primaryColour: string = '#5d9cec';
  secondaryColour: string = '#ff902b';
  //----------tham số các nhóm cột------------------
  nhomnguonid: string = Lists_khoanmuc.id;
  nhomchuongid: string = null;
  nhomloaikhoanid: string = null;
  nhomtieumucid: string = null;
  hinhthuccapphatid: string = null;
  nghiepvuid: string = null;
  nganhangid: string = Lists_nganhang.id;
  hoatdongid: string = null;
  cmmtid: string = null;
  mathongke: string = null;
  hanghoaid: string = null;
  khohangid: string = Lists_khohang.id;
  donvitinhid: string = Lists_donvitinh.id;
  hopdongid: string = null;
  lenhsuachuaid: string = null;
  doituongid: string = "532A61EA-D765-844B-8CBF-E610B209E332";
  taisanid: string = Lists_taisan.id;
  taphopid2: any;
  taphopid: any;
  Doi_Tuong_Id: any;
  //-----------------------------------------------------

  chungtuSelect: any = {
    Id: null, Don_Vi_Id: null, Copy: null, DoanhNghiepId: null
    , So_Don_Hang: null, Ngay_Dat_Hang: null,
    Loai_Don_Hang: null, Thang_Du_Kien: null, Nam_Du_Kien: null, Trang_Thai: null, TrangThaiS: null, DisableStatus: null, Lock_So_Hop_Dong: null
    , Phan_He_Id: null
    , Danh_Sach_Xes: [], Hang_Hoas: [], CreatedBy: null, ModifiedBy: null
  };
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  tongtien2: number = 0;

  onChangeHangXe($event) {
    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputmodels = response;
      });
  }

  public changeST2(drow: any) {
    this.tongtien2 = 0;
    //this.chungtuSelect.Danh_Sach_Xes.forEach(
    //  (item) => {
    //    this.tongtien2 = this.tongtien2 + (item.So_Tien === null ? 0 : item.So_Tien);
    //  }
    //);
  }
  public ngxnamOptions = {
    prefix: '',
    thousands: '',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  public ngxHSOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 1,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };

  public ngxAmOption2s = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 3,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };


  donviid: string = null;
  namketoan: number;
  username: string = null;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private repo: Repository) {



  }
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if ((event.altKey || event.metaKey) && event.keyCode == 49) {
      this.tabselected = 1;

      setTimeout(() => {
        this.setComboFocus(0, 1, 1);
      }, 500);
    }
    if ((event.altKey || event.metaKey) && event.keyCode == 50) {
      this.tabselected = 2;
      setTimeout(() => {
        this.setComboFocus(0, 1, 2);
      }, 500);
    }

    event.stopPropagation();

  }
  phanheid: string = null;
  public innerHeight: any;
  loaiketchuyen: any = null;
  hachtoandongthois: any = [];
  chitietdongthois: any = [];
  //id--ichung tu; loai: đánh dấu trường hợp copy (1: copy, 0: ko)
  loadDataInit(id: string, loai: number, pheid: string, ctu: any) {
    this.tabselected = 1;
    this.He_So = 1;
    this.HienThis = {
      Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
      , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
      , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
      , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
      , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
      , Ty_Gia: false, Ngoai_Te: false
    };

    if (id == "0" || id == "1" || id == "2") {
      this.loaiketchuyen = id;
      id = null;
    }
    this.chitietdongthois = [];
    this.innerHeight = window.innerHeight - 250;
    this.ngaychungtu = new Date();
    this.ngaydathang = new Date();
    this.tabselected = 1;
    this.phanheid = pheid;
    this.thaydoi = false;
    //----------------------lây thông tin ban đầu

    var crru = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = crru;
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
      var giatri = crru.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }

      this.doanhnghiepid = crru.id_doanh_nghiep;


      this.LoadCategory();

      // gán quyền thêm đối tượng
      var giatri1 = crru.ds_quyen.filter(name => name.Id === ('532A61EA-D765-844B-8CBF-E610B209E332').toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themdoituong = true;
        else
          this.quyen_themdoituong = false;
      }

      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_nganhang.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themnganhang = true;
        else
          this.quyen_themnganhang = false;
      }

      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_vattu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themvattuhanghoa = true;
        else
          this.quyen_themvattuhanghoa = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_taisancodinh.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themtaisan = true;
        else
          this.quyen_themtaisan = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_congcu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themcongcu = true;
        else
          this.quyen_themcongcu = false;
      }


    }

    //--------------------------------------------------------------------
    this.chungtuSelect = {
      Id: null, Don_Vi_Id: null, Copy: null, DoanhNghiepId: this.doanhnghiepid
      , So_Don_Hang: null, Ngay_Dat_Hang: null,
      Loai_Don_Hang: null, Thang_Du_Kien: null, Nam_Du_Kien: null, Trang_Thai: 0, TrangThaiS: null, DisableStatus: null, Lock_So_Hop_Dong: null
      , Phan_He_Id: null
      , Danh_Sach_Xes: [], Hang_Hoas: [], CreatedBy: null, ModifiedBy: null
    };

    if (id === null) {
      if (ctu === null) {
        this.chungtuSelect.Ngay_Dat_Hang = new Date();
        this.chungtuSelect.Don_Vi_Id = this.donviid;
        this.chungtuSelect.Phan_He_Id = this.phanheid;
        this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
        this.AddRowNew(this.chungtuSelect.Hang_Hoas);
        this.AddRowNewHD(this.chungtuSelect.Danh_Sach_Xes);

        //$("input:radio[name='a'][value='option0']").prop('checked', true);
      }
      else {
        this.chungtuSelect = ctu;
        if (this.chungtuSelect.Hang_Hoas.length == 0)
          this.AddRowNew(this.chungtuSelect.Hang_Hoas);
        this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
        
        if (this.chungtuSelect.Ngay_Dat_Hang != null) {
          this.ngaydathang = new Date(this.chungtuSelect.Ngay_Dat_Hang);
        }
        //----------gán mặc định--------------------------

      }
     
      this.fn_fixcot();
    }
    else {

      $('#loading').show();
      this.repo.postRequest("api/dathang/get_thongtin/", { Id: id, NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid, UserName: crru.user_name, DonViId: this.donviid })
        .subscribe(response => {
          //console.log(response);
          $('#loading').hide();

          this.chungtuSelect = response;
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.chungtuSelect.Phan_He_Id = this.phanheid;
          if (this.chungtuSelect.Hang_Hoas.length == 0)
            this.AddRowNew(this.chungtuSelect.Hang_Hoas);

          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;

          if (this.chungtuSelect.Trang_Thai == 2)
            this.tabselected = 2;

          if (response.Ngay_Dat_Hang != null)
            this.chungtuSelect.Ngay_Dat_Hang = new Date(response.Ngay_Dat_Hang);
          
          //if (this.chungtuSelect.Trang_Thai == 0)
          //  $("input:radio[name='a'][value='option0']").prop('checked', true);
          //if (this.chungtuSelect.Trang_Thai == 1)
          //  $("input:radio[name='a'][value='option1']").prop('checked', true);
          //if (this.chungtuSelect.Trang_Thai == 2)
          //  $("input:radio[name='a'][value='option2']").prop('checked', true);
          //if (this.chungtuSelect.Trang_Thai == 3)
          //  $("input:radio[name='a'][value='option3']").prop('checked', true);
          //if (this.chungtuSelect.Trang_Thai == 4)
          //  $("input:radio[name='a'][value='option4']").prop('checked', true);

          this.tien_hanghoa = 0;
          this.tien_danhsachxe= 0;
          

          this.chungtuSelect.Hang_Hoas.forEach(
            (item) => {
              this.tien_hanghoa = this.tien_hanghoa + (item.So_Tien === null ? 0 : item.So_Tien);
              if (item.Ngay_Giao_Xe != null)
              item.Ngay_Giao_Xe = new Date(item.Ngay_Giao_Xe);
              }
          );
          this.chungtuSelect.Danh_Sach_Xes.forEach(
            (item) => {
              this.tien_danhsachxe = this.tien_danhsachxe + (item.Gia_Nhap === null ? 0 : item.Gia_Nhap);
              if (item.Ngay_Giao_Xe != null)
                item.Ngay_Giao_Xe = new Date(item.Ngay_Giao_Xe);
             }
          );


          this.tongtien2 = 0;


          if (loai === 1) {
            let idcopy = this.chungtuSelect.Id;
            this.chungtuSelect.Id = null;
            this.chungtuSelect.Copy = 1;
            
          }
          else
            this.loading = false;

          if (loai === 1) {
            this.chungtuSelect.Ngay_Dat_Hang = new Date();
            this.ngaydathang = new Date();
          }
          else {
            this.ngaydathang = new Date(this.chungtuSelect.Ngay_Dat_Hang);
          }
          this.fn_fixcot();

        });

    }


    //setTimeout(() => {
    //  this.slKhachHang.focus();
    //}, 1000);

    // this.fixcot = 0;


  }
  fn_fixcot() {
    //console.log("độ dài: ");
    //console.log(this.chungtuSelect.Hang_Hoas.length);


    if (this.chungtuSelect.Hang_Hoas.length <= 50) {
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
      //(window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_02');
    }
    else
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
  }


  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }

    

    

   


  }


  onDuyet(row: any) {
    this.chungtuSelect.Trang_Thai = row.Id;
    if (this.chungtuSelect.Trang_Thai == 2 && this.chungtuSelect.So_Don_Hang!=null) {
      $('#loading').show();
      this.repo.postRequest("api/dathang/radio_EditValueChanged", this.chungtuSelect)
        .subscribe(response => {
          //console.log(response);
          $('#loading').hide();
          if (response.Status === "OK") {
            this.chungtuSelect.Danh_Sach_Xes = response.Data.Danh_Sach_Xes;
            this.tabselected = 2;
            this.chungtuSelect.DisableStatus = true;
           }
          else
            swal('Cảnh báo!', response.Status, 'warning');
        });
    }
    //console.log(loai);
  }

  LoadCategory(): void {
    
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_khohang.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputkhohangs = response;
    //  });
    
    this.repo.postRequest("api/doituong/gethangxe/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputhangxes = response;
      });
    this.repo.postRequest("api/doituong/getdacta/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputdactas = response;
      });


    this.repo.postRequest("api/doituong/getxuatxu/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputxuatxus = response;
      });


    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputmodels = response;
      });



    this.repo.postRequest("api/doituong/getmausac/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputmausacs = response;
      });


  }

  @ViewChildren("myinput") divs: QueryList<ElementRef>;

  
 


  selectedTab(tab: number) {
    this.tabselected = tab;
    setTimeout(() => {
      this.setComboFocus(0, 1, tab);
    }, 500);
  }
  //----------event xử lý di chuyển Enter--------------------------------------
  setComboFocus(hang: number, cot: number, luoi: number) {
    var tencombo: string = hang + '_' + cot + '_' + luoi;
    var ketqua = false;
    this.slcombo.forEach(
      (question, i, allQuestions) => {
        if (question.labelForId === tencombo) {
          ketqua = true;
          question.focus();
        }
      }
    );

    if (ketqua === false) {
      this.divs.forEach(
        (div) => {

          if (div.nativeElement.id === tencombo) {
            ketqua = true;
            div.nativeElement.focus();
          }

        }
      );
    }
  }
  keyLyDo(event: any) {
    if (event.which === 13) {
      //this.slTaiKhoanNo.focus();
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  keyTKNoPress(event: any) {
    if (event.which === 13) {
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  maxcot: number = 14;

  keyComBoPress(event: any, hang: number, cot: number, luoi: number) {

    if (1 === 1) {
      hang = hang + 1;
      //console.log("key tai " + event.keyCode);
      if (event.keyCode === 40) {
        // console.log("key tai 40");
        if (luoi === 2) {
          if (hang === this.chungtuSelect.Danh_Sach_Xes.length) {
            this.AddRowNewHD(this.chungtuSelect.Danh_Sach_Xes);
            if (this.chungtuSelect.Danh_Sach_Xes.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            setTimeout(() => {
              this.setComboFocus(hang - 1, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          //console.log("key tai lưới 1");
          if (hang === this.chungtuSelect.Hang_Hoas.length) {
            this.AddRowNew(this.chungtuSelect.Hang_Hoas);
            if (this.chungtuSelect.Hang_Hoas.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            this.setComboFocus(hang, cot, luoi);
            event.preventDefault();
          }
        }

      }

      if (event.keyCode === 38 && hang > 0) {
        //console.log("key tai 38");
        this.setComboFocus(hang - 2, cot, luoi);
        event.preventDefault();
      }

      //if (event.keyCode === 37 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot-1, luoi);
      //  event.preventDefault();
      //}
      //if (event.keyCode === 39 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot + 1, luoi);
      //  event.preventDefault();
      //}

      return;
    }

    if (luoi === 2)
      this.maxcot = 9;
    if (luoi === 1)
      this.maxcot = 17;
    if (luoi === 3)
      this.maxcot = 17;
    if (event.which === 13) {
      cot = cot + 1;

      if (cot > this.maxcot) {
        hang = hang + 1;
        cot = 1;

        if (luoi === 2) {
          if (hang === this.chungtuSelect.Danh_Sach_Xes.length) {
            this.AddRowNewHD(this.chungtuSelect.Danh_Sach_Xes);
            if (this.chungtuSelect.Danh_Sach_Xes.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          if (hang === this.chungtuSelect.Hang_Hoas.length) {
            this.AddRowNew(this.chungtuSelect.Hang_Hoas);
            if (this.chungtuSelect.Hang_Hoas.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
      }
      this.setComboFocus(hang, cot, luoi);
      event.preventDefault();
    }
  }
  RowNewClick() {
    if (this.tabselected === 2) {
      this.AddRowNewHD(this.chungtuSelect.Danh_Sach_Xes);
      if (this.chungtuSelect.Danh_Sach_Xes.length <= 50) {
        (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
      }
    }
    else {
      this.AddRowNew(this.chungtuSelect.Hang_Hoas);
      if (this.chungtuSelect.Hang_Hoas.length <= 50) {
        (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

      }
    }
  }

  AddRowNew(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    let dichvu = null;
    if (this.inputloaidichvus != null && this.inputloaidichvus.length > 0)
      dichvu = this.inputloaidichvus[0].Id;

    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Model_Xe_Id: row.Model_Xe_Id, Dac_Ta_Id: row.Dac_Ta_Id, Mau_Xe_Id: row.Mau_Xe_Id
        , So_Luong: null, Don_Gia: null, So_Tien: null
        , Ngay_Giao_Xe: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });
    }
    else {
      ctiets.push({
        showpopup: sp, Model_Xe_Id: null, Dac_Ta_Id: null, Mau_Xe_Id: null
        , So_Luong: 1
        , Don_Gia: null, So_Tien: null, Ngay_Giao_Xe: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });

    }

    // this.chungtuSelect.Hang_Hoas = ctiets;

  }

  AddRowNewHD(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    let dichvu = null;
    if (this.inputloaidichvus != null && this.inputloaidichvus.length > 0)
      dichvu = this.inputloaidichvus[0].Id;
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null, Nam_San_Xuat: null, Xuat_Xu_Id: null
        , Dac_Ta_Id: null, Model_Xe_Id: null
        , Mau_Xe_Id: null, Gia_Nhap: null, So_Khung: null, So_May: null, Ngay_Giao_Xe: null
        , Created: null, CreatedBy: null, Modified: null, ModifiedBy: null
      });
    }
    else {
      ctiets.push({
        showpopup: sp, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null, Nam_San_Xuat: null, Xuat_Xu_Id: null
        , Dac_Ta_Id: null, Model_Xe_Id: null
        , Mau_Xe_Id: null, Gia_Nhap: null, So_Khung: null, So_May: null, Ngay_Giao_Xe: null
        , Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });

    }

    //this.chungtuSelect.Danh_Sach_Xes = ctiets;

  }

  //----------event các control------------------------------------------------------

 
  
  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;
  cotSelected: number;

 
  loaithem: number = 2;
  onLoaiNguoiNop(loai: number) {
    this.loaithem = loai;
    //console.log(loai);
  }
  AddDoRong(tieude: string) {
    this.mdLuaChonDoRong.open();
  }
  AddFormDoRong() {
    this.mdLuaChonDoRong.close();
  }

 

  
  saveModal(doituong: any) {
    //console.log(doituong);
    this.mdDoiTuongChung.close();

    //if (doituong != null && Lists_doituongtaphop2.id === doituong.Nhom_Doi_Tuong_ID) {

    //  this.inputtaphops2.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    //  this.inputtaphops2 = this.inputtaphops2.slice();
    //  this.rowSelected.Doi_Tuong_Id_4 = doituong.Id;
    //}

    if (doituong != null && this.nhomnguonid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhoanmucs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhoanmucs = this.inputkhoanmucs.slice();
      if (this.cotSelected == 17)
        this.rowSelected.Khoan_Muc_Id_No = doituong.Id;
      if (this.cotSelected == 23)
        this.rowSelected.Khoan_Muc_Id_Co = doituong.Id;
    }
    if (doituong != null && this.khohangid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhohangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhohangs = this.inputkhohangs.slice();
      if (this.cotSelected == 5)
        this.rowSelected.Kho_Hang_Id_No = doituong.Id;
      if (this.cotSelected == 8)
        this.rowSelected.Kho_Hang_Id_Co = doituong.Id;
    }
    if (doituong != null && this.donvitinhid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputdonvitinhs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputdonvitinhs = this.inputdonvitinhs.slice();
      if (this.cotSelected == 6)
        this.rowSelected.Don_Vi_Tinh_Id_No = doituong.Id;
      if (this.cotSelected == 9)
        this.rowSelected.Don_Vi_Tinh_Id_Co = doituong.Id;
    }


    this.comboSeleted.focus();
  }

  indexdt: number;
  indexchon: number;
  cotdt: string;
  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Phu_Tung_Id") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_vattu.id);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Dich_Vu_Id") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_dichvu.id);
      this.mdChonDoiTuongChung.open();
    }

  }


  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    this.indexchon = index;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit(this.phanheid, Lists_xesua.id);
      this.mdChonDoiTuongChung.open();
    }
    if (index == 2 || index == 3) {
      this.dtechondoituongchungedit.loadDataInit(this.phanheid, Lists_doituong.id);
      this.mdChonDoiTuongChung.open();
    }


  }
  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.indexchon == 1) {
                this.chungtuSelect.Xe_Id = item.Id;
                this.chungtuSelect.Bien_So = item.Ma;

                this.repo.postRequest("api/baogia/thaydoi_bienso/", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: this.phanheid, NamKeToan: this.namketoan, UserName: this.currentUser.user_name })
                  .subscribe(response => {
                    if (response != null) {
                      this.chungtuSelect.Khach_Hang_Id = response.Khach_Hang_Id;
                      this.chungtuSelect.Khach_Hang = response.Khach_Hang;
                      this.chungtuSelect.Hang_Xe_Id = response.Hang_Xe_Id;
                      this.chungtuSelect.Model_Xe_Id = response.Model_Xe_Id;
                      this.chungtuSelect.Mau_Xe = response.Mau_Xe;
                      this.chungtuSelect.So_Khung = response.So_Khung;
                      this.chungtuSelect.So_May = response.So_May;
                      this.chungtuSelect.Dien_Thoai = response.Dien_Thoai;
                      this.chungtuSelect.MST = response.MST;
                      this.chungtuSelect.Hang_Bao_Hiem_Id = response.Hang_Bao_Hiem_Id;
                    }

                  });

              }
              if (this.indexchon == 2) {
                this.chungtuSelect.Khach_Hang_Id = item.Id;
                this.chungtuSelect.Khach_Hang = item.Ten;

                this.repo.postRequest("api/baogia/thaydoi_khachhang/", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: this.phanheid, NamKeToan: this.namketoan, UserName: this.currentUser.user_name })
                  .subscribe(response => {
                    if (response != null) {
                      this.chungtuSelect.Nguoi_Su_Dung = response.Nguoi_Su_Dung;
                      this.chungtuSelect.Dia_Chi = response.Dia_Chi;
                      this.chungtuSelect.Dien_Thoai = response.Dien_Thoai;
                      this.chungtuSelect.MST = response.MST;
                    }
                  });

              }
              if (this.indexchon == 3) {
                this.chungtuSelect.Phieu_Vao_Xuong = item.Id;
                this.chungtuSelect.Nhan_No = item.Ten;
              }

              this.mdChonDoiTuongChung.close();
            }
            i = i + 1;

          }
        );

      }
      else {

        if (doituong == null) {

        }
        else {
          if (this.indexchon == 1) {
            this.chungtuSelect.Xe_Id = null;
            this.chungtuSelect.Bien_So = null;
          }
          if (this.indexchon == 2) {
            this.chungtuSelect.Khach_Hang_Id = null;
            this.chungtuSelect.Khach_Hang = null;
          }
          if (this.indexchon == 3) {
            this.chungtuSelect.Phieu_Vao_Xuong = null;
            this.chungtuSelect.Nhan_No = null;
          }

        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.chungtuSelect.Hang_Hoas == null || this.chungtuSelect.Hang_Hoas == "undefined") {
              }
              else {
                if (this.cotdt == "Dich_Vu_Id") {
                  this.chungtuSelect.Hang_Hoas[this.indexdt].Phu_Tung_Id = item.Id;
                  this.chungtuSelect.Hang_Hoas[this.indexdt].Phu_Tung = item.Ten;
                }
                if (this.cotdt == "Phu_Tung_Id") {
                  this.chungtuSelect.Danh_Sach_Xes[this.indexdt].Phu_Tung_Id = item.Id;
                  this.chungtuSelect.Danh_Sach_Xes[this.indexdt].Phu_Tung = item.Ten;
                }


                this.mdChonDoiTuongChung.close();
              }
            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          if (this.cotdt == "Dich_Vu_Id") {
            this.chungtuSelect.Hang_Hoas[this.indexdt].Phu_Tung_Id = null;
            this.chungtuSelect.Hang_Hoas[this.indexdt].Phu_Tung = null;
          }
          if (this.cotdt == "Phu_Tung_Id") {
            this.chungtuSelect.Danh_Sach_Xes[this.indexdt].Phu_Tung_Id = null;
            this.chungtuSelect.Danh_Sach_Xes[this.indexdt].Phu_Tung = null;
          }

        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }


  deleterow(index: string, luoi: number) {
    //console.log(index);
    swal({
      title: 'Bạn muốn xóa dòng này phải không?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          if (luoi === 2)
            this.chungtuSelect.Danh_Sach_Xes.splice(index, 1);
          else
            this.chungtuSelect.Hang_Hoas.splice(index, 1);

          this.changeST(null);
          this.changeST2(null);
        }
      });


  }
  public savectu(loai: number) {


    //if (this.namketoan !== this.ngaylap.getFullYear()) {
    //  swal('Cảnh báo!', "Ngày hạch toán nằm ngoài năm kế toán!!!", 'warning');
    //  return;
    //}
    if (this.chungtuSelect.So_Don_Hang === null || this.chungtuSelect.So_Don_Hang === '') {
      swal('Cảnh báo!', "Bạn chưa nhập số đơn hàng", 'warning');
      return;
    }
    if (this.chungtuSelect.Ngay_Dat_Hang === null) {
      swal('Cảnh báo!', "Bạn chưa nhập ngày đặt hàng", 'warning');
      return;
    }
    if (this.chungtuSelect.Thang_Du_Kien === null) {
      swal('Cảnh báo!', "Bạn chưa nhập tháng dự kiến nhận hàng", 'warning');
      return;
    }
    if (this.chungtuSelect.Nam_Du_Kien === null) {
      swal('Cảnh báo!', "Bạn chưa nhập năm dự kiến nhận hàng", 'warning');
      return;
    }



    //for (let i = 0; i < this.chungtuSelect.Hang_Hoas.length; i++) {
    //  var row = this.chungtuSelect.Hang_Hoas[i];
    //  if (!this.datecheck(row.NgayCTG)) {
    //    swal('Cảnh báo!', "Ngày chứng từ gốc không hợp lệ", 'warning');
    //    this.setComboFocus(i, 4, 1);
    //    return;
    //  }
    //}
    //console.log("luu chung tu");
    //console.log(this.namketoan);
    //console.log(this.chungtuSelect.Ngay_Lap.getFullYear());
    //console.log(this.chungtuSelect.Ngay_Lap);
    $('#loading').show();
    this.repo.postRequest("api/dathang/addorupdate", this.chungtuSelect)
      .subscribe(response => {
        //console.log(response);
        $('#loading').hide();
        if (response.Status === "OK") {
          this.chungtuSelect.Id = response.Data.Id;
          this.chungtuSelect.Hang_Hoas = response.Data.Hang_Hoas;
          this.chungtuSelect.Danh_Sach_Xes = response.Data.Danh_Sach_Xes;
          this.savechungtu.emit(this.chungtuSelect);
        }
        else
          swal('Cảnh báo!', response.Status, 'warning');
      });



  }
  closeModal() {
    this.savechungtu.emit(null);
  }
  //-------------ham tiện ích---------------------------------------
  public changeTaiKhoan(event: any, noco: number, row: any, index: number) {
    let roW1;
    //console.log("changeTaiKhoan tt");
    //console.log(event);
    //console.log(this.inputtaikhoans);
    if (typeof event === "undefined") {

    }
    else {
      if (noco == 1) {
        this.chungtuSelect.Hang_Hoas[index].Tai_Khoan_No_Id = event;
        //this.chungtuSelect.Hang_Hoas[index].Tai_Khoan_No = event.Ma_Tai_Khoan;

      }
      else {

        this.chungtuSelect.Hang_Hoas[index].Tai_Khoan_Co_Id = event;
        if (event == ("C4B629A0-00E5-4A30-AED9-DADC21C9AE68").toLowerCase()) {
          this.chungtuSelect.Hang_Hoas[index].Doi_Tuong_Id_Co = this.chungtuSelect.Doi_Tuong_Id;
          this.chungtuSelect.Hang_Hoas[index].Doi_Tuong_Co = this.chungtuSelect.Ma_Doi_Tuong;
        }
        //this.chungtuSelect.Hang_Hoas[index].Tai_Khoan_Co = event.Ma_Tai_Khoan;
      }
      // this.chungtuSelect.Hang_Hoas[index] = roW1;
      //console.log("cau hinh tt");
      //console.log(this.chungtuSelect.Hang_Hoas);

      this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            //console.log("cau hinh tt");
            //console.log(response);
            //console.log(this.HienThis);
            this.HienThis = response.Data;
          }
        });
    }

  }
  
  public changeLuoi(drow: any) {
    this.thaydoi = true;
  }
  tongtien: number = 0;
  
  
  
  public changeSL(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong;
    //if (this.HienThis.Hang_Hoa_Id_Co == true)
    // this.layGiaHang(drow);
  }
  public changeDG(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong;
    this.changeST(drow);
  }
  public changeST(drow: any) {
    this.thaydoi = true;
    this.tien_hanghoa = 0;
    this.tien_danhsachxe = 0;
    

    this.chungtuSelect.Hang_Hoas.forEach(
      (item) => {
        //console.log(item.So_Tien);
        //this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tien_hanghoa = this.tien_hanghoa + (item.So_Tien === null ? 0 : item.So_Tien)

      }
    );
    this.chungtuSelect.Danh_Sach_Xes.forEach(
      (item) => {

        this.tien_danhsachxe = this.tien_danhsachxe + (item.Gia_Nhap === null ? 0 : item.Gia_Nhap)


      }
    );



  }
  datecheck(conformedValue) {
    if (conformedValue == null || conformedValue === "")
      return true;
    const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS']
    var dateFormat: String = 'dd/mm/yyyy';
    var maxYear: number = 9999
    var minYear: number = 1;
    const dateFormatArray = dateFormat.split(/[^dmyHMS]+/).sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b))

    const indexesOfPipedChars = []
    const maxValue = { 'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59 }
    const minValue = { 'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0 }
    const conformedValueArr = conformedValue.split('')

    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format)
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10)

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position]
        conformedValueArr[position] = 0
        indexesOfPipedChars.push(position)
      }
    })

    // Check for invalid date
    let month = 0
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)
      if (format === 'mm') {
        month = value || 0
      }
      const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format]
      if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
        const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10)
        const scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10)
        return value < scopedMinValue || value > scopedMaxValue
      }
      return value > maxValueForFormat || (textValue.length === length && value < minValue[format])
    })

    if (isInvalid) {
      return false
    }

    return true;
  }

  export(kieuin, loaiphieu) {
    //loaiphieu=1 là báo giá tổng thể
    //loaiphieu=2 là báo giá sửa chữa
    //loaiphieu=3 là báo giá bảo hiểm
    //loaiphieu=4 là báo giá bảo hành
    //loaiphieu=5 là lệnh sửa chữa
    //loaiphieu=6 là Yêu cầu xuất kho
    //loaiphieu=7 là Biên bản nghiệm thu bàn giao xe
    //loaiphieu=8 là 
    //loaiphieu=9 là báo giá phụ tùng in ở phân hệ báo giá phụ tùng
    //loaiphieu=10 là yêu cầu xuất kho in ở phân hệ yêu cầu xuất kho
    //loaiphieu=11 là báo giá tổng thể dịch vụ ở phân hệ báo giá dịch vụ (in báo giá + quyết toán + phiếu ra cổng)
    let idchungtu = "";
    idchungtu = this.chungtuSelect.Id;
    if (loaiphieu == null || loaiphieu == "") {
      swal('Cảnh báo!', 'Chưa chọn loại phiếu để in!.', 'warning');
      return;
    }
    if (idchungtu == null || idchungtu == "") {
      swal('Cảnh báo!', 'Chưa chọn phiếu để in!.', 'warning');
      return;
    }

    let tid = null;
    if (loaiphieu >= 1 && loaiphieu < 5)
      tid = "AC30DAD3-EF3C-4BB5-8281-F33484525ABD";

    if (loaiphieu == 5)
      tid = "728D225E-E4D3-4094-95D2-9A7A2348F852";
    if (loaiphieu == 6)
      tid = "49A10B8E-F74C-42D9-BD55-740AEC99A785";
    if (loaiphieu == 7)
      tid = "26E59FC5-3B0B-4ED8-A869-E5768A3DB944";
    if (loaiphieu == 9)
      tid = "DA1427C5-5C1F-4065-BD9C-A785F45C6B68";
    if (loaiphieu == 11)
      tid = "5A196D5A-F21A-4003-A061-8281D780D4DA";


    $('#loading').show();
    let data = {
      Id: tid,
      Type: kieuin,
      Data: JSON.stringify({
        DonViId: this.currentUser.id_don_vi
        , UserName: this.currentUser.user_name
        , Id: idchungtu
        , LoaiPhieu: loaiphieu
        , DoanhNghiepId: this.doanhnghiepid

      })
    };
    if (kieuin === 'xlsx') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          var file = new Blob([response.body], { type: 'vnd.ms-excel' });
          var fileURL = URL.createObjectURL(file);
          $('#loading').hide();
          var a = document.createElement("a");
          a.href = fileURL;
          a.download = "bao_cao.xlsx";
          document.body.appendChild(a);
          a.click();

        });
    }
    if (kieuin === 'pdf') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          if (response == "L") {
            this.repo.postRequest(Global.BASE_BaoCao_SAVE, data).subscribe(
              data => {
                $('#loading').hide();
              },
              error => {
                this.msg = error;
              }
            );
          }
          else {
            $('#loading').hide();
            var file = new Blob([response.body], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "EPrescription");
          }

        });
    }




  }



}
export interface Idata {
  Id: string,
  Ten: string,
  Dien_Thoai: string,
  Dia_Chi: string,
  Email: string,
  GhiChu: string,
  Created: Date,
  CreatedBy: string,
  Modified: Date,
  ModifiedBy: string
}
