import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  Lists, Lists_phongban, Lists_khohang, Lists_donvitinh, Lists_khachhang, Lists_nhacungcap
  , Lists_nhanvien, Lists_madonvi, Lists_hopdong, Lists_hopdongban, Lists_xe, Lists_doituong } from '../../../routes/menu'
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmucchungedit',
  templateUrl: './danhmucchungedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmucchungedit.component.scss'],
    providers: [ListService, Repository]
})


export class danhmucchungeditComponent implements OnInit {
  doanhnghiepid: string = null;
   
    
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    ,Doanh_Nghiep_Id: null
  };       
    nhomdtid:string=null;
    hoatdong:string=null;

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();

  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;

  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata : any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputparents = response;
      });
    

    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid
    };    
    if (id === null)
    {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
              this.doituongSelect.Ma_Doi_Tuong = response;
            });
          
          this.doituongSelect.Trang_Thai = true;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = new Date();
          this.doituongSelect.CreatedBy = this.currentUser.user_name;
          this.doituongSelect.Modified = new Date();
        this.doituongSelect.ModifiedBy = this.currentUser.user_name;
        if (rowdata != null) {
          this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
          this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
          this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
          this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
          this.doituongSelect.Parent = rowdata.Parent;
        }
          
    }
        else {
      console.log("t11");
      this.repo.postRequest("api/doituong/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
                console.log(response);
              this.doituongSelect.Id = id;
              this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
              this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
              this.doituongSelect.Trang_Thai = response.Trang_Thai;
              this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
              this.doituongSelect.Parent = response.Parent;
              this.doituongSelect.Ma_Ao = response.Ma_Ao;
              this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
              this.doituongSelect.Created = response.Created;
              this.doituongSelect.CreatedBy = response.CreatedBy;
              this.doituongSelect.Modified = new Date();
              this.doituongSelect.ModifiedBy = this.currentUser.user_name;
              

              this.doituongSelect.SapXep = response.SapXep;
            }); 
        }
        
       
    }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
      if (this.doituongSelect.Ten_Doi_Tuong == null || this.doituongSelect.Ten_Doi_Tuong == "") {
        swal('Lỗi!', 'Vui lòng nhập tên đối tượng!.', 'error');
        return;
      }
      $('#loading').show();
        this.repo.postRequest("api/doituong/addorupdate",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('-')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
    }
  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }
  indexdt: number;
  cotdt: string;
  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_khachhang.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (index == 2) {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_nhacungcap.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
  }

  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Hang_Hoa_Id") {
      this.dtechondoituongchungedit.loadDataInit(this.nhomdtid, Lists_xe.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }


  }

  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              //this.doituongSelect.Doi_Tuong_Id = item.Id;
              //this.doituongSelect.Doi_Tuong = item.Ma + ' - ' + item.Ten;
            }
            i = i + 1;

          }
        );
        this.mdChonDoiTuongChung.close();
      }
      else {
        // console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          //this.doituongSelect.Doi_Tuong_Id = null;
          //this.doituongSelect.Doi_Tuong = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.cotdt == "Hang_Hoa_Id") {
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = item.Id;
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = item.Ma;
              }
              this.mdChonDoiTuongChung.close();

            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = null;
          this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }
    
   

   


}
