<div class="dmphanhe tab-container">
  <div class="tab-content">
    <div class="row form-group">
      <label class="control-label col-2">Mã/Tên phân hệ</label>
      <div class="col-2">
        <input type="text"  tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Ma_Phan_He">
      </div>
      <div class="col-2">
        <input type="text" tabIndex="2" (keydown)="keyTenPress($event)" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Ten_Phan_He">
      </div>
      <label class="control-label col-2">Cấp trên</label>
      <div class="col-4">
        <ng-select #slCapTren
                   class="twcol"
                   autofocus
                   style="height:25px !important"
                   [tabIndex]="3"
                   [openOnEnter]="false"
                   [items]="doituongSelect.CapTrens"
                   bindValue="Id"
                   bindLabel="Ten_Phan_He"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn_PH"
                   (keydown)="keySoTKPress($event)"
                   [(ngModel)]="doituongSelect.ChiTiet.Parent">
          <!--<ng-template ng-footer-tmp>
            <a (click)="AddDoiTuong(slTaiKhoanNH)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
          </ng-template>-->
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Phan_He}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Phan_He}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Chú thích mã</label>
      <div class="col-4">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Chu_Thich_Ma">
      </div>
      <label class="control-label col-2">Chú thích tên</label>
      <div class="col-4">
        <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Chu_Thich_Ten">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Giao diện</label>
      <div class="col-4">
        <ng-select #slGiaoDien
                   class="twcol"
                   autofocus
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="doituongSelect.GiaoDiens"
                   bindValue="Ma_Giao_Dien"
                   bindLabel="Ten_Giao_Dien"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn_GD"
                   (keydown)="keySoTKPress($event)"
                   [(ngModel)]="doituongSelect.ChiTiet.Ma_Giao_Dien">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Giao_Dien}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Giao_Dien}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2">Hiển thị</label>
      <div class="col-4">
        <ng-select #slHienThi
                   class="twcol"
                   autofocus
                   [tabIndex]="8"
                   [openOnEnter]="false"
                   [items]="inputhienthis"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   (keydown)="keySoTKPress($event)"
                   [(ngModel)]="doituongSelect.ChiTiet.Loai">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>

      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Thứ tự</label>
      <div class="col-4">
        <input id="giatrikhauhao" #myinput class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="doituongSelect.ChiTiet.Thu_Tu"
               name="value"
               tabIndex="13"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>

      <!--<label class="control-label col-2">Hiển thị số lượng</label>
      <div class="col-1">
        <div class="checkbox c-checkbox">
          <label>
            <input tabIndex="21" type="checkbox" [(ngModel)]="doituongSelect.ChiTiet.Is_So_Luong" />
            <span class="fa fa-check"></span>
          </label>
        </div>
      </div>
      <label class="control-label col-2">Đối tượng thống kê</label>
      <div class="col-1">
        <div class="checkbox c-checkbox">
          <label>
            <input tabIndex="21" type="checkbox" [(ngModel)]="doituongSelect.ChiTiet.Is_Thong_Ke" />
            <span class="fa fa-check"></span>
          </label>
        </div>

      </div>-->


    </div>
    <div class="row">
      <div class="col-6">
        <span style="font-weight:bold">Danh sách tài khoản</span>
        <table id="fixTable1" class="table table-striped table-bordered table-hover table-condensed">
          <thead class="theadcss">
            <tr>
              <th *ngFor="let ut of doituongSelect.DataTieuDeTK" [ngStyle]="{'width': ut.Width}" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">{{ut.FullName}}</th>

            </tr>
            <tr>
              <td *ngFor="let ut of doituongSelect.DataTieuDeTK" [ngClass]="{'AnCot': !ut.HienThi}">
                <input *ngIf="ut.type!='bool'" class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text" [disabled]="ut.Type==='bool' ? 'true': 'false'"
                       [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearchTK(ut.TimKiem)">
              </td>
            </tr>
          </thead>
          <tbody style="max-height:250px">

            <tr *ngFor="let u of doituongSelect.TaiKhoans">
              <td *ngFor="let u1 of doituongSelect.DataTieuDeTK" [ngStyle]="{'width': u1.Width}" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal'}">
                <span style="padding-right:5px;" *ngIf="u1.Type==='decimal'">{{u[u1.ColumnName] | number}}</span>
                <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
                <span *ngIf="u1.Type==='bool'"><input type="checkbox" (ngModelChange)="TichTK(u)" [(ngModel)]="u[u1.ColumnName]" /></span>
                <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <span style="font-weight:bold">Danh sách phân hệ</span>
        <table id="fixTable2" class="table table-striped table-bordered table-hover table-condensed">
          <thead class="theadcss">
            <tr>
              <th *ngFor="let ut of doituongSelect.DataTieuDePH" [ngStyle]="{'width': ut.Width}" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">{{ut.FullName}}</th>

            </tr>
            <tr>
              <td *ngFor="let ut of doituongSelect.DataTieuDePH" [ngClass]="{'AnCot': !ut.HienThi}">
                <input class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text" [disabled]="ut.Type==='bool' ? 'true': 'false'"
                       [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearchPH(ut.TimKiem)">
              </td>
            </tr>
          </thead>
          <tbody style="max-height:250px">

            <tr *ngFor="let u of doituongSelect.PhanHes">
              <td *ngFor="let u1 of doituongSelect.DataTieuDePH" [ngStyle]="{'width': u1.Width}" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal'}">
                <span style="padding-right:5px;" *ngIf="u1.Type==='decimal'">{{u[u1.ColumnName] | number}}</span>
                <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
                <span *ngIf="u1.Type==='bool'"><input type="checkbox" (ngModelChange)="TichPH(u)" [(ngModel)]="u[u1.ColumnName]" /></span>
                <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <!--<div class="col-4">
        <span style="font-weight:bold">Danh sách chi tiết</span>
        <table id="fixTable2" class="table table-striped table-bordered table-hover table-condensed">
          <thead class="theadcss">
            <tr>
              <th *ngFor="let ut of doituongSelect.DataTieuDePH" [ngStyle]="{'width': ut.Width}" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">{{ut.FullName}}</th>
              <th>Hiển thị</th>
            </tr>
            <tr>
              <td *ngFor="let ut of doituongSelect.DataTieuDeCot" [ngClass]="{'AnCot': !ut.HienThi}">
                <input class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text" [disabled]="ut.Type==='bool' ? 'true': 'false'"
                       [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearchCot(ut.TimKiem)">
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody style="max-height:250px">

            <tr *ngFor="let u of doituongSelect.Cots">
              <td *ngFor="let u1 of doituongSelect.DataTieuDePH" [ngStyle]="{'width': u1.Width}" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal'}">
                <span style="padding-right:5px;" *ngIf="u1.Type==='decimal'">{{u[u1.ColumnName] | number}}</span>
                <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
                <span *ngIf="u1.Type==='bool'"><input type="checkbox" (ngModelChange)="TichCot(u)" [(ngModel)]="u[u1.ColumnName]" /></span>
                <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

              </td>
              <td>
                <ng-select #slGiaoDien
                           autofocus
                           [openOnEnter]="false"
                           [items]="inputluoi"
                           bindValue="Id"
                           bindLabel="Name"
                           notFoundText="Không có dữ liệu"
                           [(ngModel)]="u.Loai_Giao_Dien">
                </ng-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>-->

    </div>

  </div>






  <div class="modal-footer" style="display: flex;justify-content: space-between;">
    <div class="">

    </div>
    <div class="">
      <button class="mb-1 mr-1 btn btn-secondary" tabindex="22" (click)="closeModal()" title="Ấn ESC để thoát">Hủy bỏ</button>
      <button *ngIf="!(quyen_sua==false && doituongSelect.ChiTiet.Id!=null)" tabindex="23" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary">Lưu lại</button>
    </div>

  </div>

  <!--<modal #mdDoiTuongChung [cssClass]="'modal6'" [backdrop]="'static'">
    <modal-header [show-close]="true">
      <h4 class="modal-title">Thêm mới</h4>
    </modal-header>
    <modal-body>
      <app-dm-nganhangedit #dteChung (save)="saveModal($event)"></app-dm-nganhangedit>
    </modal-body>
  </modal>-->


</div>
