import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DBOperation } from '../../../shared/enum';
import { Global, BaoCao } from '../../../shared/global';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Repository } from '../../../services/repository'
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { CurrencyMaskInputMode } from 'ngx-currency'
import 'jquery'
import 'bootstrap'

import {
   Lists_nhacungcap,  Lists_nganhang,
  Lists_taisancodinh, Lists_congcu, Lists_vattu, Lists_nhanvien, Lists_khachhang, Lists_hanghoa
  , Lists_khohang, Lists_donvitinh, Lists_lenhsuachua, Lists_khoanmuc, Lists_madonvi, Lists_hopdong, Lists_doituong
  , Lists_taisan,
  Products_nhapkho,
  Lists_hopdongmua
} from '../../../routes/menu'
import { load } from '@telerik/kendo-intl';


const swal = require('sweetalert');
@Component({
  selector: 'app-ketoan-muahangchuahdedit',
  templateUrl: './muahangchuahdedit.component.html',
  styleUrls: ['./muahangchuahdedit.component.scss'],
  providers: [ListService, Repository],
  encapsulation: ViewEncapsulation.None
})


export class muahangchuahdeditComponent implements OnInit {
  CoBan: any = {
    CheDoKeToan: null, PhuongPhapTinhGia: null
    , SoLuong: null, DonGia: null, NgoaiTe: null, SoTien: null
  }
  HienThis: any = {
    Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
    , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
    , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
    , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
    , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
    , Ty_Gia: false, Ngoai_Te: false
  };
  DoRongs: any = {
    Dien_Giai_Chi_Tiet: 250, Hang_Hoa_Id_No: 130, Kho_Hang_Id_No: 130, Don_Vi_Tinh_Id_No: 130
    , Hang_Hoa_Id_Co: 130, Kho_Hang_Id_Co: 130, Don_Vi_Tinh_Id_Co: 130
    , Doi_Tuong_Id_No: 130, Hop_Dong_Id_No: 150
    , Tai_San_Id_No: 150, Lenh_Sua_Chua_Id_No: 150, Khoan_Muc_Id_No: 130, Doi_Tuong_Id_1_No: 120
    , Doi_Tuong_Id_Co: 130, Hop_Dong_Id_Co: 150, Tai_San_Id_Co: 150, Lenh_Sua_Chua_Id_Co: 150
    , Khoan_Muc_Id_Co: 130, Doi_Tuong_Id_1_Co: 120, So_Luong: 150, Don_Gia: 150, So_Tien: 150
    , Ty_Gia: 150, Ngoai_Te: 150
  };
  currentUser: any;
  HienThi_LapPhieuChi: boolean = false;
  doanhnghiepid: string = null;
  thaydoi: boolean = false;
  dorong: any = '250';
  fixcot: number = 0;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  quyen_themdoituong: boolean = false;
  quyen_themnguon: boolean = false;
  quyen_themchuong: boolean = false;
  quyen_themkhoan: boolean = false;
  quyen_themtieumuc: boolean = false;
  quyen_themcapphat: boolean = false;
  quyen_themnghiepvu: boolean = false;
  quyen_themnganhang: boolean = false;
  quyen_themctmt: boolean = false;
  quyen_themhoatdong: boolean = false;
  quyen_themthongke: boolean = false;
  quyen_themnhommuchi: boolean = false;
  quyen_themvattuhanghoa: boolean = false;
  quyen_themtaisan: boolean = false;
  quyen_themcongcu: boolean = false;

  quyen_themtaphop: boolean = false;
  quyen_themtaphop2: boolean = false;
  inputtaphops: any;
  inputtaphops2: any;
  
  inputtaisans: any;

  msg: string;

  @ViewChild('mdLuaChonDoRong') mdLuaChonDoRong: BsModalComponent;

  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
  @ViewChild('mdDoiTuongNH') mdDoiTuongNH: BsModalComponent;
  @ViewChild('mdCanBo') mdCanBo: BsModalComponent;
  @ViewChild('mdKhachHang') mdKhachhang: BsModalComponent;
  @ViewChild('mdLuaChon') mdLuaChon: BsModalComponent;
  @ViewChild('slKhachHang') slKhachHang: BsModalComponent;
  @ViewChild('slTaiKhoanNo') slTaiKhoanNo: BsModalComponent;
  @ViewChildren(NgSelectComponent) slcombo: QueryList<NgSelectComponent>
  @ViewChild('dteChung') doituongchungedit: any;
  @ViewChild('dtenganhang') nganhangedit: any;
  @ViewChild('dteCanBo') canboedit: any;
  @ViewChild('dteKhachHang') khachhangedit: any;
  @ViewChild("diengiaitxt") diengiaitxt: ElementRef;

  @ViewChild('mdTaiSan') mdTaiSan: BsModalComponent;
  @ViewChild('dtetaisan') taisanedit: any;


  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;

 

  datas: Idata[];
  data: Idata;
  indLoading: boolean = false;
  dataFrm: FormGroup;
  dbops: DBOperation;
  modalTitle: string;
  modalBtnTitle: string;

  inputhanghoas: any;
  inputkhohangs: any;
  inputdonvitinhs: any;
  inputhopdongs: any;
  inputlenhsuachuas: any;
  inputkhoanmucs: any;
  inputmadonvis: any;
  inputloaithues: any;

  inputtaikhoans: any;
  inputkhachhangs: any;
  inputnguonkinhphis: any;
  inputdoituongs: any;
  inputchuongs: any;
  inputcaphats: any;
  inputnghiepvus: any;
  inputnganhangs: any;
  inputhoatdongs: any;
  inputcmmts: any;
  inputthongkes: any;
  inputloaikhoans: any;
  inputtieumucs: any;
  inputlienquans: any;
  themdoituongtrenluoi: number;
  public ngaychungtu: Date = new Date();
  public ngayghiso: Date = new Date();
  taikhoanno: any = null;
  tendoituong: any = null;
  tabselected: number = 1;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  @Output() savechungtu: EventEmitter<any> = new EventEmitter();
  isHidden: number = 0;
  public loading = true;
  primaryColour: string = '#5d9cec';
  secondaryColour: string = '#ff902b';
  //----------tham số các nhóm cột------------------
  nhomnguonid: string = Lists_khoanmuc.id;
  nhomchuongid: string = null;
  nhomloaikhoanid: string = null;
  nhomtieumucid: string = null;
  hinhthuccapphatid: string = null;
  nghiepvuid: string = null;
  nganhangid: string = Lists_nganhang.id;
  hoatdongid: string = null;
  cmmtid: string = null;
  mathongke: string = null;
  hanghoaid: string = null;
  khohangid: string = Lists_khohang.id;
  donvitinhid: string = Lists_donvitinh.id;
  hopdongid: string = null;
  lenhsuachuaid: string = null;
  doituongid: string = "532A61EA-D765-844B-8CBF-E610B209E332";
  taisanid: string = Lists_taisan.id;
  taphopid2: any;
  taphopid: any;
  Doi_Tuong_Id: any;
  //-----------------------------------------------------

  chungtuSelect: any = {
    Id: null, Don_Vi_Id: null, Ho_Ten: null, Doi_Tuong_Id: null,Ma_Doi_Tuong: null,
    Phan_He_Id: null, Dien_Giai: null, Dia_Chi: null, Ngay_Chung_Tu: null, Ngay_Ghi_So_S: null, Ngay_Chung_Tu_S: null
    , Ma_Chung_Tu: null, Ngay_Ghi_So: null, Hach_Toan: null, Chung_Tu_Goc: null, Ma_Chung_Tu_1: null, Ma_Chung_Tu_2: null
    , Lien_Quan_Id: null, Ma_Lien_Quan: null, DoanhNghiepId: null, Nguoi_Lap: null, Tong_Tien: null, Ty_Gia: null, Giao_Dich_Id: null
    , ChiTiets: [], HoaDons: [], Gio: null, CreatedBy: null, ModifiedBy: null, Copy: null
    , Tai_Khoan_No_Id: null, Tai_Khoan_Co_Id: null, Ngan_Hang_Id: null, Ngan_Hang: null, Hop_Dong_Id: null
    , Ty_Le: 1.1, Tai_Khoan_Thue_Id: null, Tai_Khoan_Dang_Chuyen_Id: null, Tien_Thue: null, ListID: []

  };
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  tongtien2: number = 0;
  public changeST2(drow: any) {
    this.tongtien2 = 0;
    //this.chungtuSelect.HoaDons.forEach(
    //  (item) => {
    //    this.tongtien2 = this.tongtien2 + (item.So_Tien === null ? 0 : item.So_Tien);
    //  }
    //);
  }

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  public ngxAmOption1s = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 1,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };
  public ngxAmOption2s = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };
  public ngxAmOption3s = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 3,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };

  valuechange_ngaychungtu() {
    this.chungtuSelect.Ngay_Ghi_So = this.chungtuSelect.Ngay_Chung_Tu;
  }
  valuechange_ngayghiso() {

  }
  donviid: string = null;
  namketoan: number;
  username: string = null;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private repo: Repository) {



  }
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if ((event.altKey || event.metaKey) && event.keyCode == 49) {
      this.tabselected = 1;

      setTimeout(() => {
        this.setComboFocus(0, 1, 1);
      }, 500);
    }
    if ((event.altKey || event.metaKey) && event.keyCode == 50) {
      this.tabselected = 2;
      setTimeout(() => {
        this.setComboFocus(0, 1, 2);
      }, 500);
    }
    
    event.stopPropagation();

  }
  phanheid: string = null;
  public innerHeight: any;
  loaiketchuyen: any = null;
  hachtoandongthois: any = [];
  chitietdongthois: any = [];
  //id--ichung tu; loai: đánh dấu trường hợp copy (1: copy, 0: ko)
  loadDataInit(id: string, loai: number, pheid: string, ctu: any, tdonviid: string, lienquanid: string) {
    this.tabselected = 1;
    this.HienThis = {
      Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
      , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
      , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
      , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
      , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
      , Ty_Gia: false, Ngoai_Te: false
    };

    if (id == "0" || id == "1" || id == "2") {
      this.loaiketchuyen = id;
      id = null;
    }

   

    this.chitietdongthois = [];
    this.innerHeight = window.innerHeight - 250;
    this.ngaychungtu = new Date();
    this.ngayghiso = new Date();
    this.tabselected = 1;
    this.phanheid = pheid;
    this.thaydoi = false;
    //----------------------lây thông tin ban đầu

    if (this.phanheid.toLowerCase() == Products_nhapkho.id.toLowerCase())
      this.HienThi_LapPhieuChi = true;
    else
      this.HienThi_LapPhieuChi = false;

    var crru = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = crru;
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
      var giatri = crru.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }

      this.doanhnghiepid = crru.id_doanh_nghiep;

      this.LoadCategory();

      // gán quyền thêm đối tượng
      var giatri1 = crru.ds_quyen.filter(name => name.Id === ('532A61EA-D765-844B-8CBF-E610B209E332').toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themdoituong = true;
        else
          this.quyen_themdoituong = false;
      }
      
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_nganhang.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themnganhang = true;
        else
          this.quyen_themnganhang = false;
      }
      
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_vattu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themvattuhanghoa = true;
        else
          this.quyen_themvattuhanghoa = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_taisancodinh.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themtaisan = true;
        else
          this.quyen_themtaisan = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_congcu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themcongcu = true;
        else
          this.quyen_themcongcu = false;
      }

      
    }

    this.repo.postRequest('api/donvicauhinh/cauhinhchung/', { DoanhNghiepId: this.doanhnghiepid, Id: this.donviid })
      .subscribe(response => {
        this.CoBan.CheDoKeToan = response.CheDoKeToan;
        this.CoBan.PhuongPhapTinhGia = response.PhuongPhapTinhGia;
        this.CoBan.SoLuong = response.SoLuong;
        this.CoBan.DonGia = response.DonGia;
        this.CoBan.NgoaiTe = response.NgoaiTe;
        this.CoBan.SoTien = response.SoTien;
      });

    //--------------------------------------------------------------------
    this.chungtuSelect = {
      Id: null, Don_Vi_Id: null, Ho_Ten: null, Doi_Tuong_Id: null, Ma_Doi_Tuong: null,
      Phan_He_Id: this.phanheid, Dien_Giai: null, Dia_Chi: null, Ngay_Chung_Tu: null, Ngay_Ghi_So_S: null, Ngay_Chung_Tu_S: null
      , Ma_Chung_Tu: null, Ngay_Ghi_So: null, Hach_Toan: true, Chung_Tu_Goc: null, Ma_Chung_Tu_1: null, Ma_Chung_Tu_2: null
      , Lien_Quan_Id: null, Ma_Lien_Quan: null, DoanhNghiepId: this.doanhnghiepid, Nguoi_Lap: null, Tong_Tien: null, Ty_Gia: null, Giao_Dich_Id: null
      , ChiTiets: [], HoaDons: [], Gio: null, CreatedBy: null, ModifiedBy: null, Copy: null
      , Tai_Khoan_No_Id: null, Tai_Khoan_Co_Id: null, Ngan_Hang_Id: null, Ngan_Hang: null, Hop_Dong_Id: null
      , Ty_Le: 1.1, Tai_Khoan_Thue_Id: null, Tai_Khoan_Dang_Chuyen_Id: null, Tien_Thue: null, ListID: []
    };


    if (id === null) {
      if (this.loaiketchuyen == null) {
        if (ctu === null) {
          this.chungtuSelect.Ngay_Chung_Tu = new Date();
          this.chungtuSelect.Ngay_Ghi_So = new Date();
          this.chungtuSelect.Gio = new Date();
          this.chungtuSelect.Don_Vi_Id = this.donviid;
          this.chungtuSelect.Phan_He_Id = this.phanheid;
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.AddRowNew(this.chungtuSelect.ChiTiets);
          this.AddRowNewHD(this.chungtuSelect.HoaDons);

          if (lienquanid != null) {
            $('#loading').show();
            this.repo.postRequest("api/chungtu/get_thongtinchungtuv2/", { Id: id, NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid, UserName: crru.user_name, DonViId: this.donviid, Lien_Quan_Id: lienquanid })
              .subscribe(response => {
                //console.log(response);
                if (response != null) {
                  this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
                  this.chungtuSelect.ChiTiets = response.ChiTiets;
                  //this.chungtuSelect.Lien_Quan_Id = lienquanid;

                  this.chungtuSelect.Doi_Tuong_Id = response.Doi_Tuong_Id;
                  this.chungtuSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
                  this.chungtuSelect.Ho_Ten = response.Ho_Ten;
                  this.chungtuSelect.Dien_Giai = response.Dien_Giai;
                  this.chungtuSelect.Dia_Chi = response.Dia_Chi;

                  this.chungtuSelect.Ngay_Chung_Tu = new Date(response.Ngay_Chung_Tu);
                  this.chungtuSelect.Ngay_Ghi_So = new Date(response.Ngay_Ghi_So);
                  this.chungtuSelect.Gio = new Date(response.Gio);

                  this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
                    .subscribe(response => {
                      if (response.Status === "OK") {
                        this.HienThis = response.Data;
                      }

                    });
                  $('#loading').hide();
                  this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
                    .subscribe(response1 => {
                      //console.log(response[0].Column1);
                      if (response1 != null) {
                        this.chungtuSelect.Ma_Chung_Tu = response1[0].Ma_Chung_Tu;
                        this.chungtuSelect.Ma_Chung_Tu_1 = response1[0].Ma_Chung_Tu_1;
                        this.chungtuSelect.Ma_Chung_Tu_2 = response1[0].Ma_Chung_Tu_2;
                      }

                      this.loading = false;
                    });

                  this.fn_fixcot();
                }

              });
          }

          this.chungtuSelect.Tai_Khoan_Co_Id = ("C4B629A0-00E5-4A30-AED9-DADC21C9AE68").toLowerCase();
          this.chungtuSelect.Tai_Khoan_No_Id = ("CAAB7AC2-8C67-4D2A-A0B7-97EC06A2A805").toLowerCase();
          this.chungtuSelect.Tai_Khoan_Thue_Id = ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase();

          this.chungtuSelect.ChiTiets.forEach(
            (item1) => {
              item1.Tai_Khoan_Co_Id = ("C4B629A0-00E5-4A30-AED9-DADC21C9AE68").toLowerCase();
              item1.Tai_Khoan_No_Id = ("CAAB7AC2-8C67-4D2A-A0B7-97EC06A2A805").toLowerCase();
            }
          );

          this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
            .subscribe(response => {
              if (response.Status === "OK") {
                this.HienThis = response.Data;
              }
            });

        }
        else {
          this.chungtuSelect = ctu;
          if (this.chungtuSelect.HoaDons.length == 0)
            this.AddRowNewHD(this.chungtuSelect.HoaDons);
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
            .subscribe(response => {
              if (response.Status === "OK") {
                this.HienThis = response.Data; 
              }

            });
          if (this.chungtuSelect.HoaDons != null && this.chungtuSelect.HoaDons.length > 0) {
            this.chungtuSelect.HoaDons.forEach((item: any) => {
              if (item.Ngay_Hoa_Don != null) {
                item.Ngay_Hoa_Don = new Date(item.Ngay_Hoa_Don);
              }
            });
          }

          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          if (this.chungtuSelect.Ngay_Ghi_So != null) {
            this.ngaychungtu = new Date(this.chungtuSelect.Ngay_Ghi_So);
            this.ngayghiso = new Date(this.chungtuSelect.Ngay_Ghi_So);
          }
          //----------gán mặc định--------------------------

        }
        if (ctu === null || ctu.Ma_Lien_Quan === null || ctu.Ma_Lien_Quan === "") {
          if (ctu !== null) {
            this.chungtuSelect.Ma_Lien_Quan = ctu.Ma_Chung_Tu;
            this.chungtuSelect.Lien_Quan_Id = ctu.Id;
            this.isHidden = 3;
          }
          this.chungtuSelect.Id = null;
          //{ id: this.donviid, chuoi: tiens_phieuthu.id, status: '2019' }
          if (this.chungtuSelect.Ma_Lien_Quan != null && this.chungtuSelect.Ma_Lien_Quan != "") {
            this.repo.getRequest('api/donvicauhinh/thietlapngamdinh/' + this.donviid)
              .subscribe(response => {
                // chỉ tạo mã chứng từ mới khi tham số hệ thống cài đặt LaySoPhieuTuChungTuGoc=true
                if (response.LaySoPhieuTuChungTuGoc === false || response.LaySoPhieuTuChungTuGoc == null) {
                  this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
                    .subscribe(response1 => {
                      //console.log(response[0].Column1);
                      if (response1 != null) {
                        this.chungtuSelect.Ma_Chung_Tu = response1[0].Ma_Chung_Tu;
                        this.chungtuSelect.Ma_Chung_Tu_1 = response1[0].Ma_Chung_Tu_1;
                        this.chungtuSelect.Ma_Chung_Tu_2 = response1[0].Ma_Chung_Tu_2;
                      }

                      this.loading = false;
                    });
                }
                else
                  this.loading = false;
              });
          } else {
            this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
              .subscribe(response => {
                //console.log("ctutai");
                //console.log(response[0].Column1);
                //console.log(response[0]);
                if (response != null) {
                  this.chungtuSelect.Ma_Chung_Tu = response[0].Ma_Chung_Tu;
                  this.chungtuSelect.Ma_Chung_Tu_1 = response[0].Ma_Chung_Tu_1;
                  this.chungtuSelect.Ma_Chung_Tu_2 = response[0].Ma_Chung_Tu_2;
                }
                this.loading = false;
              });
          }

        }
        else //trường hợp sửa lại chứng từ phiếu thu từ phiếu rút tiền dự toán
        {
          this.isHidden = 3;
          var m_lienquan = this.chungtuSelect.Ma_Lien_Quan;
          var i_lienquan = this.chungtuSelect.Lien_Quan_Id;
          this.chungtuSelect.Ma_Lien_Quan = ctu.Ma_Chung_Tu;
          this.chungtuSelect.Lien_Quan_Id = ctu.Id;

          this.chungtuSelect.Ma_Chung_Tu = m_lienquan;
          this.chungtuSelect.Id = i_lienquan;
          this.loading = false;
        }
        this.fn_fixcot();
      }
      else {
        if (this.loading == false)
          $('#loading').show();
        this.repo.postRequest("api/chungtu/get_ketchuyen/", { DonViId: this.donviid, PhanHeId: this.phanheid, NamKeToan: this.namketoan, Loai: this.loaiketchuyen, UserName: crru.user_name })
          .subscribe(response => {
            //console.log(response);
            this.chungtuSelect = response;
            this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
            if (this.chungtuSelect.HoaDons.length == 0)
              this.AddRowNewHD(this.chungtuSelect.HoaDons);
            this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
              .subscribe(response => {
                if (response.Status === "OK") {
                  this.HienThis = response.Data;
                }

              });
            if (this.chungtuSelect.HoaDons != null && this.chungtuSelect.HoaDons.length > 0) {
              this.chungtuSelect.HoaDons.forEach((item: any) => {
                if (item.Ngay_Hoa_Don != null) {
                  item.Ngay_Hoa_Don = new Date(item.Ngay_Hoa_Don);
                }
              });
            }
            this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
            this.chungtuSelect.Phan_He_Id = this.phanheid;
            this.chungtuSelect.Don_Vi_Id = this.donviid;
            this.chungtuSelect.Ngay_Chung_Tu = new Date();
            this.chungtuSelect.Ngay_Ghi_So = new Date();
            this.chungtuSelect.Gio = new Date();
            this.ngaychungtu = new Date();
            this.ngayghiso = new Date();
            if (this.loading == false)
              $('#loading').hide();
            this.loading = false;
            this.fn_fixcot();
          });
      }
    }
    else {
      $('#loading').show();
      this.repo.postRequest("api/chungtu/get_thongtinchungtuv2/", { Id: id, NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid, UserName: crru.user_name, DonViId: this.donviid })
        .subscribe(response => {
          //console.log(response);
          this.chungtuSelect = response;
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          if (loai === 1)
            this.chungtuSelect.HoaDons = []; 
          if (this.chungtuSelect.HoaDons.length == 0)
            this.AddRowNewHD(this.chungtuSelect.HoaDons);
          this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
            .subscribe(response => {
              if (response.Status === "OK") {
                this.HienThis = response.Data;
              }
              this.fn_fixcot();
            });
          if (this.chungtuSelect.HoaDons != null && this.chungtuSelect.HoaDons.length > 0) {
            this.chungtuSelect.HoaDons.forEach((item: any) => {
              if (item.Ngay_Hoa_Don != null) {
                item.Ngay_Hoa_Don = new Date(item.Ngay_Hoa_Don);
              }
            });
          }
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.chungtuSelect.Ngay_Chung_Tu = new Date(response.Ngay_Chung_Tu);
          this.chungtuSelect.Ngay_Ghi_So = new Date(response.Ngay_Ghi_So);
          this.chungtuSelect.Gio =  new Date(response.Gio);
          this.tongtien2 = 0;
          //this.chungtuSelect.DongThois.forEach(
          //  (item) => {
          //    this.tongtien2 = this.tongtien2 + (item.So_Tien === null ? 0 : item.So_Tien);
          //  }
          //);
          $('#loading').hide();
          if (this.chungtuSelect.Ma_Lien_Quan !== null && this.chungtuSelect.Ma_Lien_Quan !== "") {
            this.isHidden = 3;
          }
          else
            this.isHidden = 0;

          if (loai === 1) {
            let idcopy = this.chungtuSelect.Id;
            this.chungtuSelect.Id = null;
            this.chungtuSelect.Ma_Lien_Quan = null;
            this.chungtuSelect.Lien_Quan_Id = null;
            this.chungtuSelect.Giao_Dich_Id = null;
            this.chungtuSelect.Copy = 1;
            this.repo.postRequest("api/chungtu/get_taomachungtuv2/", { id: this.donviid, chuoi: this.phanheid, status: this.namketoan, Chuoi4: idcopy, DoanhNghiepId: this.doanhnghiepid })
              .subscribe(response => {
                //console.log(response[0].Column1);
                if (response != null) {
                  this.chungtuSelect.Ma_Chung_Tu = response[0].Ma_Chung_Tu;
                  this.chungtuSelect.Ma_Chung_Tu_1 = response[0].Ma_Chung_Tu_1;
                  this.chungtuSelect.Ma_Chung_Tu_2 = response[0].Ma_Chung_Tu_2;
                }
                this.loading = false;
              });
          }
          else
            this.loading = false;
          if (loai === 1) {
            this.chungtuSelect.Ngay_Chung_Tu = new Date();
            this.chungtuSelect.Ngay_Ghi_So = new Date();
            this.chungtuSelect.Gio = new Date();
            this.ngaychungtu = new Date();
            this.ngayghiso = new Date();
          }
          else {
            this.ngaychungtu = new Date(this.chungtuSelect.Ngay_Chung_Tu);
            this.ngayghiso = new Date(this.chungtuSelect.Ngay_Ghi_So);

          }

          

        });

    }


    //setTimeout(() => {
    //  this.slKhachHang.focus();
    //}, 1000);

    // this.fixcot = 0;


  }
  fn_fixcot() {
    //console.log("độ dài: ");
    //console.log(this.chungtuSelect.ChiTiets.length);


    if (this.chungtuSelect.ChiTiets.length <= 50) {
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
      //(window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_02');
    }
    else
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
  }


  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }

    this.repo.postRequest('api/taikhoan/dstaikhoanv2', { DoanhNghiepId: this.doanhnghiepid, UserName: this.username })
      .subscribe(response => {
        this.inputtaikhoans = response;
      });
   
   
  }
  
  
  
  inputdinhkhoanmaus: any;
  dinhkhoanmau(row: any) {
    //console.log(row);

    this.repo.postRequest("api/chungtu/get_chitietdinhkhoanmau/", { id: row.Id })
      .subscribe(response => {
        if (this.chungtuSelect.ChiTiets != null && this.chungtuSelect.ChiTiets.length > 0) {
          if (this.chungtuSelect.ChiTiets.length == 1 && (this.chungtuSelect.ChiTiets[0].So_Tien == null || this.chungtuSelect.ChiTiets[0].So_Tien == 0))
            this.chungtuSelect.ChiTiets = response;
          else
            this.chungtuSelect.ChiTiets = this.chungtuSelect.ChiTiets.concat(response);
        }
        else
          this.chungtuSelect.ChiTiets = response;

      });
  }
  LoadCategory(): void {
    //---------------load phân hệ-------------------------------------------
    //this.repo.postRequest('api/chungtu/dinhkhoanmaus', { Id: null, Chuoi: this.donviid })
    //  .subscribe(response => {
    //    this.inputdinhkhoanmaus = response;
    //  });
    //------------------------------------------------------------------------
    
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv2', { NhomId: Lists_taisan.id, MaGiaoDien: '4', DonViId: this.donviid, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputtaisans = response;
    //  });
   
   
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv2', { NhomId: Lists_nganhang.id, MaGiaoDien: '', DonViId: this.donviid, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputnganhangs = response;
    //  });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: '532A61EA-D765-844B-8CBF-E610B209E332', MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputkhachhangs = response;
    //  });

    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_hanghoa.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputhanghoas = response;
    //  });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_khohang.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputkhohangs = response;
      });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_donvitinh.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputdonvitinhs = response;
      });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_hopdong.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputhopdongs = response;
    //  });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_lenhsuachua.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputlenhsuachuas = response;
    //  });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_khoanmuc.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputkhoanmucs = response;
      });

    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_madonvi.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputmadonvis = response;
      });

    this.repo.postRequest('api/doituong/getloaithue', { NhomId: Lists_madonvi.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid, Loai:0 })
      .subscribe(response => {
        this.inputloaithues = response;
      });
    
   
   
  }

  @ViewChildren("myinput") divs: QueryList<ElementRef>;


  selectedTab(tab: number) {
    this.tabselected = tab;
    setTimeout(() => {
      this.setComboFocus(0, 1, tab);
    }, 500);
  }
  //----------event xử lý di chuyển Enter--------------------------------------
  setComboFocus(hang: number, cot: number, luoi: number) {
    var tencombo: string = hang + '_' + cot + '_' + luoi;
    var ketqua = false;
    this.slcombo.forEach(
      (question, i, allQuestions) => {
        if (question.labelForId === tencombo) {
          ketqua = true;
          question.focus();
        }
      }
    );

    if (ketqua === false) {
      this.divs.forEach(
        (div) => {

          if (div.nativeElement.id === tencombo) {
            ketqua = true;
            div.nativeElement.focus();
          }

        }
      );
    }
  }
  keyLyDo(event: any) {
    if (event.which === 13) {
      //this.slTaiKhoanNo.focus();
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  keyTKNoPress(event: any) {
    if (event.which === 13) {
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  maxcot: number = 14;

  keyComBoPress(event: any, hang: number, cot: number, luoi: number) {
  
    if (1 === 1) {
      hang = hang + 1;
      //console.log("key tai " + event.keyCode);
      if (event.keyCode === 40) {
       // console.log("key tai 40");
        if (luoi === 2) {
          if (hang === this.chungtuSelect.HoaDons.length) {
            this.AddRowNewHD(this.chungtuSelect.HoaDons);
            if (this.chungtuSelect.HoaDons.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            setTimeout(() => {
              this.setComboFocus(hang-1, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          //console.log("key tai lưới 1");
          if (hang === this.chungtuSelect.ChiTiets.length) {
            this.AddRowNew(this.chungtuSelect.ChiTiets);
            if (this.chungtuSelect.ChiTiets.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            this.setComboFocus(hang, cot, luoi);
            event.preventDefault();
          }
        }
        
      }

      if (event.keyCode === 38 && hang > 0) {
        //console.log("key tai 38");
        this.setComboFocus(hang - 2, cot, luoi);
        event.preventDefault();
      }

      //if (event.keyCode === 37 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot-1, luoi);
      //  event.preventDefault();
      //}
      //if (event.keyCode === 39 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot + 1, luoi);
      //  event.preventDefault();
      //}

      return;
    }

    if (luoi === 2)
      this.maxcot = 9;
    if (luoi === 1)
      this.maxcot = 17;
    if (luoi === 3)
      this.maxcot = 17;
    if (event.which === 13) {
      cot = cot + 1;

      if (cot > this.maxcot) {
        hang = hang + 1;
        cot = 1;

        if (luoi === 2) {
          if (hang === this.chungtuSelect.HoaDons.length) {
            this.AddRowNewHD(this.chungtuSelect.HoaDons);
            if (this.chungtuSelect.HoaDons.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          if (hang === this.chungtuSelect.ChiTiets.length) {
            this.AddRowNew(this.chungtuSelect.ChiTiets);
            if (this.chungtuSelect.ChiTiets.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
              
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
      }
      this.setComboFocus(hang, cot, luoi);
      event.preventDefault();
    }
  }
  RowNewClick() {
    if (this.tabselected === 2) {
      this.AddRowNewHD(this.chungtuSelect.HoaDons);
      if (this.chungtuSelect.HoaDons.length <= 50) {
        (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
      }
    }
    else {
      this.AddRowNew(this.chungtuSelect.ChiTiets);
      if (this.chungtuSelect.ChiTiets.length <= 50) {
        (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
        
      }
    }
  }
  
  AddRowNew(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Chung_Tu_Id: row.Chung_Tu_Id, Tai_Khoan_No: row.Tai_Khoan_No, Tai_Khoan_Co: row.Tai_Khoan_Co
        , Tai_Khoan_No_Id: row.Tai_Khoan_No_Id, Tai_Khoan_Co_Id: row.Tai_Khoan_Co_Id
        , Dien_Giai_Chi_Tiet: row.Dien_Giai_Chi_Tiet, So_Luong: null, Don_Gia: null, So_Tien: null, Ty_Gia: null, Ty_Gia_No: null, Ty_Gia_Co: null
        , Ngoai_Te: null, Chi_Tiet_No_Id: row.Chi_Tiet_No_Id, Doi_Tuong_Id_No: row.Doi_Tuong_Id_No, Hang_Hoa_Id_No: row.Hang_Hoa_Id_No
        , Kho_Hang_Id_No: row.Kho_Hang_Id_No, Don_Vi_Tinh_Id_No: row.Don_Vi_Tinh_Id_No, Hop_Dong_Id_No: row.Hop_Dong_Id_No,
        Tai_San_Id_No: row.Tai_San_Id_No, Khoan_Muc_Id_No: row.Khoan_Muc_Id_No, Lenh_Sua_Chua_Id_No: row.Lenh_Sua_Chua_Id_No
        , Doi_Tuong_Id_1_No: row.Doi_Tuong_Id_1_No, Doi_Tuong_Id_2_No: row.Doi_Tuong_Id_2_No, Doi_Tuong_Id_3_No: row.Doi_Tuong_Id_3_No
        , Doi_Tuong_Id_4_No: row.Doi_Tuong_Id_4_No, Doi_Tuong_Id_5_No: row.Doi_Tuong_Id_5_No

        , Chi_Tiet_Co_Id: row.Chi_Tiet_Co_Id, Doi_Tuong_Id_Co: row.Doi_Tuong_Id_Co, Hang_Hoa_Id_Co: row.Hang_Hoa_Id_Co
        , Kho_Hang_Id_Co: row.Kho_Hang_Id_Co, Don_Vi_Tinh_Id_Co: row.Don_Vi_Tinh_Id_Co, Hop_Dong_Id_Co: row.Hop_Dong_Id_Co,
        Tai_San_Id_Co: row.Tai_San_Id_Co, Khoan_Muc_Id_Co: row.Khoan_Muc_Id_Co, Lenh_Sua_Chua_Id_Co: row.Lenh_Sua_Chua_Id_Co
        , Doi_Tuong_Id_1_Co: row.Doi_Tuong_Id_1_Co, Doi_Tuong_Id_2_Co: row.Doi_Tuong_Id_2_Co, Doi_Tuong_Id_3_Co: row.Doi_Tuong_Id_3_Co
        , Doi_Tuong_Id_4_Co: row.Doi_Tuong_Id_4_Co, Doi_Tuong_Id_5_Co: row.Doi_Tuong_Id_5_Co

        , Doi_Tuong_No: row.Doi_Tuong_No, Hang_Hoa_No: row.Hang_Hoa_No
        , Kho_Hang_No: row.Kho_Hang_No, Don_Vi_Tinh_No: row.Don_Vi_Tinh_No
        , Hop_Dong_No: row.Hop_Dong_No, Khe_Uoc_No: row.Khe_Uoc_No
        , Khoan_Muc_No: row.Khoan_Muc_No, Lenh_Sua_Chua_No: row.Lenh_Sua_Chua_No
        , Tai_San_No: row.Tai_San_No, Doi_Tuong_1_No: row.Doi_Tuong_1_No
        , Doi_Tuong_2_No: row.Doi_Tuong_2_No, Doi_Tuong_3_No: row.Doi_Tuong_3_No
        , Doi_Tuong_4_No: row.Doi_Tuong_4_No, Doi_Tuong_5_No: row.Doi_Tuong_5_No

        , Doi_Tuong_Co: row.Doi_Tuong_Co, Hang_Hoa_Co: row.Hang_Hoa_Co
        , Kho_Hang_Co: row.Kho_Hang_Co, Don_Vi_Tinh_Co: row.Don_Vi_Tinh_Co
        , Hop_Dong_Co: row.Hop_Dong_Co, Khe_Uoc_Co: row.Khe_Uoc_Co
        , Khoan_Muc_Co: row.Khoan_Muc_Co, Lenh_Sua_Chua_Co: row.Lenh_Sua_Chua_Co
        , Tai_San_Co: row.Tai_San_Co, Doi_Tuong_1_Co: row.Doi_Tuong_1_Co
        , Doi_Tuong_2_Co: row.Doi_Tuong_2_Co, Doi_Tuong_3_Co: row.Doi_Tuong_3_Co
        , Doi_Tuong_4_Co: row.Doi_Tuong_4_Co, Doi_Tuong_5_Co: row.Doi_Tuong_5_Co


      });
    }
    else {
      ctiets.push({
        showpopup: sp, Chung_Tu_Id: null, Tai_Khoan_No: null, Tai_Khoan_Co: null
        , Tai_Khoan_No_Id: null, Tai_Khoan_Co_Id: null
        , Dien_Giai_Chi_Tiet: null, So_Luong: null, Don_Gia: null, So_Tien: null, Ty_Gia: null, Ty_Gia_No: null, Ty_Gia_Co: null
        , Ngoai_Te: null, Chi_Tiet_No_Id: null, Doi_Tuong_Id_No: null, Hang_Hoa_Id_No: null
        , Kho_Hang_Id_No: null, Don_Vi_Tinh_Id_No: null, Hop_Dong_Id_No: null,
        Tai_San_Id_No: null, Khoan_Muc_Id_No: null, Lenh_Sua_Chua_Id_No: null
        , Doi_Tuong_Id_1_No: null, Doi_Tuong_Id_2_No: null, Doi_Tuong_Id_3_No: null
        , Doi_Tuong_Id_4_No: null, Doi_Tuong_Id_5_No: null

        , Chi_Tiet_Co_Id: null, Doi_Tuong_Id_Co: null, Hang_Hoa_Id_Co: null
        , Kho_Hang_Id_Co: null, Don_Vi_Tinh_Id_Co: null, Hop_Dong_Id_Co: null,
        Tai_San_Id_Co: null, Khoan_Muc_Id_Co: null, Lenh_Sua_Chua_Id_Co: null
        , Doi_Tuong_Id_1_Co: null, Doi_Tuong_Id_2_Co: null, Doi_Tuong_Id_3_Co: null
        , Doi_Tuong_Id_4_Co: null, Doi_Tuong_Id_5_Co: null

        , Doi_Tuong_No: null, Hang_Hoa_No: null
        , Kho_Hang_No: null, Don_Vi_Tinh_No: null
        , Hop_Dong_No: null, Khe_Uoc_No: null
        , Khoan_Muc_No: null, Lenh_Sua_Chua_No: null
        , Tai_San_No: null, Doi_Tuong_1_No: null
        , Doi_Tuong_2_No: null, Doi_Tuong_3_No: null
        , Doi_Tuong_4_No: null, Doi_Tuong_5_No: null

        , Doi_Tuong_Co: null, Hang_Hoa_Co: null
        , Kho_Hang_Co: null, Don_Vi_Tinh_Co: null
        , Hop_Dong_Co: null, Khe_Uoc_Co: null
        , Khoan_Muc_Co: null, Lenh_Sua_Chua_Co: null
        , Tai_San_Co: null, Doi_Tuong_1_Co: null
        , Doi_Tuong_2_Co: null, Doi_Tuong_3_Co: null
        , Doi_Tuong_4_Co: null, Doi_Tuong_5_Co: null


      });

    }

   // this.chungtuSelect.ChiTiets = ctiets;

  }

  AddRowNewHD(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

 
  

      ctiets.push({
        showpopup: sp, Id: null, Chung_Tu_Id: row.Chung_Tu_Id, Loai_Thue_Id: row.Loai_Thue_Id
        , Ngay_Hoa_Don: row.Ngay_Hoa_Don, So_Hoa_Don: row.So_Hoa_Don
        , So_Hieu: row.So_Hieu, Thue_Suat: row.Thue_Suat
        , Tien_Thue: row.Tien_Thue, Tien_Hang: row.Tien_Hang, Ma_So_Thue: null
        , Doi_Tuong: null, Dien_Giai: row.Dien_Giai, Ke_Khai: row.Ke_Khai,
        Remove_Hoa_Don: null, DoanhNghiepId: null, TransactionID: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });
    }
    else {
      ctiets.push({
        showpopup: sp, Id: null, Chung_Tu_Id: null, Loai_Thue_Id: null
        , Ngay_Hoa_Don: null, So_Hoa_Don: null
        , So_Hieu: null, Thue_Suat: null

        , Tien_Thue: null, Tien_Hang: null, Ma_So_Thue: null
        , Doi_Tuong: null, Dien_Giai: null, Ke_Khai: null,
        Remove_Hoa_Don: null, DoanhNghiepId: null, TransactionID: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null
        

      });

    }

    //this.chungtuSelect.HoaDons = ctiets;

  }

  //----------event các control------------------------------------------------------
  onLienQuanChange($event) {

    //console.log($event)
    if (typeof $event !== "undefined") {
      this.repo.postRequest("api/chungtu/get_thongtinrutdutoan/", { id: $event.Id })
        .subscribe(response => {
          //console.log(response);
          response.Ma_Chung_Tu = this.chungtuSelect.Ma_Chung_Tu;
          response.Lien_Quan_Id = response.Id;
          this.chungtuSelect = response;
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.chungtuSelect.Id = null;
          this.chungtuSelect.Phan_He_Id = this.phanheid;
          this.ngaychungtu = new Date(this.chungtuSelect.Ngay_Chung_Tu);
          this.ngayghiso = new Date(this.chungtuSelect.Ngay_Ghi_So);

        });
    }
  }
  onDoiTuongChange($event) {

    if (typeof $event === "undefined") {
      this.chungtuSelect.Ho_Ten = null;
    }
    else {
      this.chungtuSelect.Ho_Ten = $event.Ten_Doi_Tuong;
      // chỉ gán đối tượng cho chứng từ khác chứng từ kết chuyển
      //if (this.loaiketchuyen == null) {
      //  this.chungtuSelect.ChiTiets.forEach(
      //    (item) => {
      //      item.Doi_Tuong_Id = $event.Id;
      //      item.Doi_Tuong_Id2 = $event.Id;
      //    }
      //  );
      //}


      this.repo.postRequest("api/doituong/ThongTinDiaChi", { Id: $event.Id, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          //console.log(response);
          this.chungtuSelect.Dia_Chi = response.Dia_Chi;

        });
    }
  }
  onTaiNoChange($event) {
    if (typeof $event === "undefined") {
      this.taikhoanno = null;
    }
    else
      this.taikhoanno = $event.Ten_Tai_Khoan;
  }
  onTaiSanChange($event, hang: number) {
    var rs = this.chungtuSelect.ChiTiets[hang];

    if (typeof $event === "undefined") {
      rs.Dien_Giai_Chi_Tiet = null;
    }
    else {
      rs.Dien_Giai_Chi_Tiet = $event.Ten_Doi_Tuong;
      rs.Tai_Khoan_No_Id = $event.TK_Tai_San_Id;
      // rs.Chuong_Id = $event.Chuong_Id;
      rs.Loai_Khoan_Id = $event.Loai_Khoan_Id;
      rs.Tieu_Muc_Id = $event.Tieu_Muc_Id;
      rs.So_Tien = $event.Nguyen_Gia;
      if ($event != "undefined" && $event.Nguon_Id != null && $event.Nguon_Id != "undefined")
        rs.Nguon_Id = $event.Nguon_Id;
    }

  }
  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;
  cotSelected: number;

  AddDoiTuong(cobo: NgSelectComponent, hang: number, cot: number) {

    this.comboSeleted = cobo;
    this.cotSelected = cot;
   // console.log(cobo)

    cobo.close();
    this.rowSelected = this.chungtuSelect.ChiTiets[hang];

    this.doituongchungedit.loadDataInit(cobo.addTagText, null, this.donviid);
    this.mdDoiTuongChung.open();


    //if (cobo.addTagText.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
    //  this.nganhangedit.loadDataInit(cobo.addTagText, null, this.donviid, cot);
    //  this.mdDoiTuongNH.open();
    //}
    //else {
    //  if (cobo.addTagText.toLowerCase() === this.doituongid.toLowerCase()) {
    //    this.AddNguoiNop2(cobo);
    //  }
    //  else if (cobo.addTagText.toLowerCase() === Lists_taisancodinh.id.toLowerCase()) {
    //    this.taisanedit.loadDataInit(cobo.addTagText, null, this.donviid, cot);
    //    this.mdTaiSan.open();
    //  }
    //  else {
    //    this.doituongchungedit.loadDataInit(cobo.addTagText, null, this.donviid);
    //    this.mdDoiTuongChung.open();
    //  }
    //}


  }
  AddNguoiNop2(cobo: NgSelectComponent) {
    this.comboSeleted = cobo;
    this.themdoituongtrenluoi = 1;
    cobo.close();
    this.mdLuaChon.open();

  }
  AddNguoiNop(cobo: NgSelectComponent) {
    this.themdoituongtrenluoi = 0;
    this.comboSeleted = cobo;
    cobo.close();
    this.mdLuaChon.open();
  }
  loaithem: number = 2;
  onLoaiNguoiNop(loai: number) {
    this.loaithem = loai;
    //console.log(loai);
  }
  AddDoRong(tieude: string) {
    this.mdLuaChonDoRong.open();
  }
  AddFormDoRong() {
    this.mdLuaChonDoRong.close();
  }

  AddFormNguoiNop() {
    this.mdLuaChon.close();
    if (this.loaithem === 2) {
      this.canboedit.loadDataInit(Lists_nhanvien.id, null, this.donviid);
      this.mdCanBo.open();
    }
    else if (this.loaithem === 3) {
      this.khachhangedit.loadDataInit(Lists_khachhang.id, null, this.donviid);
      this.mdKhachhang.open();
    }
    else {
      this.khachhangedit.loadDataInit(Lists_nhacungcap.id, null, this.donviid);
      this.mdKhachhang.open();
    }
  }

  //------------------------------------
  saveModalTaiSan(doituong: any) {
    this.mdTaiSan.close();
    if (doituong != null) {
      //console.log(doituong);
      this.inputtaisans.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong, TK_Tai_San_Id: doituong.TK_Tai_San_Id, Nguon_Id: doituong.Nguon_Id, Loai_Khoan_Id: doituong.Loai_Khoan_Id, Tieu_Muc_Id: doituong.Tieu_Muc_Id })
      this.inputtaisans = this.inputtaisans.slice();

      this.rowSelected.Tai_San_Id = doituong.Id;
      this.rowSelected.Dien_Giai_Chi_Tiet = doituong.Ten_Doi_Tuong;
      this.rowSelected.Tai_Khoan_No_Id = doituong.TK_Tai_San_Id;
      this.rowSelected.Nguon_Id = doituong.Nguon_Id;
      this.rowSelected.Loai_Khoan_Id = doituong.Loai_Khoan_Id;
      this.rowSelected.Tieu_Muc_Id = doituong.Tieu_Muc_Id;
    }
  }

  saveModalKhacHang(doituong: any) {
    this.mdKhachhang.close();

    this.inputkhachhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputkhachhangs = this.inputkhachhangs.slice();
    if (this.cotSelected === 7)
      this.rowSelected.Doi_Tuong_Id = doituong.Id;
    if (this.cotSelected === 8)
      this.rowSelected.Doi_Tuong_Id2 = doituong.Id;

    if (this.themdoituongtrenluoi === 0) {
      this.chungtuSelect.Doi_Tuong_Id = doituong.Id;
      this.tendoituong = doituong.Ten_Doi_Tuong;
    }
  }
  saveModalCanBo(doituong: any) {
    this.mdCanBo.close();
    this.inputkhachhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputkhachhangs = this.inputkhachhangs.slice();
    if (this.cotSelected === 7)
      this.rowSelected.Doi_Tuong_Id = doituong.Id;
    if (this.cotSelected === 8)
      this.rowSelected.Doi_Tuong_Id2 = doituong.Id;
    if (this.themdoituongtrenluoi === 0) {
      this.chungtuSelect.Doi_Tuong_Id = doituong.Id;
      this.tendoituong = doituong.Ten_Doi_Tuong;
    }
  }
  saveModalNganHang(doituong: any) {
    this.mdDoiTuongNH.close();
    this.inputnganhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputnganhangs = this.inputnganhangs.slice();

    this.rowSelected.Ngan_Hang_Id = doituong.Id;
  }

  saveModal(doituong: any) {
    //console.log(doituong);
    this.mdDoiTuongChung.close();
    
    //if (doituong != null && Lists_doituongtaphop2.id === doituong.Nhom_Doi_Tuong_ID) {

    //  this.inputtaphops2.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    //  this.inputtaphops2 = this.inputtaphops2.slice();
    //  this.rowSelected.Doi_Tuong_Id_4 = doituong.Id;
    //}
   
    if (doituong != null && this.nhomnguonid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhoanmucs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhoanmucs = this.inputkhoanmucs.slice();
      if (this.cotSelected==17)
        this.rowSelected.Khoan_Muc_Id_No = doituong.Id;
      if (this.cotSelected == 23)
        this.rowSelected.Khoan_Muc_Id_Co = doituong.Id;
    }
    if (doituong != null && this.khohangid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhohangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhohangs = this.inputkhohangs.slice();
      if (this.cotSelected == 5)
        this.rowSelected.Kho_Hang_Id_No = doituong.Id;
      if (this.cotSelected == 8)
        this.rowSelected.Kho_Hang_Id_Co = doituong.Id;
    }
    if (doituong != null && this.donvitinhid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputdonvitinhs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputdonvitinhs = this.inputdonvitinhs.slice();
      if (this.cotSelected == 6)
        this.rowSelected.Don_Vi_Tinh_Id_No = doituong.Id;
      if (this.cotSelected == 9)
        this.rowSelected.Don_Vi_Tinh_Id_Co = doituong.Id;
    }
    

    this.comboSeleted.focus();
  }
  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }
  indexdt: number;
  tindex: number;
  cotdt: string;
  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Hang_Hoa_Id_No" || cot == "Hang_Hoa_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_hanghoa.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Don_Vi_Tinh_Id_No" || cot == "Don_Vi_Tinh_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_donvitinh.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Kho_Hang_Id_No" || cot == "Kho_Hang_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_khohang.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Hop_Dong_Id_No" || cot == "Hop_Dong_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_hopdong.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Doi_Tuong_Id_No" || cot == "Doi_Tuong_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_doituong.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Tai_San_Id_No" || cot == "Tai_San_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_taisan.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Lenh_Sua_Chua_Id_No" || cot == "Lenh_Sua_Chua_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_lenhsuachua.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Khoan_Muc_Id_No" || cot == "Khoan_Muc_Id_Co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_khoanmuc.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Doi_Tuong_Id_1_No" || cot == "Doi_Tuong_Id_1_co") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_madonvi.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }


  }

    
 

  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    this.tindex = index;
    let id = Lists_doituong.id;
    if (this.tindex == 2)
      id = Lists_nganhang.id;
    if (this.tindex == 3)
      id = Lists_hopdongmua.id;
    this.dtechondoituongchungedit.loadDataInit(this.phanheid, id);
    this.mBackdrop=setTimeout(() => {
      this.haveBackdrop();
    }, 500);
    this.mdChonDoiTuongChung.open();
    
  }
  phanbomuahangchuahd() {
    $('#loading').show();
    this.repo.postRequest("api/chungtu/phanbomuahangchuahd", this.chungtuSelect)
      .subscribe(response => {
        $('#loading').hide();
        if (response.Status === "OK") {
          this.chungtuSelect.ChiTiets = response.Data.ChiTiets;
          this.chungtuSelect.ListID = response.Data.ListID;
        }
      });
  }
  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (this.tindex == 1) {
              if (i == 0) {
                this.chungtuSelect.Doi_Tuong_Id = item.Id;
                this.chungtuSelect.Ma_Doi_Tuong = item.Ma;
                this.chungtuSelect.Ho_Ten = item.Ten;
                this.repo.postRequest("api/doituong/ThongTinDiaChi", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid })
                  .subscribe(response => {
                    //console.log(response);
                    if (response != null)
                      this.chungtuSelect.Dia_Chi = response.Dia_Chi;
                    this.mdChonDoiTuongChung.close();
                  });

                this.chungtuSelect.ChiTiets.forEach(
                  (item1) => {
                    item1.Doi_Tuong_Id_Co = item.Id;
                    item1.Doi_Tuong_Co = item.Ma;
                  }
                );


                //this.onDoiTuongChange(item);
                //if (this.chungtuSelect.ChiTiets == null || this.chungtuSelect.ChiTiets == "undefined") {
                //}
                //else {
                //  this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Id = item.Id;
                //}
              }
            }
            if (this.tindex == 2) {
              if (i == 0) {
                this.chungtuSelect.Ngan_Hang_Id = item.Id;
                this.chungtuSelect.Ngan_Hang = item.Ma;
                this.mdChonDoiTuongChung.close();

                this.chungtuSelect.ChiTiets.forEach(
                  (item1) => {
                    item1.Doi_Tuong_Id_Co = item.Id;
                    item1.Doi_Tuong_Co = item.Ma;
                  }
                );

              }
            }
            if (this.tindex == 3) {
              if (i == 0) {
                
                this.mdChonDoiTuongChung.close();
                this.chungtuSelect.Hop_Dong_Id = item.Id;
                $('#loading').show();
                this.repo.postRequest("api/chungtu/layhoadon", this.chungtuSelect)
                  .subscribe(response => {
                    $('#loading').hide();
                    if (response.Status === "OK") {
                      this.chungtuSelect.ChiTiets = response.Data.ChiTiets;
                    }

                  });

              }
            }

            i = i + 1;

          }
        );

      }
      else {
        // console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          if (this.tindex == 1) {
            this.chungtuSelect.Doi_Tuong_Id = null;
            this.chungtuSelect.Ma_Doi_Tuong = null;

            this.chungtuSelect.ChiTiets.forEach(
              (item1) => {
                item1.Doi_Tuong_Id_Co = null;
                item1.Doi_Tuong_Co = null;
              }
            );

          }
          if (this.tindex == 2) {
            this.chungtuSelect.Ngan_Hang_Id = null;
            this.chungtuSelect.Ngan_Hang = null;

            this.chungtuSelect.ChiTiets.forEach(
              (item1) => {
                item1.Doi_Tuong_Id_Co = null;
                item1.Doi_Tuong_Co = null;
              }
            );

          }
        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.chungtuSelect.ChiTiets == null || this.chungtuSelect.ChiTiets == "undefined") {
              }
              else {
                if (this.cotdt == "Hang_Hoa_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_No = item.Ma;
                  this.chungtuSelect.ChiTiets[this.indexdt].Dien_Giai_Chi_Tiet = item.Ten;
                  $('#loading').show();
                  this.repo.postRequest("api/chungtu/chon_Hang_Hoa_Id_No", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, UserName: this.currentUser.user_name })
                    .subscribe(response => {
                      $('#loading').hide();
                      if (response.TrangThaiS === "OK") {
                        this.chungtuSelect.ChiTiets[this.indexdt].So_Luong = response.So_Luong;
                        this.chungtuSelect.ChiTiets[this.indexdt].Don_Gia = response.Don_Gia;
                        this.chungtuSelect.ChiTiets[this.indexdt].So_Tien = response.So_Tien;
                        this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_No = response.Hop_Dong_Id_No;
                        this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_No = response.Hop_Dong_No;
                        this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_Co = response.Hop_Dong_Id_No;
                        this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Co = response.Hop_Dong_No;
                        this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Id_No = response.Don_Vi_Tinh_Id_No;
                      }
                    });

                }
                if (this.cotdt == "Hang_Hoa_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Co = item.Ma;
                  this.chungtuSelect.ChiTiets[this.indexdt].Dien_Giai_Chi_Tiet = item.Ten;
                  //xuat kho FIFO
                  //var rs = this.chungtuSelect.ChiTiets[this.indexdt];
                  //this.layGiaHang(rs);
                }
                if (this.cotdt == "Don_Vi_Tinh_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_No = item.Ten;
                }
                if (this.cotdt == "Don_Vi_Tinh_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Co = item.Ten;
                }
                if (this.cotdt == "Kho_Hang_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_No = item.Ten;
                }
                if (this.cotdt == "Kho_Hang_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Co = item.Ten;
                   //xuat kho FIFO
                  //var rs = this.chungtuSelect.ChiTiets[this.indexdt];
                  //this.layGiaHang(rs);
                }
                if (this.cotdt == "Hop_Dong_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_No = item.Ma;
                }
                if (this.cotdt == "Hop_Dong_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Co = item.Ma;
                }
                if (this.cotdt == "Doi_Tuong_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_No = item.Ma;
                }
                if (this.cotdt == "Doi_Tuong_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Co = item.Ma;
                }
                if (this.cotdt == "Tai_San_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_No = item.Ma;
                }
                if (this.cotdt == "Tai_San_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Co = item.Ma;
                }
                if (this.cotdt == "Lenh_Sua_Chua_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_No = item.Ma;
                }
                if (this.cotdt == "Lenh_Sua_Chua_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Co = item.Ma;
                }
                if (this.cotdt == "Khoan_Muc_Id_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Id_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_No = item.Ma;
                }
                if (this.cotdt == "Khoan_Muc_Id_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Id_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Co = item.Ma;
                }
                if (this.cotdt == "Doi_Tuong_Id_1_No") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_1_No = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_1_No = item.Ma;
                }
                if (this.cotdt == "Doi_Tuong_Id_1_Co") {
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_1_Co = item.Id;
                  this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_1_Co = item.Ma;
                }


                this.mdChonDoiTuongChung.close();
              }
            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          if (this.cotdt == "Hang_Hoa_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_No = null;
          }
          if (this.cotdt == "Hang_Hoa_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Hang_Hoa_Co = null;
          }
          if (this.cotdt == "Don_Vi_Tinh_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_No = null;
          }
          if (this.cotdt == "Don_Vi_Tinh_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Don_Vi_Tinh_Co = null;
          }
          if (this.cotdt == "Kho_Hang_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_No = null;
          }
          if (this.cotdt == "Kho_Hang_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Kho_Hang_Co = null;
          }
          if (this.cotdt == "Hop_Dong_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_No = null;
          }
          if (this.cotdt == "Hop_Dong_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Hop_Dong_Co = null;
          }
          if (this.cotdt == "Doi_Tuong_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_No = null;
          }
          if (this.cotdt == "Doi_Tuong_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Co = null;
          }
          if (this.cotdt == "Tai_San_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_No = null;
          }
          if (this.cotdt == "Tai_San_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Tai_San_Co = null;
          }
          if (this.cotdt == "Lenh_Sua_Chua_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_No = null;
          }
          if (this.cotdt == "Lenh_Sua_Chua_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Lenh_Sua_Chua_Co = null;
          }
          if (this.cotdt == "Khoan_Muc_Id_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Id_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_No = null;
          }
          if (this.cotdt == "Khoan_Muc_Id_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Id_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Khoan_Muc_Co = null;
          }
          if (this.cotdt == "Doi_Tuong_Id_1_No") {
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_1_No = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_1_No = null;
          }
          if (this.cotdt == "Doi_Tuong_Id_1_Co") {
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_Id_1_Co = null;
            this.chungtuSelect.ChiTiets[this.indexdt].Doi_Tuong_1_Co = null;
          }
        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }
  layGiaHang(row: any) {
    if (row.Kho_Hang_Id_Co !== null && row.Hang_Hoa_Id_Co !== null && row.So_Luong !== null && parseInt(row.So_Luong, 10) > 0) {
      var ngayhtoan = (this.chungtuSelect.Ngay_Ghi_So.getMonth() + 1) + '/' + this.chungtuSelect.Ngay_Ghi_So.getDate() + '/' + this.chungtuSelect.Ngay_Ghi_So.getFullYear();
      this.repo.postRequest('api/doituong/tinhgiafifo3', { NgayHachToan: this.chungtuSelect.Ngay_Ghi_So, Kho_Hang_Id_Co: row.Kho_Hang_Id_Co, Hang_Hoa_Id_Co: row.Hang_Hoa_Id_Co, Id: this.chungtuSelect.Id, TaiKhoan: row.Tai_Khoan_Co, SoLuong: row.So_Luong, DonViId: this.donviid, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          console.log(response);
          row.So_Tien = response;
          //if (response[0].Gia_Xuat > 0) {
          //  row.So_Luong = response[0].So_Luong_Xuat;
          //}
          //row.So_Tien = response[0].Gia_Xuat;
          //row.Don_Gia = row.So_Tien / row.So_Luong;

        });
    }

  }

  deleterow(index: string, luoi: number) {
    //console.log(index);
    swal({
      title: 'Bạn muốn xóa dòng này phải không?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          if (luoi === 2)
            this.chungtuSelect.HoaDons.splice(index, 1);
          else
            this.chungtuSelect.ChiTiets.splice(index, 1);

          this.changeST(null);
          this.changeST2(null);
        }
      });


  }
  public savectu(loai: number) {

    
    //if (this.namketoan !== this.ngayghiso.getFullYear()) {
    //  swal('Cảnh báo!', "Ngày hạch toán nằm ngoài năm kế toán!!!", 'warning');
    //  return;
    //}
    if (this.chungtuSelect.Dien_Giai === null || this.chungtuSelect.Dien_Giai === '') {
      swal('Cảnh báo!', "Bạn chưa nhập diễn giải", 'warning');
      return;
    }

    //for (let i = 0; i < this.chungtuSelect.ChiTiets.length; i++) {
    //  var row = this.chungtuSelect.ChiTiets[i];
    //  if (!this.datecheck(row.NgayCTG)) {
    //    swal('Cảnh báo!', "Ngày chứng từ gốc không hợp lệ", 'warning');
    //    this.setComboFocus(i, 4, 1);
    //    return;
    //  }
    //}
    //console.log("luu chung tu");
    //console.log(this.namketoan);
    //console.log(this.chungtuSelect.Ngay_Ghi_So.getFullYear());
    //console.log(this.chungtuSelect.Ngay_Ghi_So);
    let ngayghiso_nam = this.chungtuSelect.Ngay_Ghi_So.getFullYear();

    if (this.namketoan.toString() !== ngayghiso_nam.toString()) {
      //swal('Cảnh báo!', "Ngày hạch toán nằm ngoài năm kế toán!!!", 'warning');
      //return;
      swal({
        title: 'Ngày hạch toán nằm ngoài năm kế toán. Bạn muốn tiếp tục lưu không?',
        text: 'Dữ liệu sẽ được lưu vào năm ' + this.chungtuSelect.Ngay_Ghi_So.getFullYear().toString() + '. Vui lòng chọn năm ' + this.chungtuSelect.Ngay_Ghi_So.getFullYear().toString() + ' sau khi lưu để nhìn thấy chứng từ',
        icon: "warning",
        buttons: {
          cancel: {
            text: 'Hủy bỏ',
            value: false,
            visible: true,
          },
          confirm: {
            text: 'Đồng ý',
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true
          }
        }
      })
        .then(willDelete => {
          if (willDelete) {

            /// bắt đầu
            //this.chungtuSelect.Ngay_Ghi_So_S = this.ngayghiso.getDate() + '/' + (this.ngayghiso.getMonth() + 1) + '/' + this.ngayghiso.getFullYear();
            //this.chungtuSelect.Ngay_Chung_Tu_S = this.ngaychungtu.getDate() + '/' + (this.ngaychungtu.getMonth() + 1) + '/' + this.ngaychungtu.getFullYear();
                        
            $('#loading').show();
            this.repo.postRequest("api/chungtu/addorupdate_ctuv2", this.chungtuSelect)
              .subscribe(response => {
                //console.log(response);
                $('#loading').hide();
                if (response.Status === "OK") {
                  this.chungtuSelect.Id = response.Data.Id;
                  this.chungtuSelect.ChiTiets = response.Data.ChiTiets;
                  if (loai == 0)
                    this.savechungtu.emit(this.chungtuSelect);
                  else
                    swal('Thông báo!', "Lưu lại thành công!", 'success');
                }
                else
                  swal('Cảnh báo!', response.Status, 'warning');
              });
            // kết thúc

          }
        });
    } else {
      /// bắt đầu
      //this.chungtuSelect.Ngay_Ghi_So_S = this.ngayghiso.getDate() + '/' + (this.ngayghiso.getMonth() + 1) + '/' + this.ngayghiso.getFullYear();
      //this.chungtuSelect.Ngay_Chung_Tu_S = this.ngaychungtu.getDate() + '/' + (this.ngaychungtu.getMonth() + 1) + '/' + this.ngaychungtu.getFullYear();

      
      $('#loading').show();
      this.repo.postRequest("api/chungtu/addorupdate_ctuv2", this.chungtuSelect)
        .subscribe(response => {
          //console.log(response);
          $('#loading').hide();
          if (response.Status === "OK") {
            this.chungtuSelect.Id = response.Data.Id;
            this.chungtuSelect.ChiTiets = response.Data.ChiTiets;
            if (loai == 0)
              this.savechungtu.emit(this.chungtuSelect);
            else
              swal('Thông báo!', "Lưu lại thành công!", 'success');
          }
          else
            swal('Cảnh báo!', response.Status, 'warning');
        });
      // kết thúc
    }



  }
  closeModal() {
    this.savechungtu.emit(null);
  }
  public changeTienThue() {
    let rowct = null;
    this.chungtuSelect.ChiTiets.forEach(
      (item) => {
        if (item.Tai_Khoan_No_Id.toLowerCase() == ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase())
          rowct = item;
      }
    );

    if (this.chungtuSelect.Tien_Thue != 0) {
      var sp = 'bottom';
      if (this.chungtuSelect.ChiTiets.length > 9)
        sp = 'top'

      if (rowct == null) {
        this.chungtuSelect.ChiTiets.push({
          showpopup: sp, Chung_Tu_Id: null, Tai_Khoan_No: null, Tai_Khoan_Co: null
          , Tai_Khoan_No_Id: this.chungtuSelect.Tai_Khoan_Thue_Id, Tai_Khoan_Co_Id: this.chungtuSelect.Tai_Khoan_Co_Id
          , Dien_Giai_Chi_Tiet: null, So_Luong: null, Don_Gia: null, So_Tien: this.chungtuSelect.Tien_Thue, Ty_Gia: null, Ty_Gia_No: null, Ty_Gia_Co: null
          , Ngoai_Te: null, Chi_Tiet_No_Id: null, Doi_Tuong_Id_No: null, Hang_Hoa_Id_No: null
          , Kho_Hang_Id_No: null, Don_Vi_Tinh_Id_No: null, Hop_Dong_Id_No: null,
          Tai_San_Id_No: null, Khoan_Muc_Id_No: null, Lenh_Sua_Chua_Id_No: null
          , Doi_Tuong_Id_1_No: null, Doi_Tuong_Id_2_No: null, Doi_Tuong_Id_3_No: null
          , Doi_Tuong_Id_4_No: null, Doi_Tuong_Id_5_No: null

          , Chi_Tiet_Co_Id: null, Doi_Tuong_Id_Co: this.chungtuSelect.Doi_Tuong_Id, Hang_Hoa_Id_Co: null
          , Kho_Hang_Id_Co: null, Don_Vi_Tinh_Id_Co: null, Hop_Dong_Id_Co: null,
          Tai_San_Id_Co: null, Khoan_Muc_Id_Co: null, Lenh_Sua_Chua_Id_Co: null
          , Doi_Tuong_Id_1_Co: null, Doi_Tuong_Id_2_Co: null, Doi_Tuong_Id_3_Co: null
          , Doi_Tuong_Id_4_Co: null, Doi_Tuong_Id_5_Co: null

          , Doi_Tuong_No: null, Hang_Hoa_No: null
          , Kho_Hang_No: null, Don_Vi_Tinh_No: null
          , Hop_Dong_No: null, Khe_Uoc_No: null
          , Khoan_Muc_No: null, Lenh_Sua_Chua_No: null
          , Tai_San_No: null, Doi_Tuong_1_No: null
          , Doi_Tuong_2_No: null, Doi_Tuong_3_No: null
          , Doi_Tuong_4_No: null, Doi_Tuong_5_No: null

          , Doi_Tuong_Co: this.chungtuSelect.Ma_Doi_Tuong, Hang_Hoa_Co: null
          , Kho_Hang_Co: null, Don_Vi_Tinh_Co: null
          , Hop_Dong_Co: null, Khe_Uoc_Co: null
          , Khoan_Muc_Co: null, Lenh_Sua_Chua_Co: null
          , Tai_San_Co: null, Doi_Tuong_1_Co: null
          , Doi_Tuong_2_Co: null, Doi_Tuong_3_Co: null
          , Doi_Tuong_4_Co: null, Doi_Tuong_5_Co: null


        });
      }
      else {
       // rowct.So_Tien = this.chungtuSelect.Tien_Thue;
        this.chungtuSelect.ChiTiets.forEach(
          (item1) => {
            if (item1.Tai_Khoan_No_Id.toLowerCase() == ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase())
              item1.So_Tien = this.chungtuSelect.Tien_Thue;
          }
        );
      }

    }
  }

  public changeTaiKhoanTT(event: any, noco: number) {
    
    if (typeof event === "undefined") {

    }
    else {

      if (noco == 1) {
        this.chungtuSelect.ChiTiets.forEach(
          (item) => {
            if (item.Tai_Khoan_No_Id.toLowerCase() != ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase())
            item.Tai_Khoan_No_Id = event;
          }
        );
      }
      if (noco == 2) {
        this.chungtuSelect.ChiTiets.forEach(
          (item) => {
            item.Tai_Khoan_Co_Id = event;
          }
        );
      }
      if (noco == 3) {
        let rowct = null;
        this.chungtuSelect.ChiTiets.forEach(
          (item) => {
            if (item.Tai_Khoan_No_Id.toLowerCase() == ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase())
              rowct = item;
          }
        );

        if (this.chungtuSelect.Tien_Thue != 0) {
          var sp = 'bottom';
          if (this.chungtuSelect.ChiTiets.length > 9)
            sp = 'top'
          if (rowct == null) {
          this.chungtuSelect.ChiTiets.push({
            showpopup: sp, Chung_Tu_Id: null, Tai_Khoan_No: null, Tai_Khoan_Co: null
            , Tai_Khoan_No_Id: event, Tai_Khoan_Co_Id: this.chungtuSelect.Tai_Khoan_Co_Id
            , Dien_Giai_Chi_Tiet: null, So_Luong: null, Don_Gia: null, So_Tien: this.chungtuSelect.Tien_Thue, Ty_Gia: null, Ty_Gia_No: null, Ty_Gia_Co: null
            , Ngoai_Te: null, Chi_Tiet_No_Id: null, Doi_Tuong_Id_No: null, Hang_Hoa_Id_No: null
            , Kho_Hang_Id_No: null, Don_Vi_Tinh_Id_No: null, Hop_Dong_Id_No: null,
            Tai_San_Id_No: null, Khoan_Muc_Id_No: null, Lenh_Sua_Chua_Id_No: null
            , Doi_Tuong_Id_1_No: null, Doi_Tuong_Id_2_No: null, Doi_Tuong_Id_3_No: null
            , Doi_Tuong_Id_4_No: null, Doi_Tuong_Id_5_No: null

            , Chi_Tiet_Co_Id: null, Doi_Tuong_Id_Co: this.chungtuSelect.Doi_Tuong_Id, Hang_Hoa_Id_Co: null
            , Kho_Hang_Id_Co: null, Don_Vi_Tinh_Id_Co: null, Hop_Dong_Id_Co: null,
            Tai_San_Id_Co: null, Khoan_Muc_Id_Co: null, Lenh_Sua_Chua_Id_Co: null
            , Doi_Tuong_Id_1_Co: null, Doi_Tuong_Id_2_Co: null, Doi_Tuong_Id_3_Co: null
            , Doi_Tuong_Id_4_Co: null, Doi_Tuong_Id_5_Co: null

            , Doi_Tuong_No: null, Hang_Hoa_No: null
            , Kho_Hang_No: null, Don_Vi_Tinh_No: null
            , Hop_Dong_No: null, Khe_Uoc_No: null
            , Khoan_Muc_No: null, Lenh_Sua_Chua_No: null
            , Tai_San_No: null, Doi_Tuong_1_No: null
            , Doi_Tuong_2_No: null, Doi_Tuong_3_No: null
            , Doi_Tuong_4_No: null, Doi_Tuong_5_No: null

            , Doi_Tuong_Co: this.chungtuSelect.Ma_Doi_Tuong, Hang_Hoa_Co: null
            , Kho_Hang_Co: null, Don_Vi_Tinh_Co: null
            , Hop_Dong_Co: null, Khe_Uoc_Co: null
            , Khoan_Muc_Co: null, Lenh_Sua_Chua_Co: null
            , Tai_San_Co: null, Doi_Tuong_1_Co: null
            , Doi_Tuong_2_Co: null, Doi_Tuong_3_Co: null
            , Doi_Tuong_4_Co: null, Doi_Tuong_5_Co: null


            });
          }
          else {
            //rowct.So_Tien = this.chungtuSelect.Tien_Thue;
            this.chungtuSelect.ChiTiets.forEach(
              (item1) => {
                if (item1.Tai_Khoan_No_Id.toLowerCase() == ("01EE11D0-90C6-4BE7-87AB-E595FCD1B6AF").toLowerCase())
                  item1.So_Tien = this.chungtuSelect.Tien_Thue;
              }
            );
          }

        }
      }
      
      this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            this.HienThis = response.Data;
          }
        });
    }

  }

  //-------------ham tiện ích---------------------------------------
  public changeTaiKhoan(event: any, noco: number, row: any, index: number) {
    let roW1;
    //console.log("changeTaiKhoan tt");
    //console.log(event);
    //console.log(this.inputtaikhoans);
    if (typeof event === "undefined") {

    }
    else {
      if (noco == 1) {
        this.chungtuSelect.ChiTiets[index].Tai_Khoan_No_Id = event;
        //this.chungtuSelect.ChiTiets[index].Tai_Khoan_No = event.Ma_Tai_Khoan;

      }
      else {

        this.chungtuSelect.ChiTiets[index].Tai_Khoan_Co_Id = event;
        if (event == ("C4B629A0-00E5-4A30-AED9-DADC21C9AE68").toLowerCase()) {
          this.chungtuSelect.ChiTiets[index].Doi_Tuong_Id_Co = this.chungtuSelect.Doi_Tuong_Id;
          this.chungtuSelect.ChiTiets[index].Doi_Tuong_Co = this.chungtuSelect.Ma_Doi_Tuong;
        }
        //this.chungtuSelect.ChiTiets[index].Tai_Khoan_Co = event.Ma_Tai_Khoan;
      }
     // this.chungtuSelect.ChiTiets[index] = roW1;
      //console.log("cau hinh tt");
      //console.log(this.chungtuSelect.ChiTiets);
      
      this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            //console.log("cau hinh tt");
            //console.log(response);
            //console.log(this.HienThis);
            this.HienThis = response.Data;
            if (this.chungtuSelect.ChiTiets[index].Dien_Giai_Chi_Tiet == null || this.chungtuSelect.ChiTiets[index].Dien_Giai_Chi_Tiet == "")
              this.chungtuSelect.ChiTiets[index].Dien_Giai_Chi_Tiet = this.chungtuSelect.Dien_Giai;
          }
        });
    }
      
  }
  public changeLoaiThue(event: any, row: any, index: number) {
    let roW1;
    if (typeof event === "undefined") {

    }
    else {
      //this.chungtuSelect.HoaDons[index].Tai_Khoan_No_Id = event;
      this.chungtuSelect.HoaDons[index].Loai_Thue_Id = event;
      this.repo.postRequest("api/chungtu/thaydoiloaithue", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            this.chungtuSelect.HoaDons[index].Thue_Suat = response.Data.Thue_Suat;
            this.chungtuSelect.HoaDons[index].Tien_Hang = response.Data.Tien_Hang;
            this.chungtuSelect.HoaDons[index].Tien_Thue = response.Data.Tien_Thue;
            this.chungtuSelect.HoaDons[index].Doi_Tuong = response.Data.Doi_Tuong;
            this.chungtuSelect.HoaDons[index].Ma_So_Thue = response.Data.Ma_So_Thue;
            this.chungtuSelect.HoaDons[index].Dien_Giai = response.Data.Dien_Giai;
            this.chungtuSelect.HoaDons[index].Ngay_Hoa_Don = new Date(response.Data.Ngay_Hoa_Don);
          }
        });
    }

  }
  public changeLuoi(drow: any) {
    this.thaydoi = true;
  }
  tongtien: number = 0;
  public changeSL(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong;
    if(this.HienThis.Hang_Hoa_Id_Co==true)
      this.layGiaHang(drow);
  }
  public changeDG(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong;
    this.changeST(drow);
  }
  public changeST(drow: any) {
    this.thaydoi = true;
    this.chungtuSelect.Tong_Tien = 0;
    this.chungtuSelect.ChiTiets.forEach(
      (item) => {
        //console.log(item.So_Tien);
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
      }
    );
  }
 
  datecheck(conformedValue) {
    if (conformedValue == null || conformedValue === "")
      return true;
    const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS']
    var dateFormat: String = 'dd/mm/yyyy';
    var maxYear: number = 9999
    var minYear: number = 1;
    const dateFormatArray = dateFormat.split(/[^dmyHMS]+/).sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b))

    const indexesOfPipedChars = []
    const maxValue = { 'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59 }
    const minValue = { 'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0 }
    const conformedValueArr = conformedValue.split('')

    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format)
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10)

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position]
        conformedValueArr[position] = 0
        indexesOfPipedChars.push(position)
      }
    })

    // Check for invalid date
    let month = 0
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)
      if (format === 'mm') {
        month = value || 0
      }
      const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format]
      if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
        const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10)
        const scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10)
        return value < scopedMinValue || value > scopedMaxValue
      }
      return value > maxValueForFormat || (textValue.length === length && value < minValue[format])
    })

    if (isInvalid) {
      return false
    }

    return true;
  }

  export(kieuin, loaiphieu) {
    //loaiphieu=1 là in phiếu thu
    //loaiphieu=2 là in phiếu chi
    //loaiphieu=3 là nhập kho
    //loaiphieu=4 là xuất kho
    //loaiphieu=5 là chứng từ kế toán
    //loaiphieu=6 là hóa đơn bán hàng
    //loaiphieu=7 là biên lại thu tiền
    //loaiphieu=8 là giấy đề nghị thanh toán
    //loaiphieu=9 là bảng kê đề nghị thanh toán
    let idchungtu = "";
    idchungtu = this.chungtuSelect.Id;
    //this.datas.forEach((item: any) => {
    //  if (item.Tich == true) {
    //    if (idchungtu == "")
    //      idchungtu = item.Id;
    //    else
    //      idchungtu = idchungtu + "," + item.Id;
    //  }
    //});
    if (loaiphieu == null || loaiphieu == "") {
      swal('Cảnh báo!', 'Chưa chọn loại phiếu để in!.', 'warning');
      return;
    }
    if (idchungtu == null || idchungtu == "") {
      swal('Cảnh báo!', 'Chưa chọn phiếu để in!.', 'warning');
      return;
    }

    $('#loading').show();
    let data = {
      Id: BaoCao.BC05_PhieuChungTu,
      Type: kieuin,
      Data: JSON.stringify({
        DonViId: this.currentUser.id_don_vi
        , UserName: this.currentUser.user_name
        , IdChungTu: idchungtu
        , LoaiPhieu: loaiphieu
        , DoanhNghiepId: this.doanhnghiepid

      })
    };
    if (kieuin === 'xlsx') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          var file = new Blob([response.body], { type: 'vnd.ms-excel' });
          var fileURL = URL.createObjectURL(file);
          $('#loading').hide();
          var a = document.createElement("a");
          a.href = fileURL;
          a.download = "phieu_nhap_kho.xlsx";
          document.body.appendChild(a);
          a.click();

        });
    }
    if (kieuin === 'pdf') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          if (response == "L") {
            this.repo.postRequest(Global.BASE_BaoCao_SAVE, data).subscribe(
              data => {
                $('#loading').hide();
              },
              error => {
                this.msg = error;
              }
            );
          }
          else {
            $('#loading').hide();
            var file = new Blob([response.body], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "EPrescription");
          }

        });
    }




  }


  


}
export interface Idata {
  Id: string,
  Ten: string,
  Dien_Thoai: string,
  Dia_Chi: string,
  Email: string,
  GhiChu: string,
  Created: Date,
  CreatedBy: string,
  Modified: Date,
  ModifiedBy: string
}
