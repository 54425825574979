import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Global } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  Lists_donvitinh, Lists_phongban, Lists_loaitaisan
  } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
const swal = require('sweetalert');

@Component({
  selector: 'app-quantri-doanhnghiepedit',
  templateUrl: './doanhnghiepedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./doanhnghiepedit.component.scss'],
  providers: [ListService, Repository]
})


export class doanhnghiepeditComponent implements OnInit {

  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
  @ViewChild('dteChung') doituongchungedit: any;
  @ViewChild('slTaiKhoanNH') slTaiKhoanNH: any;
  @ViewChild('inputTenKB') inputTenKB: any;
  doanhnghiepid: string = null;
  currentUser: any;

  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  inputparents: any;
  inputnganhangs: any;



  donviid: any;
  tabselected: number = 1;

  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Don_Vi: null, Ten_Don_Vi: null, Dia_Chi: null, Ma_So_Thue: null, Dien_Thoai: null, Fax: null, Hom_Thue: null, Ma_Ao: null,
    Parent: null, Mo_Ta: null, connectionString: null, InitialCatalog: null, name: null, providerName: null
  };
  nhomdtid: string = null;


  @Output() save: EventEmitter<any> = new EventEmitter();



  constructor(private _dataService: ListService, private repo: Repository) {


  }

  ngOnInit(): void {


  }
  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  onDoiTuongChange($event) {

    if (typeof $event === "undefined") {
      this.doituongSelect.KBNN_Ten = null;
    }
    else
      this.doituongSelect.KBNN_Ten = $event.Ten_Doi_Tuong;
  }
  @ViewChild("mafocus") mafocus: ElementRef;

  loadDataInit(nhomdoituongid: string, id: string, donviid: string) {

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {

      let data = JSON.parse(authlogin);
      this.donviid = data.id_don_vi;
      this.currentUser = data;
      var giatri = data.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;


    }

    this.nhomdtid = nhomdoituongid;
    setTimeout(() => {
      this.mafocus.nativeElement.focus();
    }, 500);
   
    //this.repo.getRequest("api/donvi/getall/")
    //  .subscribe(response => {
    //    this.inputparents = response;
    //  });


    this.doituongSelect = {
      Id: null, Ma_Don_Vi: null, Ten_Don_Vi: null, Dia_Chi: null, Ma_So_Thue: null, Dien_Thoai: null, Fax: null, Hom_Thue: null, Ma_Ao: null,
      Parent: null, Mo_Ta: null, connectionString: null, InitialCatalog: null, name: null, providerName: null
    };

    this.doituongSelect.Doanh_Nghiep_Id = this.doanhnghiepid;

    if (id === null) {

    }
    else {
      console.log(id);
      this.repo.postRequest("api/doanhnghiep/getbyid/", { DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, UserName: this.currentUser.user_name, Id: id })
        .subscribe(response => {
          console.log(response);
          this.doituongSelect.Id = id;
          this.doituongSelect.Ma_Don_Vi = response.Ma_Don_Vi;
          this.doituongSelect.Ten_Don_Vi = response.Ten_Don_Vi;
          this.doituongSelect.Dia_Chi = response.Dia_Chi;
          this.doituongSelect.Ma_So_Thue = response.Ma_So_Thue;
          this.doituongSelect.Dien_Thoai = response.Dien_Thoai;
          this.doituongSelect.Fax = response.Fax;
          this.doituongSelect.Hom_Thue = response.Hom_Thue;
          this.doituongSelect.Ma_Ao = response.Ma_Ao;
          this.doituongSelect.Parent = response.Parent;
          this.doituongSelect.Mo_Ta = response.Mo_Ta;
          this.doituongSelect.connectionString = response.connectionString;
          this.doituongSelect.InitialCatalog = response.InitialCatalog;
          this.doituongSelect.name = response.name;
          this.doituongSelect.providerName = response.providerName;
          
         // this.doituongSelect.Thu_Tu = response.Thu_Tu;

        });
    }


  }
  selectedTab(tab: number) {
    this.tabselected = tab;
    if (tab === 2) {

    }
    else {
      setTimeout(() => {
        this.mafocus.nativeElement.focus();
      }, 100);
    }

  }
  keyTenPress(event: any) {
    if (event.which === 13) {
      // this.slLoaiTaiSan.focus();
      event.preventDefault();
    }
  }
  keySoTKPress(event: any) {
    if (event.which === 13) {
      this.inputTenKB.focus();
      event.preventDefault();
    }
  }



  public closeModal() {
    this.save.emit(null);
  }
  public saveproduct() {
    if (this.doituongSelect.Ma_Don_Vi == null || this.doituongSelect.Ma_Don_Vi == "") {
      swal('Lỗi!', 'Vui lòng nhập mã đơn vị sử dụng!.', 'error');
      return;
    }
    if (this.doituongSelect.Ten_Don_Vi == null || this.doituongSelect.Ten_Don_Vi == "") {
      swal('Lỗi!', 'Vui lòng nhập tên đơn vị sử dụng!.', 'error');
      return;
    }



    this.repo.postRequest("api/doanhnghiep/addorupdate", this.doituongSelect)
      .subscribe(response => {
        if (response != null && response.match('-')) {
          this.doituongSelect.Id = response;
          this.save.emit(this.doituongSelect);
        }
        else {
          swal('Lỗi!', response, 'error');
        }

      });
  }

  comboSeleted: NgSelectComponent;
  AddDoiTuong(cobo: NgSelectComponent) {

    //this.comboSeleted = cobo;
    //cobo.close();
    //this.doituongchungedit.loadDataInit(Lists_nganhang.id, null, this.donviid);
    //this.mdDoiTuongChung.open();
  }
  saveModal(doituong: any) {
    console.log(doituong);
    this.mdDoiTuongChung.close();
    this.comboSeleted.focus();
  }



}


