import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { Lists, Lists_phongban, Lists_khohang, Lists_donvitinh, Lists_khachhang, Lists_nhacungcap, Lists_xe } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'



const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmucvattuedit',
  templateUrl: './danhmucvattuedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmucvattuedit.component.scss'],
    providers: [ListService, Repository]
})


export class danhmucvattueditComponent implements OnInit {
  tabselected: number = 1;
  doanhnghiepid: string = null;
  public ngaybatdau: Date = null;
  public ngayketthuc: Date = null;
  public ngayxeve: Date = null;
  public ngaynhanno: Date = null;
  public ngaygiaochiakhoa: Date = null;
  nhomloaikhoanid: any;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputhangbaohiems: any;
  inputdonvitinhs: any;
  inputxuatxus: any;
  inputkhohangs: any;
  inputphongbans: any;
  inputhangxes: any;
  inputkhachhangs: any;
  inputmausacs: any;
  inputmodels: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    , Doanh_Nghiep_Id: null
    , So_Khung: null, So_May: null, Hang_Xe: null, Model_Xe: null
    , Nha_Cung_Cap_Id: null, Mau_Sac: null, So_Km: null, Nam_San_Xuat: null
    , Hang_Bao_Hiem_Id: null, So_Hop_Dong_Bao_Hiem: null, Ngay_Bat_Dau_Bao_Hiem: null, Ngay_Bat_Dau_Bao_HiemS: null
    , Ngay_Ket_Thuc_Bao_Hiem: null, Ngay_Ket_Thuc_Bao_HiemS: null, ViTris: []
    , Ma_Vach: null, Ten_Khac: null, Chat_Lieu: null, Chi_Phi: null, Don_Vi_Tinh_Id: null, Xuat_Xu_Id: null
    , So_Chia_Khoa: null, Kich_Co: null, Ngay_Xe_Ve: null, Ngay_Xe_VeS: null, Ngay_Lam_PDI: null
    , Ngay_Lam_PDIS: null, Ngay_Giao_Chia_Khoa: null, Ngay_Giao_Chia_KhoaS: null, Phu_Kien: null
    , Theo_Doi_Ton: null, Co_Hoa_Don: null, Chinh_Hang: null, Thue_Suat: null, Gia_Nhap: null
    , Gia_Ban_Le: null, Gia_Ban_Buon: null, Cha: null

  };       
    nhomdtid:string=null;
  hoatdong: string = null;

  inputloais: any = [
    { text: "Nhân công", value: 0 },
    { text: "Vật tư sơn", value: 1 },
  ];

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();

  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  selectedTab(tab: number) {
    this.tabselected = tab;
    //if (tab === 2) {


    //  setTimeout(() => {
    //    this.dateGhiTang.focus();
    //  }, 100);
    //}
    //else {
    //  setTimeout(() => {
    //    this.mafocus.nativeElement.focus();
    //  }, 100);
    //}

  }
  keyComBoPress(event: any, hang: number, cot: number, luoi: number) {

    //    if (event.which === 13) {
    //  cot = cot + 1;
    //  if (cot > this.maxcot) {
    //    hang = hang + 1;
    //    cot = 1;
    //    this.AddRowNew(this.doituongSelect.ChiTiets);

    //  }
    //  this.setComboFocus(hang, cot, luoi);
    //  event.preventDefault();
    //}
  }

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }

    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;
  onChangeHangXe($event) {

    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: this.doituongSelect.Hang_Xe })
      .subscribe(response => {
        this.inputmodels = response;
      });
    


  }
  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata : any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

    this.tabselected = 1;

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);
    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputparents = response;
    //  });

    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_phongban.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputphongbans = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_khohang.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputkhohangs = response;
      });
    

    this.repo.postRequest("api/doituong/gethangxe/", { DoanhNghiepId: this.doanhnghiepid,  Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputhangxes = response;
      });
    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputmodels = response;
      });

    this.repo.postRequest("api/doituong/getkhachhang/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputkhachhangs = response;
      });

    this.repo.postRequest("api/doituong/getmausac/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputmausacs = response;
      });

    this.repo.postRequest("api/doituong/gethangbaohiem/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
      .subscribe(response => {
        this.inputhangbaohiems = response;
      });

    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_donvitinh.id, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputdonvitinhs = response;
      });

    this.repo.postRequest("api/xuatxu/getall/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputxuatxus = response;
      });

    

    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid
      , So_Khung: null, So_May: null, Hang_Xe: null, Model_Xe: null
      , Nha_Cung_Cap_Id: null, Mau_Sac: null, So_Km: null, Nam_San_Xuat: null
      , Hang_Bao_Hiem_Id: null, So_Hop_Dong_Bao_Hiem: null, Ngay_Bat_Dau_Bao_Hiem: null, Ngay_Bat_Dau_Bao_HiemS: null
      , Ngay_Ket_Thuc_Bao_Hiem: null, Ngay_Ket_Thuc_Bao_HiemS: null, ViTris: []

      , Ma_Vach: null, Ten_Khac: null, Chat_Lieu: null, Chi_Phi: null, Don_Vi_Tinh_Id: null, Xuat_Xu_Id: null
      , So_Chia_Khoa: null, Kich_Co: null, Ngay_Xe_Ve: null, Ngay_Xe_VeS: null, Ngay_Lam_PDI: null
      , Ngay_Lam_PDIS: null, Ngay_Giao_Chia_Khoa: null, Ngay_Giao_Chia_KhoaS: null, Phu_Kien: null
      , Theo_Doi_Ton: null, Co_Hoa_Don: null, Chinh_Hang: null, Thue_Suat: null, Gia_Nhap: null
      , Gia_Ban_Le: null, Gia_Ban_Buon: null, Cha: null

    };    
    if (id === null)
    {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
              this.doituongSelect.Ma_Doi_Tuong = response;
            });
          
          this.doituongSelect.Trang_Thai = true;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = new Date();
          this.doituongSelect.CreatedBy = this.currentUser.user_name;
          this.doituongSelect.Modified = new Date();
          this.doituongSelect.ModifiedBy = this.currentUser.user_name;

          if (rowdata != null) {
            this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
            this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
            this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
            this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
            this.doituongSelect.Parent = rowdata.Parent;
            this.doituongSelect.Cha = rowdata.Cha;

            //this.doituongSelect.Bao_Hanh = rowdata.Bao_Hanh;
            //this.doituongSelect.Loai = rowdata.Loai;
            //this.doituongSelect.Gio_Dinh_Muc = rowdata.Gio_Dinh_Muc;
            //this.doituongSelect.Gia_Dinh_Muc = rowdata.Gia_Dinh_Muc;
            //this.doituongSelect.Gia_Ban = rowdata.Gia_Ban;
            //this.doituongSelect.Vat = rowdata.Vat;
            //this.doituongSelect.Model = rowdata.Model;
            //this.doituongSelect.Phong_Ban_Id = rowdata.Phong_Ban_Id;


          }

      this.repo.postRequest("api/doituonghang/getvitri/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, Id: this.doituongSelect.Id })
        .subscribe(response => {
          this.doituongSelect.ViTris = response;
        });
    }
        else {
      console.log("t11");
      this.repo.postRequest("api/doituonghang/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
                console.log(response);
              this.doituongSelect.Id = id;
              this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
              this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
              this.doituongSelect.Trang_Thai = response.Trang_Thai;
              this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
              this.doituongSelect.Parent = response.Parent;
              this.doituongSelect.Ma_Ao = response.Ma_Ao;
              this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
              this.doituongSelect.Created = response.Created;
              this.doituongSelect.CreatedBy = response.CreatedBy;
              this.doituongSelect.Modified = new Date();
              this.doituongSelect.ModifiedBy = this.currentUser.user_name;

              this.doituongSelect.Cha = response.Cha;

              this.doituongSelect.So_Khung = response.So_Khung;
              this.doituongSelect.So_May = response.So_May;
              this.doituongSelect.Hang_Xe = response.Hang_Xe;
              this.doituongSelect.Model_Xe = response.Model_Xe;
              this.doituongSelect.Nha_Cung_Cap_Id = response.Nha_Cung_Cap_Id;
              this.doituongSelect.Mau_Sac = response.Mau_Sac;
              this.doituongSelect.So_Km = response.So_Km;
              this.doituongSelect.Nam_San_Xuat = response.Nam_San_Xuat;

              this.doituongSelect.Hang_Bao_Hiem_Id = response.Hang_Bao_Hiem_Id;
              this.doituongSelect.So_Hop_Dong_Bao_Hiem = response.So_Hop_Dong_Bao_Hiem;
              if (response.Ngay_Bat_Dau_Bao_Hiem != null)
                this.ngaybatdau = new Date(response.Ngay_Bat_Dau_Bao_Hiem);
              else
                this.ngaybatdau = null;

              if (response.Ngay_Ket_Thuc_Bao_Hiem != null)
                this.ngayketthuc = new Date(response.Ngay_Ket_Thuc_Bao_Hiem);
              else
                this.ngayketthuc = null;
              

              if (response.Ngay_Xe_Ve != null)
                this.ngayxeve = new Date(response.Ngay_Xe_Ve);
              else
                this.ngayxeve = null;


              if (response.Ngay_Lam_PDI != null)
                this.ngaynhanno = new Date(response.Ngay_Lam_PDI);
              else
                this.ngaynhanno = null;

              if (response.Ngay_Giao_Chia_Khoa != null)
                this.ngaygiaochiakhoa = new Date(response.Ngay_Giao_Chia_Khoa);
              else
                this.ngaygiaochiakhoa = null;


              this.doituongSelect.Ma_Vach = response.Ma_Vach;
              this.doituongSelect.Ten_Khac = response.Ten_Khac;
              this.doituongSelect.Chat_Lieu = response.Chat_Lieu;
              this.doituongSelect.Chi_Phi = response.Chi_Phi;
              this.doituongSelect.Don_Vi_Tinh_Id = response.Don_Vi_Tinh_Id;
              this.doituongSelect.Xuat_Xu_Id = response.Xuat_Xu_Id;
              this.doituongSelect.So_Chia_Khoa = response.So_Chia_Khoa;
              this.doituongSelect.Kich_Co = response.Kich_Co;
              this.doituongSelect.Phu_Kien = response.Phu_Kien;
              this.doituongSelect.Theo_Doi_Ton = response.Theo_Doi_Ton;
              this.doituongSelect.Co_Hoa_Don = response.Co_Hoa_Don;
              this.doituongSelect.Chinh_Hang = response.Chinh_Hang;
              this.doituongSelect.Thue_Suat = response.Thue_Suat;
              this.doituongSelect.Gia_Nhap = response.Gia_Nhap;
              this.doituongSelect.Gia_Ban_Le = response.Gia_Ban_Le;
              this.doituongSelect.Gia_Ban_Buon = response.Gia_Ban_Buon;

              //this.doituongSelect.SapXep = response.SapXep;


              this.repo.postRequest("api/doituonghang/getvitri/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, Id: this.doituongSelect.Id })
                .subscribe(response => {
                  this.doituongSelect.ViTris = response;
                });

            }); 
        }
        
       
    }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    if (this.doituongSelect.Ma_Doi_Tuong == null || this.doituongSelect.Ma_Doi_Tuong == "") {
      swal('Lỗi!', 'Vui lòng nhập mã đối tượng!.', 'error');
      return;
    }
    if (this.ngaybatdau != null)
      this.doituongSelect.Ngay_Bat_Dau_Bao_HiemS = this.ngaybatdau.getDate() + '/' + (this.ngaybatdau.getMonth() + 1) + '/' + this.ngaybatdau.getFullYear();
    if (this.ngayketthuc != null)
      this.doituongSelect.Ngay_Ket_Thuc_Bao_HiemS = this.ngayketthuc.getDate() + '/' + (this.ngayketthuc.getMonth() + 1) + '/' + this.ngayketthuc.getFullYear();

    if (this.ngayxeve != null)
      this.doituongSelect.Ngay_Xe_Ves = this.ngayxeve.getDate() + '/' + (this.ngayxeve.getMonth() + 1) + '/' + this.ngayxeve.getFullYear();
    if (this.ngaynhanno != null)
      this.doituongSelect.Ngay_Lam_PDIS = this.ngaynhanno.getDate() + '/' + (this.ngaynhanno.getMonth() + 1) + '/' + this.ngaynhanno.getFullYear();
    if (this.ngaygiaochiakhoa != null)
      this.doituongSelect.Ngay_Giao_Chia_KhoaS = this.ngaygiaochiakhoa.getDate() + '/' + (this.ngaygiaochiakhoa.getMonth() + 1) + '/' + this.ngaygiaochiakhoa.getFullYear();

    //console.log("save_ttt");
    //console.log(this.doituongSelect);
    $('#loading').show();
    this.repo.postRequest("api/doituonghang/addorupdate",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('-')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
  }


  deleterow(index: string, luoi: number) {
    console.log(index);
    swal({
      title: 'Bạn muốn xóa dòng này phải không?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.doituongSelect.ViTris.splice(index, 1);
        }
      });
  }
  RowNewClick() {
    this.AddRowNew(this.doituongSelect.ViTris);
  }
  AddRowNew(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Id: null, Kho_Hang_Id: null, Doi_Tuong_Id: null, Tang: null,
        Ke: null, O: null
      });
    }
    else {
      ctiets.push({
        showpopup: sp, Id: null, Kho_Hang_Id: null, Doi_Tuong_Id: null,
        Tang: null, Ke: null, O: null
      });

    }

  }
   
  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }
  indexdt: number;
  cotdt: string;
  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit2(this.doituongSelect.Id, this.nhomdtid, 'Vật tư, phụ tùng');
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }

  }

  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Hang_Hoa_Id") {
      this.dtechondoituongchungedit.loadDataInit(this.nhomdtid, Lists_xe.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }


  }

  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              this.doituongSelect.Parent = item.Id;
              this.doituongSelect.Cha = item.Ma + ' - ' + item.Ten;
            }
            i = i + 1;

          }
        );
        this.mdChonDoiTuongChung.close();
      }
      else {
        // console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          this.doituongSelect.Parent = null;
          this.doituongSelect.Cha = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.cotdt == "Hang_Hoa_Id") {
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = item.Id;
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = item.Ma;
              }
              this.mdChonDoiTuongChung.close();

            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = null;
          //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }

   

   


}
