<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <div class="header-logo">
    <a class="logo">
      <img src="/assets/img/logo-login.png" style="width: 100%;">
    </a>
    <!--<div class="desc">
      <div class="desc__title1">HỆ THỐNG</div>
      <h1 class="desc__title__main">PHẦN MỀM KẾ TOÁN</h1>
    </div>-->
  </div>
  <!-- END navbar header-->
  <!-- START Left navbar-->
  <ul class="nav navbar-nav mr-auto flex-column flex-lg-row" style="margin-left:10px;">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <!-- <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
          <em class="fas fa-bars"></em>
      </a> -->
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-lg-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block" *ngFor="let item of menuItems" [ngClass]="{'dropdown': item.submenu}" dropdown>
      <a class="nav-link" *ngIf="!item.heading && !item.submenu" [routerLink]="item.link" [title]="item.text">
        <em class="{{item.icon}}" *ngIf="item.icon && item.text!=null"></em>
        <span class="title-menu-cap1">{{(item.translate | translate) || item.text}}</span>
      </a>
      <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle *ngIf="item.submenu && item.text!=null">
        <em class="{{item.icon}}" *ngIf="item.icon && item.text!=null"></em>
        <span class="title-menu-cap1">{{(item.translate | translate) || item.text}}</span>
      </a>
      <!-- START Dropdown menu-->
      <div *dropdownMenu class="dropdown-menu animated fadeInUp" [ngClass]="{'submenu_scroll': item.submenu.length>=18}">
        <ul class="dropdown-item" *ngFor="let subitem of item.submenu" [title]="subitem.text" [ngClass]="{'AnChucNang': subitem.text==null,'DuongVien': subitem.border!=null}">
          <li *ngIf="!subitem.submenu" [routerLink]="subitem.link"><a style="text-decoration: none;color:black" [routerLink]="subitem.link">{{(subitem.translate | translate) || subitem.text}}</a></li>

          <li class="dropdown-submenu" *ngIf="subitem.submenu" style="display:flex;min-width:130px; justify-content:space-between;">
            <span tabindex="-1" style="display:inline-flex">{{ subitem.text}} </span>
            <!--<i class="fa fa-chevron-right" style="float:right;margin-top:5px;font-size:10px;"></i>-->
            <ul class="dropdown-menu">
              <li *ngFor="let subitem1 of subitem.submenu" [ngClass]="{'AnChucNang': subitem1.text==null}" [routerLink]="subitem1.link">
                <a style="text-decoration: none;color:black" [routerLink]="subitem1.link"><span>{{ subitem1.text}}</span></a>
              </li>
            </ul>
            <i class="fa fa-chevron-right" style="float:right;padding-left:10px;color:#9b9797; margin-top:5px;font-size:10px;"></i>
          </li>

        </ul>

      </div>

    </li>


  </ul>
  <!-- END Left navbar-->
  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <!-- Search icon-->

    <li class="nav-item dropdown dropdown-list" dropdown *ngIf="trangthaiupdate===1">
      <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
        <em class="fa-2x mr-2 fas fa-user-circle"></em>

      </a>
      <!-- START Dropdown menu-->
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
        <div class="dropdown-item">
          <!-- START list group-->
          <div class="list-group">
            <!-- list item-->
            <div class="list-group-item list-group-item-action" (click)="capnhat()">
              <div class="media">
                <div class="align-self-start mr-2">
                  <em class="fas fa-cloud-download-alt fa-2x text-info"></em>
                </div>
                <div class="media-body">
                  <p class="m-0">Cập nhật phiên bản mới</p>
                  <p class="m-0 text-muted text-sm">Hướng dẫn cập nhật phiên bản mới</p>
                </div>
              </div>
            </div>
            <!-- list item-->
            <div class="list-group-item list-group-item-action" (click)="lichsu()">
              <div class="media">
                <div class="align-self-start mr-2">
                  <em class="fa fa-history fa-2x text-success"></em>
                </div>
                <div class="media-body">
                  <p class="m-0">Lịch sử phát hành</p>
                  <p class="m-0 text-muted text-sm">Cập nhật tính năng mới, nâng cấp, fix lỗi</p>
                </div>
              </div>
            </div>
          </div>
          <!-- END list group-->
        </div>
      </div>
      <!-- END Dropdown menu-->
    </li>


    <li>
      <div class="row" style="color:white">
        <ul>
          <li class="dropdown dropdown-list" dropdown>
            <a dropdownToggle>
              {{tendoanhnghiep}}
              <span class="caret"></span>
            </a>
            <!-- START Dropdown menu-->
            <ul *dropdownMenu class="dropdown-menu animated flipInX">
              <li>
                <!-- START list group-->
                <div class="list-group">
                  <a class="list-group-item" (click)="changedoanhnghiep(row)" *ngFor='let row of dsdoanhnghieps; let i = index'>
                    <div class="media">
                      <div class="media-body clearfix">
                       <!--Mã DV: {{row.Ma}} --->
                        <p class="m0" [innerHtml]="row.Ten"></p>
                        <p class="m0 text-muted">
                          <small [innerHtml]="row.Dia_Chi"></small>
                        </p>
                      </div>
                    </div>
                  </a>

                </div>
                <!-- END list group-->
              </li>
            </ul>
            <!-- END Dropdown menu-->
          </li>

        </ul>
    </div>
    <div class="row" style="float:right;color:white">
      <ul class="navbar-nav flex-row">
        <li class="dropdown dropdown-list" dropdown>
          <a dropdownToggle>
            {{NamString}}
            <span class="caret"></span>
          </a>
          <!-- START Dropdown menu-->
          <ul *dropdownMenu class="dropdown-menu animated flipInX">
            <li>
              <!-- START list group-->
              <div class="list-group">
                <a class="list-group-item" (click)="valueChange(row)" *ngFor='let row of nams; let i = index'>
                  <div class="media">
                    <div class="media-body clearfix">
                      <p class="m0" [innerHtml]="row.text"></p>
                    </div>
                  </div>
                </a>

              </div>
              <!-- END list group-->
            </li>
          </ul>
          <!-- END Dropdown menu-->
        </li>

        <li class="dropdown dropdown-list" dropdown>
          <a dropdownToggle>
            {{tendonvi}}
            <span class="caret"></span>
          </a>
          <!-- START Dropdown menu-->
          <ul *dropdownMenu class="dropdown-menu animated flipInX">
            <li>
              <!-- START list group-->
              <div class="list-group">
                <a class="list-group-item" (click)="changechinhanh(row)" *ngFor='let row of dschinhanhs; let i = index'>
                  <div class="media">
                    <div class="media-body clearfix">
                      <p class="m0" [innerHtml]="row.Ten"></p>
                      <!--<p class="m0 text-muted">
                  <small [innerHtml]="row.Dia_Chi"></small>
                </p>-->
                    </div>
                  </div>
                </a>

              </div>
              <!-- END list group-->
            </li>
          </ul>
          <!-- END Dropdown menu-->
        </li>
      </ul>
    </div>
    </li>
    

    <li class="nav-item dropdown dropdown-list" dropdown>
      <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
        <em class="fa-2x mr-2 fas fa-user-circle"></em>

      </a>
      <!-- START Dropdown menu-->
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
        <li>
          <!-- START list group-->
          <div class="list-group">
            <a class="list-group-item" [routerLink]="['/quantri/doimatkhau']">
              <div class="media">
                <div class="align-self-start mr-2">
                  <i class="fa fa-exchange"></i>
                </div>
                <div class="media-body clearfix">
                  <p class="m0">Đổi mật khẩu</p>
                  <p class="m0 text-muted">
                    <small>Thay đổi mật khẩu tài khoản</small>
                  </p>
                </div>
              </div>
            </a>
            <a class="list-group-item" (click)="logout()">
              <div class="media">
                <div class="align-self-start mr-2">
                  <i class="fa fa-external-link"></i>
                </div>
                <div class="media-body clearfix">
                  <p class="m0">Đăng xuất</p>
                  <p class="m0 text-muted">
                    <small>Thay tài khoản</small>
                  </p>
                </div>
              </div>
            </a>

          </div>
          <!-- END list group-->
        </li>
      </ul>
      <!-- END Dropdown menu-->
    </li>
    <li class="nav-item" style="display: flex;justify-content: center;flex-direction: column;padding-right: 10px;">

      <div style="margin-left:-20px;color:#fff;">{{username}}</div>
    </li>


    <!-- START Offsidebar button-->
    <!--<li class="nav-item">
    <a class="nav-link" (click)="toggleOffsidebar(); $event.stopPropagation()">
      <em class="icon-notebook"></em>
    </a>
  </li>-->
    <!-- END Offsidebar menu-->
  </ul>
  <!-- END Right Navbar-->

  <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>
  <ul style="margin-left: -15px;width:100%;" *ngIf="trangthaiupdate===1">
    <li>
      <div class="alert alert-warning ng-star-inserted" style="padding:2px;">Đã có phiên bản mới vui lòng cập nhật phiên bản mới. <a (click)="capnhat()">(Cập nhật tại đây)</a> </div>
    </li>
  </ul>
</nav>
<!-- END Top Navbar-->




