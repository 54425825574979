import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Global } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { Lists_donvitinh, Lists_phongban, Lists_loaitaisan} from '../../../routes/menu'
import 'jquery'
import 'bootstrap'


const swal = require('sweetalert');
@Component({
    selector: 'app-dm-danhmuctaikhoanedit',
  templateUrl: './danhmuctaikhoanedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
    styleUrls: ['./danhmuctaikhoanedit.component.scss'],
  providers: [ListService, Repository]
})


export class danhmuctaikhoaneditComponent implements OnInit {

  @ViewChild('dteChung') doituongchungedit: any;
  @ViewChild('slCapTren') slCapTren: any;
  @ViewChild('slLoaiKhoan') slLoaiKhoan: any;
  @ViewChild('slPhanHe') slPhanHe: any;
  doanhnghiepid: string = null;
  taitai: string = null;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  currentUser: any;
  inputparents: any;
  inputdanhmucs: any;

  inputhienthis: any = [
    { Id: 'N', Ma_Doi_Tuong: 'N', Ten_Doi_Tuong: 'Dư nợ' }
    , { Id: 'C', Ma_Doi_Tuong: 'C', Ten_Doi_Tuong: 'Dư có' }
    , { Id: 'NL', Ma_Doi_Tuong: 'NL', Ten_Doi_Tuong: 'Lưỡng tính' }
    //, { Id: 'CL', Ma_Doi_Tuong: 'CL', Ten_Doi_Tuong: 'Có lưỡng tính' }
    , { Id: 'K', Ma_Doi_Tuong: 'K', Ten_Doi_Tuong: 'Không số dư' }
  ];

  donviid: any;
  tabselected: number = 1;

  indLoading: boolean = false;
  doituongSelect: any = {
    ChiTiet: {
      Id: null, Ma_Tai_Khoan: null, Ten_Tai_Khoan: null
      , Nhom_Doi_Tuong_Id: null, Loai_So_Du: null
      , Parent: null
    }
    , TinhChats: []
    , PhanHes: [],
    CapTrens: [],
    DataTieuDeTK: null, DataTieuDePH: null,
    TaiKhoan_TimKiems: null, PhanHe_TimKiems: null, Doanh_Nghiep_Id: this.doanhnghiepid
  };
  nhomdtid: string = null;


  @Output() save: EventEmitter<any> = new EventEmitter();



  constructor(private _dataService: ListService, private repo: Repository) {


  }

  ngOnInit(): void {

    this.repo.getRequest("api/phanhe/getdsdanhmuc/")
      .subscribe(response => {
        console.log(response)
        this.inputdanhmucs = response;
      });
  }




  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Tai_Khoan.toLowerCase().indexOf(term) > -1 || item.Ten_Tai_Khoan.toLowerCase().indexOf(term) > -1;
  }

  comboSearchFn_GD(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Phan_He.toLowerCase().indexOf(term) > -1 || item.Ten_Phan_He.toLowerCase().indexOf(term) > -1;
  }

  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
 
  DataInit(nhomdoituongid: string, id: string, donviid: string) {

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {

      this.currentUser = JSON.parse(authlogin);
      this.donviid = this.currentUser.id_don_vi;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else 
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }


      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

    }


    this.nhomdtid = nhomdoituongid;


    //setTimeout(() => {
    //  this.mafocus.nativeElement.focus();
    //}, 500);



    this.doituongSelect = {
      ChiTiet: {
        Id: id, Ma_Tai_Khoan: null, Ten_Tai_Khoan: null
        , Nhom_Doi_Tuong_Id: null, Loai_So_Du: null
        , Parent: null
      }
      , TinhChats: []
      , PhanHes: [],
      CapTrens: [],
      DataTieuDeTK: null, DataTieuDePH: null,
      TaiKhoan_TimKiems: null, PhanHe_TimKiems: null, Doanh_Nghiep_Id: this.doanhnghiepid
    };

   
    this.repo.postRequest("api/taikhoan/getmodelbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
      .subscribe(response => {

        //console.log(response);
        this.doituongSelect.ChiTiet = response.ChiTiet;
        this.doituongSelect.TinhChats = response.TinhChats;
        this.doituongSelect.PhanHes = response.PhanHes;
        this.doituongSelect.TinhChats_TimKiems = response.TinhChats;
        this.doituongSelect.PhanHe_TimKiems = response.PhanHes;
        this.doituongSelect.CapTrens = response.CapTrens;
        this.doituongSelect.DataTieuDeTK = response.DataTieuDeTK;
        this.doituongSelect.DataTieuDePH = response.DataTieuDePH;
        this.doituongSelect.GiaoDiens = response.GiaoDiens;
        if (id === null)
          this.doituongSelect.ChiTiet.Id = null;


        console.log("taitaittt");
        console.log(response);
        console.log(this.doituongSelect);
        this.taitai = "taitai";
        console.log(this.taitai);

        

      });

  }


  public closeModal() {
    this.save.emit(null);
    //this.repo.postRequest("api/taikhoan/getmodelbyid/", { Id: this.doituongSelect.ChiTiet.Id, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {

    //    //console.log(response);
    //    this.doituongSelect.ChiTiet = response.ChiTiet;
    //    this.doituongSelect.TinhChats = response.TinhChats;
    //    this.doituongSelect.PhanHes = response.PhanHes;
    //    this.doituongSelect.TinhChats_TimKiems = response.TinhChats;
    //    this.doituongSelect.PhanHe_TimKiems = response.PhanHes;
    //    this.doituongSelect.CapTrens = response.CapTrens;
    //    this.doituongSelect.DataTieuDeTK = response.DataTieuDeTK;
    //    this.doituongSelect.DataTieuDePH = response.DataTieuDePH;
    //    this.doituongSelect.GiaoDiens = response.GiaoDiens;
        


    //    console.log("taitaittt2");
    //    console.log(response);
    //    console.log(this.doituongSelect);
    //    this.taitai = "taitai2";
    //    console.log(this.taitai);



    //  });

  }
  public saveproduct() {
    console.log("taitaittt_luu");
    console.log(this.doituongSelect);

    this.doituongSelect.TaiKhoans = this.doituongSelect.TaiKhoan_TimKiems;
    this.doituongSelect.PhanHes = this.doituongSelect.PhanHe_TimKiems;
    if (this.doituongSelect.ChiTiet.Ma_Tai_Khoan == null || this.doituongSelect.ChiTiet.Ma_Tai_Khoan == "") {
      swal('Lỗi!', 'Vui lòng nhập mã tài khoản!.', 'error');
      return;
    }

    if (this.doituongSelect.ChiTiet.Ten_Tai_Khoan == null || this.doituongSelect.ChiTiet.Ten_Tai_Khoan == "") {
      swal('Lỗi!', 'Vui lòng nhập tên tài khoản!.', 'error');
      return;
    }



    this.repo.postRequest("api/taikhoan/addorupdate2", this.doituongSelect)
      .subscribe(response => {
        console.log(response);
        //this.doituongSelect.ChiTiet.Id=response;
        this.save.emit(this.doituongSelect);
      });
  }
  keyCapTrenPress(event: any) {
    if (event.which === 13) {
      this.slLoaiKhoan.focus();
      event.preventDefault();
    }
  }
  keyLoaiTaiKhoanPress(event: any) {
    if (event.which === 13) {
      this.slPhanHe.focus();
      event.preventDefault();
    }
  }
  keyTenPress(event: any) {
    if (event.which === 13) {
      this.slCapTren.focus();
      event.preventDefault();
    }
  }
  TichTK(value) {
    this.doituongSelect.TinhChats_TimKiems.forEach((item: any) => {
      if (item.Id == value.Id)
        item.Tich = value.Tich;
    });
  }
  TichPH(value) {
    this.doituongSelect.PhanHe_TimKiems.forEach((item: any) => {
      if (item.Id == value.Id)
        item.Tich = value.Tich;
    });
  }
  changesearchTK(value) {
    let datatam = this.doituongSelect.TaiKhoan_TimKiems;
    //console.log(datatam);
    this.doituongSelect.DataTieuDeTK.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.doituongSelect.TaiKhoans = datatam;

  }
  changesearchPH(value) {
    let datatam = this.doituongSelect.PhanHe_TimKiems;
    //console.log(datatam);
    this.doituongSelect.DataTieuDePH.forEach((item: any) => {
      if (item.TimKiem != null && item.TimKiem != "") {
        datatam = datatam.filter((s) => s[item.ColumnName] !== null && s[item.ColumnName].toString().toLowerCase().indexOf(item.TimKiem.toLowerCase()) !== -1);

      }
    });
    this.doituongSelect.PhanHes = datatam;

  }
}


