import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

@Injectable()
export class ToolDataService {
  private subject = new Subject<string[]>();

  checkvalidate(formatMask: any) {
    var dateStr = formatMask.getMaskedValue();

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const parts = dateStr.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    
    
    if (year < 1770 || year > 3000 || month === 0 || month > 12 || isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }
   
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }
  
    if (!(day > 0 && day <= monthLength[month - 1])) {
      return false;
    };
    return true;
  }

}


