import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { Lists, Lists_phongban, Lists_loaitaisan, Lists_nguonvon, Lists_madonvi, Lists_donvitinh, Lists_khachhang, Lists_nhacungcap, Lists_xe } from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'



const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmuctaisanedit',
  templateUrl: './danhmuctaisanedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmuctaisanedit.component.scss'],
  providers: [ListService, Repository]
})


export class danhmuctaisaneditComponent implements OnInit {
  doanhnghiepid: string = null;
  public ngayghitang: Date = null;
  public ngaykhauhao: Date = null;
  public ngaythoikhauhao: Date = null;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputhangbaohiems: any;

  inputloaitaisans: any;
  inputphongbans: any;
  inputtaikhoans: any;
  inputxuatxus: any;
  inputdonvitinhs: any;
  inputnguonvons: any;
  inputmadonvis: any;


  inputhangxes: any;
  inputkhachhangs: any;
  inputmausacs: any;
  inputmodels: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    , Doanh_Nghiep_Id: null

    , Loai_Tai_San_Id: null, Nguon_Von_Id: null, Don_Vi_Tinh_Id: null, TK_Tai_San_Id: null
    , TK_Khau_Hao_Id: null, TK_Chi_Phi_Id: null, Khau_Hao_Chi_Tiet: null
    , Ngay_Ghi_Tang: null, Ngay_Ghi_TangS: null, Ngay_Thoi_Khau_Hao: null, Ngay_Thoi_Khau_HaoS: null
    , Phuong_Phap_Khau_Hao: null, Nam_San_Xuat: null, Xuat_Xu_Id: null
    , Tam_Dung_Khau_Hao: null, La_Tai_San: null, So_Tien: null
    , Ngay_Khau_Hao: null, Ngay_Khau_HaoS: null
    , Thoi_Gian_Khau_Hao: null, Phong_Ban_Id: null, Ma_Don_Vi_Id: null, Cha: null


  };
  nhomdtid: string = null;
  hoatdong: string = null;
  inputphuongphapkhauhaos: any = [
    { Ten_Doi_Tuong: "Theo đường thẳng", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "Tỷ lệ khấu hao / năm", Ma_Doi_Tuong: '1', Id: 1 },
    { Ten_Doi_Tuong: "Định mức khấu hao theo tháng", Ma_Doi_Tuong: '2', Id: 2 },
  ];


  inputnhacungcaps: any = [
    { Ten_Doi_Tuong: "Cá nhân", Ma_Doi_Tuong: '0', Id: false },
    { Ten_Doi_Tuong: "Tổ chức", Ma_Doi_Tuong: '1', Id: true },
  ];

  inputgioitinhs: any = [
    { Ten_Doi_Tuong: "Nữ", Ma_Doi_Tuong: '0', Id: false },
    { Ten_Doi_Tuong: "Nam", Ma_Doi_Tuong: '1', Id: true },
  ];

  inputloais: any = [
    { text: "Nhân công", value: 0 },
    { text: "Vật tư sơn", value: 1 },
  ];

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();

  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  constructor(private _dataService: ListService, private repo: Repository) {


  }

  ngOnInit(): void {

  }

  // @HostListener('document:keydown', ['$event'])
  // public handleKeyboardEvent(event: KeyboardEvent): void {

  //   if(event.key==="F9")
  //   {
  //       this.saveproduct();
  //   }
  //   event.stopPropagation();

  // }
  comboSearchFnTK(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Tai_Khoan.toLowerCase().indexOf(term) > -1 || item.Ten_Tai_Khoan.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;
  onChangeHangXe($event) {

    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: this.doituongSelect.Hang_Xe })
      .subscribe(response => {
        this.inputmodels = response;
      });



  }
  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata: any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    //if (nhomdoituongid.toLocaleUpperCase() == "5E79BF59-78D9-4C54-8EF7-0A242F5CBC24")
    //  this.hoatdong = "Ngừng theo dõi"
    //else

    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

    this.hoatdong = "Hoạt động"

    var authlogin = localStorage.getItem('currentUser');
    if (authlogin) {

      this.currentUser = JSON.parse(authlogin);
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }

      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

    }

    this.nhomdtid = nhomdoituongid;
    setTimeout(() => {
      this.mafocus.nativeElement.focus();
    }, 500);
    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputparents = response;
    //  });
    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_phongban.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputphongbans = response;
    //  });

    this.repo.postRequest("api/doituong/getxuatxu/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputxuatxus = response;
      });

    this.repo.postRequest("api/taikhoan/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputtaikhoans = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_phongban.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputphongbans = response;
      });

    this.repo.postRequest("api/doituong/getloaitaisan/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_loaitaisan.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputloaitaisans = response;
      });

    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_donvitinh.id, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputdonvitinhs = response;
      });
    
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_nguonvon.id, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputnguonvons = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_madonvi.id, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputmadonvis = response;
      });
    
   

    //this.repo.postRequest("api/doituong/gethangxe/", { DoanhNghiepId: this.doanhnghiepid,  Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputhangxes = response;
    //  });
    //this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputmodels = response;
    //  });

    //this.repo.postRequest("api/doituong/getkhachhang/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputkhachhangs = response;
    //  });

    //this.repo.postRequest("api/doituong/getmausac/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputmausacs = response;
    //  });

    //this.repo.postRequest("api/doituong/gethangbaohiem/", { DoanhNghiepId: this.doanhnghiepid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name, HangXeId: null })
    //  .subscribe(response => {
    //    this.inputhangbaohiems = response;
    //  });


    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid

      , Loai_Tai_San_Id: null, Nguon_Von_Id: null, Don_Vi_Tinh_Id: null, TK_Tai_San_Id: null
      , TK_Khau_Hao_Id: null, TK_Chi_Phi_Id: null, Khau_Hao_Chi_Tiet: null
      , Ngay_Ghi_Tang: null, Ngay_Ghi_TangS: null, Ngay_Thoi_Khau_Hao: null, Ngay_Thoi_Khau_HaoS: null
      , Phuong_Phap_Khau_Hao: null, Nam_San_Xuat: null, Xuat_Xu_Id: null
      , Tam_Dung_Khau_Hao: null, La_Tai_San: null, So_Tien: null
      , Ngay_Khau_Hao: null, Ngay_Khau_HaoS: null
      , Thoi_Gian_Khau_Hao: null, Phong_Ban_Id: null, Ma_Don_Vi_Id: null, Cha: null
    };
    if (id === null) {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
        .subscribe(response => {
          this.doituongSelect.Ma_Doi_Tuong = response;
        });

      this.doituongSelect.Trang_Thai = true;
      this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
      this.doituongSelect.Created = new Date();
      this.doituongSelect.CreatedBy = this.currentUser.user_name;
      this.doituongSelect.Modified = new Date();
      this.doituongSelect.ModifiedBy = this.currentUser.user_name;
      if (rowdata != null) {
        this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
        this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
        this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
        this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
        this.doituongSelect.Parent = rowdata.Parent;
        this.doituongSelect.Cha = rowdata.Cha;

        //this.doituongSelect.Bao_Hanh = rowdata.Bao_Hanh;
        //this.doituongSelect.Loai = rowdata.Loai;
        //this.doituongSelect.Gio_Dinh_Muc = rowdata.Gio_Dinh_Muc;
        //this.doituongSelect.Gia_Dinh_Muc = rowdata.Gia_Dinh_Muc;
        //this.doituongSelect.Gia_Ban = rowdata.Gia_Ban;
        //this.doituongSelect.Vat = rowdata.Vat;
        //this.doituongSelect.Model = rowdata.Model;
        //this.doituongSelect.Phong_Ban_Id = rowdata.Phong_Ban_Id;

      }

    }
    else {
      console.log("t11");
      this.repo.postRequest("api/doituongtaisan/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
        .subscribe(response => {
          console.log(response);
          this.doituongSelect.Id = id;
          this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
          this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
          this.doituongSelect.Trang_Thai = response.Trang_Thai;
          this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
          this.doituongSelect.Parent = response.Parent;
          this.doituongSelect.Ma_Ao = response.Ma_Ao;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = response.Created;
          this.doituongSelect.CreatedBy = response.CreatedBy;
          this.doituongSelect.Modified = new Date();
          this.doituongSelect.ModifiedBy = this.currentUser.user_name;

          this.doituongSelect.Cha = response.Cha;



          this.doituongSelect.Loai_Tai_San_Id = response.Loai_Tai_San_Id;
          this.doituongSelect.Nguon_Von_Id = response.Nguon_Von_Id;
          this.doituongSelect.Don_Vi_Tinh_Id = response.Don_Vi_Tinh_Id;
          this.doituongSelect.TK_Tai_San_Id = response.TK_Tai_San_Id;
          this.doituongSelect.TK_Khau_Hao_Id = response.TK_Khau_Hao_Id;
          this.doituongSelect.TK_Chi_Phi_Id = response.TK_Chi_Phi_Id;
          this.doituongSelect.Khau_Hao_Chi_Tiet = response.Khau_Hao_Chi_Tiet;
          if (response.Ngay_Ghi_Tang != null)
            this.ngayghitang = new Date(response.Ngay_Ghi_Tang);
          else
            this.ngayghitang = null;
          if (response.Ngay_Ghi_Tang != null)
            this.ngayghitang = new Date(response.Ngay_Ghi_Tang);
          else
            this.ngayghitang = null;

          if (response.Ngay_Khau_Hao != null)
            this.ngaykhauhao = new Date(response.Ngay_Khau_Hao);
          else
            this.ngaykhauhao = null;


          if (response.Ngay_Thoi_Khau_Hao != null)
            this.ngaythoikhauhao = new Date(response.Ngay_Thoi_Khau_Hao);
          else
            this.ngaythoikhauhao = null;

          this.doituongSelect.Phuong_Phap_Khau_Hao = response.Phuong_Phap_Khau_Hao;
          this.doituongSelect.Nam_San_Xuat = response.Nam_San_Xuat;
          this.doituongSelect.Xuat_Xu_Id = response.Xuat_Xu_Id;
          this.doituongSelect.Tam_Dung_Khau_Hao = response.Tam_Dung_Khau_Hao;
          this.doituongSelect.La_Tai_San = response.La_Tai_San;
          this.doituongSelect.So_Tien = response.So_Tien;
          this.doituongSelect.Thoi_Gian_Khau_Hao = response.Thoi_Gian_Khau_Hao;
          this.doituongSelect.Phong_Ban_Id = response.Phong_Ban_Id;
          this.doituongSelect.Ma_Don_Vi_Id = response.Ma_Don_Vi_Id;

                    



          //this.doituongSelect.SapXep = response.SapXep;
        });
    }


  }

  keyTenPress(event: any) {
    if (event.which === 13) {
      this.slCapTren.focus();
      event.preventDefault();
    }
  }
  public closeModal() {
    this.save.emit(null);
  }
  public saveproduct() {
    if (this.doituongSelect.Ma_Doi_Tuong == null || this.doituongSelect.Ma_Doi_Tuong == "") {
      swal('Lỗi!', 'Vui lòng nhập mã đối tượng!.', 'error');
      return;
    }
    if (this.ngayghitang != null)
      this.doituongSelect.Ngay_Ghi_TangS = this.ngayghitang.getDate() + '/' + (this.ngayghitang.getMonth() + 1) + '/' + this.ngayghitang.getFullYear();
    if (this.ngaykhauhao != null)
      this.doituongSelect.Ngay_Khau_HaoS = this.ngaykhauhao.getDate() + '/' + (this.ngaykhauhao.getMonth() + 1) + '/' + this.ngaykhauhao.getFullYear();

    if (this.ngaythoikhauhao != null)
      this.doituongSelect.Ngay_Thoi_Khau_HaoS = this.ngaythoikhauhao.getDate() + '/' + (this.ngaythoikhauhao.getMonth() + 1) + '/' + this.ngaythoikhauhao.getFullYear();


    //console.log("save_ttt");
    //console.log(this.doituongSelect);
    $('#loading').show();
    this.repo.postRequest("api/doituongtaisan/addorupdate", this.doituongSelect)
      .subscribe(response => {
        $('#loading').hide();
        if (response != null && response.match('-')) {
          this.doituongSelect.Id = response;
          this.save.emit(this.doituongSelect);
        }
        else {
          swal('Lỗi!', response, 'error');
        }
      });
  }

  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }
  indexdt: number;
  cotdt: string;
  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit(this.doituongSelect.Id, this.nhomdtid);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }

  }

  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Hang_Hoa_Id") {
      this.dtechondoituongchungedit.loadDataInit(this.nhomdtid, Lists_xe.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }


  }

  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              this.doituongSelect.Parent = item.Id;
              this.doituongSelect.Cha = item.Ma + ' - ' + item.Ten;
            }
            i = i + 1;

          }
        );
        this.mdChonDoiTuongChung.close();
      }
      else {
        // console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          this.doituongSelect.Parent = null;
          this.doituongSelect.Cha = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.cotdt == "Hang_Hoa_Id") {
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = item.Id;
                //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = item.Ma;
              }
              this.mdChonDoiTuongChung.close();

            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = null;
          //this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }





}
