

<div class="row">
  <!--<div class="col-6">
    <div class="row">
      <div class="col-12">
        <div style="width:100%;float:left">Phân bổ</div>
        
      </div>
      
    </div>


  </div>-->
  <div class="col-12">
    <ul class="navbar">

      <li class="nav-item" *ngIf="HienThi_KetChuyen">
        <button class="btn btn-primary" (click)="phanbo()" style="margin-left:3px; margin-right:3px">
          <i class="fas fa-edit"></i>
          Kết chuyển
        </button>
      </li>
      <li class="nav-item" *ngIf="HienThi_Luu">
        <button class="btn btn-success" (click)="savebg()" style="margin-left:3px; margin-right:3px">Lưu lại</button>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="closeModal_chondoituong()">
          <i class="fa-2x mr-2 fa fa-window-close">
          </i>
          Thoát
        </a>
      </li>
    </ul>
  </div>
</div>

<!--<div class="tab-container">

  <div style="display: flex;justify-content: space-between;background-color:#dee2e6">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:100%;">

      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}"><a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="tabselected=1" href="javascript:void(0);" id=""><span></span>Điều kiện phân bổ</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="tabselected=2" href="javascript:void(0);" id=""><span></span>Thông tin chứng từ</a></li>
    </ul>
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div style="width:100%; margin-left:7px;margin-top:3px;padding-top:0px;margin-bottom:3px;min-height:100px">
        
        

        

      </div>

    </div>
    <div class=" tab-pane font_grid" [ngClass]="{'active': tabselected==2}">
      <div class="row">
        
      </div>
    </div>
  </div>

</div>-->

<div class="row">
  <div class="col-6">
    <div class="row">
      <label class="control-label col-md-2" style="padding-top:2px">Chọn thời gian</label>
      <div class="col-md-5">
        <ng-select #slThoiGian labelForId="1"
                   class="twcol"
                   autofocus
                   [tabIndex]="1"
                   [openOnEnter]="false"
                   [items]="inputthangs"
                   bindValue="value"
                   bindLabel="text"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   style="float:left;width:100%;height:25px !important;"
                   (change)="onDoiTuongChange($event)"
                   [(ngModel)]="khoangthoigian">
        </ng-select>
      </div>
      <div class="col-md-5">
        <kendo-dateinput (blur)="onBlur()" tabindex="2" style="float:left;width:48%;height:25px !important;" (valueChange)="onChangeTuNgay($event)"
                         [(value)]="TimKiems.TuNgay">
        </kendo-dateinput>
        <span style="float:left"> - </span>
        <kendo-dateinput (blur)="onBlur()" tabindex="3" style="float:left;width:48%;height:25px !important;" (valueChange)="onChangeDenNgay($event)"
                         [(value)]="TimKiems.DenNgay">
        </kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <label class="control-label col-md-2" style="padding-top:2px">Loại phân bổ</label>
      <div class="col-md-5">
        <ng-select #slTieuMuc
                   class="twcol"
                   [tabIndex]="1"
                   [openOnEnter]="false"
                   [items]="inputloais"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="TimKiems.Loai">


          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
      <div class="col-md-5">
        <button class="btn btn-primary" (click)="search()" style="margin-left:3px; margin-right:3px">
          <!--<i class="fas fa-edit"></i>-->
          Lấy dữ liệu phân bổ
        </button>
      </div>
    </div>
    <div class="row" *ngIf="HienThi_PhanBo">
      <label class="control-label col-md-2" style="padding-top:2px">Số tiền phân bổ</label>
      <div class="col-md-5">
        <input #myinput class="form-control"
               maxlength="20"
               style="width:100%;"
               currencyMask
               [(ngModel)]="TimKiems.SoTien"
               name="value"
               [placeholder]="''"
               [options]="ngxAmOptions" autocomplete="off" />
      </div>
      <div class="col-md-5" *ngIf="HienThi_PhanBo">
        <button class="btn btn-primary" (click)="chiaphanbo()" style="margin-left:3px; margin-right:3px">
          <!--<i class="fas fa-edit"></i>-->
          Phân bổ
        </button>
      </div>
    </div>
  </div>

  <div class="col-4" *ngIf="HienThi_KetChuyen">
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Tài khoản nợ</label>
      <div class="col-3">
        <ng-select #slTaiKhoanTaiSan
                   class="twcollong"
                   autofocus
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputtaikhoantaisans"
                   bindValue="Id"
                   bindLabel="Ma_Tai_Khoan"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="TimKiems.TKNo">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-3 ng-binding">Tài khoản có</label>
      <div class="col-3">
        <ng-select #slTaiKhoanTaiSan
                   class="twcollong"
                   autofocus
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputtaikhoantaisans"
                   bindValue="Id"
                   bindLabel="Ma_Tai_Khoan"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="TimKiems.TKCo">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-9">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="TimKiems.DienGiai">
      </div>
      
    </div>
  </div>
  <div class="col-2" *ngIf="HienThi_KetChuyen">
    <div class="row">
      <label style="width:30%;float:left">Số CT</label>
      <div style="width:65%;float:left">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="TimKiems.MaChungTu">
      </div>
    </div>
    <div class="row">
      <label style="width:30%;float:left">Ngày CT</label>
      <div style="width:65%;float:left">
        <kendo-dateinput (blur)="onBlur()" tabindex="2" style="float:left;width:100%;height:25px !important;"
                         [(value)]="TimKiems.NgayChungTu">
        </kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <label style="width:30%;float:left">Ngày GS</label>
      <div style="width:65%;float:left">
        <kendo-dateinput (blur)="onBlur()" tabindex="2" style="float:left;width:100%;height:25px !important;"
                         [(value)]="TimKiems.NgayGhiSo">
        </kendo-dateinput>
      </div>
    </div>

  </div>
</div>


  <!-- lưới chi tiết -->
  <div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 150px)">
    <div class="row">
      <table class="table table-striped table-bordered table-hover table-condensed">
        <thead [ngClass]="{'theadcss': totalItems>16}">
          <!--<thead>-->
          <tr>

            <th style="width:120px;text-align:center;background-color:#f3f8fc">Mã báo giá</th>
            <th style="width:120px;text-align:center;background-color:#f3f8fc">Ngày lập</th>
            <th style="width:120px;text-align:center;background-color:#f3f8fc">Biển số</th>
            <th style="width:200px;text-align:center;background-color:#f3f8fc">Khách hàng</th>
            <th style="width:110px;text-align:center;background-color:#f3f8fc">Ngày quyết toán</th>
            <th *ngIf="TimKiems.Loai==0" style="width:120px;text-align:center;background-color:#f3f8fc">Doanh thu sơn <span style="font-size: 11px;color: #ea182b;">( {{doanhthu | number}})</span></th>
            <th *ngIf="TimKiems.Loai==0" style="width:100px;text-align:center;background-color:#f3f8fc">Phân bổ <span style="font-size: 11px;color: #ea182b;">( {{tienson | number}})</span></th>
            <th *ngIf="TimKiems.Loai==1" style="width:150px;text-align:center;background-color:#f3f8fc">Giá vốn phụ tùng <span style="font-size: 11px;color: #ea182b;">( {{tienson | number}})</span></th>

          </tr>

          <!--<tr class="filter-row">
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Ma" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Ten" placeholder="Gõ ký tự và ấn enter" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.Cha" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.GhiChu" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
            <td>
              <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                     [(ngModel)]="TimKiems.NhomDoiTuong" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
            </td>
          </tr>-->

        </thead>
        <tbody style="height:calc(100vh - 210px);">
          <tr *ngFor="let u of datas">
            <td style="width:120px;text-align:left"><a (click)="editdata(u.Id)">{{u.Ma_Bao_Gia}}</a></td>
            <td style="width:120px;text-align:center"><a (click)="editdata(u.Id)">{{u.Ngay_Lap | date: 'dd/MM/yyyy'}}</a></td>
            <td style="width:120px;text-align:left"><a (click)="editdata(u.Id)">{{u.Bien_So}}</a></td>
            <td style="width:200px;text-align:left"><a (click)="editdata(u.Id)">{{u.Khach_Hang}}</a></td>
            <td style="width:110px;text-align:center"><a (click)="editdata(u.Id)">{{u.Ngay_Quyet_Toan | date: 'dd/MM/yyyy'}}</a></td>
            <td *ngIf="TimKiems.Loai==0" style="width:120px;text-align:right"><a (click)="editdata(u.Id)">{{u.Doanh_Thu | number}}</a></td>
            <td *ngIf="TimKiems.Loai==0" style="width:100px;text-align:right">
              {{u.Tien_Son | number}}
            </td>
            <td *ngIf="TimKiems.Loai==1" style="width:150px;text-align:right">
              {{u.Tien_Son | number}}
            </td>

          </tr>
        </tbody>
      </table>


    </div>

  </div>




  <!--<bs-modal #modalchitietketchuyen class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
    <div class="modal-header">
      <h4 class="modal-title">Chi tiết cấu hình kết chuyển</h4>
      <button type="button" aria-label="Dismiss" class="close" (click)="modalchitietketchuyen.close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <bs-modal-body>
      <app-ketoan-chitietketchuyenedit #dtchitietketchuyenedit (save)="saveModal($event)"></app-ketoan-chitietketchuyenedit>
    </bs-modal-body>
  </bs-modal>-->




