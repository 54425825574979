import { Injectable } from '@angular/core';


@Injectable()
export class SettingsService {

  public user: any;
  public app: any;
  public layout: any;

  constructor() {

    // User Settings
    // -----------------------------------
    this.user = {
      name: 'John',
      job: 'ng-developer',
      picture: 'assets/img/user/02.jpg'
    };

    // App Settings
    // -----------------------------------
    this.app = {
      name: '',
      description: 'Hệ thống phần mềm quản lý báo VP',
      year: ((new Date()).getFullYear()),
      diem_doi_tien: null,
      thue_suat_vat: null,
      user_name: null,
      id_don_vi: null,
      ten_don_vi: null,
      ap_dung_tich_diem: null,
      tien_doi_diem: null,
      ap_dung_doi_diem: null,
      the_bac: null,
      the_vang: null,
      the_bach_kim: null,
      the_kim_cuong: null,
      ap_dung_tich_diem_san_pham: null,
      ap_dung_tich_diem_dich_vu: null

    };

    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      horizontal: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      offsidebarOpen: false,
      asideToggled: false,
      viewAnimation: 'ng-fadeInUp'
    };

  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }
  setUserSetting(name, value) {
    if (typeof this.user[name] !== 'undefined') {
      this.user[name] = value;
    }
  }
  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return this.layout[name] = value;
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

}
