<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-12"><span style="font-weight:bold">Thông tin khấu trừ thuế</span></label>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Từ ngày</label>
      <div class="col-9">
        <kendo-dateinput tabindex="1" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="doituongSelect.Tu_Ngay"></kendo-dateinput>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Đến ngày</label>
      <div class="col-9">
        <kendo-dateinput tabindex="2" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="doituongSelect.Den_Ngay"></kendo-dateinput>
      </div>
    </div>


    <div class="row form-group">
      <label class="control-label col-3">TK VAT đầu vào</label>
      <div class="col-9">
        <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.TK_Vao">

      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">TK VAT đầu ra</label>
      <div class="col-9">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.TK_Ra">

      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-12"><span style="font-weight:bold">Thông tin chứng từ</span></label>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Số chứng từ</label>
      <div class="col-9">
        <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Chung_Tu">

      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Ngày chứng từ</label>
      <div class="col-9">
        <kendo-dateinput tabindex="2" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="doituongSelect.Ngay_Chung_Tu"></kendo-dateinput>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Ngày ghi sổ</label>
      <div class="col-9">
        <kendo-dateinput tabindex="6" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="doituongSelect.Ngay_Hach_Toan"></kendo-dateinput>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-3">Diễn giải</label>
      <div class="col-9">
        <input type="text" tabIndex="7" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Giai">

      </div>
    </div>


  </div>



</div>
<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
    <button type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Khấu trừ</button>
  </div>

</div>


