import { Component, OnInit, ViewChild, QueryList, ViewChildren, ElementRef, Output, EventEmitter, HostListener, ViewEncapsulation } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DBOperation } from '../../../shared/enum';
import { Global, BaoCao } from '../../../shared/global';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Repository } from '../../../services/repository'
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { CurrencyMaskInputMode } from 'ngx-currency'
import { process, orderBy, filterBy } from '@progress/kendo-data-query/dist/es/main';
import 'jquery'
import 'bootstrap'

import {
  Lists_nhacungcap, Lists_nganhang,
  Lists_taisancodinh, Lists_congcu, Lists_vattu, Lists_nhanvien, Lists_khachhang, Lists_hanghoa
  , Lists_khohang, Lists_donvitinh, Lists_lenhsuachua, Lists_khoanmuc, Lists_madonvi, Lists_hopdong, Lists_doituong
  , Lists_taisan,
  Lists_xesua,
  Lists_dichvu,
  Lists_loaisuachua
} from '../../../routes/menu'
import { load } from '@telerik/kendo-intl';


const swal = require('sweetalert');
@Component({
  selector: 'app-ketoan-baogiaphutungedit',
  templateUrl: './baogiaphutungedit.component.html',
  styleUrls: ['./baogiaphutungedit.component.scss'],
  providers: [ListService, Repository],
  encapsulation: ViewEncapsulation.None
})


export class baogiaphutungeditComponent implements OnInit {
  tiencong: number = 0;
  tienvattu: number = 0;
  tienthue: number = 0;
  HienThis: any = {
    Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
    , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
    , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
    , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
    , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
    , Ty_Gia: false, Ngoai_Te: false
  };
  DoRongs: any = {
    Dien_Giai_Chi_Tiet: 250, Hang_Hoa_Id_No: 130, Kho_Hang_Id_No: 130, Don_Vi_Tinh_Id_No: 130
    , Hang_Hoa_Id_Co: 130, Kho_Hang_Id_Co: 130, Don_Vi_Tinh_Id_Co: 130
    , Doi_Tuong_Id_No: 130, Hop_Dong_Id_No: 150
    , Tai_San_Id_No: 150, Lenh_Sua_Chua_Id_No: 150, Khoan_Muc_Id_No: 130, Doi_Tuong_Id_1_No: 120
    , Doi_Tuong_Id_Co: 130, Hop_Dong_Id_Co: 150, Tai_San_Id_Co: 150, Lenh_Sua_Chua_Id_Co: 150
    , Khoan_Muc_Id_Co: 130, Doi_Tuong_Id_1_Co: 120, So_Luong: 150, Don_Gia: 150, So_Tien: 150
    , Ty_Gia: 150, Ngoai_Te: 150
  };
  inputnguoisuas: any;
  inputloaidichvus: any;
  inputhangbaohiems: any;
  inputhangxes: any;
  inputmausacs: any;
  inputmodels: any;
  currentUser: any;
  inputloaibaogias: any = [
    { Ten_Doi_Tuong: "BG sửa chữa", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "BG đồng sơn", Ma_Doi_Tuong: '1', Id: 1 },
    { Ten_Doi_Tuong: "BG khuyến mại", Ma_Doi_Tuong: '2', Id: 2 }
  ];
  He_So: number = 1;
  doanhnghiepid: string = null;
  thaydoi: boolean = false;
  dorong: any = '250';
  fixcot: number = 0;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  quyen_themdoituong: boolean = false;
  quyen_themnguon: boolean = false;
  quyen_themchuong: boolean = false;
  quyen_themkhoan: boolean = false;
  quyen_themtieumuc: boolean = false;
  quyen_themcapphat: boolean = false;
  quyen_themnghiepvu: boolean = false;
  quyen_themnganhang: boolean = false;
  quyen_themctmt: boolean = false;
  quyen_themhoatdong: boolean = false;
  quyen_themthongke: boolean = false;
  quyen_themnhommuchi: boolean = false;
  quyen_themvattuhanghoa: boolean = false;
  quyen_themtaisan: boolean = false;
  quyen_themcongcu: boolean = false;

  quyen_themtaphop: boolean = false;
  quyen_themtaphop2: boolean = false;
  inputtaphops: any;
  inputtaphops2: any;

  inputtaisans: any;

  msg: string;

  @ViewChild('mdLuaChonDoRong') mdLuaChonDoRong: BsModalComponent;

  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
  @ViewChild('mdDoiTuongNH') mdDoiTuongNH: BsModalComponent;
  @ViewChild('mdCanBo') mdCanBo: BsModalComponent;
  @ViewChild('mdKhachHang') mdKhachhang: BsModalComponent;
  @ViewChild('mdLuaChon') mdLuaChon: BsModalComponent;
  @ViewChild('slKhachHang') slKhachHang: BsModalComponent;
  @ViewChild('slTaiKhoanNo') slTaiKhoanNo: BsModalComponent;
  @ViewChildren(NgSelectComponent) slcombo: QueryList<NgSelectComponent>
  @ViewChild('dteChung') doituongchungedit: any;
  @ViewChild('dtenganhang') nganhangedit: any;
  @ViewChild('dteCanBo') canboedit: any;
  @ViewChild('dteKhachHang') khachhangedit: any;
  @ViewChild("diengiaitxt") diengiaitxt: ElementRef;

  @ViewChild('mdTaiSan') mdTaiSan: BsModalComponent;
  @ViewChild('dtetaisan') taisanedit: any;


  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;

  @ViewChild('modalphieuxuat') modalphieuxuat: BsModalComponent;
  @ViewChild('ctphieuxuatedit') ctphieuxuatedit: any;
  @ViewChild('modalquyettoan') modalquyettoan: BsModalComponent;
  @ViewChild('ctquyettoantedit') ctquyettoantedit: any;


  datas: Idata[];
  data: Idata;
  indLoading: boolean = false;
  dataFrm: FormGroup;
  dbops: DBOperation;
  modalTitle: string;
  modalBtnTitle: string;

  inputhanghoas: any;
  inputkhohangs: any;
  inputdonvitinhs: any;
  inputhopdongs: any;
  inputlenhsuachuas: any;
  inputkhoanmucs: any;
  inputmadonvis: any;
  inputloaithues: any;

  inputtaikhoans: any;
  inputkhachhangs: any;
  inputnguonkinhphis: any;
  inputdoituongs: any;
  inputchuongs: any;
  inputcaphats: any;
  inputnghiepvus: any;
  inputnganhangs: any;
  inputhoatdongs: any;
  inputcmmts: any;
  inputthongkes: any;
  inputloaikhoans: any;
  inputtieumucs: any;
  inputlienquans: any;
  themdoituongtrenluoi: number;
  public ngaychungtu: Date = new Date();
  public ngaylap: Date = new Date();
  taikhoanno: any = null;
  tendoituong: any = null;
  tabselected: number = 2;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  @Output() savechungtu: EventEmitter<any> = new EventEmitter();
  isHidden: number = 0;
  public loading = true;
  primaryColour: string = '#5d9cec';
  secondaryColour: string = '#ff902b';
  //----------tham số các nhóm cột------------------
  nhomnguonid: string = Lists_khoanmuc.id;
  nhomchuongid: string = null;
  nhomloaikhoanid: string = null;
  nhomtieumucid: string = null;
  hinhthuccapphatid: string = null;
  nghiepvuid: string = null;
  nganhangid: string = Lists_nganhang.id;
  hoatdongid: string = null;
  cmmtid: string = null;
  mathongke: string = null;
  hanghoaid: string = null;
  khohangid: string = Lists_khohang.id;
  donvitinhid: string = Lists_donvitinh.id;
  hopdongid: string = null;
  lenhsuachuaid: string = null;
  doituongid: string = "532A61EA-D765-844B-8CBF-E610B209E332";
  taisanid: string = Lists_taisan.id;
  taphopid2: any;
  taphopid: any;
  Doi_Tuong_Id: any;
  //-----------------------------------------------------

  chungtuSelect: any = {
    Id: null, Don_Vi_Id: null, Copy: null, Loai_Bao_Gia: null, Phieu_Vao_Xuong: null,
    Ma_Bao_Gia: null, Ma_Lenh_Sua: null, Ma_Yeu_Cau: null, Ma_HTC: null, Ngay_Lap: null, Khach_Hang_Id: null, Khach_Hang: null, Nhan_No: null
    , Nguoi_Su_Dung: null, Dia_Chi: null, Dien_Thoai: null, Tinh_Trang_Xe: null, MST: null, Xe_Id: null
    , Bien_So: null, So_Khung: null
    , DoanhNghiepId: null, Phan_He_Id: null, Nam: null, Huy_Bao_Gia: null
    , So_May: null, Hang_Xe_Id: null, Model_Xe_Id: null, Mau_Xe: null, So_Km: null
    , Trang_Thai: null, Ngay_Khach_Toi: null, Ngay_Du_Kien_Xong: null, Ngay_Bat_Dau_Sua: null, Ngay_Ket_Thuc_Sua: null
    , Bao_Duong_Ngay: null, Bao_Duong_Km: null, Bao_Duong_Ghi_Chu: null, Khach_Cho: null, Rua_Xe: null
    , Sua_Lai: null, Yeu_Cau_Sua: null, Tong_Tien: null, Chieu_Khau_Ty_Le: null, Chiet_Khau_Tien: null
    , Thue_Ty_Le: null, Thue_Tien: null, Chi_Phi_Khac: null, Tong_Thanh_Toan: null, Hang_Bao_Hiem_Id: null

    , NhanCongs: [], DichVus: [], CreatedBy: null, ModifiedBy: null, TieuDe: null, Dieu_Khoan: null
  };
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  tongtien2: number = 0;

  onChangeHangXe($event) {
    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: this.chungtuSelect.Hang_Xe_Id })
      .subscribe(response => {
        this.inputmodels = response;
      });
  }

  public changeST2(drow: any) {
    this.tongtien2 = 0;
    //this.chungtuSelect.DichVus.forEach(
    //  (item) => {
    //    this.tongtien2 = this.tongtien2 + (item.So_Tien === null ? 0 : item.So_Tien);
    //  }
    //);
  }

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };

  public ngxHSOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 1,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };

  public ngxAmOption2s = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 3,
    allowNegative: false
    , inputMode: CurrencyMaskInputMode.NATURAL
  };

  public Tab1_SapXep: any = {
    SapXep_CongViec: null
    , SapXep_Loai: null
    , SapXep_Gio: null
    , SapXep_HeSo: null
    , SapXep_DonGia: null
    , SapXep_SoTien: null
    , SapXep_BaoHiem: null
    , SapXep_Thue: null
    , SapXep_TienThue: null
    , SapXep_GhiChu: null
    , SapXep_ThuTu: null
  };
  public Tab2_SapXep: any = {
    SapXep_CongViec: null
    , SapXep_Loai: null
    , SapXep_Gio: null
    , SapXep_HeSo: null
    , SapXep_DonGia: null
    , SapXep_SoTien: null
    , SapXep_BaoHiem: null
    , SapXep_Thue: null
    , SapXep_TienThue: null
    , SapXep_GhiChu: null
    , SapXep_ThuTu: null
  };

  SapXepTab1(cot: string) {
    if (cot == "SapXep_CongViec") {
      //this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;

      if (this.Tab1_SapXep.SapXep_CongViec == null) {
        this.Tab1_SapXep.SapXep_CongViec = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Phu_Tung', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_CongViec == 1) {
          this.Tab1_SapXep.SapXep_CongViec = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_CongViec = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Phu_Tung', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_Loai") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      //this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_Loai == null) {
        this.Tab1_SapXep.SapXep_Loai = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Dich_Vu_Id', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_Loai == 1) {
          this.Tab1_SapXep.SapXep_Loai = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_Loai = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Dich_Vu_Id', dir: "desc" }]);
        }
      }
    }

    if (cot == "SapXep_Gio") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      //this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_Gio == null) {
        this.Tab1_SapXep.SapXep_Gio = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'So_Luong', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_Gio == 1) {
          this.Tab1_SapXep.SapXep_Gio = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_Gio = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'So_Luong', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_HeSo") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      //this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_HeSo == null) {
        this.Tab1_SapXep.SapXep_HeSo = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'He_So', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_HeSo == 1) {
          this.Tab1_SapXep.SapXep_HeSo = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_HeSo = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'He_So', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_DonGia") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      //this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_DonGia == null) {
        this.Tab1_SapXep.SapXep_DonGia = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Don_Gia', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_DonGia == 1) {
          this.Tab1_SapXep.SapXep_DonGia = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_DonGia = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Don_Gia', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_SoTien") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      //this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_SoTien == null) {
        this.Tab1_SapXep.SapXep_SoTien = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'So_Tien', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_SoTien == 1) {
          this.Tab1_SapXep.SapXep_SoTien = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_SoTien = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'So_Tien', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_BaoHiem") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      //this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_BaoHiem == null) {
        this.Tab1_SapXep.SapXep_BaoHiem = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Gia_Bao_Hiem', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_BaoHiem == 1) {
          this.Tab1_SapXep.SapXep_BaoHiem = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_BaoHiem = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Gia_Bao_Hiem', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_Thue") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      //this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_Thue == null) {
        this.Tab1_SapXep.SapXep_Thue = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thue_Suat', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_Thue == 1) {
          this.Tab1_SapXep.SapXep_Thue = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_Thue = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thue_Suat', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_TienThue") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      // this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_TienThue == null) {
        this.Tab1_SapXep.SapXep_TienThue = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Tien_Thue', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_TienThue == 1) {
          this.Tab1_SapXep.SapXep_TienThue = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_TienThue = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Tien_Thue', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_GhiChu") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      //this.Tab1_SapXep.SapXep_GhiChu = null;
      this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_GhiChu == null) {
        this.Tab1_SapXep.SapXep_GhiChu = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Ghi_Chu', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_GhiChu == 1) {
          this.Tab1_SapXep.SapXep_GhiChu = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_GhiChu = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Ghi_Chu', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_ThuTu") {
      this.Tab1_SapXep.SapXep_CongViec = null;
      this.Tab1_SapXep.SapXep_Loai = null;
      this.Tab1_SapXep.SapXep_Gio = null;
      this.Tab1_SapXep.SapXep_HeSo = null;
      this.Tab1_SapXep.SapXep_DonGia = null;
      this.Tab1_SapXep.SapXep_SoTien = null;
      this.Tab1_SapXep.SapXep_BaoHiem = null;
      this.Tab1_SapXep.SapXep_Thue = null;
      this.Tab1_SapXep.SapXep_TienThue = null;
      this.Tab1_SapXep.SapXep_GhiChu = null;
      //this.Tab1_SapXep.SapXep_ThuTu = null;
      if (this.Tab1_SapXep.SapXep_ThuTu == null) {
        this.Tab1_SapXep.SapXep_ThuTu = 0;
        this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
      }
      else {
        if (this.Tab1_SapXep.SapXep_ThuTu == 1) {
          this.Tab1_SapXep.SapXep_ThuTu = null;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab1_SapXep.SapXep_ThuTu = 1;
          this.chungtuSelect.NhanCongs = orderBy(this.chungtuSelect.NhanCongs, [{ field: 'Thu_Tu', dir: "desc" }]);
        }
      }
    }
  }
  SapXepTab2(cot: string) {
    if (cot == "SapXep_CongViec") {
      //this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;

      if (this.Tab2_SapXep.SapXep_CongViec == null) {
        this.Tab2_SapXep.SapXep_CongViec = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Phu_Tung', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_CongViec == 1) {
          this.Tab2_SapXep.SapXep_CongViec = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_CongViec = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Phu_Tung', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_Loai") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      //this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_Loai == null) {
        this.Tab2_SapXep.SapXep_Loai = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Dich_Vu_Id', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_Loai == 1) {
          this.Tab2_SapXep.SapXep_Loai = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_Loai = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Dich_Vu_Id', dir: "desc" }]);
        }
      }
    }

    if (cot == "SapXep_Gio") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      //this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_Gio == null) {
        this.Tab2_SapXep.SapXep_Gio = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'So_Luong', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_Gio == 1) {
          this.Tab2_SapXep.SapXep_Gio = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_Gio = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'So_Luong', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_HeSo") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      //this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_HeSo == null) {
        this.Tab2_SapXep.SapXep_HeSo = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'He_So', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_HeSo == 1) {
          this.Tab2_SapXep.SapXep_HeSo = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_HeSo = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'He_So', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_DonGia") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      //this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_DonGia == null) {
        this.Tab2_SapXep.SapXep_DonGia = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Don_Gia', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_DonGia == 1) {
          this.Tab2_SapXep.SapXep_DonGia = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_DonGia = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Don_Gia', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_SoTien") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      //this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_SoTien == null) {
        this.Tab2_SapXep.SapXep_SoTien = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'So_Tien', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_SoTien == 1) {
          this.Tab2_SapXep.SapXep_SoTien = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_SoTien = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'So_Tien', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_BaoHiem") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      //this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_BaoHiem == null) {
        this.Tab2_SapXep.SapXep_BaoHiem = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Gia_Bao_Hiem', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_BaoHiem == 1) {
          this.Tab2_SapXep.SapXep_BaoHiem = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_BaoHiem = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Gia_Bao_Hiem', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_Thue") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      //this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_Thue == null) {
        this.Tab2_SapXep.SapXep_Thue = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thue_Suat', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_Thue == 1) {
          this.Tab2_SapXep.SapXep_Thue = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_Thue = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thue_Suat', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_TienThue") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      // this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_TienThue == null) {
        this.Tab2_SapXep.SapXep_TienThue = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Tien_Thue', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_TienThue == 1) {
          this.Tab2_SapXep.SapXep_TienThue = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_TienThue = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Tien_Thue', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_GhiChu") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      //this.Tab2_SapXep.SapXep_GhiChu = null;
      this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_GhiChu == null) {
        this.Tab2_SapXep.SapXep_GhiChu = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Ghi_Chu', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_GhiChu == 1) {
          this.Tab2_SapXep.SapXep_GhiChu = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_GhiChu = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Ghi_Chu', dir: "desc" }]);
        }
      }
    }
    if (cot == "SapXep_ThuTu") {
      this.Tab2_SapXep.SapXep_CongViec = null;
      this.Tab2_SapXep.SapXep_Loai = null;
      this.Tab2_SapXep.SapXep_Gio = null;
      this.Tab2_SapXep.SapXep_HeSo = null;
      this.Tab2_SapXep.SapXep_DonGia = null;
      this.Tab2_SapXep.SapXep_SoTien = null;
      this.Tab2_SapXep.SapXep_BaoHiem = null;
      this.Tab2_SapXep.SapXep_Thue = null;
      this.Tab2_SapXep.SapXep_TienThue = null;
      this.Tab2_SapXep.SapXep_GhiChu = null;
      //this.Tab2_SapXep.SapXep_ThuTu = null;
      if (this.Tab2_SapXep.SapXep_ThuTu == null) {
        this.Tab2_SapXep.SapXep_ThuTu = 0;
        this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
      }
      else {
        if (this.Tab2_SapXep.SapXep_ThuTu == 1) {
          this.Tab2_SapXep.SapXep_ThuTu = null;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "asc" }]);
        }
        else {
          this.Tab2_SapXep.SapXep_ThuTu = 1;
          this.chungtuSelect.DichVus = orderBy(this.chungtuSelect.DichVus, [{ field: 'Thu_Tu', dir: "desc" }]);
        }
      }
    }
  }


  donviid: string = null;
  namketoan: number;
  username: string = null;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private repo: Repository) {



  }
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if ((event.altKey || event.metaKey) && event.keyCode == 49) {
      this.tabselected = 2;

      setTimeout(() => {
        this.setComboFocus(0, 1, 1);
      }, 500);
    }
    if ((event.altKey || event.metaKey) && event.keyCode == 50) {
      this.tabselected = 2;
      setTimeout(() => {
        this.setComboFocus(0, 1, 2);
      }, 500);
    }

    event.stopPropagation();

  }
  phanheid: string = null;
  public innerHeight: any;
  loaiketchuyen: any = null;
  hachtoandongthois: any = [];
  chitietdongthois: any = [];
  //id--ichung tu; loai: đánh dấu trường hợp copy (1: copy, 0: ko)
  loadDataInit(id: string, loai: number, pheid: string, ctu: any) {
    this.tabselected = 2;
    this.He_So = 1;
    this.HienThis = {
      Hang_Hoa_Id_No: false, Kho_Hang_Id_No: false, Don_Vi_Tinh_Id_No: false, Hang_Hoa_Id_Co: false
      , Kho_Hang_Id_Co: false, Don_Vi_Tinh_Id_Co: false, Doi_Tuong_Id_No: false, Hop_Dong_Id_No: false
      , Tai_San_Id_No: false, Lenh_Sua_Chua_Id_No: false, Khoan_Muc_Id_No: false, Doi_Tuong_Id_1_No: false
      , Doi_Tuong_Id_Co: false, Hop_Dong_Id_Co: false, Tai_San_Id_Co: false, Lenh_Sua_Chua_Id_Co: false
      , Khoan_Muc_Id_Co: false, Doi_Tuong_Id_1_Co: false, So_Luong: false, Don_Gia: false, So_Tien: false
      , Ty_Gia: false, Ngoai_Te: false
    };

    if (id == "0" || id == "1" || id == "2") {
      this.loaiketchuyen = id;
      id = null;
    }
    this.chitietdongthois = [];
    this.innerHeight = window.innerHeight - 250;
    this.ngaychungtu = new Date();
    this.ngaylap = new Date();
    this.tabselected = 2;
    this.phanheid = pheid;
    this.thaydoi = false;
    //----------------------lây thông tin ban đầu

    var crru = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUser = crru;
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
      var giatri = crru.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
        if (giatri[0].Sua == true)
          this.quyen_sua = true;
        else
          this.quyen_sua = false;
        if (giatri[0].Xoa == true)
          this.quyen_xoa = true;
        else
          this.quyen_xoa = false;
      }

      this.doanhnghiepid = crru.id_doanh_nghiep;

      this.repo.postRequest('api/taikhoan/dstaikhoanv2', { DoanhNghiepId: this.doanhnghiepid, UserName: this.username })
        .subscribe(response => {
          this.inputtaikhoans = response;
        });


      this.repo.postRequest("api/doituong/gethangxe/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: crru.user_name })
        .subscribe(response => {
          this.inputhangxes = response;
        });

      this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: crru.user_name, HangXeId: null })
        .subscribe(response => {
          this.inputmodels = response;
        });

      this.repo.postRequest("api/doituong/getloaisuachua/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_loaisuachua.id, Id: null, DonViId: this.donviid, UserName: crru.user_name })
        .subscribe(response => {
          this.inputloaidichvus = response;
        });

      this.repo.postRequest("api/doituong/getnhanvien/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_nhanvien.id, Id: null, DonViId: this.donviid, UserName: crru.user_name })
        .subscribe(response => {
          this.inputnguoisuas = response;
        });



      this.repo.postRequest("api/doituong/getmausac/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: crru.user_name, HangXeId: null })
        .subscribe(response => {
          this.inputmausacs = response;
        });

      this.repo.postRequest("api/doituong/gethangbaohiem/", { DoanhNghiepId: this.doanhnghiepid, Id: null, DonViId: this.donviid, UserName: crru.user_name, HangXeId: null })
        .subscribe(response => {
          this.inputhangbaohiems = response;
        });


      this.LoadCategory();

      // gán quyền thêm đối tượng
      var giatri1 = crru.ds_quyen.filter(name => name.Id === ('532A61EA-D765-844B-8CBF-E610B209E332').toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themdoituong = true;
        else
          this.quyen_themdoituong = false;
      }

      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_nganhang.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themnganhang = true;
        else
          this.quyen_themnganhang = false;
      }

      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_vattu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themvattuhanghoa = true;
        else
          this.quyen_themvattuhanghoa = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_taisancodinh.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themtaisan = true;
        else
          this.quyen_themtaisan = false;
      }
      giatri1 = crru.ds_quyen.filter(name => name.Id === Lists_congcu.id.toLowerCase());
      if (giatri1 != null && giatri1.length > 0) {
        if (giatri1[0].Them == true)
          this.quyen_themcongcu = true;
        else
          this.quyen_themcongcu = false;
      }


    }

    //--------------------------------------------------------------------
    this.chungtuSelect = {
      Id: null, Don_Vi_Id: null, Copy: null, Loai_Bao_Gia: 3, Phieu_Vao_Xuong: null,
      Ma_Bao_Gia: null, Ma_Lenh_Sua: null, Ma_Yeu_Cau: null, Ma_HTC: null, Ngay_Lap: null, Khach_Hang_Id: null, Khach_Hang: null, Nhan_No: null
      , Nguoi_Su_Dung: null, Dia_Chi: null, Dien_Thoai: null, Tinh_Trang_Xe: null, MST: null, Xe_Id: null
      , Bien_So: null, So_Khung: null
      , DoanhNghiepId: this.doanhnghiepid, Phan_He_Id: null, Nam: this.namketoan, Huy_Bao_Gia: null
      , So_May: null, Hang_Xe_Id: null, Model_Xe_Id: null, Mau_Xe: null, So_Km: null
      , Trang_Thai: null, Ngay_Khach_Toi: null, Ngay_Du_Kien_Xong: null, Ngay_Bat_Dau_Sua: null, Ngay_Ket_Thuc_Sua: null
      , Bao_Duong_Ngay: null, Bao_Duong_Km: null, Bao_Duong_Ghi_Chu: null, Khach_Cho: null, Rua_Xe: null
      , Sua_Lai: null, Yeu_Cau_Sua: null, Tong_Tien: null, Chieu_Khau_Ty_Le: null, Chiet_Khau_Tien: null
      , Thue_Ty_Le: 10, Thue_Tien: null, Chi_Phi_Khac: null, Tong_Thanh_Toan: null, Hang_Bao_Hiem_Id: null

      , NhanCongs: [], DichVus: [], CreatedBy: null, ModifiedBy: null, TieuDe: null, Dieu_Khoan: null
    };

    if (id === null) {
      if (ctu === null) {
        this.chungtuSelect.Ngay_Lap = new Date();
        this.chungtuSelect.Don_Vi_Id = this.donviid;
        this.chungtuSelect.Phan_He_Id = this.phanheid;
        this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
        this.chungtuSelect.Nam = this.namketoan;
        this.AddRowNew(this.chungtuSelect.NhanCongs);
        this.AddRowNewHD(this.chungtuSelect.DichVus);
        this.repo.postRequest("api/baogia/get_dieukhoan/", { Id: id, NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid, UserName: crru.user_name, DonViId: this.donviid })
          .subscribe(response => {
            this.chungtuSelect.Dieu_Khoan = response.Dieu_Khoan;
          });
      }
      else {
        this.chungtuSelect = ctu;
        if (this.chungtuSelect.DichVus.length == 0)
          this.AddRowNewHD(this.chungtuSelect.DichVus);
        this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
        this.chungtuSelect.Nam = this.namketoan;

        if (this.chungtuSelect.Ngay_Lap != null) {
          this.ngaylap = new Date(this.chungtuSelect.Ngay_Lap);
        }
        //----------gán mặc định--------------------------

      }
      this.repo.postRequest("api/baogia/get_taoma/", { DonViId: this.donviid, chuoi: this.phanheid, status: this.namketoan, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          if (response != null) {
            this.chungtuSelect.Ma_Bao_Gia = response.Ma_Bao_Gia;
            this.chungtuSelect.Ma_Lenh_Sua = response.Ma_Lenh_Sua;
            this.chungtuSelect.Ma_Yeu_Cau = response.Ma_Yeu_Cau;

          }

          this.loading = false;
        });
      this.fn_fixcot();
    }
    else {

      $('#loading').show();
      this.repo.postRequest("api/baogia/get_thongtin/", { Id: id, NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid, UserName: crru.user_name, DonViId: this.donviid })
        .subscribe(response => {
          //console.log(response);
          $('#loading').hide();

          this.chungtuSelect = response;
          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;
          this.chungtuSelect.Phan_He_Id = this.phanheid;
          this.chungtuSelect.Nam = this.namketoan;
          if (this.chungtuSelect.DichVus.length == 0)
            this.AddRowNewHD(this.chungtuSelect.DichVus);

          this.chungtuSelect.DoanhNghiepId = this.doanhnghiepid;

          if (response.Ngay_Lap != null)
            this.chungtuSelect.Ngay_Lap = new Date(response.Ngay_Lap);
          if (response.Bao_Duong_Ngay != null)
            this.chungtuSelect.Bao_Duong_Ngay = new Date(response.Bao_Duong_Ngay);
          if (response.Ngay_Bat_Dau_Sua != null)
            this.chungtuSelect.Ngay_Bat_Dau_Sua = new Date(response.Ngay_Bat_Dau_Sua);
          if (response.Ngay_Du_Kien_Xong != null)
            this.chungtuSelect.Ngay_Du_Kien_Xong = new Date(response.Ngay_Du_Kien_Xong);
          if (response.Ngay_Ket_Thuc_Sua != null)
            this.chungtuSelect.Ngay_Ket_Thuc_Sua = new Date(response.Ngay_Ket_Thuc_Sua);
          if (response.Ngay_Khach_Toi != null)
            this.chungtuSelect.Ngay_Khach_Toi = new Date(response.Ngay_Khach_Toi);



          this.tiencong = 0;
          this.tienvattu = 0;
          this.tienthue = 0;

          this.chungtuSelect.NhanCongs.forEach(
            (item) => {
              //this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
              this.tiencong = this.tiencong + (item.So_Tien === null ? 0 : item.So_Tien)
              this.tienthue = this.tienthue + (item.Tien_thue === null ? 0 : item.Tien_thue)
            }
          );
          this.chungtuSelect.DichVus.forEach(
            (item) => {
              //this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
              this.tienvattu = this.tienvattu + (item.So_Tien === null ? 0 : item.So_Tien)
              this.tienthue = this.tienthue + (item.Tien_thue === null ? 0 : item.Tien_thue)
            }
          );


          this.tongtien2 = 0;


          if (loai === 1) {
            let idcopy = this.chungtuSelect.Id;
            this.chungtuSelect.Id = null;
            this.chungtuSelect.Copy = 1;
            this.repo.postRequest("api/baogia/get_taoma/", { DonViId: this.donviid, chuoi: this.phanheid, status: this.namketoan, Chuoi4: idcopy, DoanhNghiepId: this.doanhnghiepid })
              .subscribe(response => {
                if (response != null) {
                  this.chungtuSelect.Ma_Bao_Gia = response.Ma_Bao_Gia;
                  this.chungtuSelect.Ma_Lenh_Sua = response.Ma_Lenh_Sua;
                  this.chungtuSelect.Ma_Yeu_Cau = response.Ma_Yeu_Cau;

                }

                this.loading = false;
              });
          }
          else
            this.loading = false;

          if (loai === 1) {
            this.chungtuSelect.Ngay_Lap = new Date();
            this.ngaylap = new Date();
          }
          else {
            this.ngaylap = new Date(this.chungtuSelect.Ngay_Lap);
          }
          this.fn_fixcot();

        });

    }


    //setTimeout(() => {
    //  this.slKhachHang.focus();
    //}, 1000);

    // this.fixcot = 0;


  }
  fn_fixcot() {
    //console.log("độ dài: ");
    //console.log(this.chungtuSelect.NhanCongs.length);


    if (this.chungtuSelect.NhanCongs.length <= 50) {
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
      //(window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_02');
    }
    else
      (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');
  }


  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }

   


  }




  LoadCategory(): void {
    //---------------load phân hệ-------------------------------------------
    //this.repo.postRequest('api/chungtu/dinhkhoanmaus', { Id: null, Chuoi: this.donviid })
    //  .subscribe(response => {
    //    this.inputdinhkhoanmaus = response;
    //  });
    //------------------------------------------------------------------------

    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv2', { NhomId: Lists_taisan.id, MaGiaoDien: '4', DonViId: this.donviid, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputtaisans = response;
    //  });


    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv2', { NhomId: Lists_nganhang.id, MaGiaoDien: '', DonViId: this.donviid, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputnganhangs = response;
    //  });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: '532A61EA-D765-844B-8CBF-E610B209E332', MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputkhachhangs = response;
    //  });

    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_hanghoa.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputhanghoas = response;
    //  });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_khohang.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputkhohangs = response;
      });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_donvitinh.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputdonvitinhs = response;
      });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_hopdong.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputhopdongs = response;
    //  });
    //this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_lenhsuachua.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
    //  .subscribe(response => {
    //    this.inputlenhsuachuas = response;
    //  });
    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_khoanmuc.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputkhoanmucs = response;
      });

    this.repo.postRequest('api/doituong/dsdoituongkethuatheodoituongidv21', { NhomId: Lists_madonvi.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.inputmadonvis = response;
      });

    this.repo.postRequest('api/doituong/getloaithue', { NhomId: Lists_madonvi.id, MaGiaoDien: '', DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid, Loai: 0 })
      .subscribe(response => {
        this.inputloaithues = response;
      });



  }

  @ViewChildren("myinput") divs: QueryList<ElementRef>;

  adddata_phieuxuat() {

    this.repo.postRequest('api/chungtu/get_kiemtraxuatkho', { Id: this.chungtuSelect.Id, DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid, Loai: 0 })
      .subscribe(response => {
        if (response != null && response.TrangThai != null) {
          swal('Cảnh báo!', response.TrangThai, 'warning');
        }
        else {
          this.ctphieuxuatedit.loadDataInit(null, 0, ("5CCCD93D-6444-462B-8A98-85B31691C742").toLowerCase(), null, this.donviid, this.chungtuSelect.Id);
          this.modalphieuxuat.open();
        }
      });


  }

  adddata_quyettoan() {
    
    this.repo.postRequest('api/chungtu/get_kiemtraquyettoan', { Id: this.chungtuSelect.Id, DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid, Loai: 0 })
      .subscribe(response => {
        if (response != null && response.TrangThai != null) {
          swal('Cảnh báo!', response.TrangThai, 'warning');
        }
        else {
          this.ctquyettoantedit.loadDataInit(null, 0, ("069FFF3E-002D-4AF9-8601-5542EA23F844").toLowerCase(), null, this.donviid, this.chungtuSelect.Id);
          this.modalquyettoan.open();
        }
      });

  }
  //editdata_quyettoan(id: string) {
  //  this.ctquyettoantedit.loadDataInit(id, 0, ("069FFF3E-002D-4AF9-8601-5542EA23F844").toLowerCase(), null, this.donviid, null);
  //  this.modalquyettoan.open();
  //}
  //editdata_phieuxuat(id: string) {
  //  this.ctphieuxuatedit.loadDataInit(id, 0, ("5CCCD93D-6444-462B-8A98-85B31691C742").toLowerCase(), null, this.donviid, null);
  //  this.modalphieuxuat.open();
 // } 

  savept(doituong: any) {
    this.modalphieuxuat.close();
    this.modalquyettoan.close();
  }


  selectedTab(tab: number) {
    this.tabselected = tab;
    setTimeout(() => {
      this.setComboFocus(0, 1, tab);
    }, 500);
  }
  //----------event xử lý di chuyển Enter--------------------------------------
  setComboFocus(hang: number, cot: number, luoi: number) {
    var tencombo: string = hang + '_' + cot + '_' + luoi;
    var ketqua = false;
    this.slcombo.forEach(
      (question, i, allQuestions) => {
        if (question.labelForId === tencombo) {
          ketqua = true;
          question.focus();
        }
      }
    );

    if (ketqua === false) {
      this.divs.forEach(
        (div) => {

          if (div.nativeElement.id === tencombo) {
            ketqua = true;
            div.nativeElement.focus();
          }

        }
      );
    }
  }
  keyLyDo(event: any) {
    if (event.which === 13) {
      //this.slTaiKhoanNo.focus();
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  keyTKNoPress(event: any) {
    if (event.which === 13) {
      this.setComboFocus(0, 1, 1);
      event.preventDefault();
    }
  }
  maxcot: number = 14;

  keyComBoPress(event: any, hang: number, cot: number, luoi: number) {

    if (1 === 1) {
      hang = hang + 1;
      //console.log("key tai " + event.keyCode);
      if (event.keyCode === 40) {
        // console.log("key tai 40");
        if (luoi === 2) {
          if (hang === this.chungtuSelect.DichVus.length) {
            this.AddRowNewHD(this.chungtuSelect.DichVus);
            if (this.chungtuSelect.DichVus.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            setTimeout(() => {
              this.setComboFocus(hang - 1, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          //console.log("key tai lưới 1");
          if (hang === this.chungtuSelect.NhanCongs.length) {
            this.AddRowNew(this.chungtuSelect.NhanCongs);
            if (this.chungtuSelect.NhanCongs.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
          else {
            this.setComboFocus(hang, cot, luoi);
            event.preventDefault();
          }
        }

      }

      if (event.keyCode === 38 && hang > 0) {
        //console.log("key tai 38");
        this.setComboFocus(hang - 2, cot, luoi);
        event.preventDefault();
      }

      //if (event.keyCode === 37 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot-1, luoi);
      //  event.preventDefault();
      //}
      //if (event.keyCode === 39 && hang > 0) {
      //  //console.log("key tai 38");
      //  this.setComboFocus(hang - 1, cot + 1, luoi);
      //  event.preventDefault();
      //}

      return;
    }

    if (luoi === 2)
      this.maxcot = 9;
    if (luoi === 1)
      this.maxcot = 17;
    if (luoi === 3)
      this.maxcot = 17;
    if (event.which === 13) {
      cot = cot + 1;

      if (cot > this.maxcot) {
        hang = hang + 1;
        cot = 1;

        if (luoi === 2) {
          if (hang === this.chungtuSelect.DichVus.length) {
            this.AddRowNewHD(this.chungtuSelect.DichVus);
            if (this.chungtuSelect.DichVus.length <= 50) {
              (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
        else {
          if (hang === this.chungtuSelect.NhanCongs.length) {
            this.AddRowNew(this.chungtuSelect.NhanCongs);
            if (this.chungtuSelect.NhanCongs.length <= 50) {
              (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

            }
            setTimeout(() => {
              this.setComboFocus(hang, cot, luoi);
              event.preventDefault();
            }, 1500);
          }
        }
      }
      this.setComboFocus(hang, cot, luoi);
      event.preventDefault();
    }
  }
  RowNewClick() {
    if (this.tabselected === 2) {
      this.AddRowNewHD(this.chungtuSelect.DichVus);
      if (this.chungtuSelect.DichVus.length <= 50) {
        (window as any).timer01(2, 1500, 'fixTablechungtukhac_02');
      }
    }
    else {
      this.AddRowNew(this.chungtuSelect.NhanCongs);
      if (this.chungtuSelect.NhanCongs.length <= 50) {
        (window as any).timer01(this.fixcot, 1500, 'fixTablechungtukhac_01');

      }
    }
  }

  AddRowNew(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    let dichvu = null;
    if (this.inputloaidichvus != null && this.inputloaidichvus.length > 0)
      dichvu = this.inputloaidichvus[0].Id;

    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Bao_Gia_Id: row.Bao_Gia_Id, Phu_Tung_Id: null, Phu_Tung: null
        , Dich_Vu_Id: row.Dich_Vu_Id
        , So_Luong: 1, He_So: 1
        , Don_Gia: null, So_Tien: null, Gia_Bao_Hiem: null, Thue_Suat: 10, Tien_Thue: null
        , Nguyen_Nhan: null, Ket_Qua: null, Ghi_Chu: null, Thu_Tu: ctiets.length + 1
        , Tong_Tien: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });
    }
    else {
      ctiets.push({
        showpopup: sp, Bao_Gia_Id: null, Phu_Tung_Id: null, Phu_Tung: null, Dich_Vu_Id: dichvu
        , So_Luong: 1, He_So: 1
        , Don_Gia: null, So_Tien: null, Gia_Bao_Hiem: null, Thue_Suat: 10, Tien_Thue: null
        , Nguyen_Nhan: null, Ket_Qua: null, Ghi_Chu: null, Thu_Tu: ctiets.length + 1
        , Tong_Tien: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });

    }

    // this.chungtuSelect.NhanCongs = ctiets;

  }

  AddRowNewHD(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    let dichvu = null;
    if (this.inputloaidichvus != null && this.inputloaidichvus.length > 0)
      dichvu = this.inputloaidichvus[0].Id;
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Bao_Gia_Id: row.Bao_Gia_Id, Phu_Tung_Id: null, Phu_Tung: null, Dich_Vu_Id: row.Dich_Vu_Id
        , So_Luong: 1, He_So: 1
        , Don_Gia: null, So_Tien: null, Gia_Bao_Hiem: null, Thue_Suat: null, Tien_Thue: null
        , Nguyen_Nhan: null, Ket_Qua: null, Ghi_Chu: null, Thu_Tu: ctiets.length + 1
        , Tong_Tien: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null
      });
    }
    else {
      ctiets.push({
        showpopup: sp, Bao_Gia_Id: null, Phu_Tung_Id: null, Phu_Tung: null, Dich_Vu_Id: dichvu
        , So_Luong: 1, He_So: 1
        , Don_Gia: null, So_Tien: null, Gia_Bao_Hiem: null, Thue_Suat: null, Tien_Thue: null
        , Nguyen_Nhan: null, Ket_Qua: null, Ghi_Chu: null, Thu_Tu: ctiets.length + 1
        , Tong_Tien: null, Created: null, CreatedBy: null, Modified: null, ModifiedBy: null

      });

    }

    //this.chungtuSelect.DichVus = ctiets;

  }

  //----------event các control------------------------------------------------------

  onDoiTuongChange($event) {

    if (typeof $event === "undefined") {
      this.chungtuSelect.Ho_Ten = null;
    }
    else {
      this.chungtuSelect.Ho_Ten = $event.Ten_Doi_Tuong;
      // chỉ gán đối tượng cho chứng từ khác chứng từ kết chuyển
      //if (this.loaiketchuyen == null) {
      //  this.chungtuSelect.NhanCongs.forEach(
      //    (item) => {
      //      item.Doi_Tuong_Id = $event.Id;
      //      item.Doi_Tuong_Id2 = $event.Id;
      //    }
      //  );
      //}


      this.repo.postRequest("api/doituong/ThongTinDiaChi", { Id: $event.Id, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          //console.log(response);
          this.chungtuSelect.Dia_Chi = response.Dia_Chi;

        });
    }
  }
  onTaiNoChange($event) {
    if (typeof $event === "undefined") {
      this.taikhoanno = null;
    }
    else
      this.taikhoanno = $event.Ten_Tai_Khoan;
  }
  onTaiSanChange($event, hang: number) {
    var rs = this.chungtuSelect.NhanCongs[hang];

    if (typeof $event === "undefined") {
      rs.Dien_Giai_Chi_Tiet = null;
    }
    else {
      rs.Dien_Giai_Chi_Tiet = $event.Ten_Doi_Tuong;
      rs.Tai_Khoan_No_Id = $event.TK_Tai_San_Id;
      // rs.Chuong_Id = $event.Chuong_Id;
      rs.Loai_Khoan_Id = $event.Loai_Khoan_Id;
      rs.Tieu_Muc_Id = $event.Tieu_Muc_Id;
      rs.So_Tien = $event.Nguyen_Gia;
      if ($event != "undefined" && $event.Nguon_Id != null && $event.Nguon_Id != "undefined")
        rs.Nguon_Id = $event.Nguon_Id;
    }

  }
  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;
  cotSelected: number;

  AddDoiTuong(cobo: NgSelectComponent, hang: number, cot: number) {

    this.comboSeleted = cobo;
    this.cotSelected = cot;
    // console.log(cobo)

    cobo.close();
    this.rowSelected = this.chungtuSelect.NhanCongs[hang];

    this.doituongchungedit.loadDataInit(cobo.addTagText, null, this.donviid);
    this.mdDoiTuongChung.open();


    //if (cobo.addTagText.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
    //  this.nganhangedit.loadDataInit(cobo.addTagText, null, this.donviid, cot);
    //  this.mdDoiTuongNH.open();
    //}
    //else {
    //  if (cobo.addTagText.toLowerCase() === this.doituongid.toLowerCase()) {
    //    this.AddNguoiNop2(cobo);
    //  }
    //  else if (cobo.addTagText.toLowerCase() === Lists_taisancodinh.id.toLowerCase()) {
    //    this.taisanedit.loadDataInit(cobo.addTagText, null, this.donviid, cot);
    //    this.mdTaiSan.open();
    //  }
    //  else {
    //    this.doituongchungedit.loadDataInit(cobo.addTagText, null, this.donviid);
    //    this.mdDoiTuongChung.open();
    //  }
    //}


  }
  AddNguoiNop2(cobo: NgSelectComponent) {
    this.comboSeleted = cobo;
    this.themdoituongtrenluoi = 1;
    cobo.close();
    this.mdLuaChon.open();

  }
  AddNguoiNop(cobo: NgSelectComponent) {
    this.themdoituongtrenluoi = 0;
    this.comboSeleted = cobo;
    cobo.close();
    this.mdLuaChon.open();
  }
  loaithem: number = 2;
  onLoaiNguoiNop(loai: number) {
    this.loaithem = loai;
    //console.log(loai);
  }
  AddDoRong(tieude: string) {
    this.mdLuaChonDoRong.open();
  }
  AddFormDoRong() {
    this.mdLuaChonDoRong.close();
  }

  AddFormNguoiNop() {
    this.mdLuaChon.close();
    if (this.loaithem === 2) {
      this.canboedit.loadDataInit(Lists_nhanvien.id, null, this.donviid);
      this.mdCanBo.open();
    }
    else if (this.loaithem === 3) {
      this.khachhangedit.loadDataInit(Lists_khachhang.id, null, this.donviid);
      this.mdKhachhang.open();
    }
    else {
      this.khachhangedit.loadDataInit(Lists_nhacungcap.id, null, this.donviid);
      this.mdKhachhang.open();
    }
  }

  //------------------------------------
  saveModalTaiSan(doituong: any) {
    this.mdTaiSan.close();
    if (doituong != null) {
      //console.log(doituong);
      this.inputtaisans.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong, TK_Tai_San_Id: doituong.TK_Tai_San_Id, Nguon_Id: doituong.Nguon_Id, Loai_Khoan_Id: doituong.Loai_Khoan_Id, Tieu_Muc_Id: doituong.Tieu_Muc_Id })
      this.inputtaisans = this.inputtaisans.slice();

      this.rowSelected.Tai_San_Id = doituong.Id;
      this.rowSelected.Dien_Giai_Chi_Tiet = doituong.Ten_Doi_Tuong;
      this.rowSelected.Tai_Khoan_No_Id = doituong.TK_Tai_San_Id;
      this.rowSelected.Nguon_Id = doituong.Nguon_Id;
      this.rowSelected.Loai_Khoan_Id = doituong.Loai_Khoan_Id;
      this.rowSelected.Tieu_Muc_Id = doituong.Tieu_Muc_Id;
    }
  }

  saveModalKhacHang(doituong: any) {
    this.mdKhachhang.close();

    this.inputkhachhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputkhachhangs = this.inputkhachhangs.slice();
    if (this.cotSelected === 7)
      this.rowSelected.Doi_Tuong_Id = doituong.Id;
    if (this.cotSelected === 8)
      this.rowSelected.Doi_Tuong_Id2 = doituong.Id;

    if (this.themdoituongtrenluoi === 0) {
      this.chungtuSelect.Doi_Tuong_Id = doituong.Id;
      this.tendoituong = doituong.Ten_Doi_Tuong;
    }
  }
  saveModalCanBo(doituong: any) {
    this.mdCanBo.close();
    this.inputkhachhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputkhachhangs = this.inputkhachhangs.slice();
    if (this.cotSelected === 7)
      this.rowSelected.Doi_Tuong_Id = doituong.Id;
    if (this.cotSelected === 8)
      this.rowSelected.Doi_Tuong_Id2 = doituong.Id;
    if (this.themdoituongtrenluoi === 0) {
      this.chungtuSelect.Doi_Tuong_Id = doituong.Id;
      this.tendoituong = doituong.Ten_Doi_Tuong;
    }
  }
  saveModalNganHang(doituong: any) {
    this.mdDoiTuongNH.close();
    this.inputnganhangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    this.inputnganhangs = this.inputnganhangs.slice();

    this.rowSelected.Ngan_Hang_Id = doituong.Id;
  }

  saveModal(doituong: any) {
    //console.log(doituong);
    this.mdDoiTuongChung.close();

    //if (doituong != null && Lists_doituongtaphop2.id === doituong.Nhom_Doi_Tuong_ID) {

    //  this.inputtaphops2.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
    //  this.inputtaphops2 = this.inputtaphops2.slice();
    //  this.rowSelected.Doi_Tuong_Id_4 = doituong.Id;
    //}

    if (doituong != null && this.nhomnguonid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhoanmucs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhoanmucs = this.inputkhoanmucs.slice();
      if (this.cotSelected == 17)
        this.rowSelected.Khoan_Muc_Id_No = doituong.Id;
      if (this.cotSelected == 23)
        this.rowSelected.Khoan_Muc_Id_Co = doituong.Id;
    }
    if (doituong != null && this.khohangid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputkhohangs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputkhohangs = this.inputkhohangs.slice();
      if (this.cotSelected == 5)
        this.rowSelected.Kho_Hang_Id_No = doituong.Id;
      if (this.cotSelected == 8)
        this.rowSelected.Kho_Hang_Id_Co = doituong.Id;
    }
    if (doituong != null && this.donvitinhid === doituong.Nhom_Doi_Tuong_ID) {

      this.inputdonvitinhs.push({ Id: doituong.Id, Ma_Doi_Tuong: doituong.Ma_Doi_Tuong, Ten_Doi_Tuong: doituong.Ten_Doi_Tuong })
      this.inputdonvitinhs = this.inputdonvitinhs.slice();
      if (this.cotSelected == 6)
        this.rowSelected.Don_Vi_Tinh_Id_No = doituong.Id;
      if (this.cotSelected == 9)
        this.rowSelected.Don_Vi_Tinh_Id_Co = doituong.Id;
    }


    this.comboSeleted.focus();
  }

  indexdt: number;
  indexchon: number;
  cotdt: string;
  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Phu_Tung_Id") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_vattu.id);
      this.mdChonDoiTuongChung.open();
    }
    if (cot == "Dich_Vu_Id") {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_dichvu.id);
      this.mdChonDoiTuongChung.open();
    }

  }


  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    this.indexchon = index;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit(this.phanheid, Lists_xesua.id);
      this.mdChonDoiTuongChung.open();
    }
    if (index == 2 || index == 3) {
      this.dtechondoituongchungedit.loadDataInit(this.phanheid, Lists_doituong.id);
      this.mdChonDoiTuongChung.open();
    }


  }
  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.indexchon == 1) {
                this.chungtuSelect.Xe_Id = item.Id;
                this.chungtuSelect.Bien_So = item.Ma;

                this.repo.postRequest("api/baogia/thaydoi_bienso/", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: this.phanheid, NamKeToan: this.namketoan, UserName: this.currentUser.user_name })
                  .subscribe(response => {
                    if (response != null) {
                      this.chungtuSelect.Khach_Hang_Id = response.Khach_Hang_Id;
                      this.chungtuSelect.Khach_Hang = response.Khach_Hang;
                      this.chungtuSelect.Hang_Xe_Id = response.Hang_Xe_Id;
                      this.chungtuSelect.Model_Xe_Id = response.Model_Xe_Id;
                      this.chungtuSelect.Mau_Xe = response.Mau_Xe;
                      this.chungtuSelect.So_Khung = response.So_Khung;
                      this.chungtuSelect.So_May = response.So_May;
                      this.chungtuSelect.Dien_Thoai = response.Dien_Thoai;
                      this.chungtuSelect.MST = response.MST;
                      this.chungtuSelect.Hang_Bao_Hiem_Id = response.Hang_Bao_Hiem_Id;
                    }

                  });

              }
              if (this.indexchon == 2) {
                this.chungtuSelect.Khach_Hang_Id = item.Id;
                this.chungtuSelect.Khach_Hang = item.Ma + ' - ' + item.Ten;
                this.repo.postRequest("api/baogia/thaydoi_khachhang/", { Id: item.Id, DoanhNghiepId: this.doanhnghiepid, DonViId: this.donviid, PhanHeId: this.phanheid, NamKeToan: this.namketoan, UserName: this.currentUser.user_name })
                  .subscribe(response => {
                    if (response != null) {
                      this.chungtuSelect.Nguoi_Su_Dung = response.Nguoi_Su_Dung;
                      this.chungtuSelect.Dia_Chi = response.Dia_Chi;
                      this.chungtuSelect.Dien_Thoai = response.Dien_Thoai;
                      this.chungtuSelect.MST = response.MST;
                    }
                  });

              }
              if (this.indexchon == 3) {
                this.chungtuSelect.Phieu_Vao_Xuong = item.Id;
                this.chungtuSelect.Nhan_No = item.Ma + ' - ' + item.Ten;
              }

              this.mdChonDoiTuongChung.close();
            }
            i = i + 1;

          }
        );

      }
      else {

        if (doituong == null) {

        }
        else {
          if (this.indexchon == 1) {
            this.chungtuSelect.Xe_Id = null;
            this.chungtuSelect.Bien_So = null;
          }
          if (this.indexchon == 2) {
            this.chungtuSelect.Khach_Hang_Id = null;
            this.chungtuSelect.Khach_Hang = null;
          }
          if (this.indexchon == 3) {
            this.chungtuSelect.Phieu_Vao_Xuong = null;
            this.chungtuSelect.Nhan_No = null;
          }

        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.chungtuSelect.NhanCongs == null || this.chungtuSelect.NhanCongs == "undefined") {
              }
              else {
                if (this.cotdt == "Dich_Vu_Id") {
                  this.chungtuSelect.NhanCongs[this.indexdt].Phu_Tung_Id = item.Id;
                  this.chungtuSelect.NhanCongs[this.indexdt].Phu_Tung = item.Ten;
                }
                if (this.cotdt == "Phu_Tung_Id") {
                  this.chungtuSelect.DichVus[this.indexdt].Phu_Tung_Id = item.Id;
                  this.chungtuSelect.DichVus[this.indexdt].Phu_Tung = item.Ten;
                }


                this.mdChonDoiTuongChung.close();
              }
            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          if (this.cotdt == "Dich_Vu_Id") {
            this.chungtuSelect.NhanCongs[this.indexdt].Phu_Tung_Id = null;
            this.chungtuSelect.NhanCongs[this.indexdt].Phu_Tung = null;
          }
          if (this.cotdt == "Phu_Tung_Id") {
            this.chungtuSelect.DichVus[this.indexdt].Phu_Tung_Id = null;
            this.chungtuSelect.DichVus[this.indexdt].Phu_Tung = null;
          }

        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }


  deleterow(index: string, luoi: number) {
    //console.log(index);
    swal({
      title: 'Bạn muốn xóa dòng này phải không?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          if (luoi === 2)
            this.chungtuSelect.DichVus.splice(index, 1);
          else
            this.chungtuSelect.NhanCongs.splice(index, 1);

          this.changeST(null);
          this.changeST2(null);
        }
      });


  }
  public savectu(loai: number) {


    //if (this.namketoan !== this.ngaylap.getFullYear()) {
    //  swal('Cảnh báo!', "Ngày hạch toán nằm ngoài năm kế toán!!!", 'warning');
    //  return;
    //}
    if (this.chungtuSelect.Ma_Bao_Gia === null || this.chungtuSelect.Ma_Bao_Gia === '') {
      swal('Cảnh báo!', "Bạn chưa nhập Mã báo giá", 'warning');
      return;
    }

    //for (let i = 0; i < this.chungtuSelect.NhanCongs.length; i++) {
    //  var row = this.chungtuSelect.NhanCongs[i];
    //  if (!this.datecheck(row.NgayCTG)) {
    //    swal('Cảnh báo!', "Ngày chứng từ gốc không hợp lệ", 'warning');
    //    this.setComboFocus(i, 4, 1);
    //    return;
    //  }
    //}
    //console.log("luu chung tu");
    //console.log(this.namketoan);
    //console.log(this.chungtuSelect.Ngay_Lap.getFullYear());
    //console.log(this.chungtuSelect.Ngay_Lap);
    let ngaylap_nam = this.chungtuSelect.Ngay_Lap.getFullYear();

    if (this.namketoan !== ngaylap_nam.toString()) {
      //swal('Cảnh báo!', "Ngày hạch toán nằm ngoài năm kế toán!!!", 'warning');
      //return;
      swal({
        title: 'Ngày lập nằm ngoài năm kế toán. Bạn muốn tiếp tục lưu không?',
        text: 'Dữ liệu sẽ được lưu vào năm ' + this.chungtuSelect.Ngay_Lap.getFullYear().toString() + '. Vui lòng chọn năm ' + this.chungtuSelect.Ngay_Lap.getFullYear().toString() + ' sau khi lưu để nhìn thấy báo giá',
        icon: "warning",
        buttons: {
          cancel: {
            text: 'Hủy bỏ',
            value: false,
            visible: true,
          },
          confirm: {
            text: 'Đồng ý',
            value: true,
            visible: true,
            className: "bg-danger",
            closeModal: true
          }
        }
      })
        .then(willDelete => {
          if (willDelete) {

            /// bắt đầu
            //this.chungtuSelect.Ngay_Lap_S = this.ngaylap.getDate() + '/' + (this.ngaylap.getMonth() + 1) + '/' + this.ngaylap.getFullYear();
            //this.chungtuSelect.Ngay_Lap_S = this.ngaychungtu.getDate() + '/' + (this.ngaychungtu.getMonth() + 1) + '/' + this.ngaychungtu.getFullYear();

            $('#loading').show();
            this.repo.postRequest("api/baogia/addorupdate", this.chungtuSelect)
              .subscribe(response => {
                //console.log(response);
                $('#loading').hide();
                if (response.Status === "OK") {
                  this.chungtuSelect.Id = response.Data.Id;
                  this.chungtuSelect.NhanCongs = response.Data.NhanCongs;
                  this.chungtuSelect.DichVus = response.Data.DichVus;
                  //this.savechungtu.emit(this.chungtuSelect);
                  swal('Thông báo!', "Lưu lại thành công!", 'success'); 
                }
                else
                  swal('Cảnh báo!', response.Status, 'warning');
              });
            // kết thúc

          }
        });
    } else {
      /// bắt đầu
      //this.chungtuSelect.Ngay_Lap_S = this.ngaylap.getDate() + '/' + (this.ngaylap.getMonth() + 1) + '/' + this.ngaylap.getFullYear();
      //this.chungtuSelect.Ngay_Lap_S = this.ngaychungtu.getDate() + '/' + (this.ngaychungtu.getMonth() + 1) + '/' + this.ngaychungtu.getFullYear();


      $('#loading').show();
      this.repo.postRequest("api/baogia/addorupdate", this.chungtuSelect)
        .subscribe(response => {
          //console.log(response);
          $('#loading').hide();
          if (response.Status === "OK") {
            this.chungtuSelect.Id = response.Data.Id;
            this.chungtuSelect.NhanCongs = response.Data.NhanCongs;
            this.chungtuSelect.DichVus = response.Data.DichVus;
            //this.savechungtu.emit(this.chungtuSelect);
            swal('Thông báo!', "Lưu lại thành công!", 'success'); 
          }
          else
            swal('Cảnh báo!', response.Status, 'warning');
        });
      // kết thúc
    }



  }
  closeModal() {
    this.savechungtu.emit(null);
  }
  //-------------ham tiện ích---------------------------------------
  public changeTaiKhoan(event: any, noco: number, row: any, index: number) {
    let roW1;
    //console.log("changeTaiKhoan tt");
    //console.log(event);
    //console.log(this.inputtaikhoans);
    if (typeof event === "undefined") {

    }
    else {
      if (noco == 1) {
        this.chungtuSelect.NhanCongs[index].Tai_Khoan_No_Id = event;
        //this.chungtuSelect.NhanCongs[index].Tai_Khoan_No = event.Ma_Tai_Khoan;

      }
      else {

        this.chungtuSelect.NhanCongs[index].Tai_Khoan_Co_Id = event;
        if (event == ("C4B629A0-00E5-4A30-AED9-DADC21C9AE68").toLowerCase()) {
          this.chungtuSelect.NhanCongs[index].Doi_Tuong_Id_Co = this.chungtuSelect.Doi_Tuong_Id;
          this.chungtuSelect.NhanCongs[index].Doi_Tuong_Co = this.chungtuSelect.Ma_Doi_Tuong;
        }
        //this.chungtuSelect.NhanCongs[index].Tai_Khoan_Co = event.Ma_Tai_Khoan;
      }
      // this.chungtuSelect.NhanCongs[index] = roW1;
      //console.log("cau hinh tt");
      //console.log(this.chungtuSelect.NhanCongs);

      this.repo.postRequest("api/chungtu/cauhinhhienthi", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            //console.log("cau hinh tt");
            //console.log(response);
            //console.log(this.HienThis);
            this.HienThis = response.Data;
          }
        });
    }

  }
  public changeLoaiThue(event: any, row: any, index: number) {
    let roW1;
    if (typeof event === "undefined") {

    }
    else {
      //this.chungtuSelect.DichVus[index].Tai_Khoan_No_Id = event;
      this.chungtuSelect.DichVus[index].Loai_Thue_Id = event;
      this.repo.postRequest("api/chungtu/thaydoiloaithue", this.chungtuSelect)
        .subscribe(response => {
          if (response.Status === "OK") {
            this.chungtuSelect.DichVus[index].Thue_Suat = response.Data.Thue_Suat;
            this.chungtuSelect.DichVus[index].Tien_Hang = response.Data.Tien_Hang;
            this.chungtuSelect.DichVus[index].Tien_Thue = response.Data.Tien_Thue;
            this.chungtuSelect.DichVus[index].Doi_Tuong = response.Data.Doi_Tuong;
            this.chungtuSelect.DichVus[index].Ma_So_Thue = response.Data.Ma_So_Thue;
            this.chungtuSelect.DichVus[index].Dien_Giai = response.Data.Dien_Giai;
            this.chungtuSelect.DichVus[index].Ngay_Hoa_Don = new Date(response.Data.Ngay_Hoa_Don);
          }
        });
    }

  }
  public changeLuoi(drow: any) {
    this.thaydoi = true;
  }
  tongtien: number = 0;
  public changeChietKhau() {
    this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
      + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
      - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);
  }
  public changeTyLeThue() {
    this.chungtuSelect.Tong_Tien = 0;
    this.tiencong = 0;
    this.tienvattu = 0;
    this.tienthue = 0;
    this.chungtuSelect.NhanCongs.forEach(
      (item) => {
        //console.log(item.So_Tien);
        item.He_So = this.He_So;
        item.So_Tien = item.Don_Gia * item.So_Luong * item.He_So;
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tiencong = this.tiencong + (item.So_Tien === null ? 0 : item.So_Tien)
        item.Thue_Suat = this.chungtuSelect.Thue_Ty_Le;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);

        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);

      }
    );
    this.chungtuSelect.DichVus.forEach(
      (item) => {
        //console.log(item.So_Tien);
        item.He_So = this.He_So;
        item.So_Tien = item.Don_Gia * item.So_Luong * item.He_So;
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tienvattu = this.tienvattu + (item.So_Tien === null ? 0 : item.So_Tien)
        item.Thue_Suat = this.chungtuSelect.Thue_Ty_Le;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);
        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);
      }
    );




  }
  public changeHS() {
    this.chungtuSelect.Tong_Tien = 0;
    this.tiencong = 0;
    this.tienvattu = 0;
    this.tienthue = 0;
    this.chungtuSelect.NhanCongs.forEach(
      (item) => {
        //console.log(item.So_Tien);
        item.He_So = this.He_So;
        item.So_Tien = item.Don_Gia * item.So_Luong * item.He_So;
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tiencong = this.tiencong + (item.So_Tien === null ? 0 : item.So_Tien)
        if (item.Thue_Suat == null)
          item.Thue_Suat = 10;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);

        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);

      }
    );
    this.chungtuSelect.DichVus.forEach(
      (item) => {
        //console.log(item.So_Tien);
        item.He_So = this.He_So;
        item.So_Tien = item.Don_Gia * item.So_Luong * item.He_So;
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tienvattu = this.tienvattu + (item.So_Tien === null ? 0 : item.So_Tien)
        if (item.Thue_Suat == null)
          item.Thue_Suat = 10;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);
        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);
      }
    );




  }
  public changeSL(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong * drow.He_So;
    //if (this.HienThis.Hang_Hoa_Id_Co == true)
    // this.layGiaHang(drow);
  }
  public changeDG(drow: any) {
    drow.So_Tien = drow.Don_Gia * drow.So_Luong * drow.He_So;
    this.changeST(drow);
  }
  public changeST(drow: any) {
    this.thaydoi = true;
    this.chungtuSelect.Tong_Tien = 0;
    this.tiencong = 0;
    this.tienvattu = 0;
    this.tienthue = 0;
    if (drow.Thue_Suat == null)
      drow.Thue_Suat = 10;
    drow.Tien_Thue = drow.So_Tien * (drow.Thue_Suat / 100);

    this.chungtuSelect.NhanCongs.forEach(
      (item) => {
        //console.log(item.So_Tien);
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tiencong = this.tiencong + (item.So_Tien === null ? 0 : item.So_Tien)

        if (item.Thue_Suat == null)
          item.Thue_Suat = 10;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);
        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);
      }
    );
    this.chungtuSelect.DichVus.forEach(
      (item) => {
        //console.log(item.So_Tien);
        this.chungtuSelect.Tong_Tien = this.chungtuSelect.Tong_Tien + (item.So_Tien === null ? 0 : item.So_Tien)
        this.tienvattu = this.tienvattu + (item.So_Tien === null ? 0 : item.So_Tien)

        if (item.Thue_Suat == null)
          item.Thue_Suat = 10;
        item.Tien_Thue = item.So_Tien * (item.Thue_Suat / 100);
        this.tienthue += item.Tien_Thue;

        this.chungtuSelect.Thue_Tien = this.tienthue;
        this.chungtuSelect.Tong_Thanh_Toan = this.chungtuSelect.Tong_Tien + this.chungtuSelect.Thue_Tien
          + (this.chungtuSelect.Chi_Phi_Khac != null ? this.chungtuSelect.Chi_Phi_Khac : 0)
          - (this.chungtuSelect.Chiet_Khau_Tien != null ? this.chungtuSelect.Chiet_Khau_Tien : 0);
      }
    );



  }
  datecheck(conformedValue) {
    if (conformedValue == null || conformedValue === "")
      return true;
    const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS']
    var dateFormat: String = 'dd/mm/yyyy';
    var maxYear: number = 9999
    var minYear: number = 1;
    const dateFormatArray = dateFormat.split(/[^dmyHMS]+/).sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b))

    const indexesOfPipedChars = []
    const maxValue = { 'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59 }
    const minValue = { 'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0 }
    const conformedValueArr = conformedValue.split('')

    // Check first digit
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format)
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10)

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position]
        conformedValueArr[position] = 0
        indexesOfPipedChars.push(position)
      }
    })

    // Check for invalid date
    let month = 0
    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format)
      const length = format.length
      const textValue = conformedValue.substr(position, length).replace(/\D/g, '')
      const value = parseInt(textValue, 10)
      if (format === 'mm') {
        month = value || 0
      }
      const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format]
      if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
        const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10)
        const scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10)
        return value < scopedMinValue || value > scopedMaxValue
      }
      return value > maxValueForFormat || (textValue.length === length && value < minValue[format])
    })

    if (isInvalid) {
      return false
    }

    return true;
  }

  export(kieuin, loaiphieu) {
    //loaiphieu=1 là báo giá tổng thể
    //loaiphieu=2 là báo giá sửa chữa
    //loaiphieu=3 là báo giá bảo hiểm
    //loaiphieu=4 là báo giá bảo hành
    //loaiphieu=5 là lệnh sửa chữa
    //loaiphieu=6 là Yêu cầu xuất kho
    //loaiphieu=7 là Biên bản nghiệm thu bàn giao xe
    //loaiphieu=8 là 
    //loaiphieu=9 là báo giá phụ tùng in ở phân hệ báo giá phụ tùng
    //loaiphieu=10 là yêu cầu xuất kho in ở phân hệ yêu cầu xuất kho
    //loaiphieu=11 là báo giá tổng thể dịch vụ ở phân hệ báo giá dịch vụ (in báo giá + quyết toán + phiếu ra cổng)
    let idchungtu = "";
    idchungtu = this.chungtuSelect.Id;
    if (loaiphieu == null || loaiphieu == "") {
      swal('Cảnh báo!', 'Chưa chọn loại phiếu để in!.', 'warning');
      return;
    }
    if (idchungtu == null || idchungtu == "") {
      swal('Cảnh báo!', 'Chưa chọn phiếu để in!.', 'warning');
      return;
    }

    let tid = null;
    if (loaiphieu >= 1 && loaiphieu < 5)
      tid = "AC30DAD3-EF3C-4BB5-8281-F33484525ABD";

    if (loaiphieu == 5)
      tid = "728D225E-E4D3-4094-95D2-9A7A2348F852";
    if (loaiphieu == 6)
      tid = "49A10B8E-F74C-42D9-BD55-740AEC99A785";
    if (loaiphieu == 7)
      tid = "26E59FC5-3B0B-4ED8-A869-E5768A3DB944";
    if (loaiphieu == 9)
      tid = "DA1427C5-5C1F-4065-BD9C-A785F45C6B68";
    if (loaiphieu == 11)
      tid = "5A196D5A-F21A-4003-A061-8281D780D4DA";
    // báo giá phụ tùng
    if (loaiphieu == 12)
      tid = "DFC6F830-57D6-411B-A5E9-EFBBF929F916";

    $('#loading').show();
    let data = {
      Id: tid,
      Type: kieuin,
      Data: JSON.stringify({
        DonViId: this.currentUser.id_don_vi
        , UserName: this.currentUser.user_name
        , Id: idchungtu
        , LoaiPhieu: loaiphieu
        , DoanhNghiepId: this.doanhnghiepid

      })
    };
    if (kieuin === 'xlsx') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          var file = new Blob([response.body], { type: 'vnd.ms-excel' });
          var fileURL = URL.createObjectURL(file);
          $('#loading').hide();
          var a = document.createElement("a");
          a.href = fileURL;
          a.download = "bao_cao.xlsx";
          document.body.appendChild(a);
          a.click();

        });
    }
    if (kieuin === 'pdf') {
      this.repo.postPdf(Global.BASE_BaoCao_SAVE, data)
        .subscribe(response => {
          if (response == "L") {
            this.repo.postRequest(Global.BASE_BaoCao_SAVE, data).subscribe(
              data => {
                $('#loading').hide();
              },
              error => {
                this.msg = error;
              }
            );
          }
          else {
            $('#loading').hide();
            var file = new Blob([response.body], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "EPrescription");
          }

        });
    }




  }



}
export interface Idata {
  Id: string,
  Ten: string,
  Dien_Thoai: string,
  Dia_Chi: string,
  Email: string,
  GhiChu: string,
  Created: Date,
  CreatedBy: string,
  Modified: Date,
  ModifiedBy: string
}
