import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { AuthGuard } from './shared/util/auth.guard';

import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import '@progress/kendo-angular-intl/locales/vi/all';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TabModule } from '@syncfusion/ej2-angular-navigations';





// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeVi);

@NgModule({ 
    declarations: [
        AppComponent
    ],
    imports: [
      HttpClientModule,
      BrowserAnimationsModule, // required for ng2-tag-input
      CoreModule,
      LayoutModule,
      SharedModule.forRoot(),
      SlimLoadingBarModule.forRoot(),
      RoutesModule,
      BrowserModule,      
      TabModule, 
     
      FormsModule, ReactiveFormsModule,
      //----------Syncfusion
      //DatePickerModule,
      //TimePickerModule,
      //---------------------------------
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
    ],
  providers: [
    { provide: LOCALE_ID, useValue: 'vi' },
    AuthGuard
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
