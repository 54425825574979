import { Injectable } from '@angular/core';

import { Repository } from './repository';


@Injectable()
export class AuthorizationService {
    permissions: any=[]; // Store the actions for which this user has permission
    constructor(private repo: Repository) { }
  hasPermission(authGroup: number, functionid: string) {

    if (this.permissions) {
            console.log(this.permissions);
            //console.log(authGroup);
            //console.log(functionid);
            for (var i = 0, len = this.permissions.length; i < len; i++) {              
              if (this.permissions[i].FunctionId.toLowerCase() == functionid.toLowerCase() && this.permissions[i].AccessRight == authGroup) {
                    return true;
                }
              
            }
           
        }
       
        return false;
    }

    // This method is called once and a list of permissions is stored in the permissions property
  initializePermissions() {
    return new Promise((resolve, reject) => {
           
            console.log("vao lay quyen tu database")

            this.repo.getRequest('api/user/getpermision')
                .subscribe(response => {
                  this.permissions = response;
                  resolve(true)
                });
            
        });
    }
}
