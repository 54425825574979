<ul class="navbar">
  <!--<li class="nav-item">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fab fa-buromobelexperte"></i>
        Mẫu
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="dinhkhoanmau(row)" *ngFor='let row of inputdinhkhoanmaus;'>{{row.Noi_Dung}}</a>
      </div>
    </div>
  </li>
  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null) && chungtuSelect.Lien_Quan_Id!=null">
    <a class="nav-link" (click)="savectu(1)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại và cập nhật giấy rút
    </a>
  </li>-->

  <li class="nav-item active" *ngIf="chungtuSelect.Id!=null">
    <a class="nav-link" (click)="adddata_phieuxuat()">
      <i class="fa fa-plus-square fa-2x" aria-hidden="true"></i>
      {{chungtuSelect.TieuDe}}
    </a>
  </li>

  <li class="nav-item active" *ngIf="chungtuSelect.Id!=null">
    <a class="nav-link" (click)="adddata_quyettoan()">
      <i class="fa fa-calculator fa-2x" aria-hidden="true"></i>
      Quyết toán
    </a>
  </li>

  <li class="nav-item" *ngIf="this.chungtuSelect.Id!=null">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fas fa-print"></i>
        In
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="export('pdf',11)">Báo giá + QT + phiếu ra cổng</a>
        <a class="dropdown-item" (click)="export('xlsx',11)">Báo giá + QT + phiếu ra cổng (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',1)">Báo giá tổng thể</a>
        <a class="dropdown-item" (click)="export('xlsx',1)">Báo giá tổng thể (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',2)">Báo giá sửa chữa</a>
        <a class="dropdown-item" (click)="export('xlsx',2)">Báo giá sửa chữa (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',3)">Báo giá bảo hiểm</a>
        <a class="dropdown-item" (click)="export('xlsx',3)">Báo giá bảo hiểm (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',4)">Báo giá bảo hành</a>
        <a class="dropdown-item" (click)="export('xlsx',4)">Báo giá bảo hành (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',5)">Lệnh sửa chữa</a>
        <a class="dropdown-item" (click)="export('xlsx',5)">Lệnh sửa chữa (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',6)">Yêu cầu xuất kho</a>
        <a class="dropdown-item" (click)="export('xlsx',6)">Yêu cầu xuất kho (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',7)">Biên bản nghiệm thu bàn giao</a>
        <a class="dropdown-item" (click)="export('xlsx',7)">Biên bản nghiệm thu bàn giao (excel)</a>

      </div>
    </div>
  </li>




  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null)">
    <a class="nav-link" (click)="savectu(0)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="closeModal()">
      <i class="fa-2x mr-2 far fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>
<div class="row">
  <div class="col-2">
    <div style="width:30%;float:left">Loại BG</div>
    <div style="width:70%;float:right">
      <ng-select #slLoaiBaoGia
                 labelForId="99"
                 class="twcol"
                 [tabIndex]="1"
                 [openOnEnter]="false"
                 [items]="inputloaibaogias"
                 bindValue="Id"
                 bindLabel="Ten_Doi_Tuong"
                 notFoundText="Không có dữ liệu"
                 placeholder=""
                 [(ngModel)]="chungtuSelect.Loai_Bao_Gia">
        <ng-template ng-option-tmp let-item="item">
          <div class="rTableRow">
            <div class="rTableCell rTableCell-1">{{item.Ten_Doi_Tuong}}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-2">
    <div style="width:30%;float:left">Mã BG</div>
    <div style="width:70%;float:right">
      <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Bao_Gia">
    </div>
  </div>
  <div class="col-3">
    <div style="width:25%;float:left">Ngày lập</div>
    <div style="width:40%;float:left">
      <kendo-dateinput tabindex="3" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Lap"></kendo-dateinput>
    </div>
    <div style="width:18%;float:left">Hệ số</div>
    <div style="width:17%;float:left">
      <input id="heso_10_1" #myinput class="form-control"
             maxlength="20"
             tabindex="4"
             style="width:100%;"
             currencyMask
             [(ngModel)]="He_So"
             (ngModelChange)="changeHS()"
             name="value"
             [placeholder]="'Hệ số'"
             [options]="ngxHSOptions" autocomplete="off" />
    </div>
  </div>
  <div class="col-5">
    <div style="width:12%;float:left">Mã lệnh</div>
    <div style="width:21%;float:left">
      <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Lenh_Sua">
    </div>
    <div style="width:12%;float:left">Mã YC</div>
    <div style="width:21%;float:left">
      <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Yeu_Cau">
    </div>
    <div style="width:12%;float:left">Mã HTC</div>
    <div style="width:22%;float:left">
      <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_HTC">
    </div>
  </div>
</div>

<div class="row ng-scope">
  <div class="col-4">
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Biển số xe</div>
        <div style="width:70%;float:left">
          <a *ngIf="chungtuSelect.Xe_Id===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
            <input id="99" type="text" [disabled]="true" class="form-control" placeholder="Chọn xe" [(ngModel)]="chungtuSelect.Bien_So">
          </a>
          <a *ngIf="chungtuSelect.Xe_Id!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
            <input id="99" type="text" [disabled]="true" class="form-control" placeholder="Chọn xe" [(ngModel)]="chungtuSelect.Bien_So">
          </a>
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">Km</div>
        <div style="width:70%;float:left">
          <input id="heso_km_1" #myinput class="form-control"
                 maxlength="20"
                 tabindex="6"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="chungtuSelect.So_Km"
                 name="value"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Hãng xe</div>
        <div style="width:70%;float:left">
          <ng-select #slHangxe
                     class="twcol"
                     [tabIndex]="7"
                     [openOnEnter]="false"
                     [items]="inputhangxes"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     (change)="onChangeHangXe($event)"
                     [(ngModel)]="chungtuSelect.Hang_Xe_Id">
            <ng-template ng-option-tmp let-item="item">
              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">Model xe</div>
        <div style="width:70%;float:left">
          <ng-select #slPhongban
                     class="twcol"
                     [tabIndex]="8"
                     [openOnEnter]="false"
                     [items]="inputmodels"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [searchFn]="comboSearchFn"
                     [(ngModel)]="chungtuSelect.Model_Xe_Id">
            <ng-template ng-option-tmp let-item="item">

              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>

            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Số khung</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.So_Khung">
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">Số máy</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.So_May">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Màu xe</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="11" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Mau_Xe">
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">Hãng BH</div>
        <div style="width:70%;float:left">
          <ng-select #slHangBaoHiem
                     class="twcol"
                     [tabIndex]="12"
                     [openOnEnter]="false"
                     [items]="inputhangbaohiems"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [(ngModel)]="chungtuSelect.Hang_Bao_Hiem_Id">
            <ng-template ng-option-tmp let-item="item">
              <div class="rTableRow">
                <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Chủ xe</div>
        <div style="width:70%;float:left">
          <a *ngIf="chungtuSelect.Khach_Hang_Id===null" (click)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
            <input id="99" type="text" class="form-control" placeholder="Chọn xe" [(ngModel)]="chungtuSelect.Khach_Hang">
          </a>
          <a *ngIf="chungtuSelect.Khach_Hang_Id!==null" (dblclick)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
            <input id="99" type="text" class="form-control" placeholder="Chọn xe" [(ngModel)]="chungtuSelect.Khach_Hang" (ngModelChange)="adddatachondoituongchung(2)">
          </a> 
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">Điện thoại</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Dien_Thoai">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div style="width:30%;float:left">Người SD</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Nguoi_Su_Dung">
        </div>
      </div>
      <div class="col-6">
        <div style="width:30%;float:left">MST</div>
        <div style="width:70%;float:left">
          <input type="text" tabIndex="15" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.MST">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:14%;float:left">Địa chỉ</div>
        <div style="width:86%;float:left">
          <input type="text" tabIndex="16" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Dia_Chi">
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:14%;float:left">Nhận nợ</div>
        <div style="width:86%;float:left">
          <a *ngIf="chungtuSelect.Phieu_Vao_Xuong===null" (click)="adddatachondoituongchung(3)" style="color:blue;font-weight:bold">
            <input id="99" type="text"  class="form-control" placeholder="Nhận nợ" [(ngModel)]="chungtuSelect.Nhan_No">
          </a>
          <a *ngIf="chungtuSelect.Phieu_Vao_Xuong!==null" (dblclick)="adddatachondoituongchung(3)" style="color:blue;font-weight:bold">
            <input id="99" type="text"  class="form-control" placeholder="Nhận nợ" [(ngModel)]="chungtuSelect.Nhan_No" (ngModelChange)="adddatachondoituongchung(3)">
          </a>
        </div>
      </div>

    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">
        <div style="width:14%;float:left;text-align:left">Yêu cầu bảo dưỡng</div>
        <div style="width:86%;float:left">
          <textarea type="text" tabIndex="17" class="form-control" placeholder="" rows="2" [(ngModel)]="chungtuSelect.Yeu_Cau_Sua"></textarea>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:14%;float:left;text-align:left">Tình trạng tiếp nhận</div>
        <div style="width:86%;float:left">
          <textarea type="text" tabIndex="18" class="form-control" placeholder="" rows="2" [(ngModel)]="chungtuSelect.Tinh_Trang_Xe"></textarea>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}"><a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id=""><span></span>1.Phân công lao động</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id=""><span></span>2.Phụ tùng, dầu mỡ, vật tư</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}"><a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id=""><span></span>3.Lệnh sửa chữa</a></li>
    </ul>
    <div>
      <input type="number" max="7" min="0" style="width:40px;height:20px;text-align:center;margin-right:3px" [(ngModel)]="fixcot" (change)="fn_fixcot()" />
      <a (click)="RowNewClick()" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng mới</a>
    </div>
  </div>

  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="height:calc(100vh - 350px);background:#fff;">
          <table id="fixTablechungtukhac_01" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_CongViec')">Công việc</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_CongViec==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_CongViec==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_Loai')">Loại</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_Loai==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_Loai==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_Gio')">Giờ</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_Gio==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_Gio==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_HeSo')">Hệ số</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_HeSo==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_HeSo==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_DonGia')">Đơn giá</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_DonGia==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_DonGia==0" style="color:red"></i>
                </th>
                <th width="130" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_SoTien')">Số tiền</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_SoTien==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_SoTien==0" style="color:red"></i>
                  <!--Tổng <span style="font-size: 11px;color: #ea182b;">( {{chungtuSelect.Tong_Tien | number}})</span>-->
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_BaoHiem')">Bảo hiểm</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_BaoHiem==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_BaoHiem==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_Thue')">Thuế (%)</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_Thue==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_Thue==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_TienThue')">Tiền thuế</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_TienThue==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_TienThue==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_GhiChu')">Ghi chú</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_GhiChu==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_GhiChu==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab1('SapXep_ThuTu')">STT</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab1_SapXep.SapXep_ThuTu==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab1_SapXep.SapXep_ThuTu==0" style="color:red"></i>
                </th>

              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 360px);">
              <tr *ngFor='let row of chungtuSelect.NhanCongs; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,1)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <!--Phu_Tung_Id-->
                  <a *ngIf="row.Phu_Tung_Id===null" (click)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_1" type="text"
                           (keydown)="keyComBoPress($event,i,1,1)" class="form-control"
                           style="min-width:180px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung">
                  </a>
                  <a *ngIf="row.Phu_Tung_Id!==null" (dblclick)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_1" type="text"
                           (keydown)="keyComBoPress($event,i,1,1)" class="form-control"
                           style="min-width:180px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung" (ngModelChange)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)">
                  </a>

                </td>

                <td>
                  <!--Loại dịch vụ-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,2,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:90px"
                             labelForId="{{i}}_2_1"
                             [openOnEnter]="false"
                             [items]="inputloaidichvus"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Dich_Vu_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>


                <td>

                  <input id="{{i}}_3_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,3,1)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>
                <td>

                  <input id="{{i}}_4_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,4,1)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.He_So"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_5_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,5,1)"
                         maxlength="20"
                         style="min-width:110px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxCurrencyOptions" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_6_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,6,1)"
                         maxlength="20"
                         style="min-width:130px;"
                         currencyMask
                         [(ngModel)]="row.So_Tien"
                         (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="'Số tiền'"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_7_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,7,1)"
                         maxlength="20"
                         style="min-width:120px;"
                         currencyMask
                         [(ngModel)]="row.Gia_Bao_Hiem"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_8_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,8,1)"
                         maxlength="20"
                         style="min-width:90px;"
                         currencyMask
                         [(ngModel)]="row.Thue_Suat"
                          (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_9_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,9,1)"
                         maxlength="20"
                         style="min-width:100px;"
                         currencyMask
                         [(ngModel)]="row.Tien_Thue"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>

                  <input id="{{i}}_10_1" #myinput type="text" style="min-width:120px" (keydown)="keyComBoPress($event,i,10,1)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ghi_Chu">
                </td>
                <td>
                  <input id="{{i}}_11_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,1)"
                         maxlength="20"
                         style="min-width:90px;"
                         currencyMask
                         [(ngModel)]="row.Thu_Tu"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>


                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 350px);background:#fff;">
          <table id="fixTablechungtukhac_02" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_CongViec')">Phụ tùng</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_CongViec==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_CongViec==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_Loai')">Loại</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_Loai==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_Loai==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_Gio')">Số lượng</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_Gio==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_Gio==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_HeSo')">Hệ số</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_HeSo==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_HeSo==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_DonGia')">Đơn giá</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_DonGia==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_DonGia==0" style="color:red"></i>
                </th>
                <th width="130" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_SoTien')">Số tiền</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_SoTien==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_SoTien==0" style="color:red"></i>
                  <!--Tổng <span style="font-size: 11px;color: #ea182b;">( {{chungtuSelect.Tong_Tien | number}})</span>-->
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_BaoHiem')">Bảo hiểm</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_BaoHiem==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_BaoHiem==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_Thue')">Thuế (%)</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_Thue==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_Thue==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_TienThue')">Tiền thuế</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_TienThue==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_TienThue==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_GhiChu')">Ghi chú</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_GhiChu==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_GhiChu==0" style="color:red"></i>
                </th>
                <th width="100" style="text-align:center">
                  <a (click)="SapXepTab2('SapXep_ThuTu')">STT</a>
                  <i class="fas fa-arrow-down" *ngIf="Tab2_SapXep.SapXep_ThuTu==1" style="color:red"></i>
                  <i class="fas fa-arrow-up" *ngIf="Tab2_SapXep.SapXep_ThuTu==0" style="color:red"></i>
                </th>

              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 360px);">
              <tr *ngFor='let row of chungtuSelect.DichVus; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,2)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <!--Phu_Tung_Id-->
                  <a *ngIf="row.Phu_Tung_Id===null" (click)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_2" type="text"
                           (keydown)="keyComBoPress($event,i,1,2)" class="form-control"
                           style="min-width:180px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung">
                  </a>
                  <a *ngIf="row.Phu_Tung_Id!==null" (dblclick)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_2" type="text"
                           (keydown)="keyComBoPress($event,i,1,2)" class="form-control"
                           style="min-width:180px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung" (ngModelChange)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)">
                  </a>

                </td>

                <td>
                  <!--Loại dịch vụ-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,2,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:90px"
                             labelForId="{{i}}_2_2"
                             [openOnEnter]="false"
                             [items]="inputloaidichvus"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Dich_Vu_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>


                <td>

                  <input id="{{i}}_3_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,3,2)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>
                <td>

                  <input id="{{i}}_4_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,4,2)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.He_So"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_5_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,5,2)"
                         maxlength="20"
                         style="min-width:110px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxCurrencyOptions" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_6_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,6,2)"
                         maxlength="20"
                         style="min-width:120px;"
                         currencyMask
                         [(ngModel)]="row.So_Tien"
                         (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="'Số tiền'"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_7_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,7,2)"
                         maxlength="20"
                         style="min-width:110px;"
                         currencyMask
                         [(ngModel)]="row.Gia_Bao_Hiem"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_8_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,8,2)"
                         maxlength="20"
                         style="min-width:90px;"
                         currencyMask
                         [(ngModel)]="row.Thue_Suat"
                          (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>
                  <input id="{{i}}_9_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,9,2)"
                         maxlength="20"
                         style="min-width:100px;"
                         currencyMask
                         [(ngModel)]="row.Tien_Thue"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>

                  <input id="{{i}}_10_2" #myinput type="text" style="min-width:100px" (keydown)="keyComBoPress($event,i,10,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ghi_Chu">
                </td>
                <td>
                  <input id="{{i}}_11_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,2)"
                         maxlength="20"
                         style="min-width:90px;"
                         currencyMask
                         [(ngModel)]="row.Thu_Tu"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>


                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class=" tab-pane" [ngClass]="{'active': tabselected==3}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 350px);background:#fff;">
          <table id="fixTablechungtukhac_03" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <!--<th>#</th>-->
                <th width="100" style="text-align:center">Công việc</th>
                <th width="100" style="text-align:center">Loại</th>
                <th width="100" style="text-align:center">Nguyên nhân</th>
                <th width="100" style="text-align:center">Kết quả</th>
                <th width="100" style="text-align:center">Kỹ thuật viên</th>
                <th width="100" style="text-align:center">Ghi chú</th>
              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 360px);">
              <tr *ngFor='let row of chungtuSelect.NhanCongs; let i = index' style="height:30px !important">
                <!--<td>
    <div style="width:30px;background-color:white">
      <a title="Xóa dòng" (click)="deleterow(i,1)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
    </div>
  </td>-->
                <td>
                  <!--Phu_Tung_Id-->
                  <a *ngIf="row.Phu_Tung_Id===null" (click)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_3" type="text"
                           (keydown)="keyComBoPress($event,i,1,1)" class="form-control"
                           style="min-width:200px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung">
                  </a>
                  <a *ngIf="row.Phu_Tung_Id!==null" (dblclick)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_3" type="text"
                           (keydown)="keyComBoPress($event,i,1,1)" class="form-control"
                           style="min-width:200px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung"  (ngModelChange)="adddatachondoituongchung_luoi('Dich_Vu_Id',i)">
                  </a>

                </td>

                <td>
                  <!--Loại dịch vụ-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,2,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:90px"
                             labelForId="{{i}}_2_3"
                             [openOnEnter]="false"
                             [items]="inputloaidichvus"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Dich_Vu_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td>
                  <input id="{{i}}_3_3" #myinput type="text" style="min-width:220px" (keydown)="keyComBoPress($event,i,3,3)"
                         class="form-control" placeholder="" [(ngModel)]="row.Nguyen_Nhan">
                </td>

                <td>
                  <input id="{{i}}_4_3" #myinput type="text" style="min-width:220px" (keydown)="keyComBoPress($event,i,4,3)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ket_Qua">
                </td>


                <td>
                  <ng-select #slLuoi_kythuatvien (keydown)="keyComBoPress($event,i,5,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="min-width:100px"
                             labelForId="{{i}}_5_3"
                             [openOnEnter]="false"
                             [items]="inputnguoisuas"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Nguoi_Sua_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td>
                  <input id="{{i}}_6_3" #myinput type="text" style="min-width:120px" (keydown)="keyComBoPress($event,i,6,3)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ghi_Chu">
                </td>



                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row"></div>

<div class="row">
  <div class="col-4">
    <div class="row">
      <div style="width:20%;float:left">KH tới</div>
      <div style="width:30%;float:left">
        <kendo-dateinput tabindex="30" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Khach_Toi"></kendo-dateinput>
      </div>
      <div style="width:20%;float:left">BĐ sửa chữa</div>
      <div style="width:30%;float:left">
        <kendo-dateinput tabindex="31" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Bat_Dau_Sua"></kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <div style="width:20%;float:left">Dự kiến xong</div>
      <div style="width:30%;float:left">
        <kendo-dateinput tabindex="32" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Du_Kien_Xong"></kendo-dateinput>
      </div>
      <div style="width:20%;float:left">KT sửa chữa</div>
      <div style="width:30%;float:left">
        <kendo-dateinput tabindex="33" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Ket_Thuc_Sua"></kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <div style="width:20%;float:left">Ngày BD</div>
      <div style="width:30%;float:left">
        <kendo-dateinput tabindex="34" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Bao_Duong_Ngay"></kendo-dateinput>
      </div>
      <div style="width:20%;float:left">Km bảo dưỡng</div>
      <div style="width:30%;float:left">
        <input id="heso_km_bd_1" #myinput class="form-control"
               maxlength="20"
               tabindex="35"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Bao_Duong_Km"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
    </div>
    
  </div>
  <div class="col-8">
    <div class="row">
      <div style="width:15%;float:left">Tổng tiền công</div>
      <div style="width:20%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="35"
               [disabled]="true"
               style="width:100%;"
               currencyMask
               [(ngModel)]="tiencong"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:12%;float:left">Tỷ lệ CK</div>
      <div style="width:13%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="36"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Chieu_Khau_Ty_Le"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:15%;float:left">Tiền CK</div>
      <div style="width:24%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="37"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Chiet_Khau_Tien"
                (ngModelChange)="changeChietKhau()"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>

    </div>
    <div class="row">
      <div style="width:15%;float:left">Tổng vật tư</div>
      <div style="width:20%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="35"
               [disabled]="true"
               style="width:100%;"
               currencyMask
               [(ngModel)]="tienvattu"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:12%;float:left">Tỷ lệ thuế</div>
      <div style="width:13%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="36"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Thue_Ty_Le"
               (ngModelChange)="changeTyLeThue()"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:15%;float:left">Tiền thuế</div>
      <div style="width:24%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="37"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Thue_Tien"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>

    </div>
    <div class="row">
      <div style="width:15%;float:left">Tổng tiền</div>
      <div style="width:20%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="35"
               [disabled]="true"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Tong_Tien"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:12%;float:left">Chi phí khác</div>
      <div style="width:13%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="36"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Chi_Phi_Khac"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>
      <div style="width:15%;float:left">Tổng thanh toán</div>
      <div style="width:24%;float:left">
        <input id="tiencong" #myinput class="form-control"
               maxlength="20"
               tabindex="37"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Tong_Thanh_Toan"
               name="value"
               [placeholder]="''"
               [options]="ngxCurrencyOptions" autocomplete="off" />
      </div>

    </div>
    <div class="row">
      <div style="width:20%;float:left">
        <label>
          <input tabIndex="50" type="checkbox" [(ngModel)]="chungtuSelect.Khach_Cho" />
          Khách hàng chờ
        </label>
      </div>
      <div style="width:20%;float:left">
        <label>
          <input tabIndex="50" type="checkbox" [(ngModel)]="chungtuSelect.Rua_Xe" />
          Yêu cầu Rửa xe
        </label>
      </div>
      <div style="width:20%;float:left">
        <label>
          <input tabIndex="50" type="checkbox" [(ngModel)]="chungtuSelect.Sua_Lai" />
          Yêu cầu sửa chữa lại
        </label>
      </div>
      <div style="width:20%;float:left">
        <label>
          <input tabIndex="50" type="checkbox" [(ngModel)]="chungtuSelect.Huy_Bao_Gia" />
          Hủy báo giá
        </label>
      </div>



    </div>
  </div>
</div>


<div class="modal-footer" style="display: flex;justify-content: space-between;padding:0px;">
  <div>
    <span style="float:left;padding-right:15px;line-height:25px;">Kích chuột 1 lần vào chọn đối tượng, kích đúp chuột vào tên đối tượng để hiển thị danh sách chọn</span>
  </div>
  <div class="">
  </div>

</div>


<bs-modal #mdDoiTuongChung class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucchungedit #dteChung (save)="saveModal($event)"></app-dm-danhmucchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #mdDoiTuongNH class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongNH.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucnganhangedit #dtenganhang (save)="saveModalNganHang($event)"></app-dm-danhmucnganhangedit>
  </bs-modal-body>
</bs-modal>


<!--<bs-modal #mdLuaChon class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Bạn muốn thêm người nộp là?</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChon.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(3)" value="option1" />
        <span class="fa fa-circle"></span>Khách hàng
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(1)" value="option1" />
        <span class="fa fa-circle"></span>Nhà cung cấp
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(2)" value="option2" checked="" />
        <span class="fa fa-circle"></span>Cán bộ
      </label>
    </div>
  </bs-modal-body>
  <bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">

      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChon.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormNguoiNop()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>

    </div>
  </bs-modal-footer>
</bs-modal>-->

<bs-modal #mdLuaChonDoRong class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thay đổi độ rộng</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChonDoRong.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Dien_Giai_Chi_Tiet" />
      </div>
      <label class="control-label col-3 ng-binding">Hàng hóa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kho hàng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đơn vị tính nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hàng hóa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Kho hang có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Đơn vị tính có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_Co" />
      </div>

    </div>

  </bs-modal-body>
  <!--<bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">
      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChonDoRong.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormDoRong()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>
    </div>
  </bs-modal-footer>-->
</bs-modal>

<!--<bs-modal #mdCanBo class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdCanBo.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmucnhanvienedit #dteCanBo (save)="saveModalCanBo($event)"></app-dm-danhmucnhanvienedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdKhachHang class="modal fade show in" [cssClass]="'modal600'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdKhachHang.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuckhachhangedit #dteKhachHang (save)="saveModalKhacHang($event)"></app-dm-danhmuckhachhangedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdTaiSan class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdTaiSan.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuctaisanedit #dtetaisan (save)="saveModalTaiSan($event)"></app-dm-danhmuctaisanedit>
  </bs-modal-body>
</bs-modal>-->


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchungedit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #modalphieuxuat class="modal fade show in" [cssClass]="'modalsanpham'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
  </div>
  <bs-modal-body>
    <app-ketoan-chungtuchungedit #ctphieuxuatedit (savechungtu)="savept($event)"></app-ketoan-chungtuchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #modalquyettoan class="modal fade show in" [cssClass]="'modalsanpham'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
  </div>
  <bs-modal-body>
    <app-ketoan-quyettoanedit #ctquyettoantedit (savechungtu)="savept($event)"></app-ketoan-quyettoanedit>
  </bs-modal-body>
</bs-modal>
