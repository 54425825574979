import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '../shared/util/auth.guard';
import { Home } from './menu';

export const routes: Routes = [
  {
    path: '',
    //component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      //{ path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), component: LayoutComponent, canActivate: [AuthGuard], data: { auth: '1', func: Home.id } },
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), component: LayoutComponent, canActivate: [AuthGuard]},
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
      //{ path: 'login', loadChildren: './login/login.module#LoginModule' },
      { path: 'waitlogin', loadChildren: './waitlogin/waitlogin.module#WaitLoginModule' },
      { path: 'ketoan', loadChildren: () => import('./ketoan/ketoan.module').then(m => m.KeToanModule), component: LayoutComponent, canActivate: [AuthGuard] },
      //{ path: 'nhaplieu', loadChildren: () => import('./nhaplieu/nhaplieu.module').then(m => m.NhapLieuModule), component: LayoutComponent, canActivate: [AuthGuard] },
      //{ path: 'dautu', loadChildren: () => import('./dautu/dautu.module').then(m => m.DauTuModule), component: LayoutComponent, canActivate: [AuthGuard] },
      //{ path: 'hosocongchuc', loadChildren: () => import('./hosocongchuc/hosocongchuc.module').then(m => m.HoSoCongChucModule), component: LayoutComponent, canActivate: [AuthGuard] },
      { path: 'danhmuc', loadChildren: () => import('./danhmuc/danhmuc.module').then(m => m.DanhMucModule), component: LayoutComponent, canActivate: [AuthGuard] },
      { path: 'quantri', loadChildren: () => import('./quantri/quantri.module').then(m => m.QuanTriModule), component: LayoutComponent, canActivate: [AuthGuard] },
      { path: 'baocao', loadChildren: () => import('./baocao/baocao.module').then(m => m.BaoCaoModule), component: LayoutComponent, canActivate: [AuthGuard] },

    ]
  },
  // Not found
  { path: '**', redirectTo: 'home' }

];
