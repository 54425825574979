import { Injectable } from '@angular/core';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        id: string,
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
           // this.menuItems.push(item);
        if (item.text == 'Trang chủ' || item.id==''
            || (item.id=='23199FED-325F-48F7-8E7C-3A83E68F3340' && item.text!=null) // thông báo
            || (item.id=='EC60D2A4-3345-490E-96CD-F4954D7DA4C8' && item.text!=null) // lưu trữ
            || (item.id=='9734BF3A-6E36-4D85-8C49-EEE122CAD547' && item.text!=null) // Quảng cáo
            || (item.id=='4C60DBAA-C69E-4878-B214-933D653D4F44' && item.text!=null) // phát hành
            ||
            (item.submenu != null && item.submenu.length > 0
     && item.submenu.filter((s) => s.text != null).length > 0) 
          )
               this.menuItems.push(item);
        }); 
    }

    getMenu() {

       // console.log("menutt");
       // console.log(this.menuItems);

        return this.menuItems;
    }

}
