import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import {
  Lists, Lists_phongban, Lists_khohang, Lists_donvitinh, Lists_khachhang, Lists_nhacungcap
  , Lists_nhanvien, Lists_madonvi, Lists_hopdong, Lists_hopdongban, Lists_xe, Lists_doituong
} from '../../../routes/menu'
import 'jquery'
import 'bootstrap'

//import { Lists_cmmt } from '../../../routes/menu'


const swal = require('sweetalert');
@Component({
  selector: 'app-dm-danhmuchopdongedit',
  templateUrl: './danhmuchopdongedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./danhmuchopdongedit.component.scss'],
    providers: [ListService, Repository]
})


export class danhmuchopdongeditComponent implements OnInit {
  tabselected: number = 1;
  la_hop_dong_ban: boolean = false;
  doanhnghiepid: string = null;
  public ngayky: Date = null;
  public hanthanhtoan: Date = null;
  public ngayduyet: Date = null;
  nhomloaikhoanid: any;
  quyen_them: boolean = false;
  quyen_sua: boolean = false;
  quyen_xoa: boolean = false;
  inputparents: any;
  inputkhachhangs: any;
  inputkieubanles: any;
  inputnhanviens: any;
  inputmadonvis: any;
  inputnhacungcaps: any;
  inputtientes: any;
  inputtaikhoans: any;
  inputxes: any;
  inputdactas: any;
  inputmodelxes: any;
  inputmauxes: any;
  

  inputhangbaohiems: any;
  inputdonvitinhs: any;
  inputxuatxus: any;
  inputkhohangs: any;
  inputphongbans: any;
  inputhangxes: any;
  inputmausacs: any;
  inputmodels: any;
  inputnhomctmts: any;
  indLoading: boolean = false;
  doituongSelect: any = {
    Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
    , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: null, Ma_Ao: null
    , Doanh_Nghiep_Id: null

    , HangHoas: [], Ma_Hop_Dong: null, Ten_Hop_Dong: null
    , Doi_Tuong_Id: null, Thanh_Toan: null, Kieu_Ban_Le: null, Nhan_Vien_Id: null
    , Cua_Hang_Id: null, Huy_Hop_Dong: null, Ngay_Ky: null, Ngay_KyS: null
    , Han_Thanh_Toan: null, Han_Thanh_ToanS: null, Gia_Ban: null, Gia_Tri: null
    , Tien_Te_Id: null, Gia_Tri_Ngoai_Te: null, Tai_Khoan_Id: null, Dong_Co: null, Loai_Hop_Dong: null
    , Dat_Coc: null, Phu_Kien: null, Kieu_Mua_Hang: null, So_Luong: null
    , Thang_Du_Kien: null, Nam_Du_Kien: null, Duyet_Hop_Dong: null, Ngay_Duyet: null, Ngay_DuyetS: null
    , Thung: null, Gia_Thung: null, De_Xuat_Id: null
    , Doi_Tuong: null
   

  };       
    nhomdtid:string=null;
  hoatdong: string = null;
  inputkieumuahangs: any = [
    { Ten_Doi_Tuong: "Mua hàng theo kế hoạch", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "Mua hàng ngoài kế hoạch", Ma_Doi_Tuong: '1', Id: 1 },
  ];
  inputkieuthanhtoans: any = [
    { Ten_Doi_Tuong: "Trả góp", Ma_Doi_Tuong: '0', Id: 0 },
    { Ten_Doi_Tuong: "Trả thẳng", Ma_Doi_Tuong: '1', Id: 1 },
  ];

  inputloais: any = [
    { text: "Nhân công", value: 0 },
    { text: "Vật tư sơn", value: 1 },
  ];

  @ViewChild('slCapTren', { static: false }) slCapTren: NgSelectComponent;
  @Output() save: EventEmitter<any> = new EventEmitter();

  @ViewChild('mdChonDoiTuongChung') mdChonDoiTuongChung: BsModalComponent;
  @ViewChild('dtechondoituongchungedit') dtechondoituongchungedit: any;
  
  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  selectedTab(tab: number) {
    this.tabselected = tab;
    //if (tab === 2) {


    //  setTimeout(() => {
    //    this.dateGhiTang.focus();
    //  }, 100);
    //}
    //else {
    //  setTimeout(() => {
    //    this.mafocus.nativeElement.focus();
    //  }, 100);
    //}

  }
  keyComBoPress(event: any, hang: number, cot: number, luoi: number) {

    //    if (event.which === 13) {
    //  cot = cot + 1;
    //  if (cot > this.maxcot) {
    //    hang = hang + 1;
    //    cot = 1;
    //    this.AddRowNew(this.doituongSelect.ChiTiets);

    //  }
    //  this.setComboFocus(hang, cot, luoi);
    //  event.preventDefault();
    //}
  }

    constructor( private _dataService: ListService, private repo: Repository) {
       
        
    }

    ngOnInit(): void {
       
    }

    // @HostListener('document:keydown', ['$event'])
    // public handleKeyboardEvent(event: KeyboardEvent): void {
      
    //   if(event.key==="F9")
    //   {
    //       this.saveproduct();
    //   }
    //   event.stopPropagation();
     
    // }
  comboSearchFnTK(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Tai_Khoan.toLowerCase().indexOf(term) > -1 || item.Ten_Tai_Khoan.toLowerCase().indexOf(term) > -1;
  }
    comboSearchFn(term: string, item: any) {
        term = term.toLowerCase();
        return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
    }
  @ViewChild("mafocus", { static: false }) mafocus: ElementRef;
  bnguonkinhphi: boolean = false;
  bnhomctmt: boolean = false;
  currentUser: any;
  tieudectmt: any;
  tieudectmtplace: any;
  onChangeHangXe($event) {

    this.repo.postRequest("api/doituong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, HangXeId: this.doituongSelect.Hang_Xe })
      .subscribe(response => {
        this.inputmodels = response;
      });
    


  }
  loadDataInit(nhomdoituongid: string, id: string, donviid: string, rowdata : any) {

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_tinhchatnguonkp.id.toLocaleLowerCase())
    //  this.bnguonkinhphi = true;

    //if (nhomdoituongid.toLocaleLowerCase() === Lists_cmmt.id.toLocaleLowerCase())
    //  this.bnhomctmt = true;

    if (nhomdoituongid.toLocaleUpperCase() == "0BF2B218-3D3A-4E2A-906B-06E4A13F135B")
      this.la_hop_dong_ban = true;
    



    this.tieudectmtplace = "Ghi chú";
    this.tieudectmt = "Ghi chú";

    this.tabselected = 1;

      this.hoatdong = "Hoạt động"

          var authlogin = localStorage.getItem('currentUser');
          if (authlogin) {

            this.currentUser = JSON.parse(authlogin);
            var giatri = this.currentUser.ds_quyen.filter(name => name.Id === nhomdoituongid.toLowerCase());
            if (giatri != null && giatri.length > 0) {
              if (giatri[0].Them == true)
                this.quyen_them = true;
              else
                this.quyen_them = false;
              if (giatri[0].Sua == true)
                this.quyen_sua = true;
              else
                this.quyen_sua = false;
              if (giatri[0].Xoa == true)
                this.quyen_xoa = true;
              else
                this.quyen_xoa = false;
            }

            this.doanhnghiepid = this.currentUser.id_doanh_nghiep;

          }    

        this.nhomdtid = nhomdoituongid;
        setTimeout(() => {
            this.mafocus.nativeElement.focus();  
    }, 500);

    //this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: nhomdoituongid, Id: id, DonViId: donviid, UserName: this.currentUser.user_name })
    //  .subscribe(response => {
    //    this.inputparents = response;
    //  });
     this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_khachhang.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputkhachhangs = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_nhacungcap.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputnhacungcaps = response;
      });
    this.repo.postRequest("api/kieubanle/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputkieubanles = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_nhanvien.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputnhanviens = response;
      });
    this.repo.postRequest("api/doituong/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: Lists_madonvi.id, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputmadonvis = response;
      });

    this.repo.postRequest("api/tiente/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputtientes = response;
      });

    this.repo.postRequest("api/taikhoan/getall/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputtaikhoans = response;
      });


    this.repo.postRequest("api/doituonghopdong/getxe/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputxes = response;
      });
    this.repo.postRequest("api/doituonghopdong/getdacta/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputdactas = response;
      });
    this.repo.postRequest("api/doituonghopdong/getmodelxe/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputmodelxes = response;
      });
    this.repo.postRequest("api/doituonghopdong/getmauxe/", { DoanhNghiepId: this.doanhnghiepid, NhomDoiTuongId: null, Id: null, DonViId: donviid, UserName: this.currentUser.user_name })
      .subscribe(response => {
        this.inputmauxes = response;
      });

    

    

    this.doituongSelect = {
      Id: null, Ma_Doi_Tuong: null, Ten_Doi_Tuong: null
      , Ghi_Chu: null, Parent: null, Trang_Thai: null, Nhom_Doi_Tuong_ID: nhomdoituongid, Ma_Ao: null
      , Created: null, Modified: null, CreatedBy: null, ModifiedBy: null
      , Doanh_Nghiep_Id: this.doanhnghiepid


      , HangHoas: [], Ma_Hop_Dong: null, Ten_Hop_Dong: null
      , Doi_Tuong_Id: null, Thanh_Toan: null, Kieu_Ban_Le: null, Nhan_Vien_Id: null
      , Cua_Hang_Id: null, Huy_Hop_Dong: null, Ngay_Ky: null, Ngay_KyS: null
      , Han_Thanh_Toan: null, Han_Thanh_ToanS: null, Gia_Ban: null, Gia_Tri: null
      , Tien_Te_Id: null, Gia_Tri_Ngoai_Te: null, Tai_Khoan_Id: null, Dong_Co: null, Loai_Hop_Dong: null
      , Dat_Coc: null, Phu_Kien: null, Kieu_Mua_Hang: null, So_Luong: null
      , Thang_Du_Kien: null, Nam_Du_Kien: null, Duyet_Hop_Dong: null, Ngay_Duyet: null, Ngay_DuyetS: null
      , Thung: null, Gia_Thung: null, De_Xuat_Id: null
      , Doi_Tuong: null

    };    
    if (id === null)
    {
      this.repo.postRequest("api/doituong/SinhMaDoiTuong/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
              this.doituongSelect.Ma_Doi_Tuong = response;
            });
          
          this.doituongSelect.Trang_Thai = true;
          this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
          this.doituongSelect.Created = new Date();
          this.doituongSelect.CreatedBy = this.currentUser.user_name;
          this.doituongSelect.Modified = new Date();
          this.doituongSelect.ModifiedBy = this.currentUser.user_name;

          if (rowdata != null) {
            this.doituongSelect.Ma_Doi_Tuong = rowdata.Ma;
            this.doituongSelect.Ten_Doi_Tuong = rowdata.Ten;
            this.doituongSelect.Trang_Thai = rowdata.Trang_Thai;
            this.doituongSelect.Ghi_Chu = rowdata.Ghi_Chu;
            this.doituongSelect.Parent = rowdata.Parent;

            //this.doituongSelect.Bao_Hanh = rowdata.Bao_Hanh;
            //this.doituongSelect.Loai = rowdata.Loai;
            //this.doituongSelect.Gio_Dinh_Muc = rowdata.Gio_Dinh_Muc;
            //this.doituongSelect.Gia_Dinh_Muc = rowdata.Gia_Dinh_Muc;
            //this.doituongSelect.Gia_Ban = rowdata.Gia_Ban;
            //this.doituongSelect.Vat = rowdata.Vat;
            //this.doituongSelect.Model = rowdata.Model;
            //this.doituongSelect.Phong_Ban_Id = rowdata.Phong_Ban_Id;


          }

      this.repo.postRequest("api/doituonghopdong/gethanghoa/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, Id: this.doituongSelect.Id })
        .subscribe(response => {
          this.doituongSelect.HangHoas = response;
        });
    }
        else {
      console.log("t11");
      this.repo.postRequest("api/doituonghopdong/getbyid/", { Id: id, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name })
            .subscribe(response => {  
                console.log(response);
              this.doituongSelect.Id = id;
              this.doituongSelect.Ma_Doi_Tuong = response.Ma_Doi_Tuong;
              this.doituongSelect.Ten_Doi_Tuong = response.Ten_Doi_Tuong;
              this.doituongSelect.Trang_Thai = response.Trang_Thai;
              this.doituongSelect.Ghi_Chu = response.Ghi_Chu;
              this.doituongSelect.Parent = response.Parent;
              this.doituongSelect.Ma_Ao = response.Ma_Ao;
              this.doituongSelect.Nhom_Doi_Tuong_ID = nhomdoituongid;
              this.doituongSelect.Created = response.Created;
              this.doituongSelect.CreatedBy = response.CreatedBy;
              this.doituongSelect.Modified = new Date();
              this.doituongSelect.ModifiedBy = this.currentUser.user_name;

              

              this.doituongSelect.Ma_Hop_Dong = response.Ma_Hop_Dong;
              this.doituongSelect.Ten_Hop_Dong = response.Ten_Hop_Dong;
              this.doituongSelect.Doi_Tuong_Id = response.Doi_Tuong_Id;
              this.doituongSelect.Thanh_Toan = response.Thanh_Toan;
              this.doituongSelect.Kieu_Ban_Le = response.Kieu_Ban_Le;
              this.doituongSelect.Nhan_Vien_Id = response.Nhan_Vien_Id;
              this.doituongSelect.Cua_Hang_Id = response.Cua_Hang_Id;
              this.doituongSelect.Huy_Hop_Dong = response.Huy_Hop_Dong;
              if (response.Ngay_Ky != null)
                this.ngayky = new Date(response.Ngay_Ky);
              else
                this.ngayky = null;

              if (response.Han_Thanh_Toan != null)
                this.hanthanhtoan = new Date(response.Han_Thanh_Toan);
              else
                this.hanthanhtoan = null;

              this.doituongSelect.Gia_Ban = response.Gia_Ban;
              this.doituongSelect.Gia_Tri = response.Gia_Tri;
              this.doituongSelect.Tien_Te_Id = response.Tien_Te_Id;
              this.doituongSelect.Gia_Tri_Ngoai_Te = response.Gia_Tri_Ngoai_Te;
              this.doituongSelect.Tai_Khoan_Id = response.Tai_Khoan_Id;
              this.doituongSelect.Dong_Co = response.Dong_Co;
              this.doituongSelect.Loai_Hop_Dong = response.Loai_Hop_Dong;
              this.doituongSelect.Dat_Coc = response.Dat_Coc;
              this.doituongSelect.Phu_Kien = response.Phu_Kien;
              this.doituongSelect.Kieu_Mua_Hang = response.Kieu_Mua_Hang;
              this.doituongSelect.So_Luong = response.So_Luong;
              this.doituongSelect.Thang_Du_Kien = response.Thang_Du_Kien;
              this.doituongSelect.Nam_Du_Kien = response.Nam_Du_Kien;
              this.doituongSelect.Duyet_Hop_Dong = response.Duyet_Hop_Dong;
              if (response.Ngay_Duyet != null)
                this.ngayduyet = new Date(response.Ngay_Duyet);
              else
                this.ngayduyet = null;
              this.doituongSelect.Thung = response.Thung;
              this.doituongSelect.Gia_Thung = response.Gia_Thung;
              this.doituongSelect.De_Xuat_Id = response.De_Xuat_Id;

           //this.doituongSelect.SapXep = response.SapXep;


              this.repo.postRequest("api/doituonghopdong/gethanghoa/", { DonViId: donviid, NhomDoiTuongId: nhomdoituongid, DoanhNghiepId: this.doanhnghiepid, UserName: this.currentUser.user_name, Id: this.doituongSelect.Id })
                .subscribe(response => {
                  this.doituongSelect.HangHoas = response;
                  if (this.doituongSelect.HangHoas != null) {
                    this.doituongSelect.HangHoas.forEach((item: any) => {
                      if (item.Ngay_Giao_Xe != null) {
                        item.Ngay_Giao_Xe = new Date(item.Ngay_Giao_Xe);
                      }
                    });
                  }
                });


              this.doituongSelect.Doi_Tuong = response.Doi_Tuong;
              

            }); 
        }
        
       
    }
   
    keyTenPress(event: any) { 
        if (event.which === 13) {
            this.slCapTren.focus();
            event.preventDefault();
          }
   }
    public closeModal(){
        this.save.emit(null);
    }
  public saveproduct() {
    if (this.doituongSelect.Ma_Doi_Tuong == null || this.doituongSelect.Ma_Doi_Tuong == "") {
      swal('Lỗi!', 'Vui lòng nhập mã đối tượng!.', 'error');
      return;
    }
    if (this.ngayky != null)
      this.doituongSelect.Ngay_KyS = this.ngayky.getDate() + '/' + (this.ngayky.getMonth() + 1) + '/' + this.ngayky.getFullYear();
    if (this.hanthanhtoan != null)
      this.doituongSelect.Han_Thanh_ToanS = this.hanthanhtoan.getDate() + '/' + (this.hanthanhtoan.getMonth() + 1) + '/' + this.hanthanhtoan.getFullYear();

    if (this.ngayduyet != null)
      this.doituongSelect.Ngay_DuyetS = this.ngayduyet.getDate() + '/' + (this.ngayduyet.getMonth() + 1) + '/' + this.ngayduyet.getFullYear();
    

    //console.log("save_ttt");
    //console.log(this.doituongSelect);
    $('#loading').show();
    this.repo.postRequest("api/doituonghopdong/addorupdate",this.doituongSelect)
            .subscribe(response => {  
              $('#loading').hide();
              if (response != null && response.match('-')) {
                this.doituongSelect.Id = response;
                this.save.emit(this.doituongSelect);
              }
              else {
                swal('Lỗi!', response, 'error');
              }
            }); 
  }
  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }
  indexdt: number;
  cotdt: string;
  adddatachondoituongchung(index: number) {
    this.indexdt = -1;
    if (index == 1) {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_khachhang.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
    if (index == 2) {
      this.dtechondoituongchungedit.loadDataInit(null, Lists_nhacungcap.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
  }

  adddatachondoituongchung_luoi(cot: string, index: number) {
    this.indexdt = index;
    this.cotdt = cot;
    if (cot == "Hang_Hoa_Id") {
      this.dtechondoituongchungedit.loadDataInit(this.nhomdtid, Lists_xe.id);
      this.mBackdrop = setTimeout(() => {
        this.haveBackdrop();
      }, 500);
      this.mdChonDoiTuongChung.open();
    }
   

  }

  savedsdoituongchung(doituong: any) {
    //console.log("savedsdoituongchung");
    //console.log(doituong);
    if (this.indexdt == -1) {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              this.doituongSelect.Doi_Tuong_Id = item.Id;
              this.doituongSelect.Doi_Tuong = item.Ma+' - '+ item.Ten;
            }
            i = i + 1;

          }
        );
        this.mdChonDoiTuongChung.close();
      }
      else {
        // console.log("savedsdoituongchung else");
        if (doituong == null) {
          
        }
        else {
          this.doituongSelect.Doi_Tuong_Id = null;
          this.doituongSelect.Doi_Tuong = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
    else {
      if (doituong != null && doituong.length > 0) {
        let i = 0;
        doituong.forEach(
          (item) => {
            if (i == 0) {
              if (this.cotdt == "Hang_Hoa_Id") {
                this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = item.Id;
                this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = item.Ma;
              }
              this.mdChonDoiTuongChung.close();

            }
            i = i + 1;

          }
        );

      }
      else {
        console.log("savedsdoituongchung else");
        if (doituong == null) {

        }
        else {
          this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa_Id = null;
          this.doituongSelect.HangHoas[this.indexdt].Hang_Hoa = null;
        }
        this.mdChonDoiTuongChung.close();
      }
    }
  }

  deleterow(index: string, luoi: number) {
    console.log(index);
    swal({
      title: 'Bạn muốn xóa dòng này phải không?',
      text: 'Dữ liệu xóa sẽ không phục hồi lại được!',
      icon: "warning",
      buttons: {
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.doituongSelect.HangHoas.splice(index, 1);
        }
      });
  }
  RowNewClick() {
    this.AddRowNew(this.doituongSelect.HangHoas);
  }
  AddRowNew(ctiets: any) {
    var sp = 'bottom';
    if (ctiets.length > 9)
      sp = 'top'
    if (ctiets.length > 0) //trường hợp lớn hơn 1 thì copy dòng cuối
    {
      var row = ctiets[ctiets.length - 1];

      ctiets.push({
        showpopup: sp, Id: null, Hop_Dong_Id: null, Hang_Hoa_Id: null, Model_Id: null,Hang_Hoa: null,
        Dac_Ta_Id: null, Mau_Xe_Id: null, So_Luong: null, Ngay_Giao_Xe: null, Don_Gia: null, So_Tien: null
      });
    }
    else {
      ctiets.push({
        showpopup: sp, Id: null, Hop_Dong_Id: null, Hang_Hoa_Id: null, Model_Id: null, Hang_Hoa: null,
        Dac_Ta_Id: null, Mau_Xe_Id: null, So_Luong: null, Ngay_Giao_Xe: null, Don_Gia: null, So_Tien: null
      });

    }

  }
   
    
   

   


}
