export class Global {
  // public static readonly App_url="http://localhost:64625/"
  public static readonly App_url = ""

  public static readonly App_diem_doi_tien = "diem_doi_tien";
  public static readonly App_thue_suat_vat = "thue_suat_vat";

  public static readonly App_ap_dung_tich_diem = "ap_dung_tich_diem";
  public static readonly App_tien_doi_diem = "tien_doi_diem";
  public static readonly App_ap_dung_doi_diem = "ap_dung_doi_diem";
  public static readonly App_the_bac = "the_bac";
  public static readonly App_the_vang = "the_vang";
  public static readonly App_the_bach_kim = "the_bach_kim";
  public static readonly App_the_kim_cuong = "the_kim_cuong";
  public static readonly App_ap_dung_tich_diem_san_pham = "ap_dung_tich_diem_san_pham";
  public static readonly App_ap_dung_tich_diem_dich_vu = "ap_dung_tich_diem_dich_vu";

  public static readonly BASE_USER_ENDPOINT = 'api/user/';
  public static readonly BASE_DONVI_ENDPOINT = 'api/donviapi/';
  public static readonly BASE_NHACUNGCAP_ENDPOINT = 'api/nhacungcapapi/';
  public static readonly BASE_LINHVUC_ENDPOINT = 'api/linhvucapi/';
  public static readonly BASE_DANTOC_ENDPOINT = 'api/dantocapi/';
  public static readonly BASE_SECURITY_ENDPOINT = 'api/security/';
  public static readonly BASE_ROLE_ENDPOINT = 'api/role/';
  public static readonly BASE_HUYENTINH_ENDPOINT = 'api/huyentinhapi/';
  public static readonly BASE_ENDPOINT = 'api/BaseLayout/';
  public static readonly BASE_BC1_GETID = 'api/bc1/getbyid/';
  public static readonly BASE_BC01_GETID = 'api/bc01/getbyid/';
  public static readonly BASE_BC11_GETID = 'api/bc11/getbyid/';
  public static readonly BASE_BC01_SAVE = 'api/bc01/post';
  public static readonly BASE_BC11_SAVE = 'api/bc11/post';
  public static readonly BASE_BC02_GETID = 'api/bc02/getbyid/';
  public static readonly BASE_BC02_SAVE = 'api/bc02/post';
  public static readonly BASE_NEWS_POST = 'api/news/post';
  public static readonly BASE_BaoCao_SAVE = 'api/baocao/run/';
  public static readonly BASE_BaoCao2_SAVE = 'api/baocao/run2/';

  public static readonly BASE_BC04_GETID = 'api/bc04mau1a/getbyid/';
  public static readonly BASE_BC04_SAVE = 'api/bc04mau1a/post';
  public static readonly BASE_BC05_GETID = 'api/bc05mau1b02/getbyid/';
  public static readonly BASE_BC05_SAVE = 'api/bc05mau1b02/post';
  public static readonly BASE_BC06_GETID = 'api/bc06mau1b05/getbyid/';
  public static readonly BASE_BC06_SAVE = 'api/bc06mau1b05/post';

  public static readonly BASE_BC07_GETID = 'api/bc07/getbyid/';
  public static readonly BASE_BC07_SAVE = 'api/bc07/post';

  public static readonly BASE_BC13_GETID = 'api/bc13/getbyid/';
  public static readonly BASE_BC13_SAVE = 'api/bc13/post';



}
export class BaoCao {



  // dùng chung để tải mẫu báo cáo (dùng cho chức năng tùy chỉnh báo cáo)
  public static readonly BC01_XuatMauBaoCao = 'C43BF77C-729B-4FB1-9E3C-B17FCF224BDA';
  // dùng để tải mẫu import (dùng cho chức năng nhập dữ liệu từ excel)
  public static readonly BC02_MauNhapTuExcel = '85F8063B-87A2-4CD9-849B-7E90DB5C9072';
  // dùng để xuất dữ liệu trên các lưới ra excel
  public static readonly BC03_XuatDanhSach = 'CBB9B028-4FE0-4518-8A4B-E5F16CACCAB6';
  // dùng để xuất sổ cái- hình thức nhật ký chung
  public static readonly BC04_SoCaiTaiKhoan = '4BDC6A56-C92F-4160-8655-A123890218E1';
  // dùng để xuất phiếu chứng từ
  public static readonly BC05_PhieuChungTu = '245E2024-200A-444C-ACFA-8805DE0AE59B';



  // dùng để xuất giáy rút dự toán mẫu 02a 
  public static readonly BC06_GiayRutDuToan_02a = 'A218527D-36F6-423E-BC85-C464EB800970';

  // dùng để xuất giáy rút dự toán mẫu 02b
  public static readonly BC07_GiayRutDuToan_02b = '111669E5-DB73-4B1B-920A-D73E5F09E02C';

  // dùng để xuất giáy rút von dau tu 16b1 
  public static readonly BC07_02_GiayRutDuToan_16b1 = '22FCDE59-7B80-4106-A369-7E57BAE55065';
  public static readonly BC07_02_GiayRutDuToan_16b1_05TT = '08865B78-652E-446A-B0BF-9F54F57E80D5';
  // dùng để xuất giấy nộp trả kinh phí mẫu 06 
  public static readonly BC07_03_GiayNopTraKinhPhi_06 = '2C4A1BA7-E512-4E95-8CFE-10D14C61C578';

  // dùng để xuất bảng kê thanh toán tạm ứng TT39
  public static readonly BC08_BangKeThanhToanTamUng_TT39 = '7CF94008-952E-410E-B189-22190DFDEF2B';
  // dùng để xuất giấy đề nghị thanh toán tạm ứng, ứng trước C2-03
  public static readonly BC08_02_GiayDeNghiThanhToanC2_03 = 'E13132D8-83F7-48A2-90DD-81F20C6E6EE8';
  //Giấy đề nghị thanh toán tạm ứng mẫu 05c
  public static readonly BC08_03_GiayDeNghiThanhToan05C = '282BC2AE-5844-4B4D-8D3E-3A8606C44C1C';


  // dùng để xuất ủy nhiệm chi chuyển khoản, chuyển tiền điện tử C4 02a/KB
  public static readonly BC08_03_UyNhiemChiC4_02a = 'EED0B703-0D0F-4A19-9D12-126C51513D65';

  // dùng để xuất ủy nhiệm chi C4 02c/KB
  public static readonly BC08_04_UyNhiemChiC4_02c_16c = '08F9E55B-1F11-4BA3-BFB2-AAAD90552A61';

  // dùng cho giấy rút tiền mặt C4-09/KB
  public static readonly BC08_05_GiayRutTienMat_C4_09_KB = '29E1D4AD-847B-4F02-8ACF-F8E2B19C2729';

  // dùng cho C37-HD: Giấy đề nghị thanh toán

  public static readonly BC08_06_GiayDeNghiThanhToan_C37HD = 'F48213DE-03D9-445B-B8C5-F2DFE9D4CFCA';

  public static readonly BC08_07_PhieuDieuChinhSLNS_C2_10 = '618FE92F-4C78-4671-BDA3-33DC27D98962';


  // dùng để in phiếu kiểm kê tài sản
  public static readonly BC09_PhieuKiemKeTaiSan = '04A4960E-C093-4EF9-AA2D-DAAE3BE94787';
  public static readonly BC09_PhieuKiemKeCCDC = '8C0BB31C-A927-4B72-BFBE-427F0939BCE4';

  // dùng để xuất Bảng đối chiếu dự toán kinh phí ngân sách (Mẫu số 01-SDKP/ĐVDT)
  public static readonly BC09_BangDoiChieuDuToanNS = '7AF52398-3D2F-4FDE-9532-A78695AA8134';
  // dùng để xuất Bảng đối chiếu tình hình sử dụng kinh phi (Mẫu số 02-SDKP/ĐVDT)
  public static readonly BC10_BangDoiChieuSuDungKP = '1777922D-C824-48F0-8D59-014FFAAC0E61';
  // dùng để xuất Bảng xác nhận số dư tài khoản tiền gửi (Mẫu số 05-ĐCSDTK/KBNN)
  public static readonly BC11_BangXacNhanSoDuTaiKhoanTienGui = 'DD00E60A-9E00-4A39-B5A3-1DC8DA94CF91';


  public static readonly BC12_SoCaiMucLucNganSach = '47C2A1E7-B7BE-4A41-96B0-D3CA5D2F804F';
  public static readonly BC13_SoChiTietKinhPhi = '99496F5D-873D-4CB3-9387-A348C330FFB0';
  public static readonly BC14_SoChiPhaiThuPhaiTraNoiBo = 'B8BD15D9-5805-4AE6-A66B-51AEF4393978';
  public static readonly BC15_SoNhatKyChung = 'ECE8C9E9-E48F-4245-8733-5D5D76F4AB62';
  public static readonly BC16_SoTheoDoiTMTG_NgoaiTe = '951E8C39-0AB0-4F22-914A-0829015DDE34';

  public static readonly BC17_SoCaiTaiKhoanNhomTheoDoiUng = '9F269D4E-8BC4-4E8C-8925-32597EBED9D1';
  public static readonly BC18_SoChiTietCacTaiKhoan = 'D4B584C6-A3E5-4441-B07F-8807B017630A';
  public static readonly BC18_01_SoTheoDoiNguonKinhPhi = '3C23B951-1F36-4EC4-9029-2C11279E823E';
  public static readonly BC19_SoChiTietThanhToanVoiKhachHang = '3CD8C834-20DA-4123-881E-E5A7B77A1A30';
  public static readonly BC20_SoTongHopTaiKhoan = '850DB0F0-FB95-4210-B646-9D36B69E802E';
  public static readonly BC21_SoQuyTienMat = '1226CB67-81A0-4935-9475-8AD1EF24E22A';
  public static readonly BC22_BangCanDoiSoPhatSinh = '7AE62001-9E86-4C83-AA53-28F5545B995D';
  public static readonly BC22_BangCanDoiSoPhatSinhTC = 'A324EB8D-2833-448A-B105-00EDCCD48164';
  //
  public static readonly BC23_SoTienGuiNganHang = '6A9775EA-6291-41C2-BA57-DD9A74C515A5';
  public static readonly BC24_SoChiPhiSanXuatKinhDoanh = '7EB0267F-59B9-4440-8ADE-24A54B3DEB97';
  public static readonly BC25_BangKeMuaHang = '2BD56945-185B-402C-8537-2201DDC85830';
  public static readonly BC26_DanhSachCTThanhToanChuaCoCTMuaHang = '838C2565-32D1-4F3F-8380-E7036860EEE1';
  public static readonly BC27_SoChiTietCongNoPhaiTra = 'ABB35E46-1467-4942-8E20-71CF255D7592';
  public static readonly BC28_SoChiTietCongNoPhaiTra_NgoaiTe = 'C6636A8D-594D-4697-824C-114E899F9F5D';
  public static readonly BC29_SoTongHopCongNoPhaiTra = 'BB6E236C-802C-46CB-8D60-03AB6013E0FE';
  public static readonly BC30_SoTongHopCongNoPhaiTra_NgoaiTe = '14AD483F-00C2-4DFB-A73A-3E89FC10F463';
  public static readonly BC31_SoChiTietDoanhThu_SXKD = 'D2B3D172-AA38-4C78-9C17-3F2C33F11B79';
  public static readonly BC32_SoChiTietBanHang = '7CD70EEE-B2AF-4CDA-A09E-23B0B43B9B58';
  public static readonly BC33_SoChiTietCongNoPhaiThu = 'C86516B1-194B-4048-9A3A-818308482ABF';
  public static readonly BC34_SoChiTietCongNoPhaiThu_NgoaiTe = '0745E575-034C-4121-9ADC-E3A45D7B8363';
  public static readonly BC35_SoNhatKyBanHang = '2B03B9E4-6AA3-4856-A2ED-80398E6991B6';
  public static readonly BC36_SoTongHopCongNoPhaiThu = 'CF66946A-1D58-41E6-BF29-4EEB8BF96938';
  public static readonly BC37_SoTongHopCongNoPhaiThu_NgoaiTe = 'EE20B9E4-97F5-4C04-B6EA-C8A5F05BA0F3';
  public static readonly BC38_BangTinhHaoMonTSCD = '24931D8D-BCA4-4B79-9EF4-2E036A2947C4';
  public static readonly BC39_SoTaiSanCoDinh = 'E46DDBC7-5CDE-4592-AE6D-62515D558304';
  public static readonly BC40_SoTheoDoiTSCD = '5A377C37-E0B5-4635-8180-178273912DD7';
  public static readonly BC41_BaoCaoKiemKeTSCD = 'EDDC9FC5-95BE-46D6-8B53-7029830C963B';
  public static readonly BC42_BaoCaoTinhHinhGiamTSCD = 'FD552462-B075-4B62-888D-48EEABAD4641';
  public static readonly BC43_BienBanKiemKeTSCD = 'F47CC968-E4A7-4C35-A861-A9510EAFBE87';
  public static readonly BC43_BienBanKiemKeCCDC = '21B7DD21-E03F-42F7-AECB-D1E4370C723C';

  public static readonly BC44_DanhSachTSCDTheoLoai = '087A1541-8EEE-4D36-B6EA-29E5E4077C09';
  public static readonly BC45_SoGhiGiamTSCD = '1E340128-1753-436B-8E0E-6D4BC910973B';
  public static readonly BC46_SoGhiTangTSCD = '0B219355-2DA1-45AF-9C82-5386951837A4';
  public static readonly BC47_BaoCaoTonKho = '3990650F-E3AD-4B45-861F-93E22C360C8B';
  public static readonly BC48_SoTheoDoiCongCuDungCu = '16D77C23-F370-4C67-9500-B1A3743BD54D';
  public static readonly BC49_ToKhaiThueTNCN = 'BBF9F17A-D613-4E58-83CA-C01E9F4D73C1';
  public static readonly BC50_ToKhaiQuyetToanTNCN = '7ECBD766-C2BB-48AC-9E0B-CDF5433870D0';
  public static readonly BC50_ToKhaiQuyetToanTNCN_05QTT = '498439A1-02F4-4A73-8552-C21E64DD4B07';
  public static readonly BC51_PhuLucToKhaiQuyetToanTNCN = '92D3B77A-B929-4461-BC44-D67CDF5B0CA9';
  public static readonly BC52_SoTheoDoiTamUng = '66589524-41D9-4389-8425-5F5C8760DCD0';
  public static readonly BC52_01_C42BB_GiayDeNghiThanhToanTamUng = 'E6F30F94-E956-4408-B26D-76B2B92BE9DD';
  public static readonly BC53_BangInTongHopCuaTaiKhoan = '1D14A06F-35CF-4996-824C-FD7606715FA5';
  public static readonly BC54_BaoCaoDoiChieuHachToanTaiChinhVaHachToanNgoaiBang = 'D9CD5A32-75B7-4162-9496-8A2E9A107265';
  public static readonly BC55_BaoCaoDoiChieuRutVaChiTheoMLNS = 'C9E9962F-0D2C-441A-8145-801A1C242E97';
  public static readonly BC56_BaoCaoSoDuCacChiTietCuaMotTaiKhoan = '8702C998-CB06-4176-BF33-7BAFE78F8889';
  public static readonly BC57_TongHopCongNo = 'B06932FA-E5C6-4E77-91D3-7DD19EC8DACD';
  public static readonly BC58_SoTheoDoiChuyenKhoan = 'BA2807D4-CACA-4F1B-A1E7-32A95701EF04';
  public static readonly BC59_DanhSachChungTuChuaSinhHachToanDongThoi = '5A30F97F-46D6-44CC-ADA6-8C9BD60F6105';
  public static readonly BC60_ThongKeChungTuKeToan = 'D47D35A2-91B4-469E-A130-2D9BE3354913';
  public static readonly BC61_TongHopKinhPhiDaSuDungDeNghiGhiThuGhiChi = 'FA061449-492C-4BCE-9F89-15FF6685F670';
  public static readonly BC62_BangTongHopCong = '9B737236-0010-4D30-99FD-0C3E6D249296';




  public static readonly BC63_BaoCaoTinhHinhTaiChinh = 'BBE052F1-7029-4626-806A-8C692C434A2C';
  public static readonly BC64_BaoCaoKetQuaHoatDong = '21FF2E26-3486-4B36-BE1C-B3C87C40EEFE';
  public static readonly BC65_BaoCaoLuuChuyenTienTe_TrucTiep = '1067D24D-DBA4-4B90-99CF-8115C152C5A4';
  public static readonly BC66_BaoCaoLuuChuyenTienTe_GianTiep = 'E79E5743-E6B2-43C8-B25A-1C7574AEF596';
  public static readonly BC67_BaoCaoTaiChinhMauDonGian = '5402F5FF-5BA5-4DD0-BB64-4628F659BC9C';
  public static readonly BC68_ThuyetMinhBaoCaoTaiChinh = 'EDE794E0-B59D-454A-93C9-D3BEC39B9315';
  public static readonly BC68_BSTT_BoSungThongTinTaiChinh = 'D76B6D63-013A-4B0F-B6BB-5F9067E0A195';
  public static readonly BC69_BaoCaoTaiChinhHopNhat = '695E726C-4CDE-4892-83CE-DCD3B10DE75E';
  public static readonly BC69_BSTT_BaoCaoBoSungThongTinHopNhat = '1C1B93D0-5A8A-4432-BD8E-6181715BF87E';
  public static readonly BC70_BaoCaoKetQuaHoatDongHopNhat = '252030B5-BD81-49A2-9F46-4FCEAE663B8D';
  public static readonly BC71_BaoCaoLuuChuyenTienTeHopNhat = '5CAA233E-6CAF-4FDF-A3DD-3CF11BBEF2D2';
  public static readonly BC72_BaoCaoTongHopCongNoHopNhat = '48FDFEEB-F2C7-43E2-AC0A-3FEB9A09F815';
  public static readonly BC73_SoTheoDoiChuyenKhoanHopNhat = '929F4460-7CAE-4DA6-8F53-336F27BD4880';
  public static readonly BC74_ThongKeChungTuKeToanHopNhat = '96B3B862-7CD8-43FC-A289-C299A5427DEF';
  public static readonly BC75_BangTheoDoiThuChiHopNhat = 'B761D2A2-A9E3-4C87-AE22-B6430E1EF8C1';
  public static readonly BC76_BangInTongHopCuaTaiKhoanHopNhat = 'B63941F5-1F44-40C2-B5F9-D5BE9CD99408';
  public static readonly BC77_SoTheoDoiDuToanTuNSNN_Phan1 = 'DE4D725A-06D4-4D25-9634-24887462CC86';
  public static readonly BC78_SoTheoDoiDuToanTuNSNN_Phan2 = '654FFDE9-9277-469A-B3DD-EC57567FFDA2';
  public static readonly BC79_SoTheoDoiDuToanTuNSNN_Phan3 = 'DC4CF216-510E-4F65-B8B7-F3BAACFC531A';
  public static readonly BC80_SoTheoDoiNguonVienTro_Phan2 = 'AB5290C5-0FA4-4122-B261-04A81BC2A628';
  public static readonly BC81_SoTheoDoiNguonVayNoNuocNgoai_Phan1 = '73D6DCE2-5576-412B-98E4-F9B2F3CE9659';
  public static readonly BC82_SoTheoDoiNguonVayNoNuocNgoai_Phan2 = '1995745C-2F8B-4BC1-87F6-DB91C00A3FEE';
  public static readonly BC83_SoTheoDoiKinhPhiNSNNCapBangLenhChiTien = '7EE963DF-3F12-49C8-B428-99C0E2681C75';
  public static readonly BC84_SoTheoDoiPhiDuocKhauTruDeLai_Phan1 = 'DD90EF28-50CE-4932-8BCE-EC6185FE45B4';
  public static readonly BC85_SoTheoDoiPhiDuocKhauTruDeLai_Phan2 = '9CFC6350-C103-4356-A47C-CA35BFA31F2B';
  public static readonly BC86_SoTheoDoiNguonThuKhacDeLai_P1 = 'A56E6D73-9A55-4A53-8935-29D05E13BDA1';
  public static readonly BC87_SoTheoDoiNguonThuKhacDeLai_P2 = 'E60557BF-BA6D-4F26-BFBF-637377AA30AF';
  public static readonly BC88_SoChiTietCacKhoanTamThu = '8045C7F2-7831-4EFF-9064-95349768E2AB';
  public static readonly BC89_BangKeChungTuThanhToan = '5DD25AB8-156B-4CB1-BFC3-C63F4FCC9954';
  public static readonly BC90_BangKeChungTuThucChiTamUng = 'FF0CF393-E79E-4AEE-8779-C3DC19F3F9CB';
  public static readonly BC91_BaoCaoThucHienCamKetChi = '8AF8F7EC-ACF6-4AEB-8DA0-447E3C3508EA';
  public static readonly BC92_BaoCaoQuyetToanKinhPhiHoatDong = 'DF386072-6B1C-4B4C-919F-42160C29B1CF';
  public static readonly BC93_ThuyetMinhBaoCaoQuyetToan = '0ED3B037-2CE6-48B2-A2A7-DFF16D648715';
  public static readonly BC94_BCQT_F01 = '183A51BE-2262-4713-B8B4-1905ADED0FC0';
  public static readonly BC95_BCQT_F01_02 = '4D9AB1D9-DE76-4307-8325-CCE526D107CB';
  public static readonly BC96_BangTongHopChungTu = '0078B7D7-3A15-4B65-99EE-EA912E157247';
  public static readonly BC97_NhatKySoCai = '65C9FEF8-1411-4F60-BB9C-244311336858';
  public static readonly BC98_ChungTuGhiSo = 'FC4CCBE7-3C4B-4194-AE7E-222139BD9DD0';
  public static readonly BC99_SoDangKyChungTuGhiSo = 'A58E2672-7CBE-4FD8-A72D-B97034794D70';
  public static readonly BC100_SoCaiHinhThucChungTuGhiSo = 'E66711B9-4AEF-4FFA-9FDD-C014DAC8CAD3';
  public static readonly BC101_Luong_KhongThuongXuyen = '664ED2FD-86B2-48B0-BDA4-D787C3A8E868';
  public static readonly BC102_Luong_TienLuong = '89E993B3-E70A-4663-9CF9-279F0931733B';

  public static readonly BC102_Luong_BangLuongCongChuc = 'ED41BDB1-2420-4FC1-A6E9-21F84CE89376';
  public static readonly BC102_Luong_BangLuongHopDong68 = '2E046A61-9180-472C-BA03-A4E659146B29';
  public static readonly BC102_Luong_BangLuongPhuCap = '7EC80760-817C-4B2E-A986-4E13CE6C9A49';

  public static readonly BC102_Luong_BangLuongCongChuc_TruyLinh = 'D1E64C89-DA8E-46FF-95CA-84DDFB1F1EBB';
  public static readonly BC102_Luong_BangLuongHopDong68_TruyLinh = '8724039B-AA5F-483D-828C-69C8A6AD830D';
  public static readonly BC102_Luong_BangLuongPhuCap_TruyLinh = 'E3C7E52D-235A-4BE6-B4A5-D676BA23DE5A';

  public static readonly BC103_Luong_ThueTNCN = 'D3605E1B-189A-4882-A8D9-31976A1485C2';

  public static readonly BC104_BaoCaoQuyetToanKinhPhiHoatDong_HopNhat = '045C89DD-7AE2-4F51-B468-702386561334';
  public static readonly BC105_BCQT_F01_HopNhat = '1D552443-1B88-47A4-8F8C-0A9A93E07FEC';
  public static readonly BC106_ChiTietThucHienKinhPhiNganSach = '90B34DFF-7EC5-4ACF-8C77-35DB0E0BEEAD';


  public static readonly BC150_DuToan_TongNhuCauVaDuKienKeHoachDauTu = '43BEA601-2D32-46FC-BC8C-76D2E08490F9';
  public static readonly BC151_DuToan_ChiTietDuKienKeHoachDauTu = '6439111B-F266-41AE-A670-9B17D24EF55B';
  public static readonly BC152_DuToan_TongHopKetQuaGiaoVaKeHoachDauTu = '908883D5-51DB-4CF2-A5C8-30AC96ED9EDE';
  public static readonly BC153_ChiTietKetQuaGiaoVaThongBaoKeHoachDauTu = '01A8DC11-13EA-4896-825F-A272AE4C4C26';
  public static readonly BC154_DuToan_TongHopTinhHinhThucHienVaKeHoachGiaiNganDauTu = '360D36FF-B8F4-48E0-9337-13788AD87AF4';
  public static readonly BC155_TongHopTinhHinhThucHienKeHoachDauTuPhatTrien = '0BBE1F37-4CB3-4118-BCD7-F5AF3AF28A42';
  public static readonly BC156_ChiTietTinhHinhThucHienKeHoachDauTu = '1E3B4AD1-EBDA-431A-9A88-49D2355BAF48';
  public static readonly BC157_ChiTietTinhHinhThucHienVaGiaiNganTheoKeHoachDauTu = '037297B5-DB9E-4764-915C-36E9025A9F3F';
  public static readonly BC158_BangTongHopQuyLuongCongChuc = '072B1657-385D-4831-99A5-6F57FFCDBCCF';
  public static readonly BC159_BangTongHopQuyLuongChoTuyen = 'D92CD70F-815C-4FDE-ABEF-9C864DA666DB';
  public static readonly BC160_BangTongHopQuyLuongHD68 = '85909FD1-9066-4352-B820-5DF19DFA70D4';
  public static readonly BC161_BangTongHopQuyLuong = '03768F3B-BC7F-448A-B5B2-E755BFF86BFD';
  //public static readonly BC162 = '';
  public static readonly BC163_ThuyetMinhTongHopDuToan = '4BAC5E92-F0CB-4318-928B-EA361E7428CB';
  public static readonly BC164_DuToanThuChiNganSachDuocGiaoVaPhanBo = '0D7AF629-71EA-466D-B26B-57A3AE8859E4';
  public static readonly BC165_DuToanThuChiNSNN = '3069A0A3-793C-4B7A-83B4-F3B6B00F65FD';
  public static readonly BC166_DanhGiaThucHienDuToanThuChiNS = '9C38079F-98E4-4F92-9A29-0BF49FA665EF';
  public static readonly BC167_DuToanThuChiNSNNDuocGiaoVaPhanBoChoDonViTrucThuoc = 'CD01F508-36A1-4683-91BE-A4A880E27A79';
  public static readonly BC168_DuToanThuChiNganSach = '58A2287C-A01D-4EBF-BC72-6D92FB265758';
  public static readonly BC169_SoXuLyViPhamHanhChinh = '55E02062-FE18-4F3B-B31A-C45365CE4CDE';



  public static readonly BC200_TongHopChiTietKinhPhi_TH = 'E4A42F73-5405-44E4-8106-5CD9E1ACC0E2';
  public static readonly BC200_TongHopChiTietKinhPhi_TH_MauMoi = 'EBCF6111-6890-4AA2-B560-6029AD8101DD';
  public static readonly BC201_TongHopChiTietKinhPhi_DonVi = '22A4577D-804B-4278-9E8A-4B29B760D69F';
  public static readonly BC202_BaoCaoThuChiHoatDongSuNghiepVaDichVu = '297F8466-A6E7-4FEE-B27E-58A56201E6DC';
  public static readonly BC203_BaoCaoTongHop_BienBan_TatCa = 'F80BA493-50EB-44C6-8188-E6BB9207EC7F';
  public static readonly BC203_BaoCaoTongHop_BienBan_TatCaMauMoi = '12A2AB25-DA83-4117-93BA-79C3957C674E';
  public static readonly BC204_BaoCaoTongHop_BienBan_LoaiKhoan = '55426E1D-C8B8-465F-8369-BA41CD49D0D8';
  public static readonly BC205_BaoCaoTongHop_BaoCao_TatCa = 'A18A7B93-74D3-43D8-A068-C0C635A4C74D';
  public static readonly BC206_BaoCaoTongHop_BaoCao_LoaiKhoan = '56FA9801-CBD3-459F-AD17-80B94442E7DD';
  public static readonly BC207_BienBanQuyettoanChiNganSach = 'D882F96B-7E1B-4658-ABF4-D804E23C524B';
  public static readonly BC208_BaoCaoKiemTraLoi = '24AEDC40-7246-40D0-8B70-892AFDB0E86A';
  public static readonly BC209_BaoCaoTHThuPhiLePhi = '93E4F111-B039-4426-8876-BB8D78973BDE';
  public static readonly BC210_BaoCaoKetQuaHoatDong1b02 = '884756C0-2147-4E7B-B7E8-2B6EB96D4F22';
  public static readonly BC211_BaoCaoKetQuaHoatDong1b05 = '1C2766FB-0B0C-4152-AA21-47B484F40B61';
  public static readonly BC212_QuyetToanThuChiNguonNSNN = '63F66C14-67A4-48BF-AA50-47B6A79B04DC';
  public static readonly BC213_BaoCaoTinhHinhTaiChinh_B01_BCTC = 'F88BFC03-CAE0-441E-B059-7119E4905D1D';
  public static readonly BC214_BaoCaoLuuChuyenTienTe_B03a_BCTC = '50321810-1ADC-4485-923C-A794C7A27B68';
  public static readonly BC215_BaoCaoThuyetMinhTaiChinh = '5B65FEB7-66F1-4F0F-8B7E-4941706AE92A';
  public static readonly BC214_BaoCaoLuuChuyenTienTe_B03b_BCTC = '70BDB74B-EB45-4BC5-96A2-1B92321AD9E3';

  public static readonly BC216_BaoCaoBoSungThongTinTaiChinh_B01_BSTT = 'ACFEB67F-156A-44FB-9B4F-F6EA354CCC27';
  public static readonly BC217_BaoCaoTongHop_ThucHienNSNN = '703C0BE8-DB0B-4D0E-9B46-72FADF5E6456';
  public static readonly BC218_BaoCaoTongHop_BangCanDoiSoPhatSinh = '97AD5565-ACC4-4D5A-AE28-BBB1C6F3764F';


  public static readonly BC01 = 'B3FE70AF-7EA5-435B-90B4-8EF0E2409470';
  public static readonly BC01_MauMoi = '7CDFF678-CCDC-427B-9387-B80C5A61BB75';
  public static readonly BC02 = "C78A9BFA-7576-48E1-93D7-DEA9402EA27D";
  public static readonly BCMau1a = "719008E4-8FAF-4149-9E58-D52437BAB10F";
  public static readonly BCMau1b02 = "1EB9908B-22CD-432C-B199-4AE9B39E7B64";
  public static readonly BCMau1b05 = "58F85451-77DE-45C6-A197-13F9BAACC1B1";
  public static readonly BC05 = "317DAD4D-2B46-41B2-A7C5-4FF366E1FF56";
  public static readonly BC06 = "10AE4720-C1AD-40D1-A188-39302BC9D369";


  // báo cáo dự toán
  public static readonly BC170_TongHopDuToanThuNganSach = '9E5ECBF5-B565-49D6-A128-F835DB2F4C8C';
  public static readonly BC_DT_ThuNganSach_ChiTiet = '192D062B-90AC-4077-80FE-58413743970B';
  public static readonly BC_DT_ThuNganSach_TH = '07CA3B9F-8756-4E60-AB78-D8DCC902FDA4';
  public static readonly BC_DT_ThuNganSach_TH_DonVi = '78DB03E5-FBC6-4222-A5F5-E0A3E23A5B5E';

  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc01 = '68922E1A-04B1-40A3-BD87-C60F44BEFDFB';
  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc02 = '5B687856-CFAB-4AFB-AA35-CDABC6616780';
  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc03 = 'D66245E4-41EA-43B5-A63A-87FE831A7209';
  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc04 = '0429C85F-1A69-4146-A088-B0ACC775DDB1';
  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc05 = '3700AF2B-3E9F-4EB5-84BF-67A5DCD8BA61';
  public static readonly BC_DT_ThuChiNganSach_TH_PhuLuc06 = '85909FD1-9066-4352-B820-5DF19DFA70D4';

  public static readonly BC_DT_ThuNganSach_TH_PhuLuc07 = 'CC477A56-CE25-45EE-BAE9-546FCE96B85C';
  public static readonly BC_DT_ThuNganSach_TH_PhuLuc08 = '88AE4AC6-A00D-445D-AD39-3F9227A70E8A';
  public static readonly BC_DT_TongHopDuToanTrangPhuc_TH_PhuLuc12 = '7A09E4E7-4980-448E-A60B-F3F474503313';
  public static readonly BC_DT_TongHopDuToanThietBiChuyenDung_TH_PhuLuc15 = 'AA47FE14-B784-430D-BDBC-7C7B690C1E54';

  public static readonly BC_DT_QuyetDinhPheDuyetChiTietKinhPhi = 'B0EEE210-4DDC-411B-8D89-5958683AFDB6';
  public static readonly BC_DT_ThuyetMinhTangGiamDuToan_PhuLuc09 = '693C5DBA-D887-4340-8D4D-28F55B93228B';



  public static readonly BC_DT_ChiTuChu_ChiTiet = 'DDB3E1D2-4FB1-4BAC-92F5-DB604B33BFBA';
  public static readonly BC_DT_ChiQuanLyHanhChinh_THTienDoGiaiNgan = '86ABB1F2-B029-44D4-9BA1-DC39AFBA0440';
  public static readonly BC_DT_ChiQuanLyHanhChinh_TH3Nam = '681D2906-E4BE-4B58-B55D-58B6A1F52C8A';
  public static readonly BC_DT_ChiQuanLyHanhChinh_ChiTiet = '73E1A90E-D791-4C6E-A7F5-F72754CD4840';
  public static readonly BC_DT_ChiQuanLyHanhChinh_ChiTiet2 = '856DBEA1-B5E4-4B8D-AF87-C72381946237';
  public static readonly BC_DT_ChiQuanLyHanhChinh_ChiTietSuaChua = '0E2FAA4A-9C91-4CCC-ABBB-35674CE5DAAF';
  public static readonly BC_DT_ChiQuanLyHanhChinh_TinhGianBienChe = '737E1F1B-ECBB-4CE7-AD0A-2237C5B14920';
  public static readonly BC_DT_ChiDaoTao_ChiTiet = '7ED25189-4F06-4256-8F56-E016CF661977';
  public static readonly BC_DT_ChiCTMTYTE_ChiTiet = '2CBA5CDE-203C-432F-B904-B3504A808C78';
  public static readonly BC_DT_ChiLuongCongChuc_ChiTiet = '1288A858-ED87-4ABE-8454-97C6CC2E15E4';
  public static readonly BC_DT_ChiLuongHopDong68_ChiTiet = 'EB44F8DE-E74F-4E6D-94E6-A542F2366BE3';
  public static readonly BC_DT_ChiLuongCongChuc_MauExcel = 'FE44ABF0-4ADF-4710-A0BA-0AAFA3B6E3E8';

  public static readonly BC_DT_TongHopDuToanTrangPhuc12 = '0BE5B44C-8F83-475D-89C0-0F10A28BB0CB';

  public static readonly BC_DT_TongHopDuToanThietBiChuyenDungChiTiet_15 = '7F73A4EE-3FF1-4EAF-BEF4-DDE6671592C4';

  // báo cáo dự toán
}
