<ul class="navbar">
  <!--<li class="nav-item">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fab fa-buromobelexperte"></i>
        Mẫu
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="dinhkhoanmau(row)" *ngFor='let row of inputdinhkhoanmaus;'>{{row.Noi_Dung}}</a>
      </div>
    </div>
  </li>
  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null) && chungtuSelect.Lien_Quan_Id!=null">
    <a class="nav-link" (click)="savectu(1)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại và cập nhật giấy rút
    </a>
  </li>-->

  <li class="nav-item" *ngIf="this.chungtuSelect.Id!=null">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fas fa-print"></i>
        In
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="export('pdf',1)">Phiếu thu</a>
        <a class="dropdown-item" (click)="export('xlsx',1)">Phiếu thu (excel)</a>
        <a class="dropdown-item" (click)="export('pdf',2)">Phiếu chi</a>
        <a class="dropdown-item" (click)="export('xlsx',2)">Phiếu chi (excel)</a>

        <a class="dropdown-item" (click)="export('pdf',3)">Phiếu nhập kho</a>
        <a class="dropdown-item" (click)="export('xlsx',3)">Phiếu nhập kho (excel)</a>

        <a class="dropdown-item" (click)="export('pdf',4)">Phiếu xuất kho</a>
        <a class="dropdown-item" (click)="export('xlsx',4)">Phiếu xuất kho (excel)</a>

        <a class="dropdown-item" (click)="export('pdf',5)">Chứng từ kế toán</a>
        <a class="dropdown-item" (click)="export('xlsx',5)">Chứng từ kế toán (excel)</a>

        <a class="dropdown-item">In hóa đơn VAT</a>
        <a class="dropdown-item">In hóa đơn điên tử (VNPT)</a>
        <a class="dropdown-item">In hóa đơn điện tử (MISA)</a>
      </div>
    </div>
  </li>

  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null)">
    <a class="nav-link" (click)="savectu(1)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại
    </a>
  </li>
  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null)">
    <a class="nav-link" (click)="savectu(0)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu và đóng
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link" (click)="closeModal()">
      <i class="fa-2x mr-2 far fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>
<div class="row ng-scope">
  <div class="col-8">
    <div class="row">
      <label class="control-label col-2">Đối tượng</label>
      <div class="col-2">
        <a *ngIf="chungtuSelect.Doi_Tuong_Id===null" (click)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" type="text" tabindex="1" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="chungtuSelect.Ma_Doi_Tuong">
        </a>
        <a *ngIf="chungtuSelect.Doi_Tuong_Id!==null" (dblclick)="adddatachondoituongchung(1)" style="color:blue;font-weight:bold">
          <input id="99" type="text" tabindex="1" class="form-control" placeholder="Chọn đối tượng" [(ngModel)]="chungtuSelect.Ma_Doi_Tuong" (ngModelChange)="adddatachondoituongchung(1)">
        </a>
        <!--<ng-select #slKhachHang
             labelForId="99"
             class="twcol"
             autofocus
             [tabIndex]="1"
             [openOnEnter]="false"
             [items]="inputkhachhangs"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [searchFn]="comboSearchFn"
             (change)="onDoiTuongChange($event)"
             [(ngModel)]="chungtuSelect.Doi_Tuong_Id">
    <ng-template ng-footer-tmp *ngIf="quyen_themdoituong===true">
      <a (click)="AddNguoiNop(slKhachHang)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->

      </div>
      <div class="col-8">
        <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ho_Ten">
      </div>
    </div>
    <div class="row">
      <label class="control-label col-2 ng-binding">Địa chỉ</label>
      <div class="col-10">
        <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Dia_Chi">
      </div>
    </div>
    <div class="row">
      <label class="control-label col-2 ng-binding">Diễn giải</label>
      <div class="col-10 ">
        <textarea class="form-control" [tabIndex]="5" (keydown)="keyLyDo($event)" rows="2" [(ngModel)]="chungtuSelect.Dien_Giai" placeholder=""></textarea>
      </div>
    </div>
    <div class="row">
      <label class="control-label col-2 ng-binding">Chứng từ gốc</label>
      <div class="col-3">
        <input type="text" tabIndex="6" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Chung_Tu_Goc">
      </div>
      <div class="col-2">
        <label>
          <input tabIndex="52" type="checkbox" [(ngModel)]="chungtuSelect.Hach_Toan" />
          <!--<span class="fa fa-check"></span>--> &nbsp;Hạch toán
        </label>
      </div>
      <label class="control-label col-2 ng-binding">Người tạo:&nbsp;<span style="font-weight:bold">{{chungtuSelect.CreatedBy}}</span></label>
      <label class="control-label col-3 ng-binding">Người sửa cuối:&nbsp;<span style="font-weight:bold">{{chungtuSelect.ModifiedBy}}</span></label>

    </div>


  </div>
  <div class="col-4">
    <div class="row">
      <label class="control-label col-5">Số phiếu</label>
      <div class="col-7 ">
        <input type="text" tabindex="7" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Chung_Tu">
      </div>
    </div>
    <div class="row">
      <label class="control-label col-5 ng-binding">Ngày chứng từ</label>
      <div class="col-7 ">
        <kendo-dateinput style="width:100%;" tabindex="8" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Chung_Tu" (change)="valuechange_ngaychungtu()"></kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <label class="control-label col-5 ng-binding">Ngày hạch toán</label>
      <div class="col-7 ">
        <kendo-dateinput style="width:100%;" tabindex="9" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Ghi_So" (change)="valuechange_ngayghiso()"></kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <label class="control-label col-5 ng-binding">Giờ, phút, giây</label>
      <div class="col-7 ">
        <kendo-dateinput style="width:100%;" tabindex="10" [format]="'HH:mm:ss'" [(value)]="chungtuSelect.Gio"></kendo-dateinput>
      </div>
    </div>
    <div class="row">
      <label class="control-label col-5 ng-binding">Tổng tiền</label>
      <div class="col-7">
        <input #myinput class="form-control"
               maxlength="20"
               disabled="disabled"
               style="width:100%;"
               currencyMask
               [(ngModel)]="chungtuSelect.Tong_Tien"
               name="value"
               [placeholder]="'Tổng tiền'"
               [options]="ngxAmOptions" autocomplete="off" />
      </div>
    </div>
  </div>
</div>
<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}"><a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id=""><span></span>1.Chi tiết</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id=""><span></span>2.Kê khai</a></li>
      <!--<li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}"><a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id=""><span></span>3.Hạch toán đồng thời</a></li>-->
    </ul>
    <div>
      <input type="number" max="7" min="0" style="width:40px;height:20px;text-align:center;margin-right:3px" [(ngModel)]="fixcot" (change)="fn_fixcot()" />
      <a *ngIf="tabselected==1" style="margin-right:5px;color:#1e6bb8;cursor:pointer" (click)="AddDoRong('Dien_Giai_Chi_Tiet')">Độ rộng cột</a>
      <a (click)="RowNewClick()" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng mới</a>
      <!--<a (click)="DongThoiClick1()" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 fas fa-angle-double-right"></em>Hạch toán đồng thời</a>-->
    </div>
  </div>

  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="height:calc(100vh - 225px);background:#fff;">
          <table id="fixTablechungtukhac_01" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">TK nợ</th>
                <th width="100" style="text-align:center">TK có</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Hang_Hoa_Id_No">Hàng hóa nợ</th>
                <th width="250" style="text-align:center"><a (click)="AddDoRong('Dien_Giai_Chi_Tiet')">Diễn giải</a></th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Kho_Hang_Id_No">Kho hàng nợ</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Don_Vi_Tinh_Id_No">Đơn vị tính nợ</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Hang_Hoa_Id_Co">Hàng hóa có</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Kho_Hang_Id_Co">Kho hàng có</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Don_Vi_Tinh_Id_Co">Đơn vị tính có</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.So_Luong">Số lượng</th>
                <th width="100" style="text-align:center" *ngIf="HienThis.Don_Gia">Đơn giá</th>
                <th width="130" style="text-align:center">
                  Thành tiền
                  <!--Tổng <span style="font-size: 11px;color: #ea182b;">( {{chungtuSelect.Tong_Tien | number}})</span>-->
                </th>
                <th width="130" style="text-align:center" *ngIf="HienThis.Doi_Tuong_Id_No">Đối tượng nợ</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Hop_Dong_Id_No">Hợp đồng nợ</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Tai_San_Id_No">Tài sản nợ</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Lenh_Sua_Chua_Id_No">Lệnh sửa chữa nợ</th>
                <th width="130" style="text-align:center" *ngIf="HienThis.Khoan_Muc_Id_No">Khoản mục nợ</th>
                <th width="120" style="text-align:center" *ngIf="HienThis.Doi_Tuong_Id_1_No">Mã đơn vị nợ</th>
                <th width="130" style="text-align:center" *ngIf="HienThis.Doi_Tuong_Id_Co">Đối tượng có</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Hop_Dong_Id_Co">Hợp đồng có</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Tai_San_Id_Co">Tài sản có</th>
                <th width="150" style="text-align:center" *ngIf="HienThis.Lenh_Sua_Chua_Id_Co">Lệnh sửa chữa có</th>
                <th width="130" style="text-align:center" *ngIf="HienThis.Khoan_Muc_Id_Co">Khoản mục có</th>
                <th width="120" style="text-align:center" *ngIf="HienThis.Doi_Tuong_Id_1_Co">Mã đơn vị có</th>
              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 235px);">
              <tr *ngFor='let row of chungtuSelect.ChiTiets; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,1)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <!--tài khoản nợ-->
                  <ng-select #slLuoi0 (keydown)="keyComBoPress($event,i,1,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_100"
                             labelForId="{{i}}_1_1"
                             [openOnEnter]="false"
                             [items]="inputtaikhoans"
                             bindValue="Id"
                             bindLabel="Ma_Tai_Khoan"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             (ngModelChange)="changeTaiKhoan($event,1,row,i)"
                             [(ngModel)]="row.Tai_Khoan_No_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <!--tài khoản có-->
                  <ng-select #slLuoi1 (keydown)="keyComBoPress($event,i,2,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_100"
                             labelForId="{{i}}_2_1"
                             [openOnEnter]="false"
                             [items]="inputtaikhoans"
                             bindValue="Id"
                             bindLabel="Ma_Tai_Khoan"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             (ngModelChange)="changeTaiKhoan($event,0,row,i)"
                             [(ngModel)]="row.Tai_Khoan_Co_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td *ngIf="HienThis.Hang_Hoa_Id_No">
                  <!--Hàng hóa nợ-->
                  <a *ngIf="row.Hang_Hoa_Id_No===null" (click)="adddatachondoituongchung_luoi('Hang_Hoa_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_3_1" type="text" 
                           (keydown)="keyComBoPress($event,i,3,1)" class="form-control"
                           [style.width.px]="DoRongs.Hang_Hoa_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa_No">
                  </a>
                  <a *ngIf="row.Hang_Hoa_Id_No!==null" (dblclick)="adddatachondoituongchung_luoi('Hang_Hoa_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_3_1" type="text" 
                           (keydown)="keyComBoPress($event,i,3,1)" class="form-control"
                           [style.width.px]="DoRongs.Hang_Hoa_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa_No" (ngModelChange)="adddatachondoituongchung_luoi('Hang_Hoa_Id_No',i)">
                  </a>
                  <!--<ng-select #slLuoi2 (keydown)="keyComBoPress($event,i,3,1)"
                   [dropdownPosition]="row.showpopup"
                   class="twcol"
                   [style.width.px]="DoRongs.Hang_Hoa_Id_No"
                   labelForId="{{i}}_3_1"
                   [openOnEnter]="false"
                   [items]="inputhanghoas"
                   bindValue="Id"
                   bindLabel="Ma_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [addTagText]="hanghoaid"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="row.Hang_Hoa_Id_No">

                  <ng-template ng-footer-tmp>
                    <a (click)="AddDoiTuong(slLuoi2,i,3)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <div class="rTableRow">
                      <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                      <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                    </div>

                  </ng-template>
                </ng-select>-->
                </td>
                <td>
                  <!--Diễn giải chi tiết-->
                  <input id="{{i}}_4_1" #myinput type="text" [style.width.px]="DoRongs.Dien_Giai_Chi_Tiet" (keydown)="keyComBoPress($event,i,4,1)" class="form-control" placeholder="" [(ngModel)]="row.Dien_Giai_Chi_Tiet">
                </td>
                <td *ngIf="HienThis.Kho_Hang_Id_No">
                  <!--Kho hàng nợ-->
                  <!--<a *ngIf="row.Kho_Hang_Id_No===null"  (click)="adddatachondoituongchung_luoi('Kho_Hang_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_5_1" type="text" [disabled]="true"
                           (keydown)="keyComBoPress($event,i,5,1)" class="form-control"
                           [style.width.px]="DoRongs.Kho_Hang_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Kho_Hang_No">
                  </a>
                  <a *ngIf="row.Kho_Hang_Id_No!==null"  (dblclick)="adddatachondoituongchung_luoi('Kho_Hang_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_5_1" type="text" [disabled]="true"
                           (keydown)="keyComBoPress($event,i,5,1)" class="form-control"
                           [style.width.px]="DoRongs.Kho_Hang_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Kho_Hang_No">
                  </a>-->
                   <ng-select #slLuoi_khohangno (keydown)="keyComBoPress($event,i,5,1)"
                   [dropdownPosition]="row.showpopup"
                   class="twcol"
                   [style.width.px]="DoRongs.Kho_Hang_Id_No"
                   labelForId="{{i}}_5_1"
                   [openOnEnter]="false"
                   [items]="inputkhohangs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [addTagText]="khohangid"
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="row.Kho_Hang_Id_No">

                      <ng-template ng-footer-tmp>
                        <a (click)="AddDoiTuong(slLuoi_khohangno,i,5)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <div class="rTableRow">
                          <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                          <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                        </div>

                      </ng-template>
                    </ng-select>
                </td>

                <td *ngIf="HienThis.Don_Vi_Tinh_Id_No">
                  <!--đơn vị tính nợ-->
                  <ng-select #slLuoiDVT_no (keydown)="keyComBoPress($event,i,6,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Don_Vi_Tinh_Id_No"
                             labelForId="{{i}}_6_1"
                             [openOnEnter]="false"
                             [items]="inputdonvitinhs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="donvitinhid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Don_Vi_Tinh_Id_No">

                    <ng-template ng-footer-tmp>
                      <a (click)="AddDoiTuong(slLuoiDVT_no,i,6)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.Hang_Hoa_Id_Co">
                  <!--Hàng hóa có-->
                  <a *ngIf="row.Hang_Hoa_Id_Co===null"  (click)="adddatachondoituongchung_luoi('Hang_Hoa_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_7_1" type="text" 
                           (keydown)="keyComBoPress($event,i,7,1)" class="form-control"
                           [style.width.px]="DoRongs.Hang_Hoa_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa_Co">
                  </a>
                  <a *ngIf="row.Hang_Hoa_Id_Co!==null"  (dblclick)="adddatachondoituongchung_luoi('Hang_Hoa_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_7_1" type="text" 
                           (keydown)="keyComBoPress($event,i,7,1)" class="form-control"
                           [style.width.px]="DoRongs.Hang_Hoa_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hang_Hoa_Co" (ngModelChange)="adddatachondoituongchung_luoi('Hang_Hoa_Id_Co',i)">
                  </a>
                  <!--<ng-select #slLuoi7 (keydown)="keyComBoPress($event,i,7,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Hang_Hoa_Id_Co"
             labelForId="{{i}}_7_1"
             [openOnEnter]="false"
             [items]="inputhanghoas"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="hanghoaid"
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Hang_Hoa_Id_Co">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoi7,i,7)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Kho_Hang_Id_Co">
                  <!--Kho hàng có-->
                  <ng-select #slLuoi_khohangco (keydown)="keyComBoPress($event,i,8,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Kho_Hang_Id_Co"
                             labelForId="{{i}}_8_1"
                             [openOnEnter]="false"
                             [items]="inputkhohangs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="khohangid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Kho_Hang_Id_Co">

                    <ng-template ng-footer-tmp>
                      <a (click)="AddDoiTuong(slLuoi_khohangco,i,8)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.Don_Vi_Tinh_Id_Co">
                  <!--đơn vị tính có-->
                  <ng-select #slLuoi_dvtco (keydown)="keyComBoPress($event,i,9,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Don_Vi_Tinh_Id_Co"
                             labelForId="{{i}}_9_1"
                             [openOnEnter]="false"
                             [items]="inputdonvitinhs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="donvitinhid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Don_Vi_Tinh_Id_Co">

                    <ng-template ng-footer-tmp>
                      <a (click)="AddDoiTuong(slLuoi_dvtco,i,9)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.So_Luong">

                  <input id="{{i}}_10_1" *ngIf="CoBan.SoLuong===null || CoBan.SoLuong===0" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,1)"
                         maxlength="20"
                         style="width:110px;"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="'Số lượng'"
                         [options]="ngxAmOptions" autocomplete="off" />
                  <input id="{{i}}_10_1" *ngIf="CoBan.SoLuong===1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,1)"
                         maxlength="20"
                         style="width:110px;"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="'Số lượng'"
                         [options]="ngxAmOption1s" autocomplete="off" />
                  <input id="{{i}}_10_1" *ngIf="CoBan.SoLuong===2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,1)"
                         maxlength="20"
                         style="width:110px;"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="'Số lượng'"
                         [options]="ngxAmOption2s" autocomplete="off" />
                  <input id="{{i}}_10_1" *ngIf="CoBan.SoLuong>=3" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,1)"
                         maxlength="20"
                         style="width:110px;"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="'Số lượng'"
                         [options]="ngxAmOption3s" autocomplete="off" />
                </td>
                <td *ngIf="HienThis.Don_Gia">
                  <input id="{{i}}_11_1" *ngIf="CoBan.DonGia===null || CoBan.DonGia===0" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,1)"
                         maxlength="20"
                         style="width:140px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxAmOptions" autocomplete="off" />
                  <input id="{{i}}_11_1" *ngIf="CoBan.DonGia===1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,1)"
                         maxlength="20"
                         style="width:140px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxAmOption1s" autocomplete="off" />
                  <input id="{{i}}_11_1" *ngIf="CoBan.DonGia===2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,1)"
                         maxlength="20"
                         style="width:140px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxAmOption2s" autocomplete="off" />
                  <input id="{{i}}_11_1" *ngIf="CoBan.DonGia>=3" #myinput class="form-control" (keydown)="keyComBoPress($event,i,11,1)"
                         maxlength="20"
                         style="width:140px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxAmOption3s" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_12_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,12,1)"
                         maxlength="20"
                         style="width:150px;"
                         currencyMask
                         [(ngModel)]="row.So_Tien"
                         (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="'Thành tiền'"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>


                <td *ngIf="HienThis.Doi_Tuong_Id_No">
                  <!--đối tượng nợ-->
                  <a *ngIf="row.Doi_Tuong_Id_No===null"  (click)="adddatachondoituongchung_luoi('Doi_Tuong_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_13_1" type="text" 
                           (keydown)="keyComBoPress($event,i,13,1)" class="form-control"
                           [style.width.px]="DoRongs.Doi_Tuong_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Doi_Tuong_No">
                  </a>
                  <a *ngIf="row.Doi_Tuong_Id_No!==null"  (dblclick)="adddatachondoituongchung_luoi('Doi_Tuong_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_13_1" type="text" 
                           (keydown)="keyComBoPress($event,i,13,1)" class="form-control"
                           [style.width.px]="DoRongs.Doi_Tuong_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Doi_Tuong_No" (ngModelChange)="adddatachondoituongchung_luoi('Doi_Tuong_Id_No',i)">
                  </a>
                  <!--<ng-select #slLuoi10 (keydown)="keyComBoPress($event,i,13,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Doi_Tuong_Id_No"
             labelForId="{{i}}_13_1"
             [openOnEnter]="false"
             [items]="inputkhachhangs"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="doituongid"
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Doi_Tuong_Id_No">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoi10,i,13)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Hop_Dong_Id_No">
                  <!--hợp đồng nợ-->
                  <a *ngIf="row.Hop_Dong_Id_No===null"  (click)="adddatachondoituongchung_luoi('Hop_Dong_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_14_1" type="text" 
                           (keydown)="keyComBoPress($event,i,14,1)" class="form-control"
                           [style.width.px]="DoRongs.Hop_Dong_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hop_Dong_No">
                  </a>
                  <a *ngIf="row.Hop_Dong_Id_No!==null"  (dblclick)="adddatachondoituongchung_luoi('Hop_Dong_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_14_1" type="text" 
                           (keydown)="keyComBoPress($event,i,14,1)" class="form-control"
                           [style.width.px]="DoRongs.Hop_Dong_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hop_Dong_No" (ngModelChange)="adddatachondoituongchung_luoi('Hop_Dong_Id_No',i)">
                  </a>
                  <!--<ng-select #slLuoi11 (keydown)="keyComBoPress($event,i,14,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Hop_Dong_Id_No"
             labelForId="{{i}}_14_1"
             [openOnEnter]="false"
             [items]="inputhopdongs"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="hopdongid"
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Hop_Dong_Id_No">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoi11,i,14)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>
                <td *ngIf="HienThis.Tai_San_Id_No">
                  <!--Tài sản nợ-->
                  <a *ngIf="row.Tai_San_Id_No===null"  (click)="adddatachondoituongchung_luoi('Tai_San_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_15_1" type="text" 
                           (keydown)="keyComBoPress($event,i,15,1)" class="form-control"
                           [style.width.px]="DoRongs.Tai_San_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Tai_San_No">
                  </a>
                  <a *ngIf="row.Tai_San_Id_No!==null"  (dblclick)="adddatachondoituongchung_luoi('Tai_San_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_15_1" type="text" 
                           (keydown)="keyComBoPress($event,i,15,1)" class="form-control"
                           [style.width.px]="DoRongs.Tai_San_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Tai_San_No" (ngModelChange)="adddatachondoituongchung_luoi('Tai_San_Id_No',i)">
                  </a>
                  <!--<ng-select #slTaiSan (keydown)="keyComBoPress($event,i,15,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Tai_San_Id_No"
             labelForId="{{i}}_15_1"
             [openOnEnter]="false"
             [items]="inputtaisans"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="taisanid"
             [searchFn]="comboSearchFn"
             (change)="onTaiSanChange($event,i)"
             [(ngModel)]="row.Tai_San_Id_No">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slTaiSan,i,5)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Lenh_Sua_Chua_Id_No">
                  <!--lệnh sửa chữa nợ-->
                  <a *ngIf="row.Lenh_Sua_Chua_Id_No===null"  (click)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_16_1" type="text" 
                           (keydown)="keyComBoPress($event,i,16,1)" class="form-control"
                           [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Lenh_Sua_Chua_No">
                  </a>
                  <a *ngIf="row.Lenh_Sua_Chua_Id_No!==null"  (dblclick)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_No',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_16_1" type="text" 
                           (keydown)="keyComBoPress($event,i,16,1)" class="form-control"
                           [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_No"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Lenh_Sua_Chua_No" (ngModelChange)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_No',i)">
                  </a>
                  <!--<ng-select #slLuoiChuong (keydown)="keyComBoPress($event,i,16,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_No"
             labelForId="{{i}}_16_1"
             [openOnEnter]="false"
             [items]="inputlenhsuachuas"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="lenhsuachuaid"
             [searchFn]="comboSearchFn"
             (ngModelChange)="changeLuoi(row)"
             [(ngModel)]="row.Lenh_Sua_Chua_Id_No">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoiChuong,i,16)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Khoan_Muc_Id_No">
                  <!--khoản muc nợ-->
                  <ng-select #slLuoi_khoanmucno (keydown)="keyComBoPress($event,i,17,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Khoan_Muc_Id_No"
                             labelForId="{{i}}_17_1"
                             [openOnEnter]="false"
                             [items]="inputkhoanmucs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomnguonid"
                             [searchFn]="comboSearchFn"
                             (ngModelChange)="changeLuoi(row)"
                             [(ngModel)]="row.Khoan_Muc_Id_No">

                    <ng-template ng-footer-tmp>
                      <a (click)="AddDoiTuong(slLuoi_khoanmucno,i,17)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.Doi_Tuong_Id_1_No">
                  <!--mã đơn vị nợ-->
                  <ng-select #slLuoi_madonvino (keydown)="keyComBoPress($event,i,18,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Doi_Tuong_Id_1_No"
                             labelForId="{{i}}_18_1"
                             [openOnEnter]="false"
                             [items]="inputmadonvis"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomloaikhoanid"
                             [searchFn]="comboSearchFn"
                             (ngModelChange)="changeLuoi(row)"
                             [(ngModel)]="row.Doi_Tuong_Id_1_No">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.Doi_Tuong_Id_Co">
                  <!--đối tượng có-->
                  <a *ngIf="row.Doi_Tuong_Id_Co===null"  (click)="adddatachondoituongchung_luoi('Doi_Tuong_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_19_1" type="text" 
                           (keydown)="keyComBoPress($event,i,19,1)" class="form-control"
                           [style.width.px]="DoRongs.Doi_Tuong_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Doi_Tuong_Co">
                  </a>
                  <a *ngIf="row.Doi_Tuong_Id_Co!==null"  (dblclick)="adddatachondoituongchung_luoi('Doi_Tuong_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_19_1" type="text" 
                           (keydown)="keyComBoPress($event,i,19,1)" class="form-control"
                           [style.width.px]="DoRongs.Doi_Tuong_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Doi_Tuong_Co" (ngModelChange)="adddatachondoituongchung_luoi('Doi_Tuong_Id_Co',i)">
                  </a>
                  <!--<ng-select #slLuoi19 (keydown)="keyComBoPress($event,i,19,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Doi_Tuong_Id_Co"
             labelForId="{{i}}_19_1"
             [openOnEnter]="false"
             [items]="inputkhachhangs"
             bindValue="Id"
             bindLabel="Ten_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="doituongid"
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Doi_Tuong_Id_Co">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoi19,i,19)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Hop_Dong_Id_Co">
                  <!--hợp đồng có-->
                  <a *ngIf="row.Hop_Dong_Id_Co===null"  (click)="adddatachondoituongchung_luoi('Hop_Dong_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_20_1" type="text" 
                           (keydown)="keyComBoPress($event,i,20,1)" class="form-control"
                           [style.width.px]="DoRongs.Hop_Dong_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hop_Dong_Co">
                  </a>
                  <a *ngIf="row.Hop_Dong_Id_Co!==null"  (dblclick)="adddatachondoituongchung_luoi('Hop_Dong_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_20_1" type="text" 
                           (keydown)="keyComBoPress($event,i,20,1)" class="form-control"
                           [style.width.px]="DoRongs.Hop_Dong_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Hop_Dong_Co" (ngModelChange)="adddatachondoituongchung_luoi('Hop_Dong_Id_Co',i)">
                  </a>
                  <!--<ng-select #slLuoi20 (keydown)="keyComBoPress($event,i,20,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Hop_Dong_Id_Co"
             labelForId="{{i}}_20_1"
             [openOnEnter]="false"
             [items]="inputhopdongs"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="hopdongid"
             [searchFn]="comboSearchFn"
             [(ngModel)]="row.Hop_Dong_Id_Co">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoi20,i,20)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>


                <td *ngIf="HienThis.Tai_San_Id_Co">
                  <!--Tài sản có-->
                  <a *ngIf="row.Tai_San_Id_Co===null"  (click)="adddatachondoituongchung_luoi('Tai_San_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_21_1" type="text" 
                           (keydown)="keyComBoPress($event,i,21,1)" class="form-control"
                           [style.width.px]="DoRongs.Tai_San_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Tai_San_Co">
                  </a>
                  <a *ngIf="row.Tai_San_Id_Co!==null"  (dblclick)="adddatachondoituongchung_luoi('Tai_San_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_21_1" type="text" 
                           (keydown)="keyComBoPress($event,i,21,1)" class="form-control"
                           [style.width.px]="DoRongs.Tai_San_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Tai_San_Co" (ngModelChange)="adddatachondoituongchung_luoi('Tai_San_Id_Co',i)">
                  </a>
                  <!--<ng-select #slTaiSan (keydown)="keyComBoPress($event,i,21,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Tai_San_Id_Co"
             labelForId="{{i}}_21_1"
             [openOnEnter]="false"
             [items]="inputtaisans"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="taisanid"
             [searchFn]="comboSearchFn"
             (change)="onTaiSanChange($event,i)"
             [(ngModel)]="row.Tai_San_Id_Co">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slTaiSan,i,21)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Lenh_Sua_Chua_Id_Co">
                  <!--lệnh sửa chữa có-->
                  <a *ngIf="row.Lenh_Sua_Chua_Id_Co===null"  (click)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_22_1" type="text" 
                           (keydown)="keyComBoPress($event,i,22,1)" class="form-control"
                           [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Lenh_Sua_Chua_Co">
                  </a>
                  <a *ngIf="row.Lenh_Sua_Chua_Id_Co!==null"  (dblclick)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_Co',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_22_1" type="text" 
                           (keydown)="keyComBoPress($event,i,22,1)" class="form-control"
                           [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_Co"
                           placeholder="Chọn đối tượng" [(ngModel)]="row.Lenh_Sua_Chua_Co" (ngModelChange)="adddatachondoituongchung_luoi('Lenh_Sua_Chua_Id_Co',i)">
                  </a>
                  <!--<ng-select #slLuoiChuong22 (keydown)="keyComBoPress($event,i,22,1)"
             [dropdownPosition]="row.showpopup"
             class="twcol"
             [style.width.px]="DoRongs.Lenh_Sua_Chua_Id_Co"
             labelForId="{{i}}_22_1"
             [openOnEnter]="false"
             [items]="inputlenhsuachuas"
             bindValue="Id"
             bindLabel="Ma_Doi_Tuong"
             notFoundText="Không có dữ liệu"
             placeholder=""
             [addTagText]="lenhsuachuaid"
             [searchFn]="comboSearchFn"
             (ngModelChange)="changeLuoi(row)"
             [(ngModel)]="row.Lenh_Sua_Chua_Id_Co">

    <ng-template ng-footer-tmp>
      <a (click)="AddDoiTuong(slLuoiChuong22,i,22)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
      </div>

    </ng-template>
  </ng-select>-->
                </td>

                <td *ngIf="HienThis.Khoan_Muc_Id_Co">
                  <!--khoản muc có-->
                  <ng-select #slLuoi_khoanmucco (keydown)="keyComBoPress($event,i,23,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Khoan_Muc_Id_Co"
                             labelForId="{{i}}_23_1"
                             [openOnEnter]="false"
                             [items]="inputkhoanmucs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomnguonid"
                             [searchFn]="comboSearchFn"
                             (ngModelChange)="changeLuoi(row)"
                             [(ngModel)]="row.Khoan_Muc_Id_Co">

                    <ng-template ng-footer-tmp>
                      <a (click)="AddDoiTuong(slLuoi_khoanmucco,i,23)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td *ngIf="HienThis.Doi_Tuong_Id_1_Co">
                  <!--mã đơn vị có-->
                  <ng-select #slLuoi_madonvico (keydown)="keyComBoPress($event,i,24,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             [style.width.px]="DoRongs.Doi_Tuong_Id_1_Co"
                             labelForId="{{i}}_24_1"
                             [openOnEnter]="false"
                             [items]="inputmadonvis"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomloaikhoanid"
                             [searchFn]="comboSearchFn"
                             (ngModelChange)="changeLuoi(row)"
                             [(ngModel)]="row.Doi_Tuong_Id_1_Co">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>

                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 225px);background:#fff;">
          <table id="fixTablechungtukhac_02" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100">Loại thuế</th>
                <th width="100">Ngày HĐ</th>
                <th width="100">Số seri</th>
                <th width="150">Số HĐ</th>
                <th width="250">Đơn vị</th>
                <th width="200">Mã số thuế</th>
                <th width="200">Mặt hàng</th>
                <th>Doanh thu</th>
                <th width="150">Thuế suất</th>
                <th width="150">Tiền thuế</th>
                <th width="150">Mã tra cứu HĐ</th>
              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 235px);">
              <tr *ngFor='let row of chungtuSelect.HoaDons; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,2)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <ng-select #slLuoi50 (keydown)="keyComBoPress($event,i,1,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:350px;"
                             labelForId="{{i}}_1_2"
                             [openOnEnter]="false"
                             [items]="inputloaithues"
                             bindValue="Id"
                             bindLabel="Ten_Loai_Thue"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             (ngModelChange)="changeLoaiThue($event,row,i)"
                             [(ngModel)]="row.Loai_Thue_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ten_Loai_Thue}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ma_Loai_Thue}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <kendo-dateinput id="{{i}}_2_2" style="width:110px;" (keydown)="keyComBoPress($event,i,2,2)" [format]="'dd/MM/yyyy'" [(value)]="row.Ngay_Hoa_Don"></kendo-dateinput>
                </td>
                <td>
                  <input id="{{i}}_3_2" style="width:110px;" #myinput type="text" (keydown)="keyComBoPress($event,i,3,2)" class="form-control" placeholder="" [(ngModel)]="row.So_Hieu">
                </td>
                <td>
                  <input id="{{i}}_4_2" style="width:110px;" #myinput type="text" (keydown)="keyComBoPress($event,i,4,2)" class="form-control" placeholder="" [(ngModel)]="row.So_Hoa_Don">
                </td>
                <td>
                  <input id="{{i}}_5_2" style="width:250px;" #myinput type="text" (keydown)="keyComBoPress($event,i,5,2)" class="form-control" placeholder="" [(ngModel)]="row.Doi_Tuong">
                </td>
                <td>
                  <input id="{{i}}_6_2" style="width:150px;" #myinput type="text" (keydown)="keyComBoPress($event,i,6,2)" class="form-control" placeholder="" [(ngModel)]="row.Ma_So_Thue">
                </td>
                <td>
                  <input id="{{i}}_7_2" style="width:250px;" #myinput type="text" (keydown)="keyComBoPress($event,i,7,2)" class="form-control" placeholder="" [(ngModel)]="row.Dien_Giai">
                </td>

                <td>
                  <input id="{{i}}_8_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,8,2)"
                         maxlength="20"
                         style="width:150px;"
                         currencyMask
                         [(ngModel)]="row.Tien_Hang"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>
                <td>
                  <input id="{{i}}_9_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,9,2)"
                         maxlength="20"
                         style="width:150px;"
                         currencyMask
                         [(ngModel)]="row.Thue_Suat"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>
                <td>
                  <input id="{{i}}_10_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,2)"
                         maxlength="20"
                         style="width:150px;"
                         currencyMask
                         [(ngModel)]="row.Tien_Thue"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>
                <td>
                  <input id="{{i}}_11_2" style="width:110px;" #myinput type="text" (keydown)="keyComBoPress($event,i,11,2)" class="form-control" placeholder="" [(ngModel)]="row.TransactionID">
                </td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--<div class=" tab-pane" [ngClass]="{'active': tabselected==3}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 235px);background:#fff;">
          <table id="fixTablechungtukhac_03" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100">TK nợ</th>
                <th width="100">TK có</th>
                <th width="250">Diễn giải</th>
                <th width="150">Tổng<span style="font-size: 11px;color: #ea182b;">( {{tongtien2 | number}})</span></th>
                <th width="200">Chương</th>
                <th width="200">Nguồn</th>
                <th width="200">Loại khoản</th>
                <th width="200">Mục tiểu mục</th>
                <th width="200">Cấp phát</th>
                <th width="200">Nghiệp vụ</th>
                <th width="200">Đối tượng nợ</th>
                <th width="200">Đối tượng có</th>
                <th width="200">Hoạt động</th>
                <th width="200">CTMT, dự án</th>
                <th width="200">Thống kê</th>

                <th>Đối tượng tập hợp</th>
                <th>Đối tượng tập hợp 2</th>
              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 245px);">
              <tr *ngFor='let row of chungtuSelect.DongThois; let i = index'>
                <td style="background-color:white"><a (click)="deleterow(i,3)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a></td>
                <td>
                  <ng-select #slLuoi1 (keydown)="keyComBoPress($event,i,1,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_100"
                             labelForId="{{i}}_1_3"
                             [openOnEnter]="false"
                             [items]="inputtaikhoans"
                             bindValue="Id"
                             bindLabel="Ma_Tai_Khoan"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [(ngModel)]="row.Tai_Khoan_No_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi1 (keydown)="keyComBoPress($event,i,2,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_100"
                             labelForId="{{i}}_2_3"
                             [openOnEnter]="false"
                             [items]="inputtaikhoans"
                             bindValue="Id"
                             bindLabel="Ma_Tai_Khoan"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [(ngModel)]="row.Tai_Khoan_Co_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <input id="{{i}}_3_3" #myinput type="text" [style.width.px]="dorong" (keydown)="keyComBoPress($event,i,3,3)" class="form-control" placeholder="" [(ngModel)]="row.Dien_Giai_Chi_Tiet">
                </td>
                <td>
                  <input id="{{i}}_4_3" #myinput class="form-control" (keydown)="keyComBoPress($event,i,4,3)"
                         maxlength="20"
                         style="width:150px;"
                         currencyMask
                         [(ngModel)]="row.So_Tien"
                         (ngModelChange)="changeST2(row)"
                         name="value"
                         [placeholder]="'Thành tiền'"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>
                <td>
                  <ng-select #slLuoiChuong (keydown)="keyComBoPress($event,i,5,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_90"
                             labelForId="{{i}}_5_3"
                             [openOnEnter]="false"
                             [items]="inputchuongs"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomchuongid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Chuong_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themchuong===true">
                      <a (click)="AddDoiTuong(slLuoiChuong,i,5)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi2 (keydown)="keyComBoPress($event,i,6,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_90"
                             labelForId="{{i}}_6_3"
                             [openOnEnter]="false"
                             [items]="inputnguonkinhphis"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomnguonid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Nguon_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themnguon===true">
                      <a (click)="AddDoiTuong(slLuoi2,i,6)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td>
                  <ng-select #slLuoi4 (keydown)="keyComBoPress($event,i,7,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:120px;"
                             labelForId="{{i}}_7_3"
                             [openOnEnter]="false"
                             [items]="inputloaikhoans"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomloaikhoanid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Loai_Khoan_Id">
                    <ng-template ng-footer-tmp *ngIf="quyen_themkhoan===true">
                      <a (click)="AddDoiTuong(slLuoi4,i,7)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi5 (keydown)="keyComBoPress($event,i,8,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:120px;"
                             labelForId="{{i}}_8_3"
                             [openOnEnter]="false"
                             [items]="inputtieumucs"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nhomtieumucid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Tieu_Muc_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themtieumuc===true">
                      <a (click)="AddDoiTuong(slLuoi5,i,8)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>
                <td>
                  <ng-select #slLuoi6 (keydown)="keyComBoPress($event,i,9,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol_140"
                             labelForId="{{i}}_9_3"
                             [openOnEnter]="false"
                             [items]="inputcaphats"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="hinhthuccapphatid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Hinh_Thuc_Cap_Phat_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themcapphat===true">
                      <a (click)="AddDoiTuong(slLuoi6,i,9)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>
                <td>
                  <ng-select #slLuoi7 (keydown)="keyComBoPress($event,i,10,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_10_3"
                             [openOnEnter]="false"
                             [items]="inputnghiepvus"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="nghiepvuid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Nghiep_Vu_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themnghiepvu===true">
                      <a (click)="AddDoiTuong(slLuoi7,i,10)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>
                <td>
                  <ng-select #slLuoi8 (keydown)="keyComBoPress($event,i,11,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_11_3"
                             [openOnEnter]="false"
                             [items]="inputkhachhangs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="Doi_Tuong_Id"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Doi_Tuong_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>
                <td>
                  <ng-select #slLuoi8 (keydown)="keyComBoPress($event,i,12,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_12_3"
                             [openOnEnter]="false"
                             [items]="inputkhachhangs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="'Doi_Tuong_Id2'"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Doi_Tuong_Id2">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>
                <td>
                  <ng-select #slLuoi12 (keydown)="keyComBoPress($event,i,13,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:250px;"
                             labelForId="{{i}}_13_3"
                             [openOnEnter]="false"
                             [items]="inputhoatdongs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="hoatdongid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Hoat_Dong_Id">

                    <ng-template ng-footer-tmp *ngIf="quyen_themhoatdong===true">
                      <a (click)="AddDoiTuong(slLuoi12,i,13)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi13 (keydown)="keyComBoPress($event,i,14,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:150px;"
                             labelForId="{{i}}_14_3"
                             [openOnEnter]="false"
                             [items]="inputcmmts"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="cmmtid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.CTMT_Id">
                    <ng-template ng-footer-tmp *ngIf="quyen_themctmt===true">
                      <a (click)="AddDoiTuong(slLuoi13,i,14)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi14 (keydown)="keyComBoPress($event,i,15,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol-end"
                             style="width:150px;"
                             labelForId="{{i}}_15_3"
                             [openOnEnter]="false"
                             [items]="inputthongkes"
                             bindValue="Id"
                             bindLabel="Ma_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="mathongke"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Ma_Thong_Ke_Id">
                    <ng-template ng-footer-tmp *ngIf="quyen_themthongke===true">
                      <a (click)="AddDoiTuong(slLuoi14,i,15)"><em class="mr-2 far fa-plus-square"></em>Thêm mới</a>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi19 (keydown)="keyComBoPress($event,i,16,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             labelForId="{{i}}_16_3"
                             [openOnEnter]="false"
                             [items]="inputtaphops"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="taphopid"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Doi_Tuong_Id_5">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>
                <td>
                  <ng-select #slLuoi20 (keydown)="keyComBoPress($event,i,17,3)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol twcol-end"
                             labelForId="{{i}}_17_3"
                             [openOnEnter]="false"
                             [items]="inputtaphops2"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder=""
                             [addTagText]="taphopid2"
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Doi_Tuong_Id_4">

                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>-->
  </div>



</div>

<div class="modal-footer" style="display: flex;justify-content: space-between;padding:0px;">
  <div>
    <span style="float:left;padding-right:15px;line-height:25px;">Kích đúp chuột vào mã đối tượng để hiển thị danh sách chọn</span>
    <!--<span *ngIf="isHidden==1 || isHidden==2  || isHidden==3 " style="float:left;padding-right:15px;line-height:25px;">Số phiếu rút dự toán</span>
  <input [disabled]="true" style="float:left;width:200px;" *ngIf="isHidden==1  || isHidden==3 " type="text" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Lien_Quan">
  <ng-select #slKhachHang *ngIf="isHidden == 2"
             labelForId="99"
             class="twcol"
             style="width:150px; float:left;"
             autofocus
             [tabIndex]="1"
             [openOnEnter]="false"
             [items]="inputlienquans"
             bindValue="Id"
             bindLabel="Ma_Chung_Tu"
             notFoundText="Không có dữ liệu"
             placeholder=""
             (change)="onLienQuanChange($event)"
             [(ngModel)]="chungtuSelect.Lien_Quan_Id">
    <ng-template ng-option-tmp let-item="item">
      <div class="rTableRow">
        <div class="rTableCell rTableCell-1">{{item.Ma_Chung_Tu}}</div>
        <div class="rTableCell rTableCell-2">{{item.Ngay_Chung_Tu}}</div>
      </div>

    </ng-template>
  </ng-select>-->
  </div>
  <div class="">

  </div>

</div>
<!--<ngx-loading [show]="loading" [config]="{primaryColour: primaryColour, secondaryColour: secondaryColour, tertiaryColour: primaryColour, backdropBorderRadius: '3px'}"></ngx-loading>-->

<bs-modal #mdDoiTuongChung class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucchungedit #dteChung (save)="saveModal($event)"></app-dm-danhmucchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #mdDoiTuongNH class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongNH.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucnganhangedit #dtenganhang (save)="saveModalNganHang($event)"></app-dm-danhmucnganhangedit>
  </bs-modal-body>
</bs-modal>


<!--<bs-modal #mdLuaChon class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Bạn muốn thêm người nộp là?</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChon.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(3)" value="option1" />
        <span class="fa fa-circle"></span>Khách hàng
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(1)" value="option1" />
        <span class="fa fa-circle"></span>Nhà cung cấp
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(2)" value="option2" checked="" />
        <span class="fa fa-circle"></span>Cán bộ
      </label>
    </div>
  </bs-modal-body>
  <bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">

      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChon.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormNguoiNop()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>

    </div>
  </bs-modal-footer>
</bs-modal>-->

<bs-modal #mdLuaChonDoRong class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thay đổi độ rộng</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChonDoRong.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Dien_Giai_Chi_Tiet" />
      </div>
      <label class="control-label col-3 ng-binding">Hàng hóa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kho hàng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đơn vị tính nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hàng hóa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Kho hang có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Đơn vị tính có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_Co" />
      </div>

    </div>

  </bs-modal-body>
  <!--<bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">
      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChonDoRong.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormDoRong()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>
    </div>
  </bs-modal-footer>-->
</bs-modal>

<!--<bs-modal #mdCanBo class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdCanBo.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmucnhanvienedit #dteCanBo (save)="saveModalCanBo($event)"></app-dm-danhmucnhanvienedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdKhachHang class="modal fade show in" [cssClass]="'modal600'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdKhachHang.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuckhachhangedit #dteKhachHang (save)="saveModalKhacHang($event)"></app-dm-danhmuckhachhangedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdTaiSan class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdTaiSan.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuctaisanedit #dtetaisan (save)="saveModalTaiSan($event)"></app-dm-danhmuctaisanedit>
  </bs-modal-body>
</bs-modal>-->


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchungedit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchungedit>
  </bs-modal-body>
</bs-modal>

