<ul class="navbar">
  <!--<li class="nav-item">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fab fa-buromobelexperte"></i>
        Mẫu
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="dinhkhoanmau(row)" *ngFor='let row of inputdinhkhoanmaus;'>{{row.Noi_Dung}}</a>
      </div>
    </div>
  </li>
  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null) && chungtuSelect.Lien_Quan_Id!=null">
    <a class="nav-link" (click)="savectu(1)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại và cập nhật giấy rút
    </a>
  </li>-->




  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null)">
    <a class="nav-link" (click)="savectu(0)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="closeModal()">
      <i class="fa-2x mr-2 far fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>


<div class="row ng-scope">
  <div class="col-4">
    <div class="row">
      <div class="col-12">
        <div style="width:30%;float:left">Số đơn hàng</div>
        <div style="width:70%;float:left">
          <input type="text" *ngIf="chungtuSelect.Lock_So_Hop_Dong==true" disabled="disabled" tabIndex="1" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.So_Don_Hang">
          <input type="text" *ngIf="chungtuSelect.Lock_So_Hop_Dong!==true"  tabIndex="1" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.So_Don_Hang">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:30%;float:left">Ngày đặt hàng</div>
        <div style="width:70%;float:left">
          <kendo-dateinput tabindex="2" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Dat_Hang"></kendo-dateinput>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:30%;float:left">Loại đơn hàng</div>
        <div style="width:70%;float:left">
          <ng-select #slLoaiDonHang
                     class="twcol"
                     [tabIndex]="3"
                     [openOnEnter]="false"
                     [items]="inputloaidonhangs"
                     bindValue="Id"
                     bindLabel="Ten_Doi_Tuong"
                     notFoundText="Không có dữ liệu"
                     [(ngModel)]="chungtuSelect.Loai_Don_Hang">
            <ng-template ng-option-tmp let-item="item">
              <div class="rTableRow">
                <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:30%;float:left">Dự kiến hàng về (tháng/năm)</div>
        <div style="width:30%;float:left">
          <input id="thang" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="chungtuSelect.Thang_Du_Kien"
                 name="value"
                 min="1"
                 max="12"
                 tabIndex="6"
                 [placeholder]="''"
                 [options]="ngxCurrencyOptions" autocomplete="off" />
        </div>
        <div style="width:38%;float:right">
          <input id="thang" #myinputsl class="form-control"
                 maxlength="20"
                 style="width:100%;"
                 currencyMask
                 [(ngModel)]="chungtuSelect.Nam_Du_Kien"
                 name="value"
                 min="2000"
                 max="3000"
                 tabIndex="7"
                 [placeholder]="''"
                 [options]="ngxnamOptions" autocomplete="off" />
        </div>
      </div>
      
    </div>


  </div>
  <div class="col-4">
    <div class="row" *ngIf="tien_danhsachxe>0">
      <div class="col-12">
        <div style="width:30%;float:left">Tổng giá nhập</div>
        <div style="width:70%;float:left">
          <span style="font-size: 11px;color: #ea182b;font-weight:bold">( {{tien_danhsachxe | number}})</span>
        </div>
      </div>
      
    </div>
  </div>
  <div class="col-4">
    <div class="row">
      <div class="col-12">
        <div class="c-radio" *ngFor="let u of inputtrangthais; let i = index">
          <label *ngIf="chungtuSelect.DisableStatus">
            <input disabled="disabled" type="radio" name="a" (change)="onDuyet(u)" value="option{{i}}"  [checked]="i==chungtuSelect.Trang_Thai"/>
            <span class="fa fa-circle"></span>{{u.Ten_Doi_Tuong}}
          </label>
          <label *ngIf="!chungtuSelect.DisableStatus">
            <input type="radio" name="a" (change)="onDuyet(u)" value="option{{i}}" [checked]="i==chungtuSelect.Trang_Thai"/>
            <span class="fa fa-circle"></span>{{u.Ten_Doi_Tuong}}
          </label>
        </div>
      </div>
      
    </div>
   
  </div>
  
</div>


<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}"><a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id=""><span></span>1.Thông tin loại xe đặt hàng</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id=""><span></span>2.Danh sách xe đơn hàng</a></li>
    </ul>
    <div *ngIf="tabselected==1">
      <input type="number" max="7" min="0" style="width:40px;height:20px;text-align:center;margin-right:3px" [(ngModel)]="fixcot" (change)="fn_fixcot()" />
      <a (click)="RowNewClick()" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng mới</a>
    </div>
  </div>

  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto">
        <div class="" style="height:calc(100vh - 250px);background:#fff;">
          <table id="fixTablechungtukhac_01" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">Model xe</th>
                <th width="100" style="text-align:center">Loại xe</th>
                <th width="100" style="text-align:center">Màu xe</th>
                <th width="100" style="text-align:center">Số lượng</th>
                <th width="100" style="text-align:center">Đơn giá</th>
                <th width="130" style="text-align:center">Số tiền <span *ngIf="tien_hanghoa>0" style="font-size: 11px;color: #ea182b;">( {{tien_hanghoa | number}})</span></th>
                <!--<th width="100" style="text-align:center">STT</th>-->

              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 260px);">
              <tr *ngFor='let row of chungtuSelect.Hang_Hoas; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,1)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <!--Model xe-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,1,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:180px"
                             labelForId="{{i}}_1_1"
                             [openOnEnter]="false"
                             [items]="inputmodels"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Model_Xe_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td>
                  <!--Loại xe-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,2,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:150px"
                             labelForId="{{i}}_2_1"
                             [openOnEnter]="false"
                             [items]="inputdactas"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Dac_Ta_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>

                <td>
                  <!--màu xe-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,3,1)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:120px"
                             labelForId="{{i}}_3_1"
                             [openOnEnter]="false"
                             [items]="inputmausacs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Mau_Xe_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>


               
                <td>

                  <input id="{{i}}_4_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,4,1)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_5_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,5,1)"
                         maxlength="20"
                         style="min-width:110px;"
                         currencyMask
                         [(ngModel)]="row.Don_Gia"
                         (ngModelChange)="changeDG(row)"
                         name="value"
                         [placeholder]="'Đơn giá'"
                         [options]="ngxCurrencyOptions" autocomplete="off" />
                </td>


                <td>
                  <input id="{{i}}_6_1" #myinput class="form-control" (keydown)="keyComBoPress($event,i,6,1)"
                         maxlength="20"
                         style="min-width:180px;"
                         currencyMask
                         [(ngModel)]="row.So_Tien"
                         (ngModelChange)="changeST(row)"
                         name="value"
                         [placeholder]="'Số tiền'"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

              

                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 250px);background:#fff;">
          <table id="fixTablechungtukhac_02" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">Mã</th>
                <th width="100" style="text-align:center">Model</th>
                <th width="100" style="text-align:center">Loại xe</th>
                <th width="100" style="text-align:center">Màu xe</th>
                <th width="100" style="text-align:center">Giá nhập</th>
                <th width="100" style="text-align:center">Số khung</th>
                <th width="100" style="text-align:center">Số Máy</th>
                <th width="100" style="text-align:center">Ngày giao xe</th>
                <th width="100" style="text-align:center">Xuất xứ</th>
                <th width="100" style="text-align:center">Năm sản xuất</th>

              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 260px);">
              <tr *ngFor='let row of chungtuSelect.Danh_Sach_Xes; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,2)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>

                <td>

                  <input id="{{i}}_1_2" #myinput type="text" style="min-width:140px" (keydown)="keyComBoPress($event,i,1,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ma_Doi_Tuong">
                </td>

                <td>
                  <!--Model-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,2,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:130px"
                             [disabled]="true"
                             labelForId="{{i}}_2_2"
                             [openOnEnter]="false"
                             [items]="inputmodels"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Model_Xe_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>

                </td>

                <td>

                  <input id="{{i}}_3_2"  #myinput type="text" style="min-width:160px" (keydown)="keyComBoPress($event,i,3,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ten_Doi_Tuong">
                </td>

                <td>
                  <!--Màu xe-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,4,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:110px"
                             labelForId="{{i}}_4_2"
                             [openOnEnter]="false"
                             [items]="inputmausacs"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Mau_Xe_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>


                <td>

                  <input id="{{i}}_5_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,5,2)"
                         maxlength="20"
                         style="min-width:120px"
                         currencyMask
                         [(ngModel)]="row.Gia_Nhap"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>

                <td>

                  <input id="{{i}}_6_2" #myinput type="text" style="min-width:130px" (keydown)="keyComBoPress($event,i,6,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.So_Khung">
                </td>
                <td>
                  <input id="{{i}}_7_2" #myinput type="text" style="min-width:130px" (keydown)="keyComBoPress($event,i,7,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.So_May">
                </td>

                <td>
                  <kendo-dateinput id="{{i}}_8_2" style="width:100%;min-width:110px"  [format]="'dd/MM/yyyy'" (keydown)="keyComBoPress($event,i,8,2)" [(value)]="row.Ngay_Giao_Xe"></kendo-dateinput>
                </td>


                <td>
                  <!--xuất xứ-->
                  <ng-select #slLuoi_loaidichvu (keydown)="keyComBoPress($event,i,9,2)"
                             [dropdownPosition]="row.showpopup"
                             class="twcol"
                             style="width:110px"
                             labelForId="{{i}}_9_2"
                             [openOnEnter]="false"
                             [items]="inputxuatxus"
                             bindValue="Id"
                             bindLabel="Ten_Doi_Tuong"
                             notFoundText="Không có dữ liệu"
                             placeholder="Chọn..."
                             [searchFn]="comboSearchFn"
                             [(ngModel)]="row.Xuat_Xu_Id">
                    <ng-template ng-option-tmp let-item="item">
                      <div class="rTableRow">
                        <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
                        <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
                      </div>

                    </ng-template>
                  </ng-select>
                </td>








                <td>
                  <input id="{{i}}_10_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,10,2)"
                         maxlength="20"
                         style="min-width:100px;"
                         currencyMask
                         [(ngModel)]="row.Nam_San_Xuat"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxnamOptions" autocomplete="off" />
                </td>


                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row"></div>




<div class="modal-footer" style="display: flex;justify-content: space-between;padding:0px;">
  <div>
    
  </div>
  <div class="">
  </div>

</div>






<bs-modal #mdLuaChonDoRong class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thay đổi độ rộng</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChonDoRong.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Dien_Giai_Chi_Tiet" />
      </div>
      <label class="control-label col-3 ng-binding">Hàng hóa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kho hàng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đơn vị tính nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hàng hóa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Kho hang có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Đơn vị tính có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_Co" />
      </div>

    </div>

  </bs-modal-body>
 
</bs-modal>




