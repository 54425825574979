
<div class="dmphanhe tab-container">
  <div class="tab-content">
    <div class="row form-group">
      <label class="control-label col-2">Mã/Tên tài khoản</label>
      <div class="col-2">
        <input type="text" #mafocus [autofocus] tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Ma_Tai_Khoan">
      </div>
      <div class="col-2">
        <input type="text" tabIndex="2" (keydown)="keyTenPress($event)" class="form-control" placeholder="" [(ngModel)]="doituongSelect.ChiTiet.Ten_Tai_Khoan">
      </div>
      <label class="control-label col-2">Tài khoản cấp trên</label>
      <div class="col-4">
        <ng-select #slCapTren
                   class="twcol"
                   [tabIndex]="3"
                   [openOnEnter]="false"
                   [items]="doituongSelect.CapTrens"
                   bindValue="Id"
                   bindLabel="Ma_Tai_Khoan"
                   notFoundText="Không có dữ liệu"
                   [searchFn]="comboSearchFn"
                   (keydown)="keyCapTrenPress($event)"
                   [(ngModel)]="doituongSelect.ChiTiet.Parent">
          <ng-template ng-option-tmp let-item="item">

            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Tai_Khoan}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Tai_Khoan}}</div>
            </div>

          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">Loại tài khoản</label>
      <div class="col-4">
        <ng-select #slLoaiKhoan
                   class="twcol"
                   [tabIndex]="8"
                   [openOnEnter]="false"
                   [items]="inputhienthis"
                   bindValue="Id"
                   bindLabel="Ma_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   (keydown)="keyLoaiTaiKhoanPress($event)"
                   [(ngModel)]="doituongSelect.ChiTiet.Loai_So_Du">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>

          </ng-template>
        </ng-select>

      </div>
      <label class="control-label col-2">Danh mục tổng hợp</label>
      <div class="col-4">
        <ng-select #slPhanHe
                   [tabIndex]="6"
                   [openOnEnter]="false"
                   [items]="inputdanhmucs"
                   bindValue="Id"
                   bindLabel="Ten_Phan_He"
                   notFoundText="Không có dữ liệu"
                   [(ngModel)]="doituongSelect.ChiTiet.Nhom_Doi_Tuong_Id">

        </ng-select>

      </div>

    </div>

    <div class="row">
      <div class="col-6">
        <span style="font-weight:bold">Danh sách tính chất</span>
        <table id="fixTable1" class="table table-striped table-bordered table-hover table-condensed">
          <thead class="theadcss">
            <tr>
              <th *ngFor="let ut of doituongSelect.DataTieuDeTK" [ngStyle]="{'width': ut.Width}" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">{{ut.FullName}}</th>

            </tr>
            <tr>
              <td *ngFor="let ut of doituongSelect.DataTieuDeTK" [ngClass]="{'AnCot': !ut.HienThi}">
                <input *ngIf="ut.type!='bool'" class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text"
                       [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearchTK(ut.TimKiem)">
              </td>
            </tr>
          </thead>
          <tbody style="max-height:250px">

            <tr *ngFor="let u of doituongSelect.TinhChats">
              <td *ngFor="let u1 of doituongSelect.DataTieuDeTK" [ngStyle]="{'width': u1.Width}" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal'}">
                <span style="padding-right:5px;" *ngIf="u1.Type==='decimal'">{{u[u1.ColumnName] | number}}</span>
                <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
                <span *ngIf="u1.Type==='bool'"><input type="checkbox" (ngModelChange)="TichTK(u)" [(ngModel)]="u[u1.ColumnName]" /></span>
                <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <span style="font-weight:bold">Danh sách danh mục</span>
        <table id="fixTable2" class="table table-striped table-bordered table-hover table-condensed">
          <thead class="theadcss">
            <tr>
              <th *ngFor="let ut of doituongSelect.DataTieuDePH" [ngStyle]="{'width': ut.Width}" style="text-align:center" [ngClass]="{'AnCot': !ut.HienThi}">{{ut.FullName}}</th>

            </tr>
            <tr>
              <td *ngFor="let ut of doituongSelect.DataTieuDePH" [ngClass]="{'AnCot': !ut.HienThi}">
                <input class="form-control" style="width:100%;font-style:italic;font-size:11px" type="text" [disabled]="ut.Type==='bool' ? 'true': 'false'"
                       [(ngModel)]="ut.TimKiem" placeholder="Tìm kiếm" (ngModelChange)="changesearchPH(ut.TimKiem)">
              </td>
            </tr>
          </thead>
          <tbody style="max-height:250px">

            <tr *ngFor="let u of doituongSelect.PhanHes">
              <td *ngFor="let u1 of doituongSelect.DataTieuDePH" [ngStyle]="{'width': u1.Width}" [ngClass]="{'AnCot': !u1.HienThi,'col_left': u1.Type==='string','col_center': u1.Type==='datetime','col_right': u1.Type==='decimal'}">
                <span style="padding-right:5px;" *ngIf="u1.Type==='decimal'">{{u[u1.ColumnName] | number}}</span>
                <span *ngIf="u1.Type==='string'">{{u[u1.ColumnName]}}</span>
                <span *ngIf="u1.Type==='bool'"><input type="checkbox" (ngModelChange)="TichPH(u)" [(ngModel)]="u[u1.ColumnName]" /></span>
                <span style="text-align:center;padding-left:5px;" *ngIf="u1.Type==='datetime'">{{u[u1.ColumnName] | date: 'dd/MM/yyyy'}}</span>

              </td>

            </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>




  <div class="modal-footer" style="display: flex;justify-content: space-between;">
    <div class="">

    </div>
    <div class="">
      <button class="mb-1 mr-1 btn btn-secondary" tabindex="22" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top:0px !important">Hủy bỏ</button>
      <button *ngIf="!(quyen_sua==false && doituongSelect.ChiTiet.Id!=null)" tabindex="23" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top:0px !important">Lưu lại</button>
    </div>

  </div>


</div>
