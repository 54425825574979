<ul class="navbar">
  <!--<li class="nav-item">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fab fa-buromobelexperte"></i>
        Mẫu
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="dinhkhoanmau(row)" *ngFor='let row of inputdinhkhoanmaus;'>{{row.Noi_Dung}}</a>
      </div>
    </div>
  </li>
  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null) && chungtuSelect.Lien_Quan_Id!=null">
    <a class="nav-link" (click)="savectu(1)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại và cập nhật giấy rút
    </a>
  </li>-->

  <li class="nav-item active" *ngIf="chungtuSelect.Id!=null">
    <a class="nav-link" (click)="adddata_phieuxuat()">
      <i class="fa fa-plus-square fa-2x" aria-hidden="true"></i>
      {{chungtuSelect.TieuDe}}
    </a>
  </li>

  <!--<li class="nav-item active" *ngIf="chungtuSelect.Id!=null">
    <a class="nav-link" (click)="adddata_quyettoan()">
      <i class="fa fa-calculator fa-2x" aria-hidden="true"></i>
      Quyết toán
    </a>
  </li>-->

  <li class="nav-item" *ngIf="this.chungtuSelect.Id!=null">
    <div class="btn-group mb-1 mr-1 paddingtop5" dropdown>
      <a class="nav-link" dropdownToggle>
        <i class="fa-2x mr-2 fas fa-print"></i>
        In
      </a>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <a class="dropdown-item" (click)="export('pdf',6)">Yêu cầu xuất kho</a>
        <a class="dropdown-item" (click)="export('xlsx',6)">Yêu cầu xuất kho (excel)</a>
        <!--<a class="dropdown-item" (click)="exportluoi('xlsx',1)">Xuất lưới ra excel</a>-->

      </div>
    </div>
  </li>




  <li class="nav-item active" *ngIf="!(quyen_sua==false && chungtuSelect.Id!=null)">
    <a class="nav-link" (click)="savectu(0)">
      <i class="fa-2x mr-2 fas fa-save"></i>
      Lưu lại
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="closeModal()">
      <i class="fa-2x mr-2 far fa-window-close">
      </i>
      Thoát
    </a>
  </li>
</ul>
<div class="row">

  <div class="col-3">
    <div style="width:35%;float:left">Mã yêu cầu</div>
    <div style="width:65%;float:right">
      <input type="text" tabIndex="1" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Ma_Yeu_Cau">
    </div>
  </div>
  <div class="col-2">
    <div style="width:30%;float:left">Ngày lập</div>
    <div style="width:70%;float:left">
      <kendo-dateinput tabindex="2" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="chungtuSelect.Ngay_Lap"></kendo-dateinput>
    </div>

  </div>
  
</div>

<div class="row">

  <div class="col-6">
    <div class="row">
      <div class="col-12">
        <div style="width:16%;float:left">Nhân viên</div>
        <div style="width:83%;float:left">
          <a *ngIf="chungtuSelect.Nhan_Vien_Id===null" (click)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
            <input id="99" type="text" class="form-control" placeholder="Chọn..." [(ngModel)]="chungtuSelect.Nhan_Vien">
          </a>
          <a *ngIf="chungtuSelect.Nhan_Vien_Id!==null" (dblclick)="adddatachondoituongchung(2)" style="color:blue;font-weight:bold">
            <input id="99" type="text" class="form-control" placeholder="Chọn..." [(ngModel)]="chungtuSelect.Nhan_Vien" (ngModelChange)="adddatachondoituongchung(2)">
          </a>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12">
        <div style="width:16%;float:left">Phòng ban</div>
        <div style="width:83%;float:left">
          <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="chungtuSelect.Phong_Ban">
        </div>
      </div>
      </div>

    </div>

  <div class="col-6">
    <div class="row">
      <div style="width:10%;float:left;text-align:left">Lý do xuất</div>
      <div style="width:88%;float:left">
        <textarea type="text" tabIndex="9" class="form-control" placeholder="" rows="2" [(ngModel)]="chungtuSelect.Ly_Do_Xuat"></textarea>
      </div>
    </div>

  </div>

</div>


<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id=""><span></span>1.Phụ tùng, dầu mỡ, vật tư</a></li>
      <!--<li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}"><a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id=""><span></span>2.Phụ tùng, dầu mỡ, vật tư</a></li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==3}"><a class="nav-link" [ngClass]="{'active': tabselected==3}" (click)="selectedTab(3)" href="javascript:void(0);" id=""><span></span>3.Lệnh sửa chữa</a></li>-->
    </ul>
    <div>
      <input type="number" max="7" min="0" style="width:40px;height:20px;text-align:center;margin-right:3px" [(ngModel)]="fixcot" (change)="fn_fixcot()" />
      <a (click)="RowNewClick()" style="line-height:35px;margin-right:5px;color:#1e6bb8;cursor:pointer;"><em class="mr-2 far fa-plus-square"></em>Thêm dòng mới</a>
    </div>
  </div>

  <div class="tab-content">
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row" style="margin-left: -20px;margin-top: -10px;overflow:auto;background-color:#fff;">
        <div class="" style="height:calc(100vh - 250px);background:#fff;">
          <table id="fixTablechungtukhac_02" class="table tableinput table-bordered table-hover fixTable01">
            <thead>
              <tr>
                <th>#</th>
                <th width="100" style="text-align:center">Phụ tùng</th>
                <th width="100" style="text-align:center">Số lượng</th>
                <th width="100" style="text-align:center">Ghi chú</th>
                <th width="100" style="text-align:center">STT</th>

              </tr>
            </thead>
            <tbody style="max-height:calc(100vh - 260px);">
              <tr *ngFor='let row of chungtuSelect.DichVus; let i = index' style="height:30px !important">
                <td>
                  <div style="width:30px;background-color:white">
                    <a title="Xóa dòng" (click)="deleterow(i,2)" style="margin-left:5px;cursor:pointer;color:#1e6bb8 !important" class="mb-1 far fa-trash-alt ng2-smart-action ng2-smart-action-custom-custom ng-star-inserted"></a>
                  </div>
                </td>
                <td>
                  <!--Phu_Tung_Id-->
                  <a *ngIf="row.Phu_Tung_Id===null" (click)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_2" type="text"
                           (keydown)="keyComBoPress($event,i,1,2)" class="form-control"
                           style="min-width:400px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung">
                  </a>
                  <a *ngIf="row.Phu_Tung_Id!==null" (dblclick)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)" style="color:blue;font-weight:bold">
                    <input id="{{i}}_1_2" type="text"
                           (keydown)="keyComBoPress($event,i,1,2)" class="form-control"
                           style="min-width:400px"
                           placeholder="Chọn..." [(ngModel)]="row.Phu_Tung" (ngModelChange)="adddatachondoituongchung_luoi('Phu_Tung_Id',i)">
                  </a>

                </td>

                


                <td>

                  <input id="{{i}}_2_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,2,2)"
                         maxlength="20"
                         style="min-width:90px"
                         currencyMask
                         [(ngModel)]="row.So_Luong"
                         (ngModelChange)="changeSL(row)"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOption2s" autocomplete="off" />
                </td>
               
                <td>

                  <input id="{{i}}_3_2" #myinput type="text" style="min-width:300px" (keydown)="keyComBoPress($event,i,3,2)"
                         class="form-control" placeholder="" [(ngModel)]="row.Ghi_Chu">
                </td>
                <td>
                  <input id="{{i}}_4_2" #myinput class="form-control" (keydown)="keyComBoPress($event,i,4,2)"
                         maxlength="20"
                         style="min-width:90px;"
                         currencyMask
                         [(ngModel)]="row.Thu_Tu"
                         name="value"
                         [placeholder]="''"
                         [options]="ngxAmOptions" autocomplete="off" />
                </td>


                <!--kết thúc-->
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>




<div class="modal-footer" style="display: flex;justify-content: space-between;padding:0px;">
  <div>
    <span style="float:left;padding-right:15px;line-height:25px;">Kích chuột 1 lần vào chọn đối tượng, kích đúp chuột vào tên đối tượng để hiển thị danh sách chọn</span>
  </div>
  <div class="">
  </div>

</div>


<bs-modal #mdDoiTuongChung class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucchungedit #dteChung (save)="saveModal($event)"></app-dm-danhmucchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #mdDoiTuongNH class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdDoiTuongNH.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <app-dm-danhmucnganhangedit #dtenganhang (save)="saveModalNganHang($event)"></app-dm-danhmucnganhangedit>
  </bs-modal-body>
</bs-modal>


<!--<bs-modal #mdLuaChon class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Bạn muốn thêm người nộp là?</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChon.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(3)" value="option1" />
        <span class="fa fa-circle"></span>Khách hàng
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(1)" value="option1" />
        <span class="fa fa-circle"></span>Nhà cung cấp
      </label>
    </div>
    <div class="c-radio">
      <label>
        <input type="radio" name="a" (change)="onLoaiNguoiNop(2)" value="option2" checked="" />
        <span class="fa fa-circle"></span>Cán bộ
      </label>
    </div>
  </bs-modal-body>
  <bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">

      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChon.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormNguoiNop()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>

    </div>
  </bs-modal-footer>
</bs-modal>-->

<bs-modal #mdLuaChonDoRong class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thay đổi độ rộng</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdLuaChonDoRong.close()">
      <span aria-hidden="true">×</span>
    </button><!---->
  </div>
  <bs-modal-body>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Diễn giải</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Dien_Giai_Chi_Tiet" />
      </div>
      <label class="control-label col-3 ng-binding">Hàng hóa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Kho hàng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đơn vị tính nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hàng hóa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hang_Hoa_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Kho hang có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Kho_Hang_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Đơn vị tính có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Don_Vi_Tinh_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_No" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_No" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị nợ</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_No" />
      </div>
      <label class="control-label col-3 ng-binding">Đối tượng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Hợp đồng có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Hop_Dong_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Tài sản có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Tai_San_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Lệnh sửa chữa có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Lenh_Sua_Chua_Id_Co" />
      </div>
      <label class="control-label col-3 ng-binding">Khoản mục có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Khoan_Muc_Id_Co" />
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-3 ng-binding">Mã đơn vị có</label>
      <div class="col-3">
        <input type="number" max="900" min="200" style="width:100%;height:20px;text-align:center;margin-right:3px" [(ngModel)]="DoRongs.Doi_Tuong_Id_1_Co" />
      </div>

    </div>

  </bs-modal-body>
  <!--<bs-modal-footer>
    <div style="display: flex;justify-content: space-between;">
      <div class="">
        <button class="mb-1 mr-1 btn btn-secondary" (click)="mdLuaChonDoRong.dismiss()" title="Ấn ESC để thoát">Hủy bỏ</button>
        <button type="submit" (click)="AddFormDoRong()" title="" class="mb-1 mr-1 btn btn-primary">Đồng ý</button>
      </div>
    </div>
  </bs-modal-footer>-->
</bs-modal>

<!--<bs-modal #mdCanBo class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdCanBo.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmucnhanvienedit #dteCanBo (save)="saveModalCanBo($event)"></app-dm-danhmucnhanvienedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdKhachHang class="modal fade show in" [cssClass]="'modal600'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdKhachHang.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuckhachhangedit #dteKhachHang (save)="saveModalKhacHang($event)"></app-dm-danhmuckhachhangedit>
  </bs-modal-body>
</bs-modal>

<bs-modal #mdTaiSan class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdTaiSan.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-dm-danhmuctaisanedit #dtetaisan (save)="saveModalTaiSan($event)"></app-dm-danhmuctaisanedit>
  </bs-modal-body>
</bs-modal>-->


<bs-modal #mdChonDoiTuongChung class="modal fade show in" [cssClass]="'modal800'" [backdrop]="'static'">
  <!--<div class="modal-header">
    <h4 class="modal-title">Thêm mới</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="mdChonDoiTuongChung.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>-->
  <bs-modal-body>
    <app-tonghop-chondoituongchungedit #dtechondoituongchungedit (savect)="savedsdoituongchung($event)"></app-tonghop-chondoituongchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #modalphieuxuat class="modal fade show in" [cssClass]="'modalsanpham'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
  </div>
  <bs-modal-body>
    <app-ketoan-chungtuchungedit #ctphieuxuatedit (savechungtu)="savept($event)"></app-ketoan-chungtuchungedit>
  </bs-modal-body>
</bs-modal>


<bs-modal #modalquyettoan class="modal fade show in" [cssClass]="'modalsanpham'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
  </div>
  <bs-modal-body>
    <app-ketoan-quyettoanedit #ctquyettoantedit (savechungtu)="savept($event)"></app-ketoan-quyettoanedit>
  </bs-modal-body>
</bs-modal>
