<div class="header-dangky" style="margin-left: -5px; padding: 0px; margin-right: -5px;">  
  <ul class="navbar" style="padding: 0px;">
    <li class="nav-item active">
      <a class="nav-link" (click)="savectu()">
        <i class="mr-2 fas fa-save"></i>
        Chọn
      </a>
    </li>
    
  </ul>
</div>





<!-- lưới chi tiết -->
<div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 210px)">
  <div class="row">
   
    <table class="table table-striped table-bordered table-hover">
      <thead [ngClass]="{'theadcss': totalItems>16}">
        <tr>
          <th style="width:50px;text-align:center">Chọn</th>
          <th style="width:120px;text-align:center">Mã học viên</th>
          <th style="width:200px;text-align:center">Họ và Tên</th>
          <th style="width:110px;text-align:center">Ngày sinh</th>          
          <th style="width:110px;text-align:center">UserName</th>         
          <th style="text-align:center">Đơn vị </th>

        </tr>

        <tr class="filter-row">
          <td style="text-align:center">
            <input type="checkbox" [(ngModel)]="TatCa" (click)="TichTatCa()" />
          </td>

          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.MaNV" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.HoVaTen" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
          <td>
            
          </td>
         
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:32px !important" type="text"
                   [(ngModel)]="TimKiems.SoCMND" placeholder="Tìm kiếm" (ngModelChange)="search()">
          </td>
         
         <td></td>
        </tr>

      </thead>
      <tbody style="min-height:330px">
        <tr *ngFor="let u of datas">
          <td style="text-align:center;width:50px"><input type="checkbox" [(ngModel)]="u.Tich" /></td>
          <td style="width:120px"><a (click)="editdata(u.Id)">{{u.MaNV}}</a></td>
          <td style="width:200px"><a (click)="editdata(u.Id)">{{u.HoVaTen}}</a></td>
          <td style="text-align:center;width:110px"><a (click)="editdata(u.Id)">{{u.Ngay | date: 'dd/MM/yyyy'}}</a></td>
          
          <td style="width:110px">{{u.SoCMND}}</td>
         
          <td style="">{{u.DonVi}}</td>
       
        </tr>
      </tbody>
    </table>
  </div>
  
</div>





<div class="modal-footer" style="display: flex;justify-content: space-between;margin-top:5px;">
  <div>
    <div *ngIf="totalItems>=10">
      <ul class="pagination">
        <li [ngClass]="{disabled: currentPage === 1}">
          <a (click)="setPage(1)"><<</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(currentPage + 1)">></a>
        </li>
        <li *ngFor="let page of pages" [ngClass]="{active: currentPage === page.Id}">
          <a (click)="setPage(page.Id)">{{page.Id}}</a>
        </li>
        <li [ngClass]="{disabled:currentPage === 1}">
          <a (click)="setPage(currentPage - 1)"><</a>
        </li>
        <li [ngClass]="{disabled:currentPage === totalPages}">
          <a (click)="setPage(totalPages)">>></a>
        </li>
        <li>
          <input type="number" style="width:50px;height:30px;text-align:center" [(ngModel)]="pageSize" (blur)="search1()" />
        </li>
        <li><span style="font-weight:bold">Tổng số: {{totalItems | number}}</span></li>
      </ul>
    </div>
  </div>
  <div class="">

  </div>

</div>


