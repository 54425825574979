import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { LocalDataSource } from 'ng2-smart-table';
import { NumberViewComponent } from '../../../shared/components/public/numberview.component';
import { DateViewComponent } from '../../../shared/components/public/dateview.component';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';
import {
  Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
  , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
  , Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
  , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
  , Lists_khachhang, Lists_nhanvien
  , Lists_congcu, Lists_taisancodinh, Lists_chiphi
  , Lists_tiente, Lists_xuatxu, Lists_tinh, Lists_mauson, Lists_giaodich
  , Lists_keuoc, Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
  , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
  , Lists_loaisuachua, Lists_dichvu, Lists_xesua, Lists_nganhang, DungChungs

} from '../../../routes/menu';
const swal = require('sweetalert');


@Component({
    selector: 'app-tonghop-chondoituongchungedit',
  templateUrl: './chondoituongchungedit.component.html',
    //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./chondoituongchungedit.component.scss'],
    providers: [ListService, Repository]
})


export class chondoituongchungeditComponent implements OnInit {

  @ViewChild('ejskhoahoc') public ejskhoahoc: DialogComponent;


  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('slKhachHang') slKhachHang: BsModalComponent;
  @ViewChild('slTaiKhoanNo') slTaiKhoanNo: BsModalComponent;
  
  
  
  @ViewChild('mdLuaChon_doituong') mdLuaChon_doituong: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;
  @ViewChild('dteChung') doituongchungedit: any;
  @ViewChild('modal_dmdichvu') modal_dmdichvu: BsModalComponent;
  @ViewChild('dte_dmdichvu') dte_dmdichvu: any;
  @ViewChild('modal_dmhopdong') modal_dmhopdong: BsModalComponent;
  @ViewChild('dte_dmhopdong') dte_dmhopdong: any;
  @ViewChild('mdKhachHang') mdKhachhang: BsModalComponent;
  @ViewChild('dteKhachHang') khachhangedit: any;
  @ViewChild('mdDoiTuongNH') mdDoiTuongNH: BsModalComponent;
  @ViewChild('dtenganhang') nganhangedit: any;
  @ViewChild('mdDoiTuongNhaCC') mdDoiTuongNhaCC: BsModalComponent;
  @ViewChild('dtenhacungcap') dtenhacungcap: any;
  @ViewChild('mdCanBo') mdCanBo: BsModalComponent;
  @ViewChild('dteCanBo') canboedit: any;
  @ViewChild('mdTaiSan') mdTaiSan: BsModalComponent;
  @ViewChild('dtetaisan') taisanedit: any;
  @ViewChild('mdVattu') mdVattu: BsModalComponent;
  @ViewChild('dtevattuedit') dtevattuedit: any;
  @ViewChild('md_dmxe') md_dmxe: BsModalComponent;
  @ViewChild('dte_dmxeedit') dte_dmxeedit: any;
  @ViewChild('md_dmxesua') md_dmxesua: BsModalComponent;
  @ViewChild('dte_dmxesuaedit') dte_dmxesuaedit: any;

  doituongkethua: any;
  doanhnghiepid: string = null;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  datas: any;
  data: any;
  public dulieu: any = { dataGrid: null, dataGridTimKiem: null, datatieude: null, sobanghi: null };
  indLoading: boolean = false;
  dataFrm: FormGroup;
  modalTitle: string;
  modalBtnTitle: string;
  Id: string;
  NgayHienTai: Date = new Date();
  inputDonVi: any;
  public DonViDropId: any = { Id: null, Ten: null };
  public TaiKhoanDropId: any = { Id: null, Ten: null };
  TaiKhoanId: any;
  selecteddonvis: any;
  selectedchuongs: any;
  selectedkhoans: any;
  Ma_Tai_Khoan: any;
  dropdownList_DonVi = [];
  inputTaiKhoan: any;
  dropdownList_TaiKhoan = [];
  selectedItems = [];
  dropdownSettings = {};
  khoangthoigian: number = 0;
  tabselected: number = 1;
  inputthangs: any = [
    { text: "Hôm nay", value: 0 },
    { text: "Tháng 1", value: 1 },
    { text: "Tháng 2", value: 2 },
    { text: "Tháng 3", value: 3 },
    { text: "Tháng 4", value: 4 },
    { text: "Tháng 5", value: 5 },
    { text: "Tháng 6", value: 6 },
    { text: "Tháng 7", value: 7 },
    { text: "Tháng 8", value: 8 },
    { text: "Tháng 9", value: 9 },
    { text: "Tháng 10", value: 10 },
    { text: "Tháng 11", value: 11 },
    { text: "Tháng 12", value: 12 },
    { text: "Quý I", value: 13 },
    { text: "Quý II", value: 14 },
    { text: "Quý III", value: 15 },
    { text: "Quý IV", value: 16 },
    { text: "Tuần này", value: 17 },
    { text: "Tháng này", value: 18 },
    { text: "Năm nay", value: 19 },
    { text: "Đầu năm đến nay", value: 20 },
    { text: "Năm trước", value: 21 },

  ];

  public thangs: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 }
  ];

  public quys: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },

  ];

  public nams: Array<{ text: string, value: number }> = [
    { text: (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    { text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    { text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];

  public chons: Array<{ text: string, value: number }> = [
    { text: "Theo ngày", value: 1 },
    { text: "Theo tháng", value: 2 },
    { text: "Theo quý", value: 3 },
    { text: "Theo năm", value: 4 },

  ];
  chongioitinhs: any = [{ Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Nữ" }
    , { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "Nam" }];
  TatCa: boolean = false;
  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number = 5;
  public TimKiem: string;
  public StartPage: number = 1;
  public EndPage: number = 20;
  public pageSize: number = 20;
  public pages: any;
  public totalPages: number;
  public TimKiems: any = {
    Don_Vi_Id: null
    , Doanh_Nghiep_Id: null
    , UserName: null
    , Nam: null
    , TuNgay: null
    , DenNgay: null
    , Phan_He_Id: null
    , TimKiem: null
    , Loai: null
    , startPage: null
    , endPage: null
    , Ngay: null
    , NgayS: null
    , Ma: null
    , Ten: null
    , Cha: null
    , GhiChu: null
    , NhomDoiTuong: null
    , SapXep: null
    , Modified: null
    , ModifiedS: null
    , ModifiedBy: null
    ,BaoCaoId: null
  };
  public ngaytimkiem: Date = new Date();
  TichTatCa() {
    this.datas.forEach((item: any) => {
      item.Tich = !this.TatCa;
    });
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  public TuNgay: Date = new Date();
  public DenNgay: Date = new Date();
  public TuNgay1: Date = new Date();
  public DenNgay1: Date = new Date();
  public tTuNgay: string;
  public tDenNgay: string;

 

 
  idFocus: string = '00000000-0000-0000-0000-000000000000';

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: true
  };

  public currentUser;
  donviid: string = null;
  namketoan: number;
  username: string = null;
  phanheid: any;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository) {



  }

  // @HostListener('document:keydown', ['$event'])
  public innerHeight: any
  quyen_them: boolean = false;
  loadDataInit(id: string, pheid: string) {
    this.TimKiems.BaoCaoId = id;
    this.ngaytimkiem = null;
    this.StartPage = 1;
    this.EndPage = 20;
    this.ngaytimkiem = null;
    this.innerHeight = window.innerHeight - 250;
    this.phanheid = pheid;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.donviid = this.currentUser.id_don_vi;
      this.namketoan = this.currentUser.nam_ke_toan;
      this.username = this.currentUser.user_name;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;
       
      }
     
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
    }
    this.search();
        
    
  }

  


  editdata(id: string) {
    console.log(id);
    this.modalTitle = "cập nhập thông tin hàng hóa thu giữ";
    this.modalBtnTitle = "Cập nhật";
    //this.doituongchungedit.loadDataInit(this.phanheid, id, this.donviid);
    //this.modal.open();
  }
  //saveModal(id: any) {
  //  this.Loaddatas();
  //  this.modal.close();
  //}

  
  pageChanged(event: any): void {
    // alert(event.page);
    //console.log('a');
    //console.log(event);
    this.currentPage = event.page;
    this.Loaddatas();
  }
  setPage(page: number): void {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    this.Loaddatas();
  }
  public onBlur(): void {
    this.Loaddatas();
  }
  search() {
    this.currentPage = 1;
    this.pageSize = 50;
    this.Loaddatas();
  }
  search1() {
    this.Loaddatas();
  }
  Loaddatas(): void {
    
    this.datas = [];
    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.Don_Vi_Id = this.donviid;
    this.TimKiems.Doanh_Nghiep_Id = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.Phan_He_Id = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.TimKiems.startPage = this.StartPage;
    this.TimKiems.endPage = this.EndPage;
    if (this.ngaytimkiem != null) {
      this.TimKiems.ModifiedS = this.ngaytimkiem.getFullYear() + '-' + (this.ngaytimkiem.getMonth() + 1) + '-' + this.ngaytimkiem.getDate();
    }
    else
      this.TimKiems.ModifiedS = null;


    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    $('#loading').show();
    this.repo.postRequest("api/doituong/get_doituonglistview/", this.TimKiems)
      .subscribe(response => {
        $('#loading').hide();
        this.totalPages = 0;
        this.totalItems = 0;
        if (response.length > 0) {

          var item = response[0];
          this.totalItems = item["totalRows"];
          this.totalPages = Math.ceil(this.totalItems / this.pageSize);

          let start, end;

          if (this.totalPages <= 5) {
            start = 1;
            end = this.totalPages;
          } else {
            if (this.currentPage <= 3) {
              start = 1;
              end = 5;
            } else if (this.currentPage + 1 >= this.totalPages) {
              start = this.totalPages - 4;
              end = this.totalPages;
            } else {
              start = this.currentPage - 2;
              end = this.currentPage + 2;
            }
          }

          let resultArray: Array<any> = [];
          for (var i = start; i <= end; i++) {
            resultArray.push({ Id: i, Ten: null });
          }
          this.pages = resultArray;
        }
        this.datas = response;



        //(window as any).timer(3, 1500);

      });



  }

  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }
    this.LoadCategory();
  }

  LoadCategory(): void {

  }
  //----------event xử lý di chuyển Enter--------------------------------------
  tongtien: number = 0;
  maxcot: number = 13;

  onDoiTuongChange($event) {

    //if (typeof $event === "undefined") {
    //  this.doituongSelect.Ho_Ten = null;
    //}
    //else {
    //  this.doituongSelect.Ho_Ten = $event.Ten_Doi_Tuong;

    //  this.doituongSelect.ChiTiets.forEach(
    //    (item) => {
    //      if (item.Doi_Tuong_Id2 === null)
    //        item.Doi_Tuong_Id2 = $event.Id;
    //    }
    //  );

    //  this.repo.getRequest("api/baselayout/ThongTinDiaChi/" + $event.Id)
    //    .subscribe(response => {
    //      //console.log(response);
    //      this.doituongSelect.Dia_Chi = response.Dia_Chi;

    //    });
    //}
  }

  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;

  public save_chondoituong2(item: any) {
    let tdoituong = [];
    tdoituong.push(item);
    this.savect.emit(tdoituong);
  }
  public save_chondoituong() {  
    let tdoituong = [];
    this.savect.emit(tdoituong);

    //this.datas.forEach(
    //  (item) => {
    //    if (item.Tich === true)
    //      tdoituong.push(item);
    //  }
    //);
    //if (tdoituong != null && tdoituong.length > 0)
    //  this.savect.emit(tdoituong);
    //else
    //  this.savect.emit(null);

  }
  closeModal_chondoituong() {
    let tdoituong =null;
    this.savect.emit(tdoituong);
  }
  nhomdoituongid: string;
  onLoaiNguoiNop(row: any) {
    this.nhomdoituongid = row.Id;
    //console.log(loai);
  }

  mBackdrop: any;
  haveBackdrop() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').css("z-index", -1)
      clearTimeout(this.mBackdrop);
      return true;
    }

    return false;
  }

  adddata() {

    //this.modalTitle = "Thêm mới đối tượng";
    //this.modalBtnTitle = "Thêm";
    

    //this.doituongchungedit.loadDataInit(this.phanheid, null, this.donviid,null);
    //this.modal.open();
    this.nhomdoituongid = null;
    this.repo.postRequest("api/doituong/doituongkethua/", { NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid })
      .subscribe(response => {
        this.doituongkethua = response;
        if (this.doituongkethua != null && this.doituongkethua.length > 0) {
          if (this.doituongkethua.length > 1)
            this.ejskhoahoc.show();
          else {
            this.nhomdoituongid = this.doituongkethua[0].Id;

            DungChungs.submenu.forEach(
              (div) => {
                if (div.id.toLowerCase() === this.nhomdoituongid.toLowerCase()) {
                  this.modalTitle = "Thêm mới " + div.text.toLowerCase();
                }
              }
            );

            if (this.nhomdoituongid.toLowerCase() === Lists_donvitinh.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_loaisuachua.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_khohang.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_phongban.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_nghenghiep.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_nhomkhachhang.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_linhvuc.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_loaiquanhe.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_loaitaisan.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_khoanmuc.id.toLowerCase()
              //|| this.nhomdoituongid.toLowerCase() === Lists_nguonvon.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_madonvi.id.toLowerCase()
            ) {
              this.doituongchungedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdDoiTuongChung.open();
            }

            if (this.nhomdoituongid.toLowerCase() === Lists_dichvu.id.toLowerCase()) {
              this.dte_dmdichvu.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.modal_dmdichvu.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_hopdongmua.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_hopdongban.id.toLowerCase()
            ) {
              this.dte_dmhopdong.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.modal_dmhopdong.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_khachhang.id.toLowerCase()) {
              this.khachhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdKhachhang.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_nhanvien.id.toLowerCase()) {
              this.canboedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdCanBo.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_nhacungcap.id.toLowerCase()) {
              this.dtenhacungcap.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdDoiTuongNhaCC.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
              this.nganhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdDoiTuongNH.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_taisancodinh.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_chiphi.id.toLowerCase()
              || this.nhomdoituongid.toLowerCase() === Lists_congcu.id.toLowerCase()
            ) {
              this.taisanedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdTaiSan.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_vattu.id.toLowerCase()) {
              this.dtevattuedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.mdVattu.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_xe.id.toLowerCase()) {
              this.dte_dmxeedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.md_dmxe.open();
            }
            if (this.nhomdoituongid.toLowerCase() === Lists_xesua.id.toLowerCase()) {
              this.dte_dmxesuaedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
              this.mBackdrop = setTimeout(() => {
                this.haveBackdrop();
              }, 500);
              this.md_dmxesua.open();
            }

          }
        }
        else {
          DungChungs.submenu.forEach(
            (div) => {
              if (div.id.toLowerCase() === this.phanheid.toLowerCase()) {
                this.modalTitle = "Thêm mới "  + div.text.toLowerCase();
              }
            }
          );

          }
      });

   
  }
  public dlgBtnClick = (): void => {
    this.ejskhoahoc.hide();
  }
  public dlgChonClick = (): void => {
    this.AddFormNguoiNop();
  }
  public dlgButtons: ButtonPropsModel[] = [
    { click: this.dlgChonClick.bind(this), buttonModel: { content: 'Đồng ý', isPrimary: true } },
    { click: this.dlgBtnClick.bind(this), buttonModel: { content: 'Đóng', isPrimary: true } }
  ];

  AddNguoiNop() {
    //cobo: NgSelectComponent
    //this.comboSeleted = cobo;
    //cobo.close();
    this.nhomdoituongid = null;
    this.repo.postRequest("api/doituong/doituongkethua/", { NhomDoiTuongId: this.phanheid, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          this.doituongkethua = response;
          if (this.doituongkethua != null && this.doituongkethua.length > 0) {
            if (this.doituongkethua.length > 1)
              this.mdLuaChon_doituong.open();
            else {
              this.nhomdoituongid = this.doituongkethua[0].Id;

              if (this.nhomdoituongid.toLowerCase() === Lists_donvitinh.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_loaisuachua.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_khohang.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_phongban.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_nghenghiep.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_nhomkhachhang.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_linhvuc.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_loaiquanhe.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_loaitaisan.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_khoanmuc.id.toLowerCase()
                //|| this.nhomdoituongid.toLowerCase() === Lists_nguonvon.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_madonvi.id.toLowerCase()
              ) {
                this.doituongchungedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdDoiTuongChung.open();
              }

              if (this.nhomdoituongid.toLowerCase() === Lists_dichvu.id.toLowerCase()) {
                this.dte_dmdichvu.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.modal_dmdichvu.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_hopdongmua.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_hopdongban.id.toLowerCase()
              ) {
                this.dte_dmhopdong.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.modal_dmhopdong.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_khachhang.id.toLowerCase()) {
                this.khachhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdKhachhang.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_nhanvien.id.toLowerCase()) {
                this.canboedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdCanBo.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_nhacungcap.id.toLowerCase()) {
                this.dtenhacungcap.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdDoiTuongNhaCC.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
                this.nganhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdDoiTuongNH.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_taisancodinh.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_chiphi.id.toLowerCase()
                || this.nhomdoituongid.toLowerCase() === Lists_congcu.id.toLowerCase()
              ) {
                this.taisanedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mdTaiSan.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_vattu.id.toLowerCase()) {
                this.dtevattuedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.mdVattu.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_xe.id.toLowerCase()) {
                this.dte_dmxeedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.md_dmxe.open();
              }
              if (this.nhomdoituongid.toLowerCase() === Lists_xesua.id.toLowerCase()) {
                this.dte_dmxesuaedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
                this.mBackdrop = setTimeout(() => {
                  this.haveBackdrop();
                }, 500);
                this.md_dmxesua.open();
              }

            }
          }
        });
    

   // this.mdLuaChon_doituong.open();
  }
  AddFormNguoiNop() {
    if (this.nhomdoituongid == null) {
      swal('Cảnh báo!', "Bạn chưa chọn đối tượng để thêm mới", 'warning');
      return;
    }
    //this.mdLuaChon_doituong.close();
    this.ejskhoahoc.hide();

    DungChungs.submenu.forEach(
      (div) => {
        if (div.id.toLowerCase() === this.nhomdoituongid.toLowerCase()) {
          this.modalTitle = "Thêm mới " + div.text.toLowerCase();
        }
      } 
    );


    if (this.nhomdoituongid.toLowerCase() === Lists_donvitinh.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_loaisuachua.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_khohang.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_phongban.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nghenghiep.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nhomkhachhang.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_linhvuc.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_loaiquanhe.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_loaitaisan.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_khoanmuc.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nguonvon.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_madonvi.id.toLowerCase()
       ) {
      this.doituongchungedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdDoiTuongChung.open();
    }

    if (this.nhomdoituongid.toLowerCase() === Lists_dichvu.id.toLowerCase()) {
      this.dte_dmdichvu.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.modal_dmdichvu.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_hopdongmua.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_hopdongban.id.toLowerCase()
    ) {
      this.dte_dmhopdong.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.modal_dmhopdong.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_khachhang.id.toLowerCase()) {
      this.khachhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdKhachhang.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nhanvien.id.toLowerCase()) {
      this.canboedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdCanBo.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nhacungcap.id.toLowerCase()) {
      this.dtenhacungcap.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdDoiTuongNhaCC.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
      this.nganhangedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdDoiTuongNH.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_taisancodinh.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_chiphi.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_congcu.id.toLowerCase()
     ) {
      this.taisanedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdTaiSan.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_vattu.id.toLowerCase()) {
      this.dtevattuedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.mdVattu.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_xe.id.toLowerCase()) {
      this.dte_dmxeedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.md_dmxe.open();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_xesua.id.toLowerCase()) {
      this.dte_dmxesuaedit.loadDataInit(this.nhomdoituongid, null, this.donviid, null);
      this.md_dmxesua.open();
    }
    
  }

  //------------------------------------
  saveModalTaiSan(doituong: any) {
    this.mdTaiSan.close();
    if (doituong != null) {
      //console.log(doituong);
      this.search();
    }
  }

  saveModalKhacHang(doituong: any) {
    this.mdKhachhang.close();
    this.search();
  }
  saveModalCanBo(doituong: any) {
    this.mdCanBo.close();
    this.search();
    
  }
  saveModalNganHang(doituong: any) {
    this.mdDoiTuongNH.close();
    this.search();
  }

  saveModal(doituong: any) {
    
    if (this.nhomdoituongid.toLowerCase() === Lists_donvitinh.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_loaisuachua.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_khohang.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_phongban.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nghenghiep.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nhomkhachhang.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_linhvuc.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_loaiquanhe.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_loaitaisan.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_khoanmuc.id.toLowerCase()
      //|| this.nhomdoituongid.toLowerCase() === Lists_nguonvon.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_madonvi.id.toLowerCase()
    ) {
      this.mdDoiTuongChung.close();
    }

    if (this.nhomdoituongid.toLowerCase() === Lists_dichvu.id.toLowerCase()) {
      this.modal_dmdichvu.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_hopdongmua.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_hopdongban.id.toLowerCase()
    ) {
      this.modal_dmhopdong.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_khachhang.id.toLowerCase()) {
      this.mdKhachhang.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nhanvien.id.toLowerCase()) {
      this.mdCanBo.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nhacungcap.id.toLowerCase()) {
      this.mdDoiTuongNhaCC.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_nganhang.id.toLowerCase()) {
      this.mdDoiTuongNH.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_taisancodinh.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_chiphi.id.toLowerCase()
      || this.nhomdoituongid.toLowerCase() === Lists_congcu.id.toLowerCase()
    ) {
      this.mdTaiSan.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_vattu.id.toLowerCase()) {
      this.mdVattu.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_xe.id.toLowerCase()) {
      this.md_dmxe.close();
    }
    if (this.nhomdoituongid.toLowerCase() === Lists_xesua.id.toLowerCase()) {
      this.md_dmxesua.close();
    }

    if (doituong != null)
    this.search();
  }

  

}
