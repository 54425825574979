<div class="tab-container">
  <div style="display: flex;justify-content: space-between;">
    <ul class="nav nav-tabs" ng-reflect-klass="nav" ng-reflect-ng-class="[object Object]" style="width:500px;">
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==1}">
        <a class="nav-link" [ngClass]="{'active': tabselected==1}" (click)="selectedTab(1)" href="javascript:void(0);" id="">1.Thông tin chung</a>
      </li>
      <li class="nav-item ng-star-inserted" [ngClass]="{'active': tabselected==2}">
        <a class="nav-link" [ngClass]="{'active': tabselected==2}" (click)="selectedTab(2)" href="javascript:void(0);" id="">2.Người ký</a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div class=" tab-pane" [ngClass]="{'active': tabselected==1}">
      <div class="row"><span style="font-weight:bold">Đơn vị</span></div>
      <div class="row form-group">
        <label class="control-label col-3">Mã đơn vị</label>
        <div class="col-9">
          <input type="text"  tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_Don_Vi">
        </div>
        <!--<div class="col-2">
      <input class="form-control"
             maxlength="20"
             currencyMask
             [(ngModel)]="doituongSelect.Thu_Tu"
             name="value"
             placeholder="Thứ tự"
             [options]="ngxCurrencyOptions"
             style="width:100%" />
    </div>-->
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Tên đơn vị</label>
        <div class="col-9">
          <input type="text" tabIndex="2" (keydown)="keyTenPress($event)" class="form-control" placeholder="Tên đơn vị" [(ngModel)]="doituongSelect.Ten_Don_Vi">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Địa chỉ</label>
        <div class="col-9">
          <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dia_Chi">
        </div>
      </div>

      <div class="row form-group">
        <label class="control-label col-3">Mã số thuế</label>
        <div class="col-9">
          <input type="text" tabIndex="8" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ma_So_Thue">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Điện thoại</label>
        <div class="col-9">
          <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Dien_Thoai">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Fax</label>
        <div class="col-9">
          <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Fax">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Mô tả</label>
        <div class="col-9">
          <input type="text" tabIndex="11" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Mo_Ta">
        </div>
      </div>


    </div>
    <div class="tab-pane" [ngClass]="{'active': tabselected==2}">
      <div class="row form-group">
        <label class="control-label col-3">Thủ trưởng đơn vị</label>
        <div class="col-9">
          <input type="text" tabIndex="1" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_ThuTruongDonVi">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Kế toán trưởng</label>
        <div class="col-9">
          <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_KeToanTruong">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Kế toán viên</label>
        <div class="col-9">
          <input type="text" tabIndex="3" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_KeToanVien">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Thủ quỹ</label>
        <div class="col-9">
          <input type="text" tabIndex="4" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_ThuQuy">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Thủ kho</label>
        <div class="col-9">
          <input type="text" tabIndex="5" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_ThuKho">
        </div>
      </div>
      <div class="row form-group">
        <label class="control-label col-3">Người lập báo cáo</label>
        <div class="col-9">
          <input type="text" tabIndex="6" class="form-control" placeholder="" [(ngModel)]="doituongSelect.Ky_NguoiLapBaoCao">
        </div>
      </div>
    </div>
  </div>
</div>






<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="">

  </div>
  <div class="">
    <button class="mb-1 mr-1 btn btn-secondary" tabindex="22" (click)="closeModal()" title="Ấn ESC để thoát" style="padding-top: 0px !important;">Hủy bỏ</button>
    <button *ngIf="!(quyen_sua==false && doituongSelect.Id!=null)" tabindex="23" type="submit" (click)="saveproduct()" title="Ấn F9 để lưu" class="mb-1 mr-1 btn btn-primary" style="padding-top: 0px !important;">Lưu lại</button>
  </div>

</div>

<!--<modal #mdDoiTuongChung [cssClass]="'modal6'" [backdrop]="'static'">
  <modal-header [show-close]="true">
    <h4 class="modal-title">Thêm mới</h4>
  </modal-header>
  <modal-body>
    <app-dm-nganhangedit #dteChung (save)="saveModal($event)"></app-dm-nganhangedit>

  </modal-body>
</modal>-->
