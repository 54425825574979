

<div class="row">
  <div class="col-6">
    <div class="row">
      <div class="col-8">
        <div style="width:20%;float:left">Từ ngày</div>
        <div style="width:30%;float:left">
          <kendo-dateinput tabindex="1" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="TimKiems.TuNgay"></kendo-dateinput>
        </div>
        <div style="width:20%;float:left">Đến ngày</div>
        <div style="width:30%;float:left">
          <kendo-dateinput tabindex="1" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="TimKiems.DenNgay"></kendo-dateinput>
        </div>
      </div>
      <div class="col-4">
        <label>
          <input tabIndex="52" type="checkbox" [(ngModel)]="TimKiems.XoaCT" />
          Xóa chứng từ cũ
        </label>
      </div>
    </div>


  </div>
  <div class="col-6">
    <ul class="navbar">

      <li class="nav-item">
        <button class="btn btn-primary" (click)="adddata()" style="margin-left:3px; margin-right:3px">
          <i class="fas fa-edit"></i>
          Thêm cấu hình
        </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-success" (click)="savebg()" style="margin-left:3px; margin-right:3px">Kết chuyển</button>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="closeModal_chondoituong()">
          <i class="fa-2x mr-2 fa fa-window-close">
          </i>
          Thoát
        </a>
      </li>
    </ul>
  </div>
</div>



<!-- lưới chi tiết -->
<div style="padding: 2px 10px 10px 10px;color:black !important;height: calc(100vh - 150px)">
  <div class="row">
    <table class="table table-striped table-bordered table-hover table-condensed">
      <thead [ngClass]="{'theadcss': totalItems>16}">
        <!--<thead>-->
        <tr>

          <th style="width:120px;text-align:center;background-color:#f3f8fc">Từ tài khoản</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Đến tài khoản</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Kiểu kết chuyển</th>
          <th style="width:200px;text-align:center;background-color:#f3f8fc">Diễn giải</th>
          <th style="width:110px;text-align:center;background-color:#f3f8fc">STT</th>
          <th style="width:120px;text-align:center;background-color:#f3f8fc">Hoạt động</th>
          <th style="width:100px;text-align:center;background-color:#f3f8fc"></th>

        </tr>

        <!--<tr class="filter-row">

          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ma" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Ten" placeholder="Gõ ký tự và ấn enter" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.Cha" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.GhiChu" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>
          <td>
            <input class="form-control" style="width:100%;font-style:italic;font-size:11px;height:25px !important" type="text"
                   [(ngModel)]="TimKiems.NhomDoiTuong" placeholder="Tìm kiếm" (keydown)=onKeydownEvent($event)>
          </td>


        </tr>-->

      </thead>
      <tbody style="height:calc(100vh - 210px);">
        <tr *ngFor="let u of datas">
          <td style="width:120px;text-align:center"><a (click)="editdata(u.Id)">{{u.Tai_Khoan_Di}}</a></td>
          <td style="width:120px;text-align:center"><a (click)="editdata(u.Id)">{{u.Tai_Khoan_Den}}</a></td>
          <td style="width:120px;text-align:left"><a (click)="editdata(u.Id)">{{u.Kieu_Ket_Chuyen}}</a></td>
          <td style="width:200px;text-align:left"><a (click)="editdata(u.Id)">{{u.Dien_Giai}}</a></td>
          <td style="width:110px;text-align:center"><a (click)="editdata(u.Id)">{{u.Thu_Tu}}</a></td>
          <td style="width:120px;text-align:left"><a (click)="editdata(u.Id)">{{u.Trang_Thai}}</a></td>
          <td style="width:100px;text-align:left">
            <div style="float:left;padding-left:1px">
              <a class="ng-binding" (click)="deletedata(u.Id)" style="color:#1e6bb8 !important;font-weight:normal">
                <i class="mb-1 far fa-trash-alt"></i>
                Xóa cấu hình
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>


  </div>

</div>




<bs-modal #modalchitietketchuyen class="modal fade show in" [cssClass]="'modal6'" [backdrop]="'static'" [keyboard]="true">
  <div class="modal-header">
    <h4 class="modal-title">Chi tiết cấu hình kết chuyển</h4>
    <button type="button" aria-label="Dismiss" class="close" (click)="modalchitietketchuyen.close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <bs-modal-body>
    <app-ketoan-chitietketchuyenedit #dtchitietketchuyenedit (save)="saveModal($event)"></app-ketoan-chitietketchuyenedit>
  </bs-modal-body>
</bs-modal>




