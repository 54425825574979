import { Component,ViewChild, OnInit,Output, Input,HostListener,EventEmitter,ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';

import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

import 'jquery'
import 'bootstrap'

import { retry } from 'rxjs-compat/operator/retry';
import { FileManager } from '@syncfusion/ej2-filemanager';
import { FileManagerModule, NavigationPaneService, ToolbarService, DetailsViewService } from '@syncfusion/ej2-angular-filemanager';
const swal = require('sweetalert');
import { loadCldr, L10n } from '@syncfusion/ej2-base';


declare let require: Function;
loadCldr(
  require('../../../../../node_modules/cldr-data/supplemental/numberingSystems.json'),
  require('../../../../../node_modules/cldr-data/main/vi/ca-gregorian.json'),
  require('../../../../../node_modules/cldr-data/main/vi/currencies.json'),
  require('../../../../../node_modules/cldr-data/main/vi/numbers.json'),
  require('../../../../../node_modules/cldr-data/main/vi/timeZoneNames.json')
);

L10n.load({
  "vi": {
  
    'filemanager': {
      "NewFolder": "Thêm thư mục",
      "Upload": "Tải",
      "Delete": "Xóa",
      "Rename": "Đổi tên",
      "Download": "Download",
      "Cut": "Cut",
      "Copy": "Sao chép",
      "Paste": "Dán",
      "SortBy": "Sắp xếp",
      "Refresh": "Làm mới",
      "Item-Selection": "Chọn đối tượng",
      "Items-Selection": "Chọn nhiều đối tượng",
      "View": "Thẻ",
      "Details": "Chi tiết",
      "SelectAll": "Chọn tất",
      "Open": "Mở",
      "Tooltip-NewFolder": "Tạo thư mục",
      "Tooltip-Upload": "Tải lên tệp",
      "Tooltip-Delete": "Xóa",
      "Tooltip-Rename": "Đổi tên",
      "Tooltip-Download": "Tải xuống",
      "Tooltip-Cut": "Cut",
      "Tooltip-Copy": "Sao chép",
      "Tooltip-Paste": "Dán",
      "Tooltip-SortBy": "Sắp xếp",
      "Tooltip-Refresh": "Làm mới",
      "Tooltip-Selection": "Chọn",
      "Tooltip-View": "Hiển thị thẻ",
      "Tooltip-Details": "Hiển thị chi tiết",
      "Tooltip-SelectAll": "Chọn tất cả",
      "Name": "Tên",
      "Size": "Dung lượng",
      "DateModified": "Ngày thay đổi",
      "DateCreated": "Ngày tạp",
      "Path": "Đường dẫn",
      "Modified": "Modified",
      "Created": "Created",
      "Location": "Vị trí",
      "Type": "Kiểu",
      "Permission": "Quyền",
      "Ascending": "Tăng dần",
      "Descending": "Giảm dần",
      "None": "None",
      "View-LargeIcons": "Hiển thị lớn",
      "View-Details": "Hiển thị chi tiết",
      "Search": "Tìm kiếm",
      "Button-Ok": "Đồng ý",
      "Button-Cancel": "Hủy",
      "Button-Yes": "Đồng ý",
      "Button-No": "Không",
      "Button-Create": "Tạo",
      "Button-Save": "Lưu",
      "Header-NewFolder": "Thư mục",
      "Content-NewFolder": "Nhập tên thư mục",
      "Header-Rename": "Đổi ten",
      "Content-Rename": "Nhập tên mới",
      "Header-Rename-Confirmation": "Xác nhận đổi tên",
      "Content-Rename-Confirmation": "Nếu bạn thay đổi phần mở rộng của tệp",
      "Header-Delete": "Xóa tệp",
      "Content-Delete": "Bạn có chắc xóa tệp này",
      "Header-Multiple-Delete": "Xóa nhiều tệp",
      "Content-Multiple-Delete": "Bạn có chắc chắn xóa những tệp này {0} ?",
      "Header-Folder-Delete": "Xóa thư mục",
      "Content-Folder-Delete": "Bạn chắc chắn muốn xóa thư mục này?",
      "Header-Duplicate": "Tệp đã tồn tại",
      "Content-Duplicate": "{0} Tập đã tồn tại bạn có muốn ghi đè không?",
      "Header-Upload": "Tải tệp",
      "Error": "Lỗi",
      "Validation-Empty": "Tệp hoặc thư mục trống",
      "Validation-Invalid": "Tệp hoặc thư mục {0} có chứa ký tự không hợp lệ!",
      "Validation-NewFolder-Exists": "Tệp hoặc thư mục tên {0} đã tồn tại.",
      "Validation-Rename-Exists": "Không thể đổi tên {0} thành {1}",
      "Folder-Empty": "Thư mục trống",
      "File-Upload": "Kéo tệp vào để tải lên",
      "Search-Empty": "Không có kết quả hợp lệ",
      "Search-Key": "Hãy thử với từ khóa khác",
      "Filter-Empty": "Không có kết quả",
      "Filter-Key": "Hãy thử lọc với từ khóa khác",
      "Sub-Folder-Error": "Lỗi thư mục chuyến đến là tư mục con của nó.",
      "Same-Folder-Error": "Thư mục chuyển đến cùng tên.",
      "Access-Denied": "Bạn không có quyền",
      "Access-Details": "Bạn không có quyền truy cập",
      "Header-Retry": "Tệp đã tồn tại",
      "Content-Retry": "Tệp đã tồn tại trong thư mục. Bạn muốn làm gì?",
      "Button-Keep-Both": "Giữ cả 2",
      "Button-Replace": "Thay thế",
      "Button-Skip": "Bỏ qua",
      "ApplyAll-Label": "Áp dụng cho tất cả",
      "KB": "KB",
      "Access-Message": "{0} không có quyền. Bạn cần có quyền {1} để thực hiện hành động này.",
      "Network-Error": "Lỗi mạng: Failed to send on XMLHTTPRequest: Failed to load",
      "Server-Error": "Lỗi máy chủ: Invalid response from"
    }

  }
});

@Component({
    selector: 'app-quantri-filemanager',
  templateUrl: './filemanager.component.html',

  providers: [ListService, Repository,
    NavigationPaneService, ToolbarService, DetailsViewService
  ]
})


export class filemanagerComponent implements OnInit {

  @Input() DeNghi: number;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileManager')
  public fileManagerInstance: FileManager;

  public ajaxSettings: object;
  public view: string;
  public hostUrl: string = '/';
  public codeClass: string = null;

 
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository) {


   
   
  }  

  public ngOnInit(): void {
  
    this.view = "Details";

    //interval(2000).pipe(take(1))
    //  .subscribe(() => {
    //    // place you code here
    //    this.fileManagerInstance.refreshLayout();
    //   this.fileManagerInstance.disableToolbarItems(["newfolder"]);
    //  });
  }
  currentUser: any;
  loaiuser: number
  loadDataInit(Id: any, codeClass,cr) {
    this.codeClass = codeClass;

    //------------------quyen--------------------
    this.currentUser = cr;
    if (this.currentUser) {
      this.loaiuser = this.currentUser.loai;     
    }   
   
    //--------------------------------------------

    this.ajaxSettings = {
      url: this.hostUrl + 'FileManager/FileOperations',
      getImageUrl: this.hostUrl + 'FileManager/GetImage',
      uploadUrl: this.hostUrl + 'FileManager/Upload',
      downloadUrl: this.hostUrl + 'FileManager/Download'
    };
    if (this.loaiuser == 3) {
      console.log(this.loaiuser);
      setTimeout(() => {        
        this.fileManagerInstance.disableToolbarItems(["newfolder", "Upload", "Paste", "Delete", "Rename", "Cut"]);        
      }, 1000);
    }
  }
  ondisable() {
    this.fileManagerInstance.disableToolbarItems(["newfolder"]);
    this.fileManagerInstance.refreshLayout();
  }
  onmenuOpen(args) {
    if (this.loaiuser == 3) 
    this.fileManagerInstance.disableMenuItems(["newfolder", "Upload", "Paste", "Delete", "Rename", "Cut"]);
  }
  OnbeforeSend(args) {
  
    var data = JSON.parse(args.ajaxSettings.data);    
    if (args.action == 'Upload')
    {
      //data[0]["CodeClass"] = this.codeClass;
      //data[0]["Loai"] = 1;
      data.push({ codeclass: this.codeClass });
      data.push({ loai: 1 });
      args.ajaxSettings.data = JSON.stringify(data);     
    }
    else {      
      data["CodeClass"] = this.codeClass;
      data["Loai"] = 1;     
      args.ajaxSettings.data = JSON.stringify(data);      
    }
 
  }; 
  savedsdoituong(doituong1: any) {
  }
}
