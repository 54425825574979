import { NgModule, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationService } from '../services/authentication.service'
import { ListService } from '../services/list.service';
import { Repository } from '../services/repository'
import { menu } from './menu';
import { routes } from './routes';




@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  declarations: [

  ],
  exports: [
    RouterModule,

  ],
  providers: [AuthenticationService, ListService, Repository]
})

export class RoutesModule {

  public access: boolean = true;
  public rows: Array<any> = menu;
  public danhmuc: any;
  public datas: Array<any>;
  constructor(public menuService: MenuService, tr: TranslatorService, private repo: Repository) {
    var currentUser1 = localStorage.getItem('currentUser');

     if (currentUser1) {
      let data = JSON.parse(currentUser1);

      this.datas = data.ds_quyen;
     

      // tạm bỏ đi để tạo file demo html
       this.rows.forEach((item: any) => {
         if (item.submenu != null) {
           var concap1 = false;
           item.submenu.forEach((item1: any) => {
             var concap2 = false;
             if (item1.submenu == null) {// kiểm tra xem có câp 3 ko
               var access1 = false;
               var giatri = data.ds_quyen.filter(name => name.Id === item1.id.toLowerCase());
               console.log("t211");
               if (giatri != null && giatri.length > 0 && giatri[0].Xem == true) {
                 access1 = true;
                 concap1 = true;
                 concap2 = true;
               }
               if (access1 == false && item1.id != '') {
                 if (item.submenu.indexOf(item1) != -1) {
                   item1.text = null;
                   // item.submenu.splice(item.submenu.indexOf(item1), 1); //  loai bo chuc nang nay khong co quyen truy cap
                 }
               }
             }
             else {//me nu có câp 3

               item1.submenu.forEach((item2: any) => {
                 var access1 = false;
                 var giatri = data.ds_quyen.filter(name => name.Id === item2.id.toLowerCase());
                 if (giatri != null && giatri.length > 0 && giatri[0].Xem == true) {
                   access1 = true;
                   concap1 = true;
                   concap2 = true;
                 }
                 if (access1 == false && item2.id != '') {
                   if (item1.submenu.indexOf(item2) != -1) {
                     item2.text = null;
                   }
                 }
               });
                
             }
             if (concap2 == false)
               item1.text = null;

           });//for cấp 2
           if (concap1 == false)
             item.text = null;
         }

       });
      menuService.addMenu(this.rows);
    }
    

  }
}


export interface IRoleRight {
  Id: string,
  RoleId: string,
  FunctionId: string,
  AccessRight: boolean

}

export interface IAuthen {
  FunctionRight: string,
  Token: string,
  Access: boolean

}
