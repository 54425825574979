import { Component, ViewChild, OnInit, Output, Input, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { ListService } from '../../../services/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Global, BaoCao } from '../../../shared/global';
import { Repository } from '../../../services/repository'
import { NgSelectComponent } from '@ng-select/ng-select';
import { LocalDataSource } from 'ng2-smart-table';
import { NumberViewComponent } from '../../../shared/components/public/numberview.component';
import { DateViewComponent } from '../../../shared/components/public/dateview.component';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { BsModalComponent, BsModalBodyComponent, BsModalService } from 'ng2-bs3-modal';
import { DialogComponent, ButtonPropsModel } from '@syncfusion/ej2-angular-popups';
import { SignalrService } from '../../../services/SignalrService'

import 'jquery'
import 'bootstrap'
import { Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';
import {
  Lists_vattu, Lists_xe, Lists_hanghoa, Lists_khohang
  , Lists_nhacungcap, Lists_phongban, Lists_hopdongmua
  , Lists_hopdongban, Lists_nghenghiep, Lists_hangxe, Lists_nhomkhachhang
  , Lists_linhvuc, Lists_modelxe, Lists_nguonkhach, Lists_kieubanle, Lists_loaiquanhe
  , Lists_khachhang, Lists_nhanvien
  , Lists_congcu, Lists_taisancodinh, Lists_chiphi
  , Lists_tiente, Lists_xuatxu, Lists_tinh, Lists_mauson, Lists_giaodich
  , Lists_keuoc, Lists_loaitaisan, Lists_taikhoan, Lists_khoanmuc
  , Lists_nguonvon, Lists_donvitinh, Lists_madonvi
  , Lists_loaisuachua, Lists_dichvu, Lists_xesua, Lists_nganhang

} from '../../../routes/menu';
const swal = require('sweetalert');




@Component({
  selector: 'app-ketoan-hoadonvat_misaedit',
  templateUrl: './hoadonvat_misaedit.component.html',
  //templateUrl: 'ClientApp/routes/DanhMuc/data/data.component.html',
  styleUrls: ['./hoadonvat_misaedit.component.scss'],
  providers: [ListService, Repository, SignalrService]
})


export class hoadonvat_misaeditComponent implements OnInit {

  XoaCT: boolean = true;
  TuThang: number = 1;
  DenThang: number = 1;

  @ViewChild('mdLuaChon_doituong') mdLuaChon_doituong: BsModalComponent;
  @ViewChild('mdDoiTuongChung') mdDoiTuongChung: BsModalComponent;



  doituongkethua: any;
  doanhnghiepid: string = null;
  @Output() savect: EventEmitter<any> = new EventEmitter();
  datas: any;
  data: any;
  public dulieu: any = { dataGrid: null, dataGridTimKiem: null, datatieude: null, sobanghi: null };
  indLoading: boolean = false;
  dataFrm: FormGroup;
  modalTitle: string;
  modalBtnTitle: string;
  Id: string;
  NgayHienTai: Date = new Date();
  inputDonVi: any;
  public DonViDropId: any = { Id: null, Ten: null };
  public TaiKhoanDropId: any = { Id: null, Ten: null };
  TaiKhoanId: any;
  selecteddonvis: any;
  selectedchuongs: any;
  selectedkhoans: any;
  Ma_Tai_Khoan: any;
  dropdownList_DonVi = [];
  inputTaiKhoan: any;
  dropdownList_TaiKhoan = [];
  selectedItems = [];
  dropdownSettings = {};
  khoangthoigian: number = 0;
  tabselected: number = 1;

  public thangs: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
    { text: "11", value: 11 },
    { text: "12", value: 12 }
  ];

  public quys: Array<{ text: string, value: number }> = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },

  ];

  public nams: Array<{ text: string, value: number }> = [
    { text: (this.NgayHienTai.getFullYear() - 3).toString(), value: (this.NgayHienTai.getFullYear() - 3) },
    { text: (this.NgayHienTai.getFullYear() - 2).toString(), value: (this.NgayHienTai.getFullYear() - 2) },
    { text: (this.NgayHienTai.getFullYear() - 1).toString(), value: (this.NgayHienTai.getFullYear() - 1) },
    { text: this.NgayHienTai.getFullYear().toString(), value: this.NgayHienTai.getFullYear() },
    { text: (this.NgayHienTai.getFullYear() + 1).toString(), value: (this.NgayHienTai.getFullYear() + 1) },
    { text: (this.NgayHienTai.getFullYear() + 2).toString(), value: (this.NgayHienTai.getFullYear() + 2) },
    { text: (this.NgayHienTai.getFullYear() + 3).toString(), value: (this.NgayHienTai.getFullYear() + 3) },

  ];
  inputkhachhangs: any;
  inputhinhthucthanhtoans: any = [
    { Ten_Doi_Tuong: "Tiền mặt", Ma_Doi_Tuong: "Tiền mặt", Id: 1 },
    { Ten_Doi_Tuong: "Chuyển khoản", Ma_Doi_Tuong: "CK", Id: 2 },
    { Ten_Doi_Tuong: "TM/CK", Ma_Doi_Tuong: "TM/CK", Id: 3 },
    
  ];

  public chons: Array<{ text: string, value: number }> = [
    { text: "Theo ngày", value: 1 },
    { text: "Theo tháng", value: 2 },
    { text: "Theo quý", value: 3 },
    { text: "Theo năm", value: 4 },

  ];
  chongioitinhs: any = [{ Id: 0, Ma_Doi_Tuong: "0", Ten_Doi_Tuong: "Nữ" }
    , { Id: 1, Ma_Doi_Tuong: "1", Ten_Doi_Tuong: "Nam" }];
  TatCa: boolean = false;
  public totalItems: number = 0;
  public currentPage: number = 1;
  public maxSize: number = 5;
  public TimKiem: string;
  public StartPage: number = 1;
  public EndPage: number = 20;
  public pageSize: number = 20;
  public pages: any;
  public totalPages: number;
  public TimKiems: any = {
    Id: null
    , DoanhNghiepId: null
    , UserName: null
    , PhanHeId: null
    , DonViId: null
    , Nam: null
    , Loai: null
    , TuNgay: null
    , DenNgay: null
    , Ngay_Ghi_So: null
    , NgayIn: null
    , TrangThai: null
    , ChungTuId: null
    , SoHD: null
    , SoHD_Cap: null
    , HinhThucThanhToan: 'TM/CK'
    , LoaiHoaDon: null
    , GhiChu: null
    , Email: null
    , TenNguoiNhan: null
    , LinkLocal: null
    , Link: null
    , Link1: null
    , Link2: null
    , Link3: null
    , UngDung_TenDangNhap: null
    , UngDung_MatKhau: null
    , Service_TenDangNhap: null
    , Service_MatKhau: null
    , Service_TenMayChu: null
    , Service_Cong: null
    , SerialCert: null
    , MST: null
    , MauSo: null
    , KyHieu: null
    , DoiTuongId: null
    , PS5111: null
    , PS5113: null
    , PSNO138: null
    , Ten_Don_Vi: null
    , Dia_Chi: null
    , Ma_So_Thue: null

  };
  public ngaytimkiem: Date = new Date();
  TichTatCa() {
    this.datas.forEach((item: any) => {
      item.Tich = !this.TatCa;
    });
  }
  onKeydownEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  public TuNgay: Date = new Date();
  public DenNgay: Date = new Date();
  public TuNgay1: Date = new Date();
  public DenNgay1: Date = new Date();
  public tTuNgay: string;
  public tDenNgay: string;




  idFocus: string = '00000000-0000-0000-0000-000000000000';

  public ngxCurrencyOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: false
  };
  public ngxAmOptions = {
    prefix: '',
    thousands: ',',
    decimal: '.',
    precision: 0,
    allowNegative: true
  };

  public currentUser;
  donviid: string = null;
  namketoan: number;
  username: string = null;
  phanheid: any;
  //-----------------cac hàm khởi tạo dữ liệu ban đầu
  constructor(private fb: FormBuilder, private _dataService: ListService, private repo: Repository, private _signalRService: SignalrService) {


    this._signalRService.send_signatureReceived_misa.subscribe((data: any) => {
      //console.log("send_signatureReceived - taitai");
      //console.log(signature);
      $('#loading').hide();
      console.log("misa - taitai");
      console.log(data);

      if (data.TrangThai != null) {
        // cập nhật thông tin hóa đơn
        if (data.SoHD != null) {
          data.KyHieu = this.TimKiems.KyHieu;
          data.UserName = this.TimKiems.UserName;
          data.PS5111 = this.TimKiems.PS5111;
          data.PSNO138 = this.TimKiems.PSNO138;
          this.repo.postRequest('api/chungtu/luuthongtinhoadon_misa', data)
            .subscribe(response => {
              if (response != null && response.TrangThai2 != null)
                swal('Cảnh báo!', response.TrangThai2, 'warning');

            });
        }
        swal('Cảnh báo!', data.TrangThai, 'warning');
        
      }
      else {
        // cập nhật thông tin hóa đơn
        if (data.SoHD != null) {
          data.KyHieu = this.TimKiems.KyHieu;
          data.UserName = this.TimKiems.UserName;
          data.PS5111 = this.TimKiems.PS5111;
          data.PSNO138 = this.TimKiems.PSNO138;
          this.repo.postRequest('api/chungtu/luuthongtinhoadon_misa', data)
            .subscribe(response => {
              if (response != null && response.TrangThai2 != null)
                swal('Cảnh báo!', response.TrangThai2, 'warning');
              
            });
        }
      }


    });

    this._signalRService.send_error_webReceived.subscribe((cer: any) => {
      //console.log("send_error_webReceived - taitai");
      //console.log(cer);
      $('#loading').hide();
      if (cer.TrangThai.toLowerCase().indexOf('đã phát hành thành công') !== -1 || cer.TrangThai.toLowerCase().indexOf('hóa đơn đã phát hành') !== -1) {
        // cập nhật thông tin hóa đơn
        if (cer.SoHD != null) {
          cer.KyHieu = this.TimKiems.KyHieu;
          cer.UserName = this.TimKiems.UserName;
          cer.PS5111 = this.TimKiems.PS5111;
          cer.PSNO138 = this.TimKiems.PSNO138;
          this.repo.postRequest('api/chungtu/luuthongtinhoadon_misa', cer)
            .subscribe(response => {
              if (response != null && response.TrangThai2 != null)
                swal('Cảnh báo!', response.TrangThai2, 'warning');

            });
        }
      }
      swal('Cảnh báo!', cer.TrangThai, 'warning');

      

    });



  }

  // @HostListener('document:keydown', ['$event'])
  public innerHeight: any
  quyen_them: boolean = false;
  loadDataInit(pheid: string, idchungtu: string) {

    this.ngaytimkiem = null;
    this.StartPage = 1;
    this.EndPage = 20;
    this.ngaytimkiem = null;
    this.innerHeight = window.innerHeight - 250;
    this.phanheid = pheid;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.donviid = this.currentUser.id_don_vi;
      this.namketoan = this.currentUser.nam_ke_toan;
      this.username = this.currentUser.user_name;
      var giatri = this.currentUser.ds_quyen.filter(name => name.Id === this.phanheid.toLowerCase());
      if (giatri != null && giatri.length > 0) {
        if (giatri[0].Them == true)
          this.quyen_them = true;
        else
          this.quyen_them = false;

      }

      this.datas = [];
      this.doanhnghiepid = this.currentUser.id_doanh_nghiep;
      let tdate = new Date();
      this.TimKiems.TuNgay = new Date(this.namketoan, tdate.getMonth(), 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay = new Date(this.namketoan, tdate.getMonth() + 1, 1, 0, 0, 0, 0);
      this.TimKiems.DenNgay.setDate(this.TimKiems.DenNgay.getDate() - 1);


      // lấy thong tin đối tượng
      this.repo.postRequest('api/chungtu/dsdoituong', { Id: idchungtu, DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          this.inputkhachhangs = response;
        });

      this.repo.postRequest('api/chungtu/hoadonvat_misa_thongtin', { Id: idchungtu, DonViId: this.donviid, NamKeToan: this.namketoan, UserName: this.username, DoanhNghiepId: this.doanhnghiepid })
        .subscribe(response => {
          this.TimKiems = response;
          this.TimKiems.HinhThucThanhToan = 'TM/CK';
          this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
          this.TimKiems.DonViId = this.donviid;
          this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
          this.TimKiems.UserName = this.currentUser.user_name;
          this.TimKiems.PhanHeId = this.phanheid;
          this.TimKiems.Nam = this.currentUser.nam_ke_toan;

          if (response.NgayIn != null)
            this.TimKiems.NgayIn = new Date(response.NgayIn);
          if (response.Ngay_Ghi_So != null)
            this.TimKiems.Ngay_Ghi_So = new Date(response.Ngay_Ghi_So);

        });



    }
    this.search();


  }
  adddata2() {
    this.modalTitle = "Chi tiết cấu hình";
    this.modalBtnTitle = "Cập nhật";

    if (this.TimKiems.DoiTuongId == null) {
      swal('Lỗi!', 'Hóa đơn của không được trống!.', 'error');
      return;
    }


    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.DonViId = this.donviid;
    this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.PhanHeId = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;



    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    this._signalRService.startConnection();
    $('#loading').show();
    //console.log("request_sign_tt");
    //console.log(this.TimKiems);
    this._signalRService.proxy.invoke("request_preview_web_misa", 'eDMS2020', this.TimKiems);


  }
  adddata() {
    this.modalTitle = "Chi tiết cấu hình";
    this.modalBtnTitle = "Cập nhật";

    if (this.TimKiems.DoiTuongId == null) {
      swal('Lỗi!', 'Hóa đơn của không được trống!.', 'error');
      return;
    }


    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.DonViId = this.donviid;
    this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.PhanHeId = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;



    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };
    this._signalRService.startConnection();
    $('#loading').show();
    //console.log("request_sign_tt");
    //console.log(this.TimKiems);
    this._signalRService.proxy.invoke("request_print_web_misa", 'eDMS2020', this.TimKiems);


  }

  valuechange() {
    //this.TimKiems.HinhThucThanhToan = 'TM/CK';
    this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
    this.TimKiems.DonViId = this.donviid;
    this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.PhanHeId = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;
    this.repo.postRequest('api/chungtu/hoadonvat_misa_luu', this.TimKiems)
      .subscribe(response => {
        this.TimKiems = response;
       // this.TimKiems.HinhThucThanhToan = 'TM/CK';
        this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
        this.TimKiems.DonViId = this.donviid;
        this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
        this.TimKiems.UserName = this.currentUser.user_name;
        this.TimKiems.PhanHeId = this.phanheid;
        this.TimKiems.Nam = this.currentUser.nam_ke_toan;
      });
  }

  editdata(id: string) {
    this.modalTitle = "Chi tiết cấu hình";
    this.modalBtnTitle = "Cập nhật";

  }
  saveModal(id: any) {
    //this.Loaddatas();

  }



  search() {

  }

  savebg() {

    if (this.TimKiems.DoiTuongId == null) {
      swal('Lỗi!', 'Hóa đơn của không được trống!.', 'error');
      return;
    }


    this.StartPage = (this.currentPage - 1) * this.pageSize + 1;
    this.EndPage = this.currentPage * this.pageSize;
    this.totalItems = 0;
    this.TimKiems.DonViId = this.donviid;
    this.TimKiems.DoanhNghiepId = this.doanhnghiepid;
    this.TimKiems.UserName = this.currentUser.user_name;
    this.TimKiems.PhanHeId = this.phanheid;
    this.TimKiems.Nam = this.currentUser.nam_ke_toan;



    let data = {
      Id: null,
      Type: null,
      Data: JSON.stringify(this.TimKiems)
    };

    swal({
      title: 'Bạn có muốn phát hành hóa đơn không?',
      text: 'Thông báo!',
      icon: "warning",
      buttons: {

        confirm: {
          text: 'Đồng ý',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        },
        cancel: {
          text: 'Hủy bỏ',
          value: false,
          visible: true,
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {

          this._signalRService.startConnection();
          $('#loading').show();
          this._signalRService.proxy.invoke("request_sign_web_misa", 'eDMS2020', this.TimKiems);

          //this._signalRService.proxy.invoke("request_sign_web_misa", 'eDMS2020', this.TimKiems.LinkLocal, this.TimKiems.DoiTuongId, this.TimKiems.ChungTuId, this.TimKiems.DoanhNghiepId);
          //this._signalRService.proxy.invoke("request_sign_web_misa", 'eDMS2020', "http://localhost:57678/api/", this.TimKiems.DoiTuongId, this.TimKiems.ChungTuId, this.TimKiems.DoanhNghiepId);



          //$('#loading').show();
          //this.repo.postRequest("api/chungtu/luuketchuyen/", this.TimKiems)
          //  .subscribe(response => {
          //    $('#loading').hide();
          //    if (response.TrangThai == "OK") {
          //      swal('Thông báo!', "Kết chuyển thành công!", 'success');
          //    }
          //    else
          //      swal('Cảnh báo!', response.TrangThai, 'warning');
          //  });

        }
      });



  }


  ngOnInit(): void {
    var crru = JSON.parse(localStorage.getItem('currentUser'));
    if (crru != null) {
      this.donviid = crru.id_don_vi;
      this.namketoan = crru.nam_ke_toan;
      this.username = crru.user_name;
    }
    this.LoadCategory();
  }

  LoadCategory(): void {

  }
  //----------event xử lý di chuyển Enter--------------------------------------
  tongtien: number = 0;
  maxcot: number = 13;



  comboSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Doi_Tuong.toLowerCase().indexOf(term) > -1 || item.Ten_Doi_Tuong.toLowerCase().indexOf(term) > -1;
  }
  comboSearchFn_DV(term: string, item: any) {
    term = term.toLowerCase();
    return item.Ma_Don_Vi.toLowerCase().indexOf(term) > -1 || item.Ten_Don_Vi.toLowerCase().indexOf(term) > -1;
  }
  rowSelected: any = null;
  comboSeleted: NgSelectComponent;

  public save_chondoituong2(item: any) {
    let tdoituong = [];
    tdoituong.push(item);
    this.savect.emit(tdoituong);
  }
  public save_chondoituong() {
    let tdoituong = [];
    this.savect.emit(tdoituong);

    //this.datas.forEach(
    //  (item) => {
    //    if (item.Tich === true)
    //      tdoituong.push(item);
    //  }
    //);
    //if (tdoituong != null && tdoituong.length > 0)
    //  this.savect.emit(tdoituong);
    //else
    //  this.savect.emit(null);

  }
  closeModal_chondoituong() {
    let tdoituong = null;
    this.savect.emit(tdoituong);
  }
  nhomdoituongid: string;
  onLoaiNguoiNop(row: any) {
    this.nhomdoituongid = row.Id;
    //console.log(loai);
  }





}
