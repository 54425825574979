
<div class="row ng-scope">
  <div class="col-12">
    <div class="row form-group">
      <label class="control-label col-2">Ngày in HĐ</label>
      <div class="col-4">
        <kendo-dateinput tabindex="1" style="width:100%;" [format]="'dd/MM/yyyy'" [(value)]="TimKiems.NgayIn"></kendo-dateinput>
      </div>
      <label class="control-label col-2">Số hóa đơn</label>
      <div class="col-4">
        <input type="text" tabIndex="2" class="form-control" placeholder="" [(ngModel)]="TimKiems.SoHD">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Hình thức TT</label>
      <div class="col-4">
        <ng-select #slLoaiBaoGia
                   labelForId="99"
                   class="twcol"
                   [tabIndex]="3"
                   [openOnEnter]="false"
                   [items]="inputhinhthucthanhtoans"
                   bindValue="Ma_Doi_Tuong"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [(ngModel)]="TimKiems.HinhThucThanhToan">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <label class="control-label col-2">Hóa đơn của</label>
      <div class="col-4">
        <ng-select #slKhachHang
                   labelForId="99"
                   class="twcol"
                   autofocus
                   [tabIndex]="4"
                   [openOnEnter]="false"
                   [items]="inputkhachhangs"
                   bindValue="Id"
                   bindLabel="Ten_Doi_Tuong"
                   notFoundText="Không có dữ liệu"
                   placeholder=""
                   [searchFn]="comboSearchFn"
                   [(ngModel)]="TimKiems.DoiTuongId">
          <ng-template ng-option-tmp let-item="item">
            <div class="rTableRow">
              <div class="rTableCell rTableCell-1">{{item.Ma_Doi_Tuong}}</div>
              <div class="rTableCell rTableCell-2">{{item.Ten_Doi_Tuong}}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Ghi chú</label>
      <div class="col-10">
        <textarea class="form-control" [tabIndex]="5" rows="2" [(ngModel)]="TimKiems.GhiChu" placeholder=""></textarea>
      </div>
    </div>

    <div class="row" style="font-weight:bold">
      <div class="col-12">Thông tin ứng dụng phát hành</div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">Email nhận HĐ</label>
      <div class="col-4">
        <input type="text" tabIndex="6" class="form-control" placeholder="" [(ngModel)]="TimKiems.Email" (change)="valuechange()">
      </div>
      <label class="control-label col-2">Link ứng dụng</label>
      <div class="col-4">
        <input type="text" tabIndex="7" class="form-control" placeholder="" [(ngModel)]="TimKiems.Link" (change)="valuechange()">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Tên đăng nhập</label>
      <div class="col-4">
        <input type="text" tabIndex="8" class="form-control" placeholder="" [(ngModel)]="TimKiems.UngDung_TenDangNhap" (change)="valuechange()">
      </div>
      <label class="control-label col-2">Mật khẩu</label>
      <div class="col-4">
        <input type="text" tabIndex="9" class="form-control" placeholder="" [(ngModel)]="TimKiems.UngDung_MatKhau" (change)="valuechange()">
      </div>
    </div>


    <div class="row" style="font-weight:bold">
      <div class="col-12">Thông tin Webservice</div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">Public service</label>
      <div class="col-10">
        <input type="text" tabIndex="10" class="form-control" placeholder="" [(ngModel)]="TimKiems.Link1" (change)="valuechange()">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Business service</label>
      <div class="col-10">
        <input type="text" tabIndex="11" class="form-control" placeholder="" [(ngModel)]="TimKiems.Link2" (change)="valuechange()">
      </div>
    </div>
    <div class="row form-group">
      <label class="control-label col-2">Portal service</label>
      <div class="col-10">
        <input type="text" tabIndex="12" class="form-control" placeholder="" [(ngModel)]="TimKiems.Link3" (change)="valuechange()">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">SerialCert</label>
      <div class="col-4">
        <input type="text" tabIndex="13" class="form-control" placeholder="" [(ngModel)]="TimKiems.SerialCert" (change)="valuechange()">
      </div>
      <label class="control-label col-2">Mã số thuế</label>
      <div class="col-4">
        <input type="text" tabIndex="14" class="form-control" placeholder="" [(ngModel)]="TimKiems.MST" (change)="valuechange()">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">Mẫu hóa đơn</label>
      <div class="col-4">
        <input type="text" tabIndex="15" class="form-control" placeholder="" [(ngModel)]="TimKiems.MauSo" (change)="valuechange()">
      </div>
      <label class="control-label col-2">Ký hiệu</label>
      <div class="col-4">
        <input type="text" tabIndex="16" class="form-control" placeholder="" [(ngModel)]="TimKiems.KyHieu" (change)="valuechange()">
      </div>
    </div>

    <div class="row form-group">
      <label class="control-label col-2">Tên đăng nhập</label>
      <div class="col-4">
        <input type="text" tabIndex="17" class="form-control" placeholder="" [(ngModel)]="TimKiems.Service_TenDangNhap" (change)="valuechange()">
      </div>
      <label class="control-label col-2">Mật khẩu</label>
      <div class="col-4">
        <input type="text" tabIndex="18" class="form-control" placeholder="" [(ngModel)]="TimKiems.Service_MatKhau" (change)="valuechange()">
      </div>
    </div>



  </div>

</div>




<div class="modal-footer" style="display: flex;justify-content: space-between;">
  <div class="checkbox c-checkbox">
    <label>
      <!--<input tabIndex="5" type="checkbox" [(ngModel)]="doituongSelect.Trang_Thai" />
      <span class="fa fa-check"></span>{{hoatdong}}-->
    </label>
  </div>
  <div class="">
    <a *ngIf="TimKiems.SoHD!==null" style="margin-right:20px;color:blue;font-weight:bold">Hóa đơn đã phát hành</a>
    <a *ngIf="TimKiems.SoHD==null" style="margin-right:20px;color:red;font-weight:bold">Hóa đơn chưa phát hành</a>
    <a href="/Import/HeThong/chuongtrinhkyso.rar" style="margin-right:20px;color:red;font-weight:bold">Tải chương trình ký số</a>

    <button class="btn btn-success" (click)="savebg()" style="margin-left:3px; margin-right:3px">Phát hành và in hóa đơn</button>
    <button class="btn btn-primary" (click)="adddata()" style="margin-left:3px; margin-right:3px">In hóa đơn</button>
    <button class="mb-1 mr-1 btn btn-secondary" (click)="closeModal_chondoituong()" title="">Hủy bỏ</button>
  </div>

</div>








