import { Injectable, EventEmitter } from '@angular/core';
import { hubConnection } from 'signalr-no-jquery';
//import 'jquery'
import * as jQuery from 'jquery';

@Injectable()
export class SignalrService {

  //https://tedu.com.vn/lap-trinh-angular-2-can-ban/huong-dan-tich-hop-angular-2-cli-voi-signalr-de-hien-thi-thong-bao-realtime-92.html

  // các thư viện
  // node_modules\@types\signalr-no-jquery
  // node_modules\signalr-no-jquery
  // node_modules\signalr
  // node_modules\jquery-deferred
  // node_modules\jquery-param


  // Declare the variables  
  public proxy: any;
  public proxyName: string = 'ServiceSign';
  public connection: any;
  // create the Event Emitter  
  public cerReceived: EventEmitter<any>;
  public send_signatureReceived: EventEmitter<any>;
  public send_error_webReceived: EventEmitter<any>;

  public send_signatureReceived_vpnt: EventEmitter<any>;
  //public send_printReceived_vpnt: EventEmitter<any>;
  public send_signatureReceived_misa: EventEmitter<any>;

  public connectionEstablished: EventEmitter<Boolean>;
  public connectionExists: Boolean;

  constructor() {
    // Constructor initialization  
    this.connectionEstablished = new EventEmitter<Boolean>();
    this.cerReceived = new EventEmitter<any>();
    this.send_signatureReceived = new EventEmitter<any>();
    this.send_error_webReceived = new EventEmitter<any>();
    this.send_signatureReceived_vpnt = new EventEmitter<any>();
    //this.send_printReceived_vpnt = new EventEmitter<any>();

    this.send_signatureReceived_misa = new EventEmitter<any>();



    this.connectionExists = false;
    // create hub connection   
    this.connection = hubConnection('http://localhost:3868'); //jQuery.hubConnection("http://localhost:3868");
    //this.connection.qs = { "access_token": _authenService.getLoggedInUser().access_token };
    // create new proxy as name already given in top  
    this.proxy = this.connection.createHubProxy(this.proxyName);
    // register on server events  
    this.registerOnServerEvents();
    // call the connecion start method to start the connection to send and receive events.  
    this.startConnection();
  }
  // check in the browser console for either signalr connected or not  
  public startConnection(): void {
    this.connection.start({ withCredentials: false }).done((data: any) => {
      console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
      this.connectionEstablished.emit(true);
      this.connectionExists = true;
    }).fail((error: any) => {
      console.log('Could not connect ' + error);
      this.connectionEstablished.emit(false);
    });
  }

  private registerOnServerEvents(): void {
    this.proxy.on('send_cer_web', (cer: any) => {
      this.cerReceived.emit(cer);
    });

    this.proxy.on('send_signature_web', (data: any) => {
      this.send_signatureReceived.emit(data);
    });

    this.proxy.on('send_error_web', (data: any) => {
      this.send_error_webReceived.emit(data);
    });

    this.proxy.on('send_signature_web_vnpt', (data: any) => {
      console.log('send_signature_web_vnpt connect ttt');
      this.send_signatureReceived_vpnt.emit(data);
    });

    //this.proxy.on('request_print_web_vnpt', (data: any) => {
    //  this.send_printReceived_vpnt.emit(data);
    //});


    this.proxy.on('send_signature_web_misa', (data: any) => {
      console.log('send_signature_web_misa connect ttt');
      this.send_signatureReceived_misa.emit(data);
    });


  }
}
